import React from "react";
import { ResponsiveContainer, Tooltip, PieChart, Pie, Legend, Cell, Label } from "recharts";
import Separator from '@components/Separator';
import styles from './piechart.module.css';


import pageHint from '@images/info.svg';


const COLORS = ["#00BA88", "#669FF6", "#7381EA", "#133454", "#415167", "#A2A1B2", "#A17FEF", "#FF7F50", "#FFD476", "#FF3407"];



function PieChartWidget(props) {

    return (
        <React.Fragment>
            <div className={styles.featuredItem}>
                <span className={styles.featuredTitleHeader}>{props.headerTitle}
                    <span className="pageHint">
                        <img src={pageHint} />
                        <span className="tooltiptext">{props.toolTip}</span>
                    </span>
                </span>

                {/* <span className="pageHint">
                    <span className={styles.featuredTitleYear}>{props.headerTitleYear}
                    </span>
                    <span className="tooltiptext">{props.dateToolTip}</span>
                </span> */}

                <Separator />
                <span className={styles.featuredTitle}>{props.title}</span>
                <br />
                <span className={styles.featuredSub}>{props.subTitle}</span>

                <div className={styles.featuredTextContainer}>
                    <ResponsiveContainer  width="99%"  aspect={1} >
                        <PieChart>
                            <Tooltip />
                            <Legend className={styles.label_text} layout="horizontal" verticalAlign="top" align="left"  />

                            <Pie data={props.data} dataKey={props.dataKey} cx="50%" cy="50%" outerRadius={100} label nameKey={props.dataLabel}
                                legend>

                                {
                                    props.data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>



                        </PieChart>
                    </ResponsiveContainer>
                </div>
            </div>







        </React.Fragment>

    );
}
export default PieChartWidget