import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import DropDownMenu from '@components/DropDownMenu';
import SpinnerModal from '@components/SpinnerModal';
import MUIDataTable from 'mui-datatables';
import ValidationsManager from '@components/ValidationsManager';
import DataCaptureService from '@services/DataCaptureService';
import ProfileManagementService from '@services/ProfileManagementService';
import SurveyService from '@services/SurveyService';
import EditIcon from '@mui/icons-material/Edit';
import Chip from '@material-ui/core/Chip';
import Alert from '@mui/material/Alert';
import styles from './updatesetup.module.css';


class UpdateSetup extends Component {

    state = {
        loading: false,
        organisationsLoading: false,
        selectedSectorId: null,
        surveysUsers: null,
        allSectors: [],
        allOrganisationContacts: [],
        allOrganisationStatuses: [],
    }

    constructor() {
        super();
    }

    async componentDidMount() {
        this.setState({ loading: true });
        const updateFunctionEnabled = process.env.REACT_APP_RESEARCHER_UPDATE_FUNCTION === 'ENABLED';

        if (updateFunctionEnabled) {
            const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
            this.setState({ surveysUsers: surveysUsers });

            const sectors = await SurveyService.getAllSectors();
            sectors.map(item => this.state.allSectors.push({ key: item.id, value: item.name.trim() }));

            const organisationContacts = await DataCaptureService.getAllOrganisationContactsBySurveyCycleId(this.props.surveyCycleId);
            this.setState({ allOrganisationContacts: organisationContacts });
        }
        // Stuff to be loaded goes here

        this.setState({ loading: false });
    }

    onChangeContact = (evt, organisationStateId) => {

        let value = null;

        if (evt.target.value !== undefined && evt.target.value != null && evt.target.value.length > 0) {
            value = evt.target.value
        }

        let organisationStatuses = this.state.allOrganisationStatuses;
        let questionnaireTrackerId = organisationStatuses.filter((organisationStatus) => organisationStatus.organisationStateId == organisationStateId)[0].questionnaireTrackerId;
        if (questionnaireTrackerId !== undefined && questionnaireTrackerId != null) {
            DataCaptureService.setQuestionnaireTrackerFilledInBy(questionnaireTrackerId, value);
            organisationStatuses.filter((organisationStatus) => organisationStatus.organisationStateId == organisationStateId)[0].filledInBy = value;
            this.setState({ allOrganisationStatuses: organisationStatuses });
        }
    }

    onChangeSector = (evt) => {
        this.setState({ selectedSectorId: evt.target.value });
        this.reloadOrganisations(evt.target.value, this.props.surveyCycleId);
    }

    async onStartUpdate(evt, organisationStateId, datacapture1Id, datacapture2Id) {

        if (this.props.onSetupComplete !== undefined && this.props.onSetupComplete != null) {
            const validations = await ValidationsManager.initialiseValidations(organisationStateId);
            this.props.onSetupComplete(evt, this.state.allOrganisationStatuses.filter(org => org.organisationStateId == organisationStateId)[0], 'RESEARCHER_UPDATE', datacapture1Id, datacapture2Id);
        }
    }

    async reloadOrganisations(sectorId, surveyCycleId) {
        this.setState({ organisationsLoading: true });

        try {
            sectorId = (sectorId !== undefined && sectorId != null && sectorId != '') ? sectorId : null;
            this.setState({ selectedSectorId: sectorId });

            const organisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId(sectorId, surveyCycleId);
            this.setState({ allOrganisationStatuses: organisationStatuses.filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true)) });

            this.setState({ organisationsLoading: false });
        } catch (ex) {
            // SupportAlert.displayAlert('Network Error', 'An unexpected network error occured while loading the organisations list. Please try again later.');
        }

        this.setState({ organisationsLoading: false });
    }

    getSurveysUserNameById(userId) {
        let name = (this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].firstname + ' ' + this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].lastname);
        name = userId == 37 ? 'RDI Respondent' : name;
        return name;
    }

    getContactNameByOrganisationAndContactState(organisationStateId, contactStateId) {
        let orgContacts = this.state.allOrganisationContacts.filter(contact => contact.organisationStateId == organisationStateId);

        let contact = (contactStateId != null && orgContacts !== undefined && orgContacts != null && orgContacts.length > 0)
            ? orgContacts.filter(contact => contact.contactStateId == contactStateId)[0]
            : null;

        return contact != null ? (contact.title + ' ' + contact.firstname + ' ' + contact.lastname) : 'No Contact Selected';
    }


    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            let columns = [

                { label: "Organisation Name", name: "organisationName" },
                { label: "Receipt Number", name: "receiptNumber" },

                { label: "Organisation Contact", name: "organisationContact" },

                { label: "1st Capture", name: "capture1" },
                { label: "2nd Capture", name: "capture2" },

                { label: "Update", name: "update" },

            ];

            let tableData = [];

            this.state.allOrganisationStatuses
                .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                .filter(orgStatus => (orgStatus.sectorId == this.state.selectedSectorId
                    && orgStatus.datacapture1Completed == true && orgStatus.datacapture2Completed == true
                ))
                .sort((a, b) => (((a.parentOrganisationStateName !== undefined && a.parentOrganisationStateName !== null && a.parentOrganisationStateName !== '') ? a.parentOrganisationStateName + ' / ' : '') + a.organisationStateName).toUpperCase() > (((b.parentOrganisationStateName !== undefined && b.parentOrganisationStateName !== null && b.parentOrganisationStateName !== '') ? b.parentOrganisationStateName + ' / ' : '') + b.organisationStateName).toUpperCase() ? 1 : -1)
                .map(organisationStatus => {
                    let organisationContactList = this.state.allOrganisationContacts.filter((organisationContact) => organisationContact.organisationStateId == organisationStatus.organisationStateId);

                    let organisationContacts = [];

                    organisationContactList.map(item => {
                        if (!organisationContacts.some(element => element.key === item.contactStateId)) {
                            organisationContacts.push({ key: item.contactStateId, value: this.getContactNameByOrganisationAndContactState(organisationStatus.organisationStateId, item.contactStateId) });
                        }
                    });

                    let thisSurveysUsers = [];

                    this.state.surveysUsers.filter((surveysUser) => surveysUser.active == true).map(item => {
                        thisSurveysUsers.push({ key: item.id, value: this.getSurveysUserNameById(item.id) });
                    });

                    let org_name = ((organisationStatus.parentOrganisationStateName !== undefined && organisationStatus.parentOrganisationStateName !== null && organisationStatus.parentOrganisationStateName !== '') ? organisationStatus.parentOrganisationStateName + ' / ' : '') + organisationStatus.organisationStateName;

                    let row = {
                        organisationName: org_name,
                        receiptNumber: ((organisationStatus.receiptNumber != null) ? organisationStatus.receiptNumber : 'Not received'),
                        organisationContact: <DropDownMenu
                            name={'contact'}
                            label={'Contact'}
                            value={organisationStatus.filledInBy}
                            onChange={(evt) => this.onChangeContact(evt, organisationStatus.organisationStateId)}
                            showKeys={false}
                            menuOptions={organisationContacts.sort((a, b) => a.value > b.value ? 1 : -1)}
                            placeholder='Not Selected'
                            required
                        />,
                        capture1: (organisationStatus.datacapture1Id == null
                            ? ((organisationStatus.fieldworkerId == this.props.capturedById)
                                ? (organisationStatus.filledInBy != null
                                    ? 'Questionnaire not received and checked '
                                    : 'Select contact to start capture'
                                )
                                : 'Not started'
                            )
                            : (organisationStatus.datacapture1Completed
                                ? ('Completed by ' + this.getSurveysUserNameById(organisationStatus.datacapture1CapturedById))
                                : ('Capture in progress by ' + this.getSurveysUserNameById(organisationStatus.datacapture1CapturedById)
                                )
                            )
                        ),
                        capture2: (organisationStatus.datacapture2Id == null
                            ? (organisationStatus.filledInBy != null
                                ? 'Not started'
                                : (organisationStatus.datacapture1Completed
                                    ? 'Select contact to start capture'
                                    : 'Not started'
                                )
                            )
                            : (organisationStatus.datacapture2Completed
                                ? ('Completed by ' + this.getSurveysUserNameById(organisationStatus.datacapture2CapturedById))
                                : ('Capture in progress by ' + this.getSurveysUserNameById(organisationStatus.datacapture2CapturedById))
                            )
                        ),
                        update: (organisationStatus.filledInBy === undefined || organisationStatus.filledInBy == null
                            ? 'Select contact to start update'
                            : (organisationStatus.datacapture1Completed && organisationStatus.datacapture2Completed)
                                ?
                                <Chip variant="outlined" icon={<EditIcon />} label="Update Capture"
                                    onClick={evt => this.onStartUpdate(evt, organisationStatus.organisationStateId, organisationStatus.datacapture1Id, organisationStatus.datacapture2Id)}
                                />
                                : '1st or 2nd Capture not completed'
                        ),
                    }
                    tableData.push(row);
                });

            let options = {
                download: false,
                print: false,
                padding: 'none',
                size: 'small',
                rowsPerPage: [10],
                rowsPerPageOptions: [10, 20, 50, 100],
                jumpToPage: true,
                selectableRowsHideCheckboxes: true,
            };

            const updateFunctionEnabled = process.env.REACT_APP_RESEARCHER_UPDATE_FUNCTION === 'ENABLED';
            const updateDisabledMessage = process.env.REACT_APP_RESEARCHER_UPDATE_FUNCTION_DISABLED_MESSAGE;

            return (
                <div className={styles.updateSetupContainer} >
                    <div className={styles.featuredDropDown}>
                        <span className={styles.featuredTitleHeader}><h2>{'Data Update'}</h2></span>
                        <hr />
                        <Grid container item xs={15} >
                            {updateFunctionEnabled
                                ? <DropDownMenu
                                    name={'sector'}
                                    label={'Sector'}
                                    onChange={(evt) => this.onChangeSector(evt)}
                                    showKeys={false}
                                    menuOptions={this.state.allSectors.sort((a, b) => a.value > b.value ? 1 : -1)}
                                    placeholder='Select Sector'
                                    required
                                />
                                : <Alert variant="outlined" severity="warning">{updateDisabledMessage}</Alert>
                            }
                        </Grid>
                        <br />
                    </div>
                    <div className={styles.featuredItem}>


                        <Grid container item xs={15} >
                            {(this.state.selectedSectorId != null && this.state.organisationsLoading == false)
                                ? <div className={styles.flex_row_container}>
                                    <MUIDataTable
                                        title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{'Organisations'.toUpperCase()}</h1></div>}
                                        data={tableData}
                                        columns={columns}
                                        options={options}
                                    />
                                </div>
                                : (
                                    this.state.organisationsLoading == true
                                        ? <SpinnerModal settings={this.state.organisationsLoading} />
                                        : <div />
                                )
                            }
                        </Grid>
                    </div>
                </div>
            )
        }
    }
}

export default UpdateSetup;