import React, { Component } from 'react';
import axios from "axios";

const PROFILE_MANAGEMENT_SERVICE = process.env.REACT_APP_PROFILE_MANAGEMENT_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + '/profilemanagement';

export default function ProfileManagementService() {
}

export async function getOrganisationStatesBySectorIdAndSurveyCycleId(sectorId, surveyCycleId) {

    let url = PROFILE_MANAGEMENT_SERVICE + '/surveys/organisationstate';

    if ((sectorId != null && sectorId != '') || (surveyCycleId != null && surveyCycleId != '')) {
        url = (sectorId != null && sectorId != '') ? url + '/sector/' + sectorId : url;
        url = (surveyCycleId != null && surveyCycleId != '') ? url + '/surveycycle/' + surveyCycleId : url;
    } else {
        url = url + "/all";
    }

    const organisationStateURL = axios.create({ baseURL: url });

    const organisationStates = await organisationStateURL.get('')
        .then(response => {
            const data = response.data

            // Returning the organisation by organisationId
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (organisationStates != null) {
        return organisationStates;
    }

    return null;
}

/**
 * getAllOrganisations
 * @param (NONE)
 * @returns {map}
 */
export async function getAllOrganisations() {

    const url = PROFILE_MANAGEMENT_SERVICE + '/surveys/organisation/all';
    const allOrganisations = axios.create({ baseURL: url });

    const organisations = await allOrganisations.get('')
        .then(response => {
            const data = response.data

            // Returning the organisation by organisationId
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (organisations != null) {
        return organisations;
    }

    return null;
}

/**
 * getOrganisationById
 * @param {long} organisationId - Organisation ID
 * @returns {map}
 */
export async function getOrganisationById(organisationId) {

    const url = PROFILE_MANAGEMENT_SERVICE + '/surveys/organisation/' + organisationId;
    const organisationById = axios.create({ baseURL: url });

    const organisation = await organisationById.get('')
        .then(response => {
            const data = response.data

            // Returning the organisation by organisationId
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (organisation != null) {
        return organisation;
    }

    return null;
}

/**
 * GetOrganisationContactStateDetailByEmailSectorSurveycycle
 * @param {string} email - Email address.
 * @param {long} sectorId - Sector Id.
 * @param {long} surveyCycleId - Survey Cycle Id.
 * @returns {map}
 */
export async function getOrganisationContactStateDetailByEmailSectorSurveyCycle(email, sectorId, surveyCycleId) {

    const url = PROFILE_MANAGEMENT_SERVICE + '/surveys/organisationconstactstatedetail/email/' + email + '/sector/' + sectorId + '/surveycycle/' + surveyCycleId;
    const organisationContactStatebyEmail = axios.create({ baseURL: url });

    const organisationContactState = await organisationContactStatebyEmail.get('')
        .then(response => {
            const data = response.data

            // Returning the last organisation constact state sorted by the id desc for this (email, sector, surveycycle)
            return data.sort((a, b) => a.id < b.id ? 1 : -1)[0];
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (organisationContactState != null) {
        return organisationContactState;
    }

    return null;
}

/**
 * getSurveysUserByEmail
 * @param {string} email - Email address.
 * @returns {map}
 */
export async function getSurveysUserByEmail(email) {

    const url = PROFILE_MANAGEMENT_SERVICE + '/surveys/user/email/' + email;
    const surveysUserByEmail = axios.create({ baseURL: url });

    const surveysUser = await surveysUserByEmail.get('')
        .then(response => {
            const data = response.data

            // Returning the surveys_user by email address
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (surveysUser != null) {
        return surveysUser;
    }

    return null;
}

/**
 * getAllSurveysUsers
 * @param {none} 
 * @returns {map}
 */
 export async function getAllSurveysUsers() {

    const url = PROFILE_MANAGEMENT_SERVICE + '/surveys/user/all';
    const allSurveysUsers = axios.create({ baseURL: url });

    const surveysUsers = await allSurveysUsers.get('')
        .then(response => {
            const data = response.data

            // Returning the surveys_user by email address
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (surveysUsers != null) {
        return surveysUsers;
    }

    return null;
}

/**
 * Update User
 * @param {map} body - map.
 * @returns {string}
 */
export async function updateRealmUser(body) {

    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
    };

    const result = await axios.post(PROFILE_MANAGEMENT_SERVICE + '/update/user', body, { headers })
        .then(response => {
            return response.data
        })
        .catch((e) => {
            console.error('There was an error!', e);
            return 'User update error'
        });

    return result
}

/**
 * Get Realm User
 * @param {map} body - map.
 * @returns {string}
 */
export async function getRealmUser(userId) {

    const url = PROFILE_MANAGEMENT_SERVICE + '/user/userid/' + userId;
    const reamUserByUserId = axios.create({ baseURL: url });

    const realmUser = await reamUserByUserId.get('')
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
            return null
        });

    return realmUser;
}

/**
 * Get All Realm Users
 * @param {map} body - map.
 * @returns {user}
 */
export async function getAllRealmUsers() {

    const url = PROFILE_MANAGEMENT_SERVICE + '/users/all';
    const reamUserByUserId = axios.create({ baseURL: url });

    const realmUsers = await reamUserByUserId.get('')
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
            return null
        });

    return realmUsers;
}

/**
 * Get Realm User by username(email)
 * @param none
 * @returns {string}
 */
 export async function getUserByUsername(username) {

    const url = PROFILE_MANAGEMENT_SERVICE + '/user/email/' + username;
    const reamUserByUserId = axios.create({ baseURL: url });

    const realmUser = await reamUserByUserId.get('')
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
            return null
        });

    return realmUser;
}

/**
 * Get Realm User
 * @param {map} body - map.
 * @returns {string}
 */
 export async function sendNewOneTimePasswordForUserId(userid) {

    const url = PROFILE_MANAGEMENT_SERVICE + '/sendotp/' + userid;

    const reamUserByUserId = axios.create({ baseURL: url });

    const result = await reamUserByUserId.get('')
        .then(response => {
            return response.data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
            return null
        });

    return result;
}

/**
 * Create User
 * @param {map} body - map.
 * @returns {string}
 */
export function createRealmUser(body) {

    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
    };
    
    const result = axios.post(PROFILE_MANAGEMENT_SERVICE + '/create/user', body, { headers })
        .then(response => {
            return response.data
        })
        .catch((e) => {
            console.error('There was an error!', e);
            return 'User create error'
        });

    return result;

}

/**
 * Deregister User
 * @param {map} body - map.
 * @returns {string}
 */
export function deregisterRealmUser(body) {

    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
    };
    
    const result = axios.post(PROFILE_MANAGEMENT_SERVICE + '/delete/user', body, { headers })
        .then(response => {
            return response.data
        })
        .catch((e) => {
            console.error('There was an error!', e);
            return 'User deregister error'
        });

    return result;

}

export async function sendEmail(toEmailAddress, subject, message) {

    const url = PROFILE_MANAGEMENT_SERVICE + '/sendemail/to/' + toEmailAddress + '/subject/' + subject + '/message/' + message;
    const email = axios.create({ baseURL: url });

    const emailResponse = await email.post('/')
        .then(response => {
            const data = response.data

            // Returning the organisation by organisationId
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (emailResponse != null) {
        return emailResponse;
    }

    return null;
}

export async function subscribeToNewsletter(email) {

    const url = PROFILE_MANAGEMENT_SERVICE + '/subscribe/' + email;
    const subscribe = axios.create({ baseURL: url });

    const subscribeResponse = await subscribe.post()
        .then(response => {
            const data = response.data
            // Returning the organisation by organisationId
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (subscribeResponse != null) {
        return subscribeResponse;
    }

    return null;
}