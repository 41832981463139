import React, { Component } from "react";
import { Link } from 'react-router-dom';


import Support from "@components/Support";
import References from "@components/References";
import Signup from "@components/Signup";
import Contact from "@components/Contact";
import Separator from "@components/Separator";
import Footer from "@components/Footer";
import NavigationBar from "@components/NavigationBar";

import { hsrcLogoInstance, statsSALogoInstance, dsiLogoInstance, dashboardHomeImage } from "@components/Globals";

import styles from './homemobile.module.css';

function HomeMobile(props) {

            return (
                <div>
                    <NavigationBar />

                        <div style={{ marginBottom: 60 }}>

                        <h1 className="title__32px">
                            <text className="title__32px">
                                Understanding SA’s R&D landscape
                            </text>
                        </h1>
                                                <text className="text__16px">
                                                    Countries that invest in R&D grow their economies, create jobs and have better human development outcomes. The HSRC, in collaboration with the DSI and Stats SA, has been measuring R&D investment activity in the public and private research sectors for more than 20 years, informing policy development to support innovation and competitiveness.
                                                </text>

                        <br /><br />
                        <text className="text__14px">
                            The HSRC's annual National Survey of Inputs into Research and Experimental Development (R&D), known as the 'R&D Survey' is part of  a long-term research project that helps us understand R&D in the country and map changes over time.

                            <br /><br />
                            To make it easy to participate we have created this digital platform.
                        </text>
                        </div>

                                            <div style={{ marginBottom: 60 }} >
                                                <Link to='/login'>
                                                    <button variant="contained" className="button"  >
                                                    Start Survey Now
                                                    </button>
                                                </Link>
                                            </div>

                        <table width="100%" border="0">
                        <tr>
                        <td>
                        <img className={styles.logos} src={dsiLogoInstance} />
                                                </td>
                                                </tr>
                        <tr>
                        <td>
                        <img className={styles.logos} src={hsrcLogoInstance} />
                                                </td>
                                                </tr>
                        <tr>
                        <td>
                        <img className={styles.logos} src={statsSALogoInstance} />
                        </td>
                        </tr>
                        </table>



                                                                                    <div style={{ marginBottom: 60 }}>

                                                                                        <h1 className="title__24px">
                                                                                            <text className="title__24px">
                                                                                                 Create Your <span style={{ color: "#FF7F50"}}> R&D Digital Dashboard</span>
                                                                                            </text>
                                                                                        </h1>

                                                                                        <text className="text__14px">
                                                                                            For more than 20 years the HSRC has been collecting and analysing R&D data from R&D performing organisations and businesses. Our new online survey instrument will collate your data as a user-friendly dashboard to measure changes over time.                         </text>
                                                                                    </div>


                                                                                                        <div style={{ marginBottom: 10, marginLeft: 0 }}>
                                                                                                            {}
                                                                                                            <img src={dashboardHomeImage} alt="" style={{ width: 400, height: 350 }} />
                                                                                                        </div>

                                                                                                                            <div style={{ marginBottom: 60 }} >


                                                                                                                                <h1 className="title__54px">

                                                                                                                                    <text className="title__32px"> Our New Survey</text>
                                                                                                                                    <text>&nbsp;</text>
                                                                                                                                    <text className="title__32px__orange">Process</text>
                                                                                                                                </h1>
                                                                                                                                <text className="text__14px">
                                                                                                                                    We rely on your organisation’s R&D data to help us build the big picture of R&D in South Africa. But we think you should get something back, too.
                                                                                                                                    <br /><br />
                                                                                                                                    We are developing a  tool to provide a new kind of data service. This will enable your organisation to participate in South Africa’s R&D Survey, and see how your R&D data fits into the bigger picture.                        </text>
                                                                                                                            </div>
                                                                                                                            <div style={{ marginBottom: 60 }} >
                                                                                                                                <Support />
                                                                                                                            </div>

                                    <div >

                                        <h1 className="title__32px" >

                                            <text className="title__32px">South Africa is part of
                                            </text>

                                            <text className="title__32px__orange"> A global community of R&D Survey</text>


                                            <text className="title__32px"> performers</text>
                                        </h1>


                                        <text className="text__14px" style={{ marginBottom: 60 }}>
                                            Countries all over the world conduct R&D surveys each year to track key indicators. This data enables better decision-making for economy and society.
                                        </text>
                                    </div>
                                    <div className={styles.references_block} style={{ marginBottom: 60, marginTop: 60 }} >
                                        <References />
                                    </div>

                                                        <div className={styles.signup_block}>

                                                            <h1 className="title__32px">
                                                                <text className="title__32px">Sign Up To </text>
                                                                <text className="title__32px__orange">Our Newsletter</text>
                                                            </h1>
                                                            <Signup />



                                                    </div>

                                                    <Separator />
                                                    <Contact />
                                                    <Separator />
                                                    <Footer />
                                                    </div>
                )
}
export default HomeMobile;