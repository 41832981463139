import React, { Component } from "react";
import InstructionWidget from '@components/InstructionWidget';
import SpinnerModal from '@components/SpinnerModal';
import QuestionState from './QuestionState';
import styles from './questionnaire.module.css';

class CompoundQuestionState extends Component {
    state = {
        loading: true,
        compoundQuestionState: null,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ compoundQuestionState: this.props.compoundQuestionState });

        this.setState({ loading: false });
    }

    handleOnChange = (evt) => {
        this.setState({ sessionStateChanged: true });
        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        this.setState({ sessionStateChanged: true });
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {

            let BUSINESS_SECTOR = 1;
            let GOV_SECTOR = 2;
            let HE_SECTOR = 3;
            let NPO_SECTOR = 4;
            let SC_SECTOR = 5;

            let intramuralRnDTextHE = `• INTRAMURAL R&D refers to R&D performed by the reporting unit on its own behalf or on behalf of others.
• Intramural R&D expenditures are all current expenditures (including labour and other costs) plus gross fixed capital expenditures (such as for land, buildings, machinery and equipment) for R&D performed within a statistical unit during a specific reference period, whatever the source of funds.
• It excludes R&D projects funded by this organisation but carried out by others using their own facilities.
• Intramural R&D must be distinguished from Extramural (outsourced) R&D which should be reported under Part 5.
• Only R&D performed in South Africa should be recorded.`;

            let intramuralRnDTextGov = `• Intramural R&D must be distinguished from extramural R&D which should be reported under Part 5. \n• Only R&D performed within the borders of South Africa should be recorded.`;
            let definitionTextGov = `Report for all R&D personnel, permanent and contract (six months or longer) \n\nInternal R&D personnel. \nPersons employed by the reporting unit who contribute to the unit’s intramural R&D activities. \n\nResearchers\nResearchers are professionals engaged in the conception or creation of new knowledge. They conduct research and improve or develop concepts, theories, models, techniques, instrumentation, software or operational methods.This category must include Research Managers and other Research Executives.\n\nTechnicians directly supporting R&D\nTechnicians and equivalent staff are persons whose main tasks require technical knowledge and experience in one or more fields of engineering, the physical and life sciences, or the social sciences, humanities and the arts.\nThey participate in R&D by performing scientific and technical tasks involving the application of concepts and operational methods and the use of research equipment, normally under the supervision of researchers.\n\nOther personnel directly supporting R&D\nOther supporting staff includes skilled and unskilled craftsmen, and administrative, secretarial and clerical staff participating in R&D projects or directly associated with such projects.\n\nNote: All foreign headcounts should be recorded in the non - South African category\n\nDo not include personnel indirectly supporting R&D\nTypical examples are transportation, storage, cleaning, repair, maintenance and security activities, as well as administration and clerical activities undertaken not exclusively for R&D (such as the activities of central finance and personnel departments). Allowance for these should be made under overheads in R&D expenditure (Other Current Expenditure – Question 7D) but such persons should not be included as R&D Personnel.`
            let definitionTextBus = `Report for all R&D personnel, permanent and contract (six months or longer) \n\nInternal R&D personnel. \nPersons employed by the reporting unit who contribute to the unit’s intramural R&D activities. \n\nResearchers\nResearchers are professionals engaged in the conception or creation of new knowledge. They conduct research and improve or develop concepts, theories, models, techniques, instrumentation, software or operational methods.This category must include Research Managers and other Research Executives.\n\nTechnicians directly supporting R&D\nTechnicians and equivalent staff are persons whose main tasks require technical knowledge and experience in one or more fields of engineering, the physical and life sciences, or the social sciences, humanities and the arts.\nThey participate in R&D by performing scientific and technical tasks involving the application of concepts and operational methods and the use of research equipment, normally under the supervision of researchers.\n\nOther personnel directly supporting R&D\nOther supporting staff includes skilled and unskilled craftsmen, and administrative, secretarial and clerical staff participating in R&D projects or directly associated with such projects.\n\nNote: All foreign headcounts should be recorded in the non - South African category\n\nDo not include personnel indirectly supporting R&D\nTypical examples are transportation, storage, cleaning, repair, maintenance and security activities, as well as administration and clerical activities undertaken not exclusively for R&D (such as the activities of central finance and personnel departments). Allowance for these should be made under overheads in R&D expenditure (Other Current Expenditure – Question 11D) but such persons should not be included as R&D Personnel.`
            let HE_GUF_instructionText = `NOTE F: THE CALCULATION OF GENERAL UNIVERSITY FUNDS

To calculate General University Funds please subtract the subtotal of all external sources listed above(F) from the total in -house R&D expenditure reported in Q8.

General University Funds will therefore comprise components of the Higher Education Vote and the HEI’s own funds(e.g.income from endowments, shareholdings, property, student fees, and subscriptions to journals).

In order to enable us to classify the source of these funds more accurately, please provide your best estimate of the split of these General University Funds that can be attributed to the Higher Education Vote and the University’s Own Funds.

You may use a percentage distribution to calculate the split.`;

            let data = { "name": this.props.questionnairePart.name };

            return (
                <div className={styles.featuredItem} >
                    <span className={styles.compoundQuestionStateTitle}>{this.state.compoundQuestionState.name}</span>
                    <br />

                    {this.props.sectorId == HE_SECTOR && this.props.questionnairePart.name.toUpperCase().includes('PART 3')
                        && this.state.compoundQuestionState.name.toUpperCase().includes('Calculation of General University Funds'.toUpperCase())
                        ? <div >< InstructionWidget text={HE_GUF_instructionText} /><br /></div>
                        : <div></div>}

                    {this.props.sectorId == BUSINESS_SECTOR && this.props.questionnairePart.name.toUpperCase().includes('PART 2')
                        ? <div >< InstructionWidget text={definitionTextBus} /><br /></div>
                        : <div></div>}

                    {(this.props.sectorId == GOV_SECTOR || this.props.sectorId == SC_SECTOR || this.props.sectorId == NPO_SECTOR) && this.props.questionnairePart.name.toUpperCase().includes('PART 2')
                        ? <div >< InstructionWidget text={definitionTextGov} /><br /></div>
                        : <div></div>}

                    {((this.props.sectorId == GOV_SECTOR || this.props.sectorId == SC_SECTOR || this.props.sectorId == NPO_SECTOR) 
                    && this.state.compoundQuestionState.name.toUpperCase().includes('R&D Performed'.toUpperCase())
                    && !this.state.compoundQuestionState.name.toUpperCase().includes('R&D Performed (Outsourced)'.toUpperCase()))
                        ? <div >< InstructionWidget text={intramuralRnDTextGov} /><br /></div>
                        : <div></div>}

                    {((this.props.sectorId == HE_SECTOR) 
                    && this.state.compoundQuestionState.name.toUpperCase().includes('R&D Performed'.toUpperCase())
                    && !this.state.compoundQuestionState.name.toUpperCase().includes('R&D Performed (Outsourced)'.toUpperCase()))
                        ? <div >< InstructionWidget text={intramuralRnDTextHE} /><br /></div>
                        : <div></div>}

                    <div >
                        {this.state.compoundQuestionState.questionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1).map(questionState => {
                            return (
                                <div>
                                    <QuestionState
                                        captureType={this.props.captureType}
                                        key={questionState.sequenceNumber}
                                        questionState={questionState}
                                        sectorId={this.props.sectorId}
                                        onChange={this.handleOnChange}
                                        onBlur={this.handleOnBlur}
                                        questionnairePartId={this.props.questionnairePartId}
                                        compoundQuestionStateId={this.props.compoundQuestionState.id}
                                    />
                                </div>
                            )
                        })}
                    </div>
                    <br />
                </div>
            )
        }
    }
}
export default CompoundQuestionState;
