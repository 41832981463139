import React, { Component } from "react";
import NumberTools from '@components/NumberTools';
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class HEPostgradFTEQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    async handleOnChange(evt, isCurrency) {
        let key = evt.target.attributes.questionFactId.value + '_' + evt.target.attributes.ordinalEntry.value;
        let value = evt.target.value;

        if (!NumberTools.isValidDecimalNumber(value)) {
            evt.preventDefault();
        } else {
            if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                const result = await CustomDialog(
                    <UpdateAuditDialog
                        currentAnswer={SessionStateManager.getSessionValue(key)}
                        numericInput={true}
                        startEndornment={isCurrency ? 'R' : null}
                    />,
                    {
                        className: styles.updateAuditDialog,
                        title: 'Update Submitted Value',
                        showCloseIcon: false,
                        isFocusLock: true,
                        isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                    }
                );

                if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                    SessionStateManager.setSessionValue(key, result.newValue.toString().replace(/\s/g, ''), this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                    this.setState({ sessionStateChanged: true });
                } else {
                    evt.preventDefault();
                }
            } else {
                SessionStateManager.setSessionValue(key, value.toString().replace(/\s/g, ''), this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
                this.setState({ sessionStateChanged: true });
            }

            if (this.props.onChange !== undefined && this.props.onChange != null) {
                this.props.onChange(evt);
            }
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    getNumberFromSessionState(key) {
        return (SessionStateManager.getSessionValue(key) !== undefined && SessionStateManager.getSessionValue(key) != null && !isNaN(SessionStateManager.getSessionValue(key))) ? Number(SessionStateManager.getSessionValue(key).toFixed(2)) : 0;
    }

    render() {

        let fteInputCellValidationErrorMessage = 'FTE value must be less then or equal to corresponding headcount value';
        let tableQuestionFactStates = this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberX > b.sequenceNumberX ? 1 : -1);

        return (
            <div className={styles.fteQuestionContainer}>
                <table className="table">
                    <thead>
                        <tr>
                            <th rowSpan='2'>Postgraduate Student Categories</th>
                            <th colSpan='2'>HeadCounts (From Q6)</th>
                            <th colSpan='2'>Total Full-Time Equivalents (FTE's)</th>
                            <th rowSpan='2'>Total value of salaries, stipends and bursaries. R'000 Excl VAT</th>
                        </tr>
                        <tr>
                            <th>M</th>
                            <th>F</th>
                            <th>M</th>
                            <th>F</th>
                        </tr>

                    </thead>
                    <tbody>
                        {[...new Set(tableQuestionFactStates
                            .filter(
                                questionFactState => (
                                    !questionFactState.rowDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('TOTAL')
                                )
                            )
                            .map((item) => item.sequenceNumberX))]
                            .sort()
                            .map(rowIndex => {
                                let rowLabel = tableQuestionFactStates
                                    .filter(questionFactState => (questionFactState.sequenceNumberX == rowIndex))
                                    .filter(questionFactState => !questionFactState.rowDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                                    .filter(questionFactState => questionFactState.sequenceNumberX == rowIndex)[0].rowDimensionStates[0].dimensionAlias;

                                return <tr>
                                    <th>{rowLabel}</th>

                                    {tableQuestionFactStates
                                        .filter(questionFactState => (questionFactState.sequenceNumberX == rowIndex))
                                        .sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)
                                        .map(questionFactState =>
                                            (questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('SALARIES')
                                                || ((questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                                                    && (questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[1].dimensionAlias.toUpperCase().includes('TOTAL')))
                                                || ((questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('HEADCOUNT'))
                                                    && (questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[1].dimensionAlias.toUpperCase().includes('MALE')))
                                                || ((questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('HEADCOUNT'))
                                                    && (questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[1].dimensionAlias.toUpperCase().includes('TOTAL')))
                                            )
                                                ? ((questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('HEADCOUNT'))
                                                    && (questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[1].dimensionAlias.toUpperCase().includes('MALE')))
                                                    ? <td
                                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : null}
                                                    >
                                                        <input
                                                            className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : styles.tableTotalCell}
                                                            min='0'
                                                            type="text"
                                                            id={(rowLabel.toUpperCase().includes('MASTERS') ? (rowLabel.toUpperCase().includes('FULL RESEARCH') ? 'MASTERS_FULL' : 'MASTERS_COURSEWORK') : rowLabel.toUpperCase().replace(/\s/g, '_').replace('-', '_')) + '_' + questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[1].dimensionAlias.toUpperCase() + '_TOTAL'}
                                                            value={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0')}
                                                            questionId={'' + this.props.questionState.question.id}
                                                            ordinalEntry={0}
                                                            questionFactId={questionFactState.questionFactId}
                                                            placeholder='0'
                                                            readOnly
                                                        />
                                                    </td>
                                                    : <td
                                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableInputBoxUpdated : null}
                                                    >
                                                        <div className={styles.flexCenterFitContent}>
                                                            <span className="inputRandSign">R</span>
                                                        <input
                                                            className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                                            min='0'
                                                            type="text"
                                                            id="fte_input_cell"
                                                            value={NumberTools.transformToCurrency(SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0'))}
                                                            questionId={this.props.questionState.question.id}
                                                            ordinalEntry={0}
                                                            questionFactId={questionFactState.questionFactId}
                                                            onClick={(evt) => {
                                                                if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                                                                    this.handleOnChange(evt, true);
                                                                }
                                                            }}
                                                            onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key) || (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE')) { event.preventDefault(); } }}
                                                            onChange={(evt) => this.handleOnChange(evt, true)}
                                                            onBlur={(evt) => this.handleOnBlur(evt)}
                                                            placeholder='0'
                                                            />
                                                            </div>
                                                    </td>
                                                : <td className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.cellHintUpdated : styles.cellHint}>
                                                    {(ValidationsManager.validateFact(questionFactState.questionFactId) === true) ? '' : <span className={styles.tooltiptext}>{fteInputCellValidationErrorMessage}</span>}
                                                    <input
                                                        className={ValidationsManager.validateFact(questionFactState.questionFactId) === true
                                                            ? (ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableInputBoxUpdated : styles.tableInputBox)
                                                            : (ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.redTextUpdated : styles.redText)}
                                                        min='0'
                                                        type="text"
                                                        id="input_cell"
                                                        questionId={this.props.questionState.question.id}
                                                        ordinalEntry={0}
                                                        questionFactId={questionFactState.questionFactId}
                                                        value={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0')}
                                                        placeholder='0'
                                                        onClick={(evt) => {
                                                            if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                                                                this.handleOnChange(evt, false);
                                                            }
                                                        }}
                                                        onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key) || (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE')) { event.preventDefault(); } }}
                                                        onChange={(evt) => this.handleOnChange(evt, false)}
                                                        onBlur={(evt) => this.handleOnBlur(evt)}
                                                    />
                                                </td>
                                        )
                                    }
                                </tr>
                            }
                            )
                        }
                        {[...new Set(tableQuestionFactStates
                            .filter(
                                questionFactState => (
                                    questionFactState.rowDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('TOTAL')
                                )
                            )
                            .map((item) => item.sequenceNumberX))]
                            .sort()
                            .map(rowIndex => {
                                let totalLabel = tableQuestionFactStates
                                    .filter(questionFactState => (questionFactState.sequenceNumberX == rowIndex))
                                    .filter(questionFactState => questionFactState.rowDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                                    .filter(questionFactState => questionFactState.sequenceNumberX == rowIndex)[0].rowDimensionStates[0].dimensionAlias.toUpperCase()

                                return <tr>
                                    <th colSpan='5' >{totalLabel}</th>

                                    {tableQuestionFactStates
                                        .filter(questionFactState => (questionFactState.sequenceNumberX == rowIndex))
                                        .sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)
                                        .map(questionFactState =>
                                            <td
                                                className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : null}
                                            >
                                                <div className={styles.flexCenterFitContent}>
                                                    <span className="inputRandSign">R</span>
                                                    <input
                                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : styles.tableTotalCell}
                                                        min='0'
                                                        type="text"
                                                        value={NumberTools.transformToCurrency(this.getNumberFromSessionState(questionFactState.questionFactId + '_0'))}
                                                        id="table_total"
                                                        name={this.props.questionState.question.id}
                                                        ordinalEntry={0}
                                                        questionFactId={questionFactState.questionFactId}
                                                        placeholder='0'
                                                        readOnly
                                                    />
                                                </div>
                                            </td>
                                        )
                                    }
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <br />
            </div>
        )
    }
}
export default HEPostgradFTEQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    {...props}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if (
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                            result.confirmed = true;
                            dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}