import React, { Component } from "react";
import NumberTools from "@components/NumberTools";
import SessionStateManager from "@components/SessionStateManager";
import styles from '../questionnaire.module.css';

class HELabourCostQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    handleOnChange = (evt) => {
        let key = evt.target.attributes.questionFactId.value + '_' + evt.target.attributes.ordinalEntry.value;
        let value = evt.target.value;
        SessionStateManager.setSessionValue(key, value, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId);
        this.setState({ sessionStateChanged: true });
        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            return <div className={styles.heLabourCostQuestionContainer}>
                <table className='table' >
                    <thead>
                        <tr>
                            <th width='40%' />
                            <th width='60%'>R'000 (Excl. VAT)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.questionState.questionFactStates
                            .filter(questionFactState => questionFactState !== null)
                            .sort((a, b) => (a.sequenceNumberX > b.sequenceNumberX) ? 1 : -1).map(questionFactState => {
                                return <tr>
                                    <td>{questionFactState.rowDimensionStates[0].dimensionAlias}</td>
                                    <td className={styles.tableTotalCell}>
                                        <div className={styles.flexCenterFitContent}>
                                            <span className="inputRandSign">R</span>
                                            <input
                                                className={styles.tableTotalCell}
                                                type="text"
                                                value={NumberTools.transformToCurrency(SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0'))}
                                                questionId={this.props.questionState.question.id}
                                                ordinalEntry={0}
                                                questionFactId={questionFactState.questionFactId}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        }
    }
}
export default HELabourCostQuestion;