import FieldValidator from '@components/FieldValidator';
import MultiColorTitle from '@components/MultiColorTitle';
import ProfileManagementService from '@services/ProfileManagementService';
import SurveyService from '@services/SurveyService';
import LoadingModal from '@components/LoadingModal';
import DropDownMenu from '@components/DropDownMenu';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import styles from './userprofile.module.css';
import { IconButton } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Alert } from 'react-st-modal';

class ResearcherProfile extends Component {
    state = {
        loading: false,
        errors: '',

        validEmail: false,
        validFirstName: false,
        validLastName: false,
        validCellphone: false,
        validWorkPhone: false,
        validRole: false,
        validOrganisation: false,
        validSurveysUser: false,

        realmId: '',
        emailAddress: '',
        title: '',
        firstName: '',
        lastName: '',
        cellphone: '',
        workPhone: '',
        designation: '',
        role: '',
        surveysUserId: null,

        organisationId: '',
        registeredName: 'HSRC',
        organisationType: 'Science Council',
        physicalAddress: '134 Pretorius Street. Pretoria, South Africa',
        postalCode: '0002',
        province: 'Gauteng',

        allOrganisations: [],
        allSectors: [],
        allSurveyCycles: [],
        surveysUsers: []
    }

    constructor(props) {
        super(props);

        this.validRole = this.validRole.bind(this);
        this.handleSubmitUpdateAccount = this.handleSubmitUpdateAccount.bind(this);
        this.handleSubmitCreateAccount = this.handleSubmitCreateAccount.bind(this);

    }

    async componentDidMount() {

        this.setState({ loading: true });

        function parseJwt(token) {
            var base64Payload = token.split('.')[1];
            var payload = Buffer.from(base64Payload, 'base64');
            return JSON.parse(payload.toString());
        }

        let bearer = localStorage.getItem('Bearer');
        if (!bearer){
            window.location = "/login";
        
          }

        let payload = parseJwt(bearer);

        this.state.role = payload.group_id.toUpperCase();
        this.state.validRole = (this.state.role == 'RESEARCHER')

        if (this.props.profileAction.toLowerCase() != 'create') {
            this.state.realmId = payload.sub;
            this.state.emailAddress = payload.email;

            const surveysUser = await ProfileManagementService.getSurveysUserByEmail(this.state.emailAddress);

            const realmUser = await ProfileManagementService.getRealmUser(this.state.realmId);

            if (surveysUser != null && realmUser != null) {

                this.setState({
                    validEmail: FieldValidator.validateDomainEmail(realmUser.email, '@hsrc.ac.za'),
                    validFirstName: FieldValidator.validateName(realmUser.firstName),
                    validLastName: FieldValidator.validateName(realmUser.lastName),
                    validCellphone: FieldValidator.validatePhoneNumber((realmUser.attributes.cellphone != null && realmUser.attributes.cellphone.length > 0) ? realmUser.attributes.cellphone[0] : ''),
                    validWorkPhone: FieldValidator.validatePhoneNumber((realmUser.attributes.workphone != null && realmUser.attributes.workphone.length > 0) ? realmUser.attributes.workphone[0] : ''),

                    emailAddress: realmUser.email,
                    firstName: realmUser.firstName,
                    lastName: realmUser.lastName,
                    cellphone: (realmUser.attributes.cellphone != null && realmUser.attributes.cellphone.length > 0) ? realmUser.attributes.cellphone[0] : '',
                    workPhone: (realmUser.attributes.workphone != null && realmUser.attributes.workphone.length > 0) ? realmUser.attributes.workphone[0] : '',
                    designation: (realmUser.attributes.designation != null && realmUser.attributes.designation.length > 0) ? realmUser.attributes.designation[0] : '',
                    user_id: (realmUser.attributes.user_id != null && realmUser.attributes.user_id.length > 0) ? realmUser.attributes.user_id[0] : ''

                })
            }

            this.state.realmId = payload.sub;

            this.state.role = payload.group_id.toUpperCase();
            this.state.validRole = this.validRole(this.state.role);
        }

        if (this.props.profileAction.toLowerCase() == 'create') {
            this.state.validRole = (this.state.role == 'RESEARCHER')

            const sectors = await SurveyService.getAllSectors();
            sectors.map(item => this.state.allSectors.push({ key: item.id, value: item.name.trim() }));

            const surveyCycles = await SurveyService.getAllSurveyCycles();
            surveyCycles.map(item => this.state.allSurveyCycles.push({ key: item.id, value: item.name.trim() }));

            const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
            this.setState({ surveysUsers: surveysUsers });
        }

        this.setState({ loading: false });
    }

    buttonDisabled() {

        if (this.props.profileAction.toLowerCase() == 'create') {
            return !(
                this.state.validSurveysUser &&
                this.state.validRole &&
                this.state.validEmail &&
                this.state.validFirstName &&
                this.state.validLastName &&
                this.state.validCellphone &&
                this.state.validWorkPhone
            )
        } else {

            return !(
                this.state.validEmail &&
                this.state.validFirstName &&
                this.state.validLastName &&
                this.state.validCellphone &&
                this.state.validWorkPhone
            )
        }
    }

    validRole(role) {
        return role.toUpperCase() == 'RESEARCHER'
    }

    handleChangedValue = (evt) => {
        switch (evt.target.name) {
            case 'emailAddress': {
                this.setState({ validEmail: FieldValidator.validateDomainEmail(evt.target.value, '@hsrc.ac.za') });
                this.setState({ emailAddress: evt.target.value });
                break;
            }
            case 'firstName': {
                this.setState({ validFirstName: FieldValidator.validateName(evt.target.value) });
                this.setState({ firstName: evt.target.value });
                break;
            }
            case 'lastName': {
                this.setState({ validLastName: FieldValidator.validateName(evt.target.value) });
                this.setState({ lastName: evt.target.value });
                break;
            }
            case 'cellphone': {
                this.setState({ validCellphone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ cellphone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'workPhone': {
                this.setState({ validWorkPhone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ workPhone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'designation': {
                this.setState({ designation: evt.target.value });
                break;
            }
            case 'surveysUser': {
                this.setState({ validSurveysUser: evt.target.value.trim().length > 0 });
                this.setState({ surveysUserId: evt.target.value });
                break;
            }
            case 'organisation': {
                this.setState({ validOrganisation: evt.target.value.trim().length > 0 });
                this.setState({ organisationId: evt.target.value });
                break;
            }
            default: {
                break;
            }
        }
    }

    async handleSubmitUpdateAccount(event) {

        event.preventDefault();

        this.setState({ loading: true });

        let body = {
            userid: this.state.realmId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: null,
            email: this.state.emailAddress,
            attributes: {
                cellphone: [this.state.cellphone],
                workphone: [this.state.workPhone],
                designation: [this.state.designation]
            },
            roles: ['dsp_researcher', 'dsp_respondent']
        };

        const result = await ProfileManagementService.updateRealmUser(body);

        if (result == 'User updated') {
            this.setState({ loading: false });
            { Alert('Researcher details updated.', 'Update Researcher') };
        } else {
            this.setState({ loading: false });
            { Alert('Researcher details could not be updated, please try again later.', 'Update Researcher') };
        }

    }

    async handleSubmitCreateAccount(event) {

        event.preventDefault();

        this.setState({ loading: true });

        let body = {
            userid: '',
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: null,
            email: this.state.emailAddress,
            attributes: {
                group_id: ["RESEARCHER"],
                user_id: [this.state.surveysUserId],
                source_of_data: [25],
                sector_id: [this.state.sectorId],
                survey_cycle_id: [this.state.surveyCycleId],
                org_id: [this.state.organisationId],
                org_state_id: [this.state.organisationStateId],
                cellphone: [this.state.cellphone],
                workphone: [this.state.workPhone],
                designation: [this.state.designation],
                created_by_realm_id: [this.state.realmId]
            },
            roles: ['dsp_researcher']
        };

        const result = await ProfileManagementService.createRealmUser(body);

        if (result == 'Created') {

            this.setState({ loading: false });
            { Alert('Researcher has been created successfully', 'Create Researcher') };

            this.setState({ firstName: '' });
            this.setState({ lastName: '' });
            this.setState({ emailAddress: '' });
            this.setState({ cellphone: '' });
            this.setState({ workPhone: '' });
            this.setState({ designation: '' });

        } else {
            this.setState({ loading: false });
            { Alert('Researcher could not be created, please try again later.', 'Create Researcher') };
        }

    }

    getSurveysUserNameById(userId) {
        let name = (this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].firstname + ' ' + this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].lastname);
        return name;
    }

    createAccount = () => {

        let thisSurveysUsers = [];

        this.state.surveysUsers.filter((surveysUser) => surveysUser.active == true).map(item => {
            thisSurveysUsers.push({ key: item.id, value: this.getSurveysUserNameById(item.id) });
        });


        return (
            <div className={styles.tab_column_container}>
                <p className="description text-center">
                    <MultiColorTitle title_font="text__12px"
                        first_color="bold__blue"
                        first_text="Account Log in Details"
                    />
                </p>
                <p>
                    <TextField
                        error={this.state.emailAddress.length > 0 && !this.state.validEmail}
                        helperText={this.state.emailAddress.length > 0 && !this.state.validEmail ? 'Invalid email' : null}
                        className={styles.input_user_profile_account}
                        require="required"
                        name={'emailAddress'}
                        type="text"
                        placeholder={'name@business.co.za'}
                        id="outlined-basic"
                        size="small"
                        label={'Email Address'}
                        required
                        value={this.state.emailAddress}
                        onChange={this.handleChangedValue}
                        required
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </p>
                <p className="description text-center">
                    <MultiColorTitle title_font="text__12px"
                        first_color="bold__blue"
                        first_text="Details of researcher"
                    />
                </p>
                <div className={styles.grid_container} >
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.firstName.length > 0 && !this.state.validFirstName}
                                    helperText={!FieldValidator.validateName(this.state.firstName) ? 'Invalid First Name' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'firstName'}
                                    type="text"
                                    placeholder={'First Name'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'First Name'}
                                    required
                                    value={this.state.firstName}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.lastName.length > 0 && !this.state.validLastName}
                                    helperText={!FieldValidator.validateName(this.state.lastName) ? 'Invalid Last Name' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'lastName'}
                                    type="text"
                                    placeholder={'Last Name'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Last Name'}
                                    required
                                    value={this.state.lastName}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </p>
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.cellphone.length > 0 && !this.state.validCellphone}
                                    helperText={!this.state.validCellphone ? 'Invalid cellphone number' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'cellphone'}
                                    type="text"
                                    placeholder={'072 - 309 - 4059'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Cellphone'}
                                    required
                                    value={this.state.cellphone}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.workPhone.length > 0 && !this.state.validWorkPhone}
                                    helperText={!this.state.validWorkPhone ? 'Invalid work phone number' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'workPhone'}
                                    type="text"
                                    placeholder={'011 - 238 - 4859'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Work Telephone'}
                                    required
                                    value={this.state.workPhone}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </p>
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <TextField
                                    className={styles.input_user_profile_details}
                                    name={'designation'}
                                    type="text"
                                    placeholder={'Designation'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Designation'}
                                    value={this.state.designation}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                <DropDownMenu
                                    name={'surveysUser'}
                                    label={'RDSMS Surveys User'}
                                    value={this.state.surveysUserId}
                                    onChange={this.handleChangedValue}
                                    showKeys={false}
                                    menuOptions={thisSurveysUsers.sort((a, b) => a.value > b.value ? 1 : -1)}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </p>

                    <hr style={{ marginBottom: 20, marginTop: 50 }} />

                    <p>
                        <div style={{ marginBottom: 10, marginTop: 20 }}>
                            <input
                                className={styles.profile_button}
                                variant="contained"
                                type="submit"
                                size="large"
                                value="Create"
                                color={this.buttonDisabled() ? 'primary' : 'secondary'}
                                onClick={this.handleSubmitCreateAccount}
                                disabled={this.buttonDisabled()}
                            />
                            <text style={{ maxWidth: 485 }} className="text__12px">
                                {this.state.errors}
                            </text>
                        </div>
                    </p>
                </div>
            </div>
        )
    }

    updateAccount = () => {
        return (
            <div className={styles.tab_column_container}>
                <p className="description text-center">
                    <MultiColorTitle title_font="text__12px"
                        first_color="bold__blue"
                        first_text="Account Log in Details"
                    />
                </p>
                <p>
                    <TextField
                        error={this.state.emailAddress.length > 0 && !this.state.validEmail}
                        helperText={this.state.emailAddress.length > 0 && !this.state.validEmail ? 'Invalid email' : null}
                        className={styles.input_user_profile_account}
                        require="required"
                        name={'emailAddress'}
                        type="text"
                        placeholder={'name@business.co.za'}
                        id="outlined-basic"
                        size="small"
                        label={'Email Address'}
                        required
                        disabled
                        value={this.state.emailAddress}
                        onChange={this.handleChangedValue}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </p>
                <p className="description text-center">
                    <MultiColorTitle title_font="text__12px"
                        first_color="bold__blue"
                        first_text="Details of primary person completing the form"
                    />
                </p>
                <div className={styles.grid_container} >
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.firstName.length > 0 && !this.state.validFirstName}
                                    helperText={!FieldValidator.validateName(this.state.firstName) ? 'Invalid First Name' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'firstName'}
                                    type="text"
                                    placeholder={'First Name'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'First Name'}
                                    required
                                    value={this.state.firstName}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.lastName.length > 0 && !this.state.validLastName}
                                    helperText={!FieldValidator.validateName(this.state.lastName) ? 'Invalid Last Name' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'lastName'}
                                    type="text"
                                    placeholder={'Last Name'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Last Name'}
                                    required
                                    value={this.state.lastName}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </p>
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.cellphone.length > 0 && !this.state.validCellphone}
                                    helperText={!this.state.validCellphone ? 'Invalid cellphone number' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'cellphone'}
                                    type="text"
                                    placeholder={'072 - 309 - 4059'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Cellphone'}
                                    required
                                    value={this.state.cellphone}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.workPhone.length > 0 && !this.state.validWorkPhone}
                                    helperText={!this.state.validWorkPhone ? 'Invalid work phone number' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'workPhone'}
                                    type="text"
                                    placeholder={'011 - 238 - 4859'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Work Telephone'}
                                    required
                                    value={this.state.workPhone}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </p>
                    <hr style={{ marginBottom: 20, marginTop: 50 }} />

                    <p>
                        <div style={{ marginBottom: 10, marginTop: 20 }}>
                            <input
                                className={styles.profile_button}
                                variant="contained"
                                type="submit"
                                size="large"
                                value="Update"
                                color={this.buttonDisabled() ? 'primary' : 'secondary'}
                                onClick={this.handleSubmitUpdateAccount}
                                disabled={this.buttonDisabled()}
                            />
                            <text style={{ maxWidth: 485 }} className="text__12px">
                                {this.state.errors}
                            </text>
                        </div>
                    </p>
                </div>
            </div>

        )
    }

    updateOrganisation = () => {
        return this.viewOrganisation();
    }

    viewAccount = () => {
        return (
            <div className={styles.tab_column_container}>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Title:"
                        second_color="blue"
                        second_text={this.state.title}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="First name:"
                        second_color="blue"
                        second_text={this.state.firstName}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Last Name:"
                        second_color="blue"
                        second_text={this.state.lastName}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Email Address:"
                        second_color="blue"
                        second_text={this.state.emailAddress}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Cellphone:"
                        second_color="blue"
                        second_text={this.state.cellphone}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Work Telephone:"
                        second_color="blue"
                        second_text={this.state.workPhone}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Designation:"
                        second_color="blue"
                        second_text={this.state.designation}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Role:"
                        second_color="blue"
                        second_text={this.state.role}
                    />
                </p>
            </div >
        )
    }

    viewOrganisation = () => {
        return (
            <div>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Registered Name Of Company:"
                        second_color="blue"
                        second_text={this.state.registeredName}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Type of Organisation:"
                        second_color="blue"
                        second_text={this.state.organisationType}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Physical Address:"
                        second_color="blue"
                        second_text={this.state.physicalAddress}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Province:"
                        second_color="blue"
                        second_text={this.state.province}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Postal Code:"
                        second_color="blue"
                        second_text={this.state.postalCode}
                    />
                </p>
            </div >
        )
    }

    render() {
        if (this.state.loading === true) {
            return <LoadingModal settings={true} />
        } else {
            switch (this.props.profileAction + '_' + this.props.profileDetail) {
                case 'create_account': return this.createAccount()
                // case 'create_organisation': return this.createOrganisation()
                case 'update_account': return this.updateAccount()
                case 'update_organisation': return this.updateOrganisation() // Note: Organisation view shown for update
                case 'view_account': return this.viewAccount()
                case 'view_organisation': return this.viewOrganisation()
                default: return <div>ResearcherProfile: Unknown Action/Detail</div>
            }
        }
    }
}
export default ResearcherProfile