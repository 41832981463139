import React, { Component } from "react";



import { downloadButton } from '@components/Globals';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

import styles from './research.module.css';

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

function ResearchPaper(props) {


    return (
        <div className={styles.container}>
            <Paper sx={{ flexGrow: 1, borderRadius: 0, boxShadow: 'none' }}>
                <Grid container spacing={2}>
                    <Grid item>
                        <ButtonBase sx={{ width: 115, height: 164 }}>
                            <Img alt="" src={props.img_src} />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2} alignItems={'left'}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle" className={props.title_font} component="div">
                                    {props.title}              </Typography>
                                <Typography variant="body2" className={props.text_font} gutterBottom>
                                    {props.text}
                                </Typography>
                                <Typography className={props.text_font} variant="body2" >
                                    {props.text_2}
                                </Typography>
                                {(props.text_3 != null && props.text_3.trim() != '') ?
                                    <Typography className={props.text_font} variant="body2">
                                        {props.text_3}
                                    </Typography>
                                    : <div />
                                }
                            </Grid>
                            <Grid item>

                                <a href={props.link_url} target="_blank" download className={styles.downloads}>
                                    <ButtonBase sx={{ width: 107, height: 32 }}>
                                        <Img alt="" src={downloadButton} />
                                    </ButtonBase>
                                </a>

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Paper>


        </div>
    );
}


export default ResearchPaper;