import { React, Component } from "react";
import { Link } from 'react-router-dom';


import NotFound from '@images/NotFoundPage/not_found_icon.svg';
import NavigationBar from "@components/NavigationBar";
import Contact from "@components/Contact";
import Separator from "@components/Separator";
import Footer from "@components/Footer";

import styles from "./pagenotfound.module.css";

class PageNotFound extends Component {

  render() {
    return <div>
      <NavigationBar />
      <div className={styles.container_pagenotfound}>
        <img src={NotFound} />
        <p>
          <br />
          <br />
          <center><text className="title__54px__orange">Oops!</text></center>
          <br />
          <div className="text__18px">We can't seem to find the page you're looking for.</div>
          <br />
          <div className="text__18px">Here are some helpful links instead.</div>
          <br />
          <Link to="/login" className={styles.links}><u>Log In</u></Link>&emsp;

          <Link to="/" className={styles.links}><u>Home</u></Link>&emsp;
          <Link to="/about" className={styles.links}><u>The R&D Survey</u></Link>&emsp;
          <Link to="/faq" className={styles.links}><u>FAQ</u></Link>&emsp;
          <Link to="/research" className={styles.links}><u>R&D Research</u></Link>
        </p>
      </div>
      <Separator />
      <Contact />
      <Separator />
      <Footer />
    </div>;
  }
}
export default PageNotFound;