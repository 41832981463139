import React, { Component } from "react";
import SpinnerModal from '@components/SpinnerModal';
import Separator from '@components/Separator';
import TableWidget from '@components/TableWidget';
import HintCard from '@components/HintCard';
import InstructionWidget from '@components/InstructionWidget';
import pageHint from '@images/info.svg';
import QuestionFactory from "./QuestionFactory";
import SessionStateManager from "@components/SessionStateManager";
import SurveyService from '@services/SurveyService';
import styles from './questionnaire.module.css';

class QuestionState extends Component {
    state = {
        loading: true,
    }

    constructor(props) {
        super(props);
    }

    handleOnChange = (evt) => {
        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            return (
                <div className={styles.featuredItem} >
                    <div className={styles.avoidbreak_inside}>
                        {this.props.questionState.text !== undefined && this.props.questionState.text != null && this.props.questionState.text != ''
                            ? this.props.questionState.text
                            : this.props.questionState.name}
                        {(this.props.questionState.notes != null && this.props.questionState.notes != '')
                            ? <span className="pageHint">
                                <img src={pageHint} />
                                <span className="tooltiptext"><HintCard hintText={this.props.questionState.notes.replace("{bullet}", "•")} /></span>
                            </span>
                            : <div />
                        }
                    <DisplayInstructionBox name={this.props.questionState.name} sectorId={this.props.sectorId} />
                    <br />

                    <QuestionFactory
                        captureType={this.props.captureType}    
                        questionState={this.props.questionState}
                        sectorId={this.props.sectorId}
                        onChange={this.handleOnChange}
                        onBlur={this.handleOnBlur}
                        questionnairePartId={this.props.questionnairePartId}
                        compoundQuestionStateId={this.props.compoundQuestionStateId}
                    />
                    </div>
                </div>
            )
        }
    }
}
export default QuestionState;
function DisplayInstructionBox(props) {
    let BUSINESS_SECTOR = 1;
    let GOV_SECTOR = 2;
    let HE_SECTOR = 3;
    let NPO_SECTOR = 4;
    let SC_SECTOR = 5;

    let definitionText = "-";
    let instructionText = "-";


    if (props.name.toUpperCase().includes('No In-House or Outsourced'.toUpperCase())
        && (props.sectorId == BUSINESS_SECTOR)
    ) {
        instructionText = `• If you have conducted intramural (or in-house) R&D in the ${process.env.REACT_APP_CURRENT_SURVEY_CYCLE_YEAR_LABEL} financial year please continue to Part 2.
• Only R&D performed within the borders of South Africa should be recorded.
• If you have conducted only extramural (or outsourced) R&D in the ${process.env.REACT_APP_CURRENT_SURVEY_CYCLE_YEAR_LABEL} financial year, please continue to Part 5.
• If you have indicated no intramural or extramural R&D in Questions 8b and 8c, please tick below.`;
    }


    if (props.name.toUpperCase().includes('Full-time Equivalents and Labour Cost of R&D Personnel'.toUpperCase())
        && (props.sectorId == BUSINESS_SECTOR)
    ) {
        instructionText = `CALCULATING FULL-TIME EQUIVALENT (FTE)

NOTE: For the purpose of this survey, an employee can work a maximum of one FTE in a year.

The following equation can be used to calculate FTE/effort on R&D:
(Person/s employed) x (Portion of their job spent on R&D) x (Portion of the year employed) = FTE on R&D

The following equation can be used to calculate FTE / effort on R&D:FTE on R&D
For example:
    - a full - time employee who devotes 100 % of their time to R&D 
    1 x 1 x 1 = 1 FTE on R&D 
    - a full - time employee spending 40 % of their time on R&D during half of the survey year 
    1 x 0.4 persons x 0.5 years = 0.2 FTE on R&D
    - a part - time employee working 40 % of a full - time year doing only R&D
    1 x 0.4 x 1 = 0.4 FTE on R&D
    - 20 full - time researchers spending 40 % of their time on R&D during the survey year:
    20 x 0.4 x 1 = 8 FTE on R&D

NOTE: please calculate FTEs for all R&D personnel`;
    }

    if (props.name.toUpperCase().includes('Expenditure Location'.toUpperCase())
        && (props.sectorId == BUSINESS_SECTOR)
    ) {
        instructionText = `Specify where R&D is actually performed, rather than where it is managed/financed from.`;
    }

    if (props.name.toUpperCase().includes('Multi-disciplinary R&D expenditure'.toUpperCase())
        && (props.sectorId == BUSINESS_SECTOR)) {
        instructionText = `Multi-disciplinary R&D combines several research fields or disciplines. If your organisation performs R&D, as described below please provide the applicable percentage of total R&D expenditure. 

Note that the percentages will most likely not total 100%.`;
    }


    if (props.name.toUpperCase().includes('Collaborative R&D - local versus foreign'.toUpperCase())
        && (props.sectorId == BUSINESS_SECTOR)
    ) {
        instructionText = `Note: In the table below a single collaborative R&D project with several partners may be ticked in several places. Collaborative R&D may be intramural or extramural. 
R&D collaboration can occur without expenditure – please note zero expenditure in such cases.`;
    }

    if (props.name.toUpperCase().includes('NIL return'.toUpperCase())
        && (props.sectorId == GOV_SECTOR || props.sectorId == SC_SECTOR || props.sectorId == NPO_SECTOR)
    ) {
        instructionText = `• If you have conducted intramural (or in-house) R&D in the ${process.env.REACT_APP_CURRENT_SURVEY_CYCLE_YEAR_LABEL} financial year please continue to Part 2.
• If you have conducted only extramural (or outsourced) R&D in the ${process.env.REACT_APP_CURRENT_SURVEY_CYCLE_YEAR_LABEL} financial year, please continue to Part 5.
• If you have indicated no intramural R&D or extramural R&D in Questions 4a and 4b above, please tick below.`;
    }

    if (props.name.toUpperCase().includes('Full-time Equivalents and Labour Cost of R&D Personnel'.toUpperCase())
        && (props.sectorId == GOV_SECTOR || props.sectorId == SC_SECTOR || props.sectorId == NPO_SECTOR)
    ) {
        instructionText = `CALCULATING FULL-TIME EQUIVALENT (FTE) 
NOTE: For the purpose of this survey, an employee can work a maximum of one FTE in a year. 
    The following equation can be used to calculate FTE / effort on R&D:FTE on R&D
    For example: 
    - a full - time employee who devotes 100 % of their time to R&D 
        1 x 1 x 1 = 1 FTE on R&D 
    - a full - time employee spending 40 % of their time on R&D during half of the survey year 
        1 x 0.4 persons x 0.5 years = 0.2 FTE on R&D 
    - a part - time employee working 40 % of a full - time year doing only R&D 
        1 x 0.4 x 1 = 0.4 FTE on R&D 
    - 20 full - time researchers spending 40 % of their time on R&D during the survey year: 
        20 x 0.4 x 1 = 8 FTE on R&D  
NOTE: please calculate FTEs for all R&D personnel`;
    }

    if (props.name.toUpperCase().includes('Expenditure Location'.toUpperCase())
        && (props.sectorId == GOV_SECTOR || props.sectorId == SC_SECTOR || props.sectorId == NPO_SECTOR)
    ) {
        instructionText = `Specify where R&D is actually performed, rather than where it is managed/financed from.`;
    }

    if (props.name.toUpperCase().includes('Multi-disciplinary R&D expenditure'.toUpperCase())
        && (props.sectorId == GOV_SECTOR || props.sectorId == SC_SECTOR || props.sectorId == NPO_SECTOR)
    ) {
        instructionText = `Multi-disciplinary R&D combines several research fields or disciplines. If your organisation performs R&D, as described below please provide the applicable percentage of total R&D expenditure.

Note that the percentages will most likely not total 100%.`;
    }
    if (props.name.toUpperCase().includes('Collaborative R&D - local versus foreign'.toUpperCase())
        && (props.sectorId == GOV_SECTOR || props.sectorId == SC_SECTOR || props.sectorId == NPO_SECTOR)
    ) {
        instructionText = `Note: In the table below a single collaborative R&D project with several partners may be ticked in several places. Collaborative R&D may be intramural or extramural. 
R&D collaboration can occur without expenditure – please note zero expenditure in such cases.`;
    }


    if (props.name.toUpperCase().includes('Full-time Equivalents and Labour Cost of R&D Personnel'.toUpperCase())
        && (props.sectorId == HE_SECTOR)
    ) {
        instructionText = `NOTE A: CALCULATION OF RESEARCHER HEADCOUNTS AND FTE'S USING HEMIS DATA
                    
HEMIS data for the ${process.env.REACT_APP_CURRENT_SURVEY_CYCLE_FROM_YEAR} academic year should be used to calculate researcher headcounts and FTE's.
◘    To extract this data from HEMIS use the SFTE final table structure and the Staff Programme
Classification(element number / name: 044 / staff programme) Classification Code 020(Research) as
    the primary filter.
◘    We suggest that the data be opened in Microsoft Access or Excel.
◘    Create a table with the following variables present:
        • Gender                         Element 012
        • Race                             Element 013
        •	Personnel Category       Element 039
        •	FTE Value                     Element 043
        •	Qualification Type         Element 046
        
◘    Only report on data pertaining to instruction / research professionals(Classification Code: 01).

◘    Please capture all staff, namely: permanent / temporary status, part - time / full–time and joint appointments.The number of records present
        should provide the headcount, while the total of the FTE values will provide the FTE value for Research that is required.
    
◘    Should you wish to extract this information at Faculty level, extract the data using CESM categories as a filter, and then divide these
        CESM's according to Faculty.
    
NOTE B: CALCULATION OF TECHNICIAN AND OTHER SUPPORT STAFF HEADCOUNT AND FTE'S
    Unfortunately HEMIS data only reports on technicians and other staff DOING research and not SUPPORTING research.Technicians and other staff DOING
    research should be included under the Researcher category.HEMIS data as such could therefore not be used to calculate the headcount and Research
    FTE's of technicians and other staff supporting research.  This information should rather be obtained from Management Information, Faculty Officers
    and / or Faculty Deans.
    Please note:  Total  FTE's should only include such staff members that support research for at least 10% of their time, NOT ALL Technicians
    Executive / Managerial or Administrative staff.
    
    CALCULATING RESEARCH FULL - TIME EQUIVALENTS:
    For the purpose of this survey, a person can work a maximum of 1 FTE in a year.This is why the Research FTE is not defined by specifying the
    maximum number of working hours in a month or year.
    The following equation can be used to calculate person years of effort on R&D:
    (Person / s employed) x(Portion of their job spent on R&D) x(Portion of the year employed) = FTE  on R&D
    For example:
    -a full time employee who devotes 100 % of their time to R&D
    1 x 1 x 1 = 1 FTE on R&D
        - a full time employee spending 40 % of his / her time on R&D during half of the survey year:
    1 x 0.4 persons x 0.5 years = 0.2 FTE on R&D
        - a part - time employee working 40 % of a full time year doing only R&D
    1 x 0.4 x 1 = 0.4 FTE on R&D
        - 20 full - time researchers  spending 40 % of their time on R&D during the survey year:
    20 x 0.4 x 1 = 8 FTE on R&D

NOTE: please calculate FTEs for all R&D personnel
    Indirect Services:
    The labour costs of persons providing indirect services such as security and maintenance personnel, staff of central libraries, IT departments or
    head offices, should be excluded here but the relevant contribution included under “Other Current Costs in Question 8D.`;
    }

    return (
        instructionText != "-" ? <div ><InstructionWidget text={instructionText} /></div> : <div />
    )
}