import React, { Component, useState } from "react";
import Grid from '@material-ui/core/Grid';
import DataCaptureService from "@services/DataCaptureService";
import ProfileManagementService from "@services/ProfileManagementService";
import SpinnerModal from "@components/SpinnerModal";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from './addrespondentdialog.module.css';
import DropDownMenu from '@components/DropDownMenu';
import SupportAlert from '@components/SupportAlert';
import TextField from '@material-ui/core/TextField';
import FieldValidator from '@components/FieldValidator';

class AddRespondentDialog extends Component {
    state = {
        loading: false,
        organisationsLoading: false,
        surveyCyclesLoading: false,
        sectorsLoading: false,
        contactsLoading: false,
        realmUsers: this.props.realmUsers,

        emailAddress: '',
        firstName: '',
        lastName: '',
        designation: '',
        role: '',
        cellphone: '',
        workPhone: '',

        validEmail: false,
        validFirstName: false,
        validLastName: false,
        validCellphone: true,
        validWorkPhone: true,

        lookUps: [],
        allOrganisationStates: [],
        allOrganisationContacts: [],

        allSectorsDropdown: [],
        allSurveyCyclesDropdown: [],
        allOrganisationsDropdown: [],
        selectedOrganisationContactsDropdown: [],
        
        selectedOrganisationContacts: [],
        selectedOrganisationStateId: null,
        selectedOrganisationId: null,
        selectedContact: null,
        selectedSectorId: null,
        selectedSurveyCycle: process.env.REACT_APP_CURRENT_SURVEY_CYCLE,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ lookUps: this.props.lookUps });

        this.setState({ realmUsers: this.props.realmUsers });

        this.setState({ allSurveyCyclesDropdown: this.props.allSurveyCyclesDropdown });

        this.setState({ allSectorsDropdown: this.props.allSectorsDropdown });

        this.setState({ loading: false });
    }

    async reloadOrganisationContacts() {
        this.setState({ contactsLoading: true });

        try {
            const organisationContacts = await DataCaptureService.getAllOrganisationContactsBySurveyCycleId(this.state.selectedSurveyCycle);
            this.setState({ allOrganisationContacts: organisationContacts });
        } catch (ex) {
            console.log('Network error loading organisation contacts');
        }

        this.setState({ contactsLoading: false });

    }

    getLookUpNameById = (id) => {
        return this.state.lookUps.filter(lookUp => lookUp.key == id)[0].value;
    }

    clearDetails = () => {
        this.setState({ emailAddress: '' });
        this.setState({ validEmail: false });
        this.setState({ firstName: '' });
        this.setState({ validFirstName: false });
        this.setState({ lastName: '' });
        this.setState({ validLastName: false });
        this.setState({ designation: '' });
        this.setState({ selectedContact: null });
        this.setState({ selectedOrganisationContactsDropdown: [] });
    }

    userAlreadyExist = () => {
        return this.state.realmUsers.some(item => item.email.toLowerCase() === this.state.emailAddress.toLowerCase());
    }

    handleChangedValue = (evt) => {
        switch (evt.target.name) {
            case 'emailAddress': {
                this.setState({ validEmail: FieldValidator.validateEmail(evt.target.value) });
                this.setState({ emailAddress: evt.target.value });
                break;
            }
            case 'firstName': {
                this.setState({ validFirstName: FieldValidator.validateName(evt.target.value) });
                this.setState({ firstName: evt.target.value });
                break;
            }
            case 'lastName': {
                this.setState({ validLastName: FieldValidator.validateName(evt.target.value) });
                this.setState({ lastName: evt.target.value });
                break;
            }
            case 'cellphone': {
                this.setState({ validCellphone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ cellphone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'workPhone': {
                this.setState({ validWorkPhone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ workPhone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'designation': {
                this.setState({ designation: evt.target.value });
                break;
            }
            case 'surveyCycle': {
                this.setState({ validSurveyCycleId: evt.target.value.trim().length > 0 });
                this.setState({ selectedSurveyCycle: evt.target.value });
                this.clearDetails();
                this.reloadAllOrganisations(this.state.selectedSectorId, evt.target.value);
                break;
            }
            case 'sector': {
                this.setState({ validSectorId: evt.target.value.trim().length > 0 });
                this.setState({ selectedSectorId: evt.target.value });
                this.clearDetails();
                this.reloadAllOrganisations(evt.target.value, this.state.selectedSurveyCycle);
                break;
            }
            case 'organisation': {
                if (evt.target.value !== undefined && evt.target.value !== null && (evt.target.value.trim().length > 0)) {
                    this.setState({ validOrganisationId: evt.target.value.trim().length > 0 });
                    let orgId = this.state.allOrganisationStates.filter(organisationState => organisationState.id == evt.target.value)[0].organisationId;
                    this.setState({ selectedOrganisationId: parseFloat(orgId) });
                    this.setState({ selectedOrganisationStateId: parseFloat(evt.target.value) });
                    this.clearDetails();
                    this.reloadSelectedOrganisationContacts(parseFloat(evt.target.value));
                } else {
                    this.setState({ validOrganisationId: false });
                    this.setState({ selectedOrganisationId: null });
                    this.setState({ selectedOrganisationStateId: null });
                    this.setState({ selectedContact: null });
                    this.clearDetails();
                }
                break;
            }
            case 'contactState': {
                if (evt.target.value !== undefined && evt.target.value !== null && (evt.target.value.trim().length > 0)) {
                    this.setState({ validContactStateId: evt.target.value.trim().length > 0 });
                    this.setState({ contactStateId: parseFloat(evt.target.value) });
                    let newSelectedContact = this.state.selectedOrganisationContacts.filter(contact => contact.contactStateId == evt.target.value)[0];
                    this.setState({ selectedContact: newSelectedContact });

                    if (newSelectedContact !== undefined && newSelectedContact !== null) {
                        if (newSelectedContact.email !== undefined && newSelectedContact.email !== null) {
                            this.setState({ emailAddress: newSelectedContact.email.toLowerCase() });
                            this.setState({ validEmail: FieldValidator.validateEmail(this.state.selectedOrganisationContacts.filter(contact => contact.contactStateId == evt.target.value)[0].email) });
                        } else {
                            this.setState({ validEmail: false });
                        }

                        if (newSelectedContact.firstname !== undefined && newSelectedContact.firstname !== null) {
                            this.setState({ firstName: newSelectedContact.firstname });
                            this.setState({ validFirstName: FieldValidator.validateName(newSelectedContact.firstname) });
                        }

                        if (newSelectedContact.lastname !== undefined && newSelectedContact.lastname !== null) {
                            this.setState({ lastName: newSelectedContact.lastname });
                            this.setState({ validLastName: FieldValidator.validateName(newSelectedContact.lastname) });
                        }

                        if (newSelectedContact.designation !== undefined && newSelectedContact.designation !== null) {
                            this.setState({ designation: newSelectedContact.designation });
                        }
                    } else {
                        this.setState({ emailAddress: '' });
                        this.setState({ validEmail: false });
                        this.setState({ firstName: '' });
                        this.setState({ validFirstName: false });
                        this.setState({ lastName: '' });
                        this.setState({ validLastName: false });
                        this.setState({ designation: '' });
                    }
                } else {
                    this.setState({ validContactStateId: false });
                    this.setState({ contactStateId: null });
                    this.setState({ selectedContact: null });
                    this.setState({ emailAddress: '' });
                    this.setState({ validEmail: false });
                    this.setState({ firstName: '' });
                    this.setState({ validFirstName: false });
                    this.setState({ lastName: '' });
                    this.setState({ validLastName: false });
                    this.setState({ designation: '' });
                    this.setState({ selectedContact: null });
                }

                break;
            }
            default: {
                break;
            }
        }
    }

    async reloadAllOrganisations(sectorId, surveyCycleId) {
        this.setState({ organisationsLoading: true });

        try {

            const orgs = new Array();
            this.setState({ allOrganisationsDropdown: orgs });

            const organisationStates = await (async () => {
                const organisationStates = await ProfileManagementService.getOrganisationStatesBySectorIdAndSurveyCycleId(sectorId, surveyCycleId);
                return organisationStates;
            })([]);
            this.setState({ allOrganisationStates: organisationStates });

            organisationStates
                .filter(organisationState => organisationState.unitOfMeasure == true)
                .filter(organisationState => organisationState.organisationStatusId == 10)
                .map(organisationState => {
                    let orgName = organisationState.name.trim();
                    if (organisationState.parentId !== null) {
                        let parent = organisationStates.filter(orgState => orgState.id == organisationState.parentId)[0];
                        if (parent !== undefined && parent !== null) {
                            orgName = parent.name + ' / ' + orgName;

                            if (parent !== null && parent.parentId !== null) {
                                let grandparent = organisationStates.filter(orgState => orgState.id == parent.parentId)[0];
                                if (grandparent !== undefined && grandparent !== null) {
                                    orgName = grandparent.name + ' / ' + orgName;
                                }
                            }
                        }
                    }
                    orgs.push({ key: organisationState.id, value: orgName.trim() });
                });
            this.setState({ allOrganisationsDropdown: orgs.sort((a, b) => a.value > b.value ? 1 : -1) });

        } catch (ex) {
            SupportAlert.displayAlert('Network Error', 'An unexpected error occured while loading the organisations list. Please try again later.');
        }
        this.setState({ organisationsLoading: false });

    }

    async reloadSelectedOrganisationContacts(organisationStateId) {
        this.setState({ contactsLoading: true });

        try {

            const contacts = new Array();
            this.setState({ selectedOrganisationContactsDropdown: contacts });
            const contactStates = await DataCaptureService.getAllOrganisationContactsByOrganisationStateId(organisationStateId);
            this.setState({ selectedOrganisationContacts: contactStates });
            contactStates
                .filter(contactState => contactState.organisationStateId == organisationStateId)
                .map(contactState => {
                    if (!contacts.some(contact => contact.key === contactState.contactStateId)) {
                        let contactTypes = contactStates
                            .filter(cs => cs.contactStateId === contactState.contactStateId)
                            .sort((a, b) => (a.contactTypeId > b.contactTypeId) ? 1 : -1)
                            .map(cs => this.getLookUpNameById(cs.contactTypeId))
                            .join(',');
                        contacts.push({ key: contactState.contactStateId, value: (contactState.title + ' ' + contactState.firstname + ' ' + contactState.lastname + ' [' + contactTypes + '] ') })
                    }
                }
                );
            this.setState({ selectedOrganisationContactsDropdown: contacts.sort((a, b) => a.value > b.value ? 1 : -1) });

        } catch (ex) {
            SupportAlert.displayAlert('Network Error', 'An unexpected network error occured while loading the contacts list. Please try again later.');
        }
        this.setState({ contactsLoading: false });

    }

    getData() {

        let data = {
            userid: '',
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: null,
            enabled: true,
            email: this.state.emailAddress,
            attributes: {
                group_id: ["RESPONDENT"],
                source_of_data: [22],
                user_id: [parseInt(this.state.selectedContact.contactStateId)],
                sector_id: [parseInt(this.state.selectedSectorId)],
                survey_cycle_id: [parseInt(this.state.selectedSurveyCycle)],
                org_id: [parseInt(this.state.selectedOrganisationId)],
                org_state_id: [this.state.selectedOrganisationStateId],
                cellphone: [this.state.cellphone],
                workphone: [this.state.workPhone],
                designation: [this.state.designation],
            },
            realmRoles: ['dsp_respondent'],
            clientRoles: {
                role: ['respondents']
            },

        };

        return data;

    }

    dialogBody() {

        return <div className={styles.flex_row_container}>
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"Organisation Details"}</text>
                </h1>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    {(this.state.surveyCyclesLoading !== undefined && this.state.surveyCyclesLoading !== null && this.state.surveyCyclesLoading === true)
                                        ? <TextField
                                            className={styles.dialogInputs}
                                            require="required"
                                            name={'surveyCycle'}
                                            type="text"
                                            placeholder={''}
                                            id="outlined-basic"
                                            size="small"
                                            label={'Survey Cycle'}
                                            required
                                            value={'Loading ...'}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            readOnly
                                        />
                                        : <DropDownMenu
                                            name={'surveyCycle'}
                                            label={'Survey Cycle'}
                                            value={this.state.selectedSurveyCycle}
                                            onChange={(evt) => this.handleChangedValue(evt)}
                                            showKeys={false}
                                            menuOptions={this.state.allSurveyCyclesDropdown.sort((a, b) => a.key > b.key ? 1 : -1)}
                                            required
                                        />
                                    }
                                </Grid>
                                <Grid container item xs={6} >
                                    {(this.state.sectorsLoading !== undefined && this.state.sectorsLoading !== null && this.state.sectorsLoading === true)
                                        ? <TextField
                                            className={styles.dialogInputs}
                                            require="required"
                                            name={'sector'}
                                            type="text"
                                            placeholder={''}
                                            id="outlined-basic"
                                            size="small"
                                            label={'Sector'}
                                            required
                                            value={'Loading ...'}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            readOnly
                                        />
                                        : <DropDownMenu
                                            name={'sector'}
                                            label={'Sector'}
                                            onChange={(evt) => this.handleChangedValue(evt)}
                                            showKeys={false}
                                            menuOptions={this.state.allSectorsDropdown.sort((a, b) => a.value > b.value ? 1 : -1)}
                                            required
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    {(this.state.organisationsLoading != null && this.state.organisationsLoading == true) ?
                                        <TextField
                                            className={styles.dialogInputs}
                                            require="required"
                                            name={'organisation'}
                                            type="text"
                                            placeholder={''}
                                            id="outlined-basic"
                                            size="small"
                                            label={'Organisation'}
                                            required
                                            value={'Loading ...'}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            readOnly
                                        />
                                        : <DropDownMenu
                                            name={'organisation'}
                                            label={'Organisation'}
                                            onChange={(evt) => this.handleChangedValue(evt)}
                                            showKeys={false}
                                            menuOptions={this.state.allOrganisationsDropdown.sort((a, b) => a.value > b.value ? 1 : -1)}
                                            required
                                            busyLoading={this.state.organisationsLoading}
                                        />
                                    }
                                </Grid>
                                <Grid container item xs={6} >
                                    {(this.state.contactsLoading != null && this.state.contactsLoading == true) ?
                                        <TextField
                                            className={styles.dialogInputs}
                                            require="required"
                                            name={'contactState'}
                                            type="text"
                                            placeholder={'Last Name'}
                                            id="outlined-basic"
                                            size="small"
                                            label={'RDSMS Organisation Contact'}
                                            required
                                            value={'Loading ...'}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            readOnly
                                        />
                                        : <DropDownMenu
                                            name={'contactState'}
                                            label={'RDSMS Organisation Contact'}
                                            onChange={(evt) => this.handleChangedValue(evt)}
                                            showKeys={false}
                                            menuOptions={this.state.selectedOrganisationContactsDropdown.sort((a, b) => a.value > b.value ? 1 : -1)}
                                            required
                                            busyLoading={this.state.contactsLoading}
                                            helperText={(this.state.selectedContact !== null && this.userAlreadyExist()) ? 'User already registered on RDI' : null}
                                            error={(this.state.selectedContact !== null && this.userAlreadyExist())}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
            </div>
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"Primary Respondent Details"}</text>
                </h1>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        error={this.state.firstName.length > 0 && !this.state.validFirstName}
                                        helperText={!FieldValidator.validateName(this.state.firstName) ? 'Invalid First Name' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'firstName'}
                                        type="text"
                                        placeholder={'First Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'First Name'}
                                        required
                                        value={this.state.firstName}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >
                                    <TextField
                                        error={this.state.lastName.length > 0 && !this.state.validLastName}
                                        helperText={!FieldValidator.validateName(this.state.lastName) ? 'Invalid Last Name' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'lastName'}
                                        type="text"
                                        placeholder={'Last Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Last Name'}
                                        required
                                        value={this.state.lastName}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        error={this.state.cellphone.length > 0 && !this.state.validCellphone}
                                        helperText={!this.state.validCellphone ? 'Invalid cellphone number' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'cellphone'}
                                        type="text"
                                        placeholder={'072 - 309 - 4059'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Cellphone'}
                                        required={false}
                                        value={this.state.cellphone}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >
                                    <TextField
                                        error={this.state.workPhone.length > 0 && !this.state.validWorkPhone}
                                        helperText={!this.state.validWorkPhone ? 'Invalid work phone number' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'workPhone'}
                                        type="text"
                                        placeholder={'011 - 238 - 4859'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Work Telephone'}
                                        required={false}
                                        value={this.state.workPhone}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        className={styles.dialogInputs}
                                        name={'designation'}
                                        type="text"
                                        placeholder={'Designation'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Designation'}
                                        value={this.state.designation}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>


            </div>
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"Account Log in Details"}</text>
                </h1>
                <p>
                    <TextField
                        disabled
                        error={this.state.selectedContact !== null && this.state.emailAddress.length >= 0 && !this.state.validEmail}
                        helperText={this.state.selectedContact !== null && this.state.emailAddress.length >= 0 && !this.state.validEmail ? 'Invalid email address stored on RDSMS for this user' : null}
                        className={styles.dialogInputs}
                        require="required"
                        name={'emailAddress'}
                        type="text"
                        placeholder={'name@business.co.za'}
                        id="outlined-basic"
                        size="small"
                        label={'Email Address'}
                        required
                        value={this.state.emailAddress}
                        onChange={this.handleChangedValue}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </p>
            </div>
        </div>
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} label={'Dialog loading ...'} />
        } else {
            return <DialogWrapper
                {...this.props}
                onClick={(evt) => this.props.nextButton.onClick(evt, this.getData())}
                validEmail={this.state.validEmail && (this.state.selectedContact !== null && !this.userAlreadyExist())}
                content={
                    <React.Fragment>
                        {this.dialogBody()}
                    </React.Fragment>
                }
            />
        }
    }

}
export default AddRespondentDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            if (
                                !props.validEmail
                            ) {
                                SupportAlert.displayAlert('User registeration failed', 'Invalid email address stored on RDSMS for this user or this user is already registered to use RDI. Please review supplied information carefully.');
                            } else {
                                props.onClick(evt);
                                dialog.close(true);
                            }
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}

