import React, { useState, Component } from 'react';
import CaptureSetup from '../../components/CaptureSetup/CaptureSetup';
import Questionnaire from '@components/Questionnaire';
import SessionStateManager from '@components/SessionStateManager';
import DataCaptureService from '@services/DataCaptureService';
import styles from './datacapture.module.css';
import { CustomDialog, useDialog } from 'react-st-modal';

class SurveyStatus extends Component {

    state = {
        surveyCycleId: process.env.REACT_APP_CURRENT_SURVEY_CYCLE,
        setupComplete: false,
        organisationStateId: null,
        organisationStatus: null,
        capturedById: null,
        filledInById: null,
        captureType: null,
        dataCaptureId: null,
    }

    constructor() {
        super();
    }

    parseJwt(token) {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }

    async componentDidMount() {
        this.setState({ loading: true });

        SessionStateManager.clear();

        let bearer = localStorage.getItem('Bearer');
        if (!bearer){
            window.location = "/login";
        
          }
          

        let payload = this.parseJwt(bearer);

        this.setState({ capturedById: payload.user_id });

        this.setState({ loading: false });
    }

    onSetupComplete = (evt, organisationStatus, captureType, dataCaptureId) => {
        this.setState({ organisationStateId: organisationStatus.organisationStateId });
        this.setState({ organisationStatus: organisationStatus });
        this.setState({ captureType: captureType });
        this.setState({ dataCaptureId: dataCaptureId });
        this.setState({ filledInById: organisationStatus.filledInBy });
        this.setState({ setupComplete: true });
    }

    handleOnQuestionnaireComplete = (evt) => {
        let dialogContent = <Questionnaire
            organisationStatus={this.state.organisationStatus}
            organisationStateId={this.state.organisationStateId}
            capturedById={this.state.capturedById}
            filledInById={this.state.filledInById}
            captureType={this.state.captureType}
            printview={true}
        />

        const val = (async () => {
                const result = await CustomDialog(<QuestionnaireConfirmationDialog content={dialogContent} />, {
                    className: styles.printviewDialog,
                    title: 'Review & Confirm Questionnaire Submission',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                });

                if (result == true) {
                    this.completeSurvey();
                } else {
                    evt.preventDefault();
                }
            })([]);

    }

    completeSurvey() {
        const dataCapture = {
            "id": this.state.dataCaptureId,
            "completed": true,
            "capturedById": this.state.capturedById,
            "questionnaireTrackerId": this.state.organisationStatus.questionnaireTrackerId
        };

        DataCaptureService.completeCapture(dataCapture, this.state.captureType);

        this.setState({ setupComplete: false });
        this.setState({ organisationStateId: null });
        this.setState({ organisationStatus: null });
        this.setState({ captureType: null });
        this.setState({ filledInById: null });
        this.setState({ dataCaptureId: null });
    }

    render() {

        if (!this.state.setupComplete) {
            return <div className={styles.container_datacapture}>
                <div className={styles.container_row}>
                    <CaptureSetup surveyCycleId={this.state.surveyCycleId} capturedById={this.state.capturedById} onSetupComplete={this.onSetupComplete} />
                </div>
            </div>
        } else {
            return <div className={styles.container_datacapture}>
                <div className={styles.container_row}>
                    <Questionnaire
                        organisationStatus={this.state.organisationStatus}
                        organisationStateId={this.state.organisationStateId}
                        capturedById={this.state.capturedById}
                        filledInById={this.state.filledInById}
                        captureType={this.state.captureType}
                        printview={false}                                                // Set to false for navigation buttons
                        onQuestionnaireComplete={this.handleOnQuestionnaireComplete}
                        dataCaptureId={this.state.dataCaptureId}
                    />
                </div>
            </div>
        }

    }
}
export default SurveyStatus;

function QuestionnaireConfirmationDialog(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div className={styles.confirmDialogOverlay}>
                {props.content}
            </div>
            <div>
                <button type="button" className="btnBack"
                    onClick={() => {
                        dialog.close(false);
                    }}
                >
                    Cancel
                </button>
                <button type="button" className="btnNext"
                    onClick={() => {
                        dialog.close(true);
                    }}
                >
                    Submit
                </button>
            </div>
        </div>
    );
}