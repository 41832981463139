import React, { Component } from "react";
import SpinnerModal from '@components/SpinnerModal';
import SessionStateManager from "@components/SessionStateManager";
import styles from './questionnaire.module.css';

class QuestionnaireNavigationBar extends Component {
    state = {
        loading: true,
        falseLoading: false,
        stateChanged: false,
        questionnaireParts: this.props.questionnaireParts,
        previousQuestionnairePartId: null,
        currentQuestionnairePartId: null,
        nextQuestionnairePartId: null,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ questionnaireParts: this.props.questionnaireParts });
        this.setState({ currentQuestionnairePartId: this.props.currentQuestionnairePartId });

        if (this.props.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].id == this.props.currentQuestionnairePartId) {
            this.setState({ previousQuestionnairePartId: null });
            this.setState({ nextQuestionnairePartId: this.props.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[1].id });
        }

        if (this.props.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[this.props.questionnaireParts.length - 1].id == this.props.currentQuestionnairePartId) {
            this.setState({ nextQuestionnairePartId: null });
            this.setState({ previousQuestionnairePartId: this.props.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[this.props.questionnaireParts.length - 2].id });
        }

        // Stuff to be loaded goes here

        this.setState({ loading: false });
    }

    handleBack = (evt) => {

        this.setState({ nextQuestionnairePartId: this.state.currentQuestionnairePartId });
        this.setState({ currentQuestionnairePartId: evt.target.value });
        if (this.props.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].id == evt.target.value) {
            this.setState({ previousQuestionnairePartId: null });
        }

        for (let i = (this.props.questionnaireParts.length - 1); i > 0; i--) {
            if (this.props.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[i].id == evt.target.value) {
                this.setState({ previousQuestionnairePartId: this.props.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[i - 1].id });
            }
        };

        this.setState({ stateChanged: true });
        if (this.props.onNext !== undefined && this.props.onNext != null) {
            this.props.onNext(evt);
        }
    }

    handleNext = (evt) => {

        let thisValue = SessionStateManager.getSessionValue('954_0');
        if (!(this.props.captureType == 'RESPONDENT' && thisValue !== undefined && thisValue != null && (JSON.parse(thisValue) == true))) {
            this.setState({ previousQuestionnairePartId: this.state.currentQuestionnairePartId });
            this.setState({ currentQuestionnairePartId: evt.target.value });
            if (this.props.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[this.props.questionnaireParts.length - 1].id == evt.target.value) {
                this.setState({ nextQuestionnairePartId: null });
            }

            for (let i = 1; i < (this.props.questionnaireParts.length - 1); i++) {
                if (this.props.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[i].id == evt.target.value) {
                    this.setState({ nextQuestionnairePartId: this.props.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[i + 1].id });
                }
            };
        }

        this.setState({ stateChanged: true });
        if (this.props.onNext !== undefined && this.props.onNext != null) {
            this.props.onNext(evt);
        }
    }

    handleSave = (evt) => {

        if (this.props.onSave !== undefined && this.props.onSave != null) {
            this.props.onSave(evt);
        }

    }

    handleSubmit = (evt) => {
        if (this.props.onSubmit !== undefined && this.props.onSubmit != null) {
            this.props.onSubmit(evt);
        }
    }

    handleUpdateComplete = (evt) => {
        if (this.props.onUpdateComplete !== undefined && this.props.onUpdateComplete != null) {
            this.props.onUpdateComplete(evt);
        }
        window.location = "/researcherdashboard";
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            return (
                <div className={styles.questionnaireNavigationBarContainer}>
                    <table className={styles.questionnaireNavigationBarTable}>
                        <tbody>
                            <tr>
                                <td className={styles.questionnaireNavigationBarCell}>
                                    {
                                        this.state.previousQuestionnairePartId != null
                                            ? <button value={this.state.previousQuestionnairePartId} variant="contained" onClick={this.handleBack} className="btnBack">
                                                Back
                                            </button>
                                            : <div />
                                    }
                                </td>
                                <td className={styles.questionnaireNavigationBarCell}>
                                    {this.state.falseLoading == true
                                        ? <div style={{ display: (this.state.falseLoading == true) ? 'flex' : 'none' }} className={styles.modal}>
                                            <div className={styles.modal_content}>
                                                <div className={styles.loader}></div>
                                                <div className={styles.modal_text}>Saving...</div>
                                            </div>
                                        </div>
                                        : <button
                                            value={this.state.currentQuestionnairePartId}
                                            onClick={evt => {
                                                this.handleSave(evt);
                                                const func = (async () => {
                                                    this.setState({ falseLoading: true });
                                                    await new Promise(resolve => setTimeout(resolve, 2000));
                                                    this.setState({ falseLoading: false });
                                                })([]);
                                            }}
                                            type="button"
                                            className="btnNext"
                                        >Save
                                        </button>
                                    }
                                </td>
                                <td className={styles.questionnaireNavigationBarCell}>
                                    {
                                        this.state.nextQuestionnairePartId != null
                                            ? <button value={this.state.nextQuestionnairePartId} onClick={this.handleNext} type="button" className="btnNext">
                                                Next
                                            </button>
                                            : this.props.captureType == 'RESEARCHER_UPDATE'
                                                ? <button value={true} onClick={this.handleUpdateComplete} type="button" className="btnNext">
                                                    Complete
                                                </button>
                                                : <button value={true} onClick={this.handleSubmit} type="button" className="btnNext">
                                                    Review
                                                </button>
                                    }
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            )
        }
    }
}
export default QuestionnaireNavigationBar;