import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';

export default class DropDownMenu extends Component {

    handleChange = (evt) => {
        this.props.onChange(evt);
    }

    render() {
        return (
            <TextField
                error={(this.props.error !== undefined && this.props.error !== null) ? this.props.error : null}
                helperText={(this.props.helperText !== undefined && this.props.helperText !== null) ? this.props.helperText : null}
                className={this.props.className}
                value={this.props.value}
                name={this.props.name}
                id="dropdown-menu"
                fullWidth={true}
                select
                required={(this.props.required !== null && this.props.required === true) ? true : false}
                variant="outlined"
                size="small"
                label={this.props.label}
                onChange={(event) => this.handleChange(event)}
                readOnly={this.props.readOnly !== undefined && this.props.readOnly !== null && this.props.readOnly == true}
                SelectProps={{
                    native: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            >
                <option key={''} value={''}>
                    {this.props.busyLoading ? 'loading ...' : this.props.placeholder}
                </option>
                {this.props.menuOptions.map((option) => (
                    <option key={option.key} value={option.key}>
                        {(this.props.showKeys != null && this.props.showKeys == true) ? option.key + ' - ' + option.value : option.value}
                    </option>
                ))}
            </TextField>
        );
    }
}