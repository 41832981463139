import React, { Component } from "react";
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class HEPostgradHeadCountQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    async handleOnChange(evt) {
        let key = evt.target.attributes.questionFactId.value + '_' + evt.target.attributes.ordinalEntry.value;
        let value = evt.target.value;

        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
            const result = await CustomDialog(
                <UpdateAuditDialog
                    currentAnswer={SessionStateManager.getSessionValue(key)}
                    numericInput={true}
                />,
                {
                    className: styles.updateAuditDialog,
                    title: 'Update Submitted Value',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );

            if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                SessionStateManager.setSessionValue(key, result.newValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                this.setState({ sessionStateChanged: true });
            } else {
                evt.preventDefault();
                return;
            }
        } else {
            SessionStateManager.setSessionValue(key, value, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
            this.setState({ sessionStateChanged: true });
        }

        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    renderTable(tableName) {

        let tableQuestionFactStates = this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1);
        return (
            <div className={styles.headcountQuestionContainer}>
                <text className="text__16px">{tableName}</text>
                <table className="table">
                    <thead>
                        <tr>
                            <th rowSpan='2'>Personnel Categories and Highest Qualification</th>
                            <th colSpan='2'>African</th>
                            <th colSpan='2'>Coloured</th>
                            <th colSpan='2'>Indian/Asian</th>
                            <th colSpan='2'>White</th>
                            <th colSpan='2'>Non-SA</th>
                            <th colSpan='2'>Subtotal</th>
                            <th rowSpan='2'>Total</th>
                        </tr>
                        <tr>
                            <th>M</th>
                            <th>F</th>

                            <th>M</th>
                            <th>F</th>

                            <th>M</th>
                            <th>F</th>

                            <th>M</th>
                            <th>F</th>

                            <th>M</th>
                            <th>F</th>

                            <th>M</th>
                            <th>F</th>
                        </tr>

                    </thead>
                    <tbody>
                        {[...new Set(tableQuestionFactStates
                            .filter(
                                questionFactState => (
                                    (!questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                                )
                            )
                            .map((item) => item.sequenceNumberX))]
                            .sort()
                            .map(rowIndex =>
                                <tr>
                                    <th>{tableQuestionFactStates
                                        .filter(questionFactState => questionFactState.sequenceNumberX == rowIndex)[0].rowDimensionStates[0].dimensionAlias}</th>

                                    {tableQuestionFactStates
                                        .filter(questionFactState => (questionFactState.sequenceNumberX == rowIndex))
                                        .sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)
                                        .map(questionFactState =>
                                            questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().replace('-', '').includes('SUBTOTAL')
                                                ? <td
                                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : null}
                                                >
                                                    <input
                                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : styles.tableTotalCell}
                                                        type="text"
                                                        id="subtotal"
                                                        value={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0')}
                                                        questionId={this.props.questionState.question.id}
                                                        ordinalEntry={0}
                                                        questionFactId={questionFactState.questionFactId}
                                                        placeholder='0'
                                                        readOnly
                                                    />
                                                </td>
                                                : ((questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                                                    ? <td
                                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : null}
                                                    >
                                                        <input
                                                            className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : styles.tableTotalCell}
                                                            type="text"
                                                            id="total"
                                                            value={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0')}
                                                            questionId={this.props.questionState.question.id}
                                                            ordinalEntry={0}
                                                            questionFactId={questionFactState.questionFactId}
                                                            placeholder='0'
                                                            readOnly
                                                        />
                                                    </td>
                                                    : <td
                                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableInputBoxUpdated : null}
                                                    >
                                                        <input
                                                            className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                                            min='0'
                                                            type="text"
                                                            id="input_cell"
                                                            questionId={this.props.questionState.question.id}
                                                            ordinalEntry={0}
                                                            questionFactId={questionFactState.questionFactId}
                                                            value={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0')}
                                                            placeholder='0'
                                                            onClick={(evt) => {
                                                                if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                                                                    this.handleOnChange(evt);
                                                                }
                                                            }}
                                                            onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key) || (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE')) { event.preventDefault(); } }}
                                                            onChange={(evt) => this.handleOnChange(evt)}
                                                            onBlur={(evt) => this.handleOnBlur(evt)}
                                                        />
                                                    </td>
                                                )
                                        )
                                    }
                                </tr>
                            )
                        }

                        {[[...new Set(tableQuestionFactStates
                            .filter(questionFactState => questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                            .map((item) => item.sequenceNumberX))]
                            .sort((a, b) => a > b ? 1 : -1).pop()]
                            .map(rowIndex =>
                                <tr>
                                    <th colSpan='11'>{tableQuestionFactStates
                                        .filter(questionFactState => questionFactState.sequenceNumberX == rowIndex)
                                        .sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[0].rowDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase()}</th>

                                    {tableQuestionFactStates
                                        .filter(questionFactState => (questionFactState.sequenceNumberX == rowIndex))
                                        .sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)
                                        .map(questionFactState => questionFactState.columnDimensionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].dimensionAlias.toUpperCase().replace('-', '').includes('SUBTOTAL')
                                            ? <td
                                                className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : null}
                                            >
                                                <input
                                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : styles.tableTotalCell}
                                                    min='0'
                                                    type="text"
                                                    id='subtotal'
                                                    value={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0')}
                                                    questionId={this.props.questionState.question.id}
                                                    ordinalEntry={0}
                                                    questionFactId={questionFactState.questionFactId}
                                                    placeholder={0}
                                                    readOnly
                                                />
                                            </td>
                                            : <td
                                                className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : null}
                                            >
                                                <input
                                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : styles.tableTotalCell}
                                                    min='0'
                                                    type="text"
                                                    id='total'
                                                    value={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0')}
                                                    questionId={this.props.questionState.question.id}
                                                    ordinalEntry={0}
                                                    questionFactId={questionFactState.questionFactId}
                                                    placeholder={0}
                                                    readOnly
                                                />
                                            </td>
                                        )
                                    }
                                </tr>
                            )
                        }
                    </tbody>
                </table>
                <br />
            </div>
        )
    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            return this.renderTable()
        }
    }
}
export default HEPostgradHeadCountQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    {...props}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if (
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                            result.confirmed = true;
                            dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}