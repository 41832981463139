// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".research_downloads__ysKu8:hover{\r\n  color: var(--hsrcOrange) !important; \r\n}\r\n\r\n.research_container__1X1aY{\r\n  background-color: var(--hsrcBackgroundLight);\r\n}", "",{"version":3,"sources":["webpack://src/components/ResearchPaper/research.module.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".downloads:hover{\r\n  color: var(--hsrcOrange) !important; \r\n}\r\n\r\n.container{\r\n  background-color: var(--hsrcBackgroundLight);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloads": "research_downloads__ysKu8",
	"container": "research_container__1X1aY"
};
export default ___CSS_LOADER_EXPORT___;
