import React from 'react';

import styles from './logout.module.css';

function Logout() {


    localStorage.clear();
    sessionStorage.clear();

    window.location.href = '/login';


    return <React.Fragment>
        <div className={styles.container_logout}>


            <div>
                <h1 className="text__18px">
                    <text className="text__18px">
                        You have been logged out successfully
                    </text>
                    <text className="text__18px__orange">
                    We’re redirecting you to the Home Page.
                    </text>
                </h1>

            </div>

        </div>
    </React.Fragment>
}

export default Logout;