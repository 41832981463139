import React, {Component} from "react";

import MeasureSupportTrackImage from "@images/Home/measureSupportTrack.svg";

import styles from './support.module.css';

class Support extends Component {
   

    render() {
        return (
            <div className={styles.support_container}>
                <img className={"measure_support_track_image"} src={MeasureSupportTrackImage} />
            </div>
        );
    }

}
export default Support;