import React, { Component, useState } from "react";
import DataCaptureService from "@services/DataCaptureService";
import ProfileManagementService from "@services/ProfileManagementService";
import FieldworkManagementService from "@services/FieldworkManagementService";
import SpinnerModal from "@components/SpinnerModal";
import TableWidget from '@components/TableWidget';
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from './interactionhistorydialog.module.css';

class InteractionHistoryDialog extends Component {
    state = {
        loading: true,
        interactionTypes: [],
        surveysUsers: [],
        interactionHistory: []
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        const sourcesOfData = await DataCaptureService.getLookups();

        const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
        this.setState({ surveysUsers: surveysUsers });

        let interactionTypes = [];
        sourcesOfData.filter((lookup) => (lookup.type.includes('_INTERACTION_'))).map(item => {
            interactionTypes.push({ key: item.varenum, value: item.name });
        });

        const savedInteractionHistory = await FieldworkManagementService.getInteractionsByOrganisationStateId(this.props.organisationStatus.organisationStateId);
        if (savedInteractionHistory !== undefined && savedInteractionHistory !== null && savedInteractionHistory != '') {
            this.setState({ interactionHistory: savedInteractionHistory });
        }

        this.setState({ interactionTypes: interactionTypes });

        this.setState({ loading: false });
    }

    getContactNameByContactStateId(contactStateId) {
        let orgContacts = this.props.organisationStatus.organisationContactList;

        let contact = (contactStateId != null && orgContacts !== undefined && orgContacts != null && orgContacts.length > 0)
            ? orgContacts.filter(contact => contact.contactStateId == contactStateId)[0]
            : null;

        return contact != null ? (contact.title + ' ' + contact.firstname + ' ' + contact.lastname) : 'No selected contact';
    }

    getSurveysUserNameById(userId) {
        let name = (this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].firstname + ' ' + this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].lastname);
        return name;
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            let organisationContacts = [];

            this.props.organisationStatus.organisationContactList.map(item => {
                organisationContacts.push({ key: item.contactId, value: this.getContactNameByContactStateId(item.contactStateId) });
            });

            let contact = this.props.organisationStatus !== undefined && this.props.organisationStatus !== null && this.props.organisationStatus.filledInBy !== undefined && this.props.organisationStatus.filledInBy !== null
                ? this.props.organisationStatus.organisationContactList.filter(contact => contact.contactStateId == this.props.organisationStatus.filledInBy)[0]
                : null;

            let columns = [
                { label: "Interaction Date", name: "interactionDate" },
                { label: "Interacting Fieldworker", name: "interactingFieldworker" },
                { label: "Interaction Type", name: "interactionType" },
                { label: "Interaction Status", name: "interactionStatus" },
                { label: "Comment", name: "comment" },
            ]

            let tableData = [];

            this.state.interactionHistory.sort((a, b) => a.id < b.id ? 1 : -1).map(interaction => {
                let row = {
                    interactionDate: new Date(interaction.interactionDate).toLocaleString('en-ZA'),
                    interactingFieldworker: this.getSurveysUserNameById(interaction.interactedBy),
                    interactionType: this.state.interactionTypes.filter(i => i.key == interaction.interactionType)[0].value,
                    interactionStatus: this.state.interactionTypes.filter(i => i.key == interaction.interactionStatus)[0].value,
                    comment: interaction.comment,
                }
                tableData.push(row);
            });

            let options = {
                selectableRowsHideCheckboxes: true,
            };

            return <DialogWrapper {...this.props} onClick={(evt) => this.props.nextButton.onClick(evt, this.state.organisationStatus)} content={
                <React.Fragment>
                    <div className={styles.featuredInteractionHistoryDialogItem}>
                        <div className={styles.featuredItem} >
                            <span><h1 className={styles.featuredTitleHeader}>{'Organisation Details'}</h1></span>
                            <div className={styles.featuredItem} >
                                {'Organisation name : ' + ((this.props.organisationStatus.parentOrganisationStateName !== undefined && this.props.organisationStatus.parentOrganisationStateName !== null && this.props.organisationStatus.parentOrganisationStateName !== '') ? this.props.organisationStatus.parentOrganisationStateName + ' / ' : '') + this.props.organisationStatus.organisationStateName}<br />
                                {'Receipt number : ' + (this.props.organisationStatus.receiptNumber !== undefined && this.props.organisationStatus.receiptNumber !== null ? this.props.organisationStatus.receiptNumber : 'Not received')}<br />
                                {'Researcher : ' + this.props.organisationStatus.selectedResearcher}<br />
                                {'Survey Critical Firm/Unit : ' + ((this.props.organisationStatus.surveyCritical !== undefined && this.props.organisationStatus.surveyCritical !== null && this.props.organisationStatus.surveyCritical === true) ? 'Yes' : 'No')}<br />
                            </div>
                        </div>
                        <div className={styles.featuredItem} >
                            <span><h1 className={styles.featuredTitleHeader}>{'Respondent Details'}</h1></span>
                            <div className={styles.featuredItem} >
                                {'Selected Contact : ' + this.getContactNameByContactStateId(this.props.organisationStatus.filledInBy)}<br />
                                {'Email : ' + ((contact !== undefined && contact !== null) ? contact.email : 'Not available')}<br />
                            </div>
                        </div>
                        {/* <div className={styles.featuredItem} > */}
                        {/* <span><h1 className={styles.featuredTitleHeader}>{'Interaction History'}</h1></span> */}
                        {this.state.interactionHistory !== undefined && this.state.interactionHistory !== null && this.state.interactionHistory.length > 0
                            ? <TableWidget title={<h1 className={styles.featuredTitleHeader}>{'Interaction History'}</h1>} titleSeperator={false} rows={tableData} columns={columns} options={options} />
                            : <div className={styles.featuredItem} >
                                <span>
                                    <h1 className={styles.featuredTitleHeader}>{'Interaction History'}</h1>
                                </span>
                                <div className={styles.featuredItem}>No interaction history for this organisation during current survey cycle</div>
                            </div>
                        }
                        {/* </div> */}
                    </div>
                </React.Fragment>
            }
            />
        }
    }

}
export default InteractionHistoryDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            props.onClick(evt);
                            dialog.close(true);
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}

