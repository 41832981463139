import React, { Component, useState } from "react";
import DataCaptureService from "@services/DataCaptureService";
import ProfileManagementService from "@services/ProfileManagementService";
import ResearcherViewDialog from '@components/ResearcherViewDialog';
import SpinnerModal from "@components/SpinnerModal";
import MUIDataTable from 'mui-datatables';
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from './sectorviewdialog.module.css';
import CSVGenerator from '@components/CSVGenerator';
import NumberTools from '@components/NumberTools';

class SectorViewDialog extends Component {
    state = {
        loading: true,
        surveysUsers: [],
        allOrganisationStatuses: [],
        sectorId: null,
        organisationStatus: null,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ sectorId: this.props.sectorId });

        const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
        this.setState({ surveysUsers: surveysUsers });

        const organisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId(this.props.sectorId, process.env.REACT_APP_CURRENT_SURVEY_CYCLE);
        this.setState({ allOrganisationStatuses: organisationStatuses });

        this.setState({ loading: false });
    }

    getSurveysUserNameById(userId) {
        let name = (this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].firstname + ' ' + this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].lastname);
        return name;
    }

    async onResearcherClicked(rowData, rowMeta) {
        let fieldworkerId = rowData[0];
        let sectorId = rowData[1];

        let sectorName = rowData[2];

        let title = 'Researcher: ' + rowData[3] + ' | Sector: ' + sectorName;

        const result = await CustomDialog(
            <ResearcherViewDialog
                {...this.props}
                backButton={{ text: 'Close' }}
                sectorId={sectorId}
                sectorName={sectorName}
                fieldworkerId={fieldworkerId}
            />,
            {
                className: styles.sectorResearcherViewDialog,
                title: title,
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }

    calculateERD() {
        let total_ERD = 0;

        this.state.allOrganisationStatuses
            .filter(orgStatus => orgStatus.sectorId == this.props.sectorId)
            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
            .filter(orgStatus => (orgStatus.expenditure !== undefined && orgStatus.expenditure !== null && orgStatus.expenditure > 0))
            .map(orgStatus => {
                total_ERD = total_ERD + (isNaN(orgStatus.expenditure) ? 0 : orgStatus.expenditure);
            });

        return total_ERD;
    }

    fieldworkerSectorViewTable() {
        let total_ERD = this.calculateERD();

        let sectorExpenditureLabel = '';

        switch (this.props.sectorId) {
            case 1: {
                sectorExpenditureLabel = 'BERD';
                break;
            };

            case 2: {
                sectorExpenditureLabel = 'GOVERD';
                break;
            }

            case 3: {
                sectorExpenditureLabel = 'HERD';
                break;
            }

            case 4: {
                sectorExpenditureLabel = 'NPO Expenditure on R&D';
                break;
            }

            case 5: {
                sectorExpenditureLabel = 'Science Councils Expenditure on R&D';
                break;
            }

        }

        let fieldworkerTableData = [];

        let fieldworkerColumns = [
            {
                label: "Fieldworker ID", name: "fieldworkerId"
                , options: {
                    display: false,
                }
            },
            {
                label: "Sector ID", name: "sectorId"
                , options: {
                    display: false,
                }
            },
            {
                label: "Sector Name", name: "sectorName"
                , options: {
                    display: false,
                }
            },
            { label: "Researcher", name: "fieldworkerName" },
            { label: "In Field", name: "assignedCount" },
            { label: "Returned", name: "returnedCount" },
            { label: "Nil Returns", name: "nilReturnCount" },
            { label: "Responses (not nil)", name: "notNilCount" },
            { label: "% Nils vs Returns", name: "percentageNils" },
            { label: "R&D Expenditure (R`000)", name: "ERD_Value" },
            { label: "% of " + sectorExpenditureLabel + " (R " + NumberTools.transformToCurrency(total_ERD / 1000) + ')', name: "percentageERD" },
        ];

        let thisSurveysUsers = [];

        if (this.state.surveysUsers !== undefined && this.state.surveysUsers !== null && this.state.surveysUsers.length > 0) {
            this.state.surveysUsers.filter((surveysUser) => surveysUser.active == true).map(item => {
                thisSurveysUsers.push({ key: item.id, value: this.getSurveysUserNameById(item.id) });
            });

            thisSurveysUsers.map((surveysUser) => {
                if (surveysUser.key != 37 && surveysUser.key != 1) {
                    // Exclude super user and RDI online user
                    let assignedCount = 0;
                    let returnedCount = 0;
                    let onlineReturnCount = 0;
                    let nilReturnCount = 0;
                    let ERD_Sum = 0;

                    if (this.state.allOrganisationStatuses !== undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length > 0) {
                        assignedCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.sectorId == this.props.sectorId)
                            .filter(orgStatus => orgStatus.fieldworkerId == surveysUser.key)
                            .length;

                        returnedCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.sectorId == this.props.sectorId)
                            .filter(orgStatus => orgStatus.fieldworkerId == surveysUser.key)
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .length;

                        onlineReturnCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.sectorId == this.props.sectorId)
                            .filter(orgStatus => orgStatus.fieldworkerId == surveysUser.key)
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .filter(orgStatus => orgStatus.datacapture1CapturedById == 37)
                            .length;

                        nilReturnCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.sectorId == this.props.sectorId)
                            .filter(orgStatus => orgStatus.fieldworkerId == surveysUser.key)
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .filter(orgStatus => (orgStatus.nilReturn !== undefined && orgStatus.nilReturn !== null && orgStatus.nilReturn === true))
                            .length;

                        this.state.allOrganisationStatuses
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.sectorId == this.props.sectorId)
                            .filter(orgStatus => orgStatus.fieldworkerId == surveysUser.key)
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .filter(orgStatus => (orgStatus.expenditure !== undefined && orgStatus.expenditure !== null && orgStatus.expenditure > 0))
                            .map(orgStatus => {
                                ERD_Sum = ERD_Sum + (isNaN(orgStatus.expenditure) ? 0 : orgStatus.expenditure);
                            });
                    }

                    let row = {
                        fieldworkerId: surveysUser.key,
                        sectorId: this.props.sectorId,
                        sectorName: this.props.sectorName,
                        fieldworkerName: surveysUser.value,
                        assignedCount: assignedCount,
                        returnedCount: returnedCount,
                        nilReturnCount: nilReturnCount,
                        notNilCount: (returnedCount - nilReturnCount),
                        percentageNils: (returnedCount > 0 ? ((100 * nilReturnCount) / returnedCount) : 0).toFixed(2) + ' %',
                        ERD_Value: 'R ' + NumberTools.transformToCurrency(ERD_Sum / 1000),
                        percentageERD: (total_ERD > 0 ? ((100 * ERD_Sum) / total_ERD) : 0).toFixed(2) + ' %',
                    }
                    fieldworkerTableData.push(row);
                }

            });
        }

        let tableTitle = ('Fieldwork Summary').toUpperCase();

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [10],
            rowsPerPageOptions: [10, 20, 50, 100],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            onRowClick: this.onResearcherClicked,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            row.data.shift(); // shift out fieldworkerId
                            row.data.shift(); // shift out sectorId
                            row.data.shift(); // shift out sectorName
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        return <div className={styles.flex_row_container}>
            <div className={styles.featuredItem}>
                <MUIDataTable
                    title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                    data={fieldworkerTableData}
                    columns={fieldworkerColumns}
                    options={options}
                />
            </div>
        </div>
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            return <DialogWrapper
                {...this.props}
                onClick={(evt) => this.props.nextButton.onClick(evt, this.state.organisationStatus)}
                content={
                    <React.Fragment>
                        {this.fieldworkerSectorViewTable()}
                    </React.Fragment>
                }
            />
        }
    }

}
export default SectorViewDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            props.onClick(evt);
                            dialog.close(true);
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}

