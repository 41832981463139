import React, { useState, Component } from 'react';
import PreviousSurveysSetup from '../../components/PreviousSurveysSetup/PreviousSurveysSetup';
import SessionStateManager from '@components/SessionStateManager';
import styles from './previoussurveys.module.css';

class PreviousSurveys extends Component {

    state = {
        surveyCycleId: process.env.REACT_APP_CURRENT_SURVEY_CYCLE,
        capturedById: null,
    }

    constructor() {
        super();
    }

    parseJwt(token) {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }

    async componentDidMount() {
        this.setState({ loading: true });

        SessionStateManager.clear();

        let bearer = localStorage.getItem('Bearer');
        if (!bearer){
            window.location = "/login";
        
          }

        let payload = this.parseJwt(bearer);

        this.setState({ capturedById: payload.user_id });

        this.setState({ loading: false });
    }

    render() {
        return <div className={styles.container_previoussurveys}>
            <div className={styles.container_row}>
                <PreviousSurveysSetup surveyCycleId={this.state.surveyCycleId} capturedById={this.state.capturedById} />
            </div>
        </div>
    }
}
export default PreviousSurveys;