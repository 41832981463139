import MessagingService, {
    sendEmail,
    sendRespondentCompletedEmail,
    sendLog
} from './MessagingService';

export default {
    MessagingService,
    sendEmail,
    sendRespondentCompletedEmail,
    sendLog
}