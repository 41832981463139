import React, { Component } from "react";
import { ArrowForward } from '@mui/icons-material'
import axios from "axios";
import qs from "qs";
import styles from './signup.module.css';
import ProfileManagementService from '@services/ProfileManagementService';
import { Alert } from 'react-st-modal';

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: "",
            email: "",

        };
    }



    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    }

    handleSubmit = event => {
        event.preventDefault();

        this.subScribe();
    }


    async subScribe() {

        const result = await ProfileManagementService.subscribeToNewsletter(this.state.email);

        try {
            { Alert(result, 'Newsletter Subscription') };

        } catch (ex) {
            { Alert("Newsletter Subscription failed, please try again later", 'Newsletter Subscription') };
        }
    }

    render() {
        return (
            <div className={styles.signup_container}>
                <text className="text__24px">
                    Sign up to find out more and to receive occasional news and updates
                </text>
                <form className={styles.signup_form} onSubmit={this.handleSubmit}>
                    <input className={styles.signup__input} onChange={this.handleEmailChange} required placeholder="Email Address"></input>
                    <button className={styles.signup__button} type="submit"><ArrowForward className={styles.arrows} /></button>

                </form>

            </div>
        );
    }

}
export default Signup;