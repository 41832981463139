import React, { Component } from "react";


export default function SectorContact(props) {



  return (
    <React.Fragment>
      <div>
        {props.sector.toUpperCase().includes('GOV') ?
          <table width="100%">
            <thead>
              <tr>
                <th >Sector</th>
                <th >Name</th>
                <th >Contact Number</th>
                <th >E-mail</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Government</td>
                <td>Dr Mario Clayford (Sector Leader)</td>
                <td>021 466 7829</td>
                <td>mclayford@hsrc.ac.za</td>
              </tr>
              <tr>
                <td>Government</td>
                <td>Mr Theodore Sass</td>
                <td>061 470 6983 </td>
                <td>tsass@hsrc.ac.za</td>
              </tr>
              <tr>
                <td>Not-For-Profit</td>
                <td>Ms Natasha Saunders (Sector Leader)</td>
                <td>021 466 7886</td>
                <td>nsaunders@hsrc.ac.za</td>
              </tr>
              <tr>
                <td>Science Councils</td>
                <td>Dr Mario Clayford (Sector Leader)</td>
                <td>021 466 7829</td>
                <td>mclayford@hsrc.ac.za</td>
              </tr>
            </tbody>
          </table>


          : <span></span>}

        {props.sector.toUpperCase().includes('BUSINESS') ?
          <table width="100%">
            <thead>
              <tr>

                <th >Name</th>
                <th >Contact Number</th>
                <th >E-mail</th>
              </tr>
            </thead>
            <tbody>
              <tr>

                <td>Mr Jerry Mathekga (Sector Leader)</td>
                <td>021 466 7811</td>
                <td>jmathekga@hsrc.ac.za</td>
              </tr>
              <tr>

                <td>Dr Kgabo Ramoroka </td>
                <td>021 466 8004</td>
                <td>khramoroka@hsrc.ac.za</td>
              </tr>


              <tr>

                <td>Mr Sintu Mavi</td>
                <td></td>
                <td>smavi@hsrc.ac.za</td>
              </tr>
              <tr>

                <td>Ms Litha Phika</td>
                <td>021 466 7811</td>
                <td>lphika@hsrc.ac.za</td>
              </tr>
  
        <tr>
              
                <td>Mr Theodore Sass</td>
                <td>021 466 7872</td>
                <td>tsass@hsrc.ac.za</td>
              </tr>


            </tbody>
          </table>

          : <span></span>}

        {props.sector.toUpperCase().includes('EDUCATION') ?

          <table width="100%">
            <thead>
              <tr>

                <th >Name</th>
                <th >Contact Number</th>
                <th >E-mail</th>
              </tr>
            </thead>
            <tbody>

              <tr>

                <td>Ms Natalie Vlotman</td>
                <td>021 466 7826</td>
                <td>
                  nvlotman@hsrc.ac.za </td>
              </tr>
              <tr>

                <td>Mr Theodore Sass</td>
                <td>021 466 7872</td>
                <td>tsass@hsrc.ac.za</td>
              </tr>


            </tbody>
          </table>

          : <span></span>}
      </div>
    </React.Fragment>
  )

}

