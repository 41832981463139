import FieldworkManagementService, {
    saveRdiMetadata,
    saveRdiRespondentInteraction,
    saveLogicalErrors,
    getLogicalErrorsByQuestionnaireTrackerId,
    getAllLogicalErrorsBySurveyCycle,
    getInteractionsByOrganisationStateId,
    getInteractionsBySurveyCycleAndSurveysUser,
    getInteractionsBySurveyCycle,
    getFieldworkReportDataBySurveyCycle
} from './FieldworkManagementService';

export default { FieldworkManagementService, saveRdiMetadata, saveRdiRespondentInteraction, saveLogicalErrors, getLogicalErrorsByQuestionnaireTrackerId, getAllLogicalErrorsBySurveyCycle, getInteractionsByOrganisationStateId, getInteractionsBySurveyCycleAndSurveysUser, getInteractionsBySurveyCycle, getFieldworkReportDataBySurveyCycle }