import React from 'react';


import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip, Cell
} from 'recharts';

import pageHint from '@images/info.svg';

import styles from "./barchart.module.css";
import Separator from "@components/Separator";



const COLORS = ["#00BA88", "#669FF6", "#7381EA", "#133454", "#415167", "#A2A1B2", "#A17FEF", "#FF7F50", "#FFD476", "#FF3407"];

export default function BarChartWidget(props) {

  let xAxis = props.xAxis;
  let dataKey = props.dataKey1;

  return (
    <React.Fragment>
      <div className={styles.featuredItem}>
        <span className={styles.featuredTitleHeader}>{props.headerTitle}
          <span className="pageHint">
            <img src={pageHint} />
            <span className="tooltiptext">{props.toolTip}</span>
          </span>
        </span>


        <Separator />
        <span className={styles.featuredTitle}>{props.title}</span>
        <br />
        <span className={styles.featuredSub}>{props.subTitle}</span>

        <div className={styles.featuredTextContainer}>
          <ResponsiveContainer width="99%"  aspect={1}>
            <BarChart type="monotone" data={props.data} margin={{ left: 40, right: 50, top: 60, bottom: 40 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip className="label_text" />
              <Bar dataKey={dataKey} barSize={10} radius={[5, 5, 5, 5]}  >
                {
                  props.data.map((entry, index) => <Cell fill={COLORS[index % COLORS.length]} />)
                }
              </Bar>
              
              <XAxis
                angle={-90}
                dataKey={xAxis}
                textAnchor="end"
                interval={0}
                tickFormatter={(value) => value}
                height={175}
              />
              
              <YAxis />

            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

    </React.Fragment>


  );

}
