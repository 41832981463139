import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import deleteButton from '@images/close.svg';
import addButton from '@images/addrow_icon.svg';
import DropDownMenu from "@components/DropDownMenu";
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import styles from '../questionnaire.module.css';
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';

class ActivitySICQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
        activitySicCodesValues: new Map(),
        activitySICCodesRows: [],
        activitySicCodesTotal: 0,
        rowFactIds: null,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        // things to load before screen loads goes here

        this.setState({ loading: false });
    }

    async handleOnChange(evt, questionFactId, ordinal, menuOptions, numericInput) {
        let key = questionFactId + '_' + ordinal;
        let value = evt.target.value;

        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
            const result = await CustomDialog(
                <UpdateAuditDialog
                    currentAnswer={SessionStateManager.getSessionValue(key)}
                    menuOptions={menuOptions}
                    numericInput={numericInput}
                />,
                {
                    className: styles.updateAuditDialog,
                    title: 'Update Submitted Value',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );

            if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                SessionStateManager.setSessionValue(key, result.newValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                this.setState({ sessionStateChanged: true });
            } else {
                evt.preventDefault();
            }
        } else {
            SessionStateManager.setSessionValue(key, value, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");

            for (let ord = 0; ord <= ordinal; ord++) {
                this.state.rowFactIds.forEach(factId => {
                    let thisKey = factId + '_' + ord;
                    let thisValue = SessionStateManager.getSessionValue(thisKey);
                    thisValue = (thisValue !== undefined && thisValue != null) ? thisValue : '';
                    SessionStateManager.setSessionValue(thisKey, thisValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
                });
            }

            this.setState({ sessionStateChanged: true });
        }
        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    CONFIG_DEFAULT_MINIMUM_ROWS = 3;

    handleAddActivitySICCodesRow = (evt, factIds) => {
        this.addActivitySICCodesRow(factIds);
    };

    addActivitySICCodesRow = (factIds) => {
        const item = {
            ordinalEntry: parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + this.state.activitySICCodesRows.length),
            factIds: factIds
        };
        this.setState({
            activitySICCodesRows: [...this.state.activitySICCodesRows, item]
        });
    };

    handleRemoveActivitySICCodesRow = (evt) => {

        if (this.state.activitySICCodesRows.length > 0) {
            let rowToRemove = this.state.activitySICCodesRows.sort((a, b) => a.ordinalEntry > b.ordinalEntry ? 1 : -1)[this.state.activitySICCodesRows.length - 1];

            // Updating total by deleting all values for factIds of this row and re-calculating total
            rowToRemove.factIds.forEach(factId => {
                let thisKey = 'activity_sic_' + rowToRemove.ordinalEntry + '_' + factId
                this.state.activitySicCodesValues.delete(thisKey);
            });

            let sum = parseFloat(0);
            this.state.activitySicCodesValues.forEach((value, key) => {
                sum = sum + parseFloat(value)
            });
            this.setState({ activitySicCodesTotal: sum });

            // Updating DB by nullifying all facts saved against this row if any
            // this.nullifyFacts(rowToRemove.ordinalEntry, rowToRemove.factIds)

            rowToRemove.factIds.forEach(factId => {
                let thisKey = factId + '_' + rowToRemove.ordinalEntry;
                SessionStateManager.setSessionValue(thisKey, '', this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId);
                SessionStateManager.deleteSessionValue(thisKey);
            });
            this.setState({ sessionStateChanged: true });

            this.setState({
                activitySICCodesRows: this.state.activitySICCodesRows.sort((a, b) => a.ordinalEntry > b.ordinalEntry ? 1 : -1).slice(0, -1)
            });
        }
    };

    calculatePercentageTotal(valueFactId) {
        let sum = parseFloat(0);
        let activityCodes = SessionStateManager.allEntriesByQuestionFactId(valueFactId);

        activityCodes.map((item) => {
            sum = sum + parseFloat(item.value != null && item.value != '' && !isNaN(item.value) ? item.value : 0);
        });

        return sum.toFixed(2);
    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            let dropdownItems = new Array();

            this.props.questionState.question.questionDimensions.filter(dimension => dimension.canBeQuestionMeasureGroup == true)[0].questionDimensions.map(dimension => {
                dropdownItems.push({ key: dimension.name, value: dimension.description.trim() })
            });

            let activityFactId = this.props.questionState.questionFactStates.sort((a, b) => (a.sequenceNumberY > b.sequenceNumberY) ? 1 : -1)[0].questionFactId;
            let sicCodeFactId = this.props.questionState.questionFactStates.sort((a, b) => (a.sequenceNumberY > b.sequenceNumberY) ? 1 : -1)[1].questionFactId;
            let percentFactId = this.props.questionState.questionFactStates.sort((a, b) => (a.sequenceNumberY > b.sequenceNumberY) ? 1 : -1)[2].questionFactId;
            let rowFactIds = [activityFactId, sicCodeFactId, percentFactId];
            if (this.state.rowFactIds == null) {
                this.setState({ rowFactIds: rowFactIds });
            }

            let initialRows = 0;

            let activitiesCount = SessionStateManager.countEntriesByQuestionFactId(activityFactId);
            initialRows = activitiesCount;

            let sicCodeCount = SessionStateManager.countEntriesByQuestionFactId(sicCodeFactId);
            initialRows = sicCodeCount > initialRows ? sicCodeCount : initialRows;

            let percentCount = SessionStateManager.countEntriesByQuestionFactId(percentFactId);
            initialRows = percentCount > initialRows ? percentCount : initialRows;

            let rowsToAdd = (this.CONFIG_DEFAULT_MINIMUM_ROWS < initialRows) ? (initialRows - this.CONFIG_DEFAULT_MINIMUM_ROWS - this.state.activitySICCodesRows.length) : 0;

            for (var rows = 0; rows < rowsToAdd; rows++) {
                try {
                    this.addActivitySICCodesRow(rowFactIds);
                } catch (e) { }
            }

            let defaultOrdinals = [];
            for (var ordinal = 0; ordinal < this.CONFIG_DEFAULT_MINIMUM_ROWS; ordinal++) {
                defaultOrdinals.push(ordinal);
            }

            return <div className={styles.activitySICQuestionContainer}>
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th width="40%">Activities</th>
                            <th width="40%">SIC</th>
                            <th width="20%">Company Income Obtained (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {defaultOrdinals.sort().map(ordinal => (
                            <tr>
                                <td>
                                    {(ordinal == 1)
                                        ? <img
                                            src={deleteButton}
                                            alt="delete row"
                                            onClick={this.handleRemoveActivitySICCodesRow}
                                        />
                                        : (ordinal == 0)
                                            ? <img
                                                src={addButton}
                                                alt="add row"
                                                onClick={(evt) => this.handleAddActivitySICCodesRow(evt, rowFactIds)}
                                            />
                                            : <div />
                                    }
                                </td>
                                <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, activityFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : null}
                                >
                                    <input
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, activityFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                        type="text"
                                        questionId={this.props.questionState.question.id}
                                        ordinalEntry={ordinal}
                                        questionFactId={activityFactId}
                                        value={SessionStateManager.getSessionValue(activityFactId + '_' + ordinal)}
                                        onChange={evt => this.handleOnChange(evt, activityFactId, ordinal, null, false)}
                                        onBlur={this.handleOnBlur}
                                        placeholder={'Activity'}
                                    />
                                </td>
                                <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, sicCodeFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : null}
                                >
                                    <DropDownMenu
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, sicCodeFactId + '_' + ordinal) ? styles.dropdownBoxUpdated : styles.dropdownBox}
                                        questionId={this.props.questionState.question.id}
                                        questionFactId={sicCodeFactId}
                                        ordinalEntry={ordinal}
                                        name={'sic_codes'}
                                        label={'SIC Code'}
                                        value={SessionStateManager.getSessionValue(sicCodeFactId + '_' + ordinal)}
                                        onChange={evt => this.handleOnChange(evt, sicCodeFactId, ordinal, dropdownItems.sort((a, b) => a.key > b.key ? 1 : -1), false)}
                                        showKeys={true}
                                        menuOptions={dropdownItems.sort((a, b) => a.key > b.key ? 1 : -1)}
                                        placeholder={'SIC Codes'}
                                        required
                                    />
                                </td>
                                <td
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, percentFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : null}
                                >
                                    <div className={styles.percentage}>
                                        <input
                                            className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, percentFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                            type="text"
                                            questionId={this.props.questionState.question.id}
                                            ordinalEntry={ordinal}
                                            questionFactId={percentFactId}
                                            placeholder='0.00'
                                            value={SessionStateManager.getSessionValue(percentFactId + '_' + ordinal)}
                                            onClick={(evt) => {
                                                if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                                                    this.handleOnChange(evt, percentFactId, ordinal, null, true);
                                                }
                                            }}
                                            onKeyPress={(event) => {
                                                let input = '' + event.target.value + event.key;
                                                let pattern = /^(\d{1,3})(\.[0-9]{0,2})?$/;
                                                let matched = pattern.test(input);
                                                if (!matched || parseFloat(input) > 100
                                                    || (this.props.captureType !== undefined
                                                        && this.props.captureType != null
                                                        && this.props.captureType == 'RESEARCHER_UPDATE')) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={evt => this.handleOnChange(evt, percentFactId, ordinal, null, true)}
                                            onBlur={this.handleOnBlur}
                                        />
                                    </div>
                                </td>
                            </tr>)
                        )}

                        {this.state.activitySICCodesRows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td></td>
                                <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, activityFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.tableInputBoxUpdated : null}
                                >
                                    <input
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, activityFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                        type="text"
                                        questionId={this.props.questionState.question.id}
                                        ordinalEntry={parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)}
                                        questionFactId={activityFactId}
                                        value={SessionStateManager.getSessionValue(activityFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx))}
                                        onChange={evt => this.handleOnChange(evt, activityFactId, parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx), null, false)}
                                        onBlur={this.handleOnBlur}
                                        placeholder={'Activity'}
                                    />
                                </td>
                                <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, sicCodeFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.dropdownBoxUpdated : null}
                                >
                                    <DropDownMenu
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, sicCodeFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.dropdownBoxUpdated : styles.dropdownBox}
                                        questionId={this.props.questionState.question.id}
                                        questionFactId={sicCodeFactId}
                                        ordinalEntry={parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)}
                                        name={'sic_codes'}
                                        label={'SIC Code'}
                                        value={SessionStateManager.getSessionValue(sicCodeFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx))}
                                        onChange={evt => this.handleOnChange(evt, sicCodeFactId, parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx), dropdownItems.sort((a, b) => a.key > b.key ? 1 : -1), false)}
                                        showKeys={true}
                                        menuOptions={dropdownItems.sort((a, b) => a.key > b.key ? 1 : -1)}
                                        placeholder={'SIC Codes'}
                                        required
                                    />
                                </td>
                                <td
                                        className={ValidationsManager.hasValueBeenUpdated(percentFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.tableInputBoxUpdated : null}
                                >
                                    <div className={styles.percentage}>
                                        <input
                                            className={ValidationsManager.hasValueBeenUpdated(percentFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                            type="text"
                                            questionId={this.props.questionState.question.id}
                                            ordinalEntry={parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)}
                                            questionFactId={percentFactId}
                                            placeholder='0.00'
                                            value={SessionStateManager.getSessionValue(percentFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx))}
                                            onClick={(evt) => {
                                                if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                                                    this.handleOnChange(evt, percentFactId, parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx), null, true);
                                                }
                                            }}
                                            onKeyPress={(event) => {
                                                let input = '' + event.target.value + event.key;
                                                let pattern = /^(\d{1,3})(\.[0-9]{0,2})?$/;
                                                let matched = pattern.test(input);
                                                if (!matched || parseFloat(input) > 100
                                                    || (this.props.captureType !== undefined
                                                        && this.props.captureType != null
                                                        && this.props.captureType == 'RESEARCHER_UPDATE')) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={evt => this.handleOnChange(evt, percentFactId, parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx), null, true)}
                                            onBlur={this.handleOnBlur}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td></td>
                            <td align='right' colSpan='2'>TOTAL</td>
                            <td>
                                <div className={styles.percentage}>
                                    <TextField
                                        questionId={this.props.questionState.question.id + ''}
                                        error={this.calculatePercentageTotal(percentFactId) != 100.00}
                                        helperText={this.calculatePercentageTotal(percentFactId) != 100.00 ? 'Total must sum to 100%' : null}
                                        type="number"
                                        id="outlined-error-helper-text"
                                        size="normal"
                                        value={this.calculatePercentageTotal(percentFactId)}
                                        ordinalEntry={0}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder='0.00'
                                        readOnly
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
    }
}
export default ActivitySICQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    {...props}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if (
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                            result.confirmed = true;
                            dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}
