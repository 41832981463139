import React, {Component} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css"

class MultiColorTextLink extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
                <text className={this.props.title_font+"__"+this.props.first_color}>
                    <text className={this.props.title_font+"__"+this.props.first_color}>{this.props.first_text}</text>
                    <text>&nbsp;</text>
                    <a href={this.props.link_url}>
                        <text style={this.props.link_style} className={this.props.title_font+"__"+this.props.link_color}>{this.props.link_text}</text>
                    </a>
                    <text>&nbsp;</text>
                    <text className={this.props.title_font+"__"+this.props.third_color}>{this.props.third_text}</text>
                </text>
        );
    }

}
export default MultiColorTextLink;