// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, " .dashboard_dashboard_overview_container__3lZmp{ \r\n  display: flex;\r\n  flex:  5 1;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  align-content: center;\r\n  justify-content: center;\r\n  background-color: var(--hsrcBackgroundLight);\r\n  border-radius: 1rem;\r\n  padding: 2rem;\r\n \r\n }\r\n\r\n.dashboard_featured__3IuiE {\r\n  width: 100%;\r\n  display: flex;\r\n  flex: 1 1; \r\n  flex-direction: row;\r\n} \r\n\r\n.dashboard_flex_row_container__2_XhQ{\r\n  display: flex;\r\n  flex-direction: row;\r\n  flex: 1 1; \r\n  width: 100%; \r\n  align-items: flex-start;\r\n    align-content: center;\r\n    justify-content: center;\r\n \r\n}", "",{"version":3,"sources":["webpack://src/pages/ResearcherDashboard/pages/Dashboard/dashboard.module.css"],"names":[],"mappings":"CAAC;EACC,aAAa;EACb,UAAQ;EACR,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;EACrB,uBAAuB;EACvB,4CAA4C;EAC5C,mBAAmB;EACnB,aAAa;;CAEd;;AAED;EACE,WAAW;EACX,aAAa;EACb,SAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAO;EACP,WAAW;EACX,uBAAuB;IACrB,qBAAqB;IACrB,uBAAuB;;AAE3B","sourcesContent":[" .dashboard_overview_container{ \r\n  display: flex;\r\n  flex:  5;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  align-content: center;\r\n  justify-content: center;\r\n  background-color: var(--hsrcBackgroundLight);\r\n  border-radius: 1rem;\r\n  padding: 2rem;\r\n \r\n }\r\n\r\n.featured {\r\n  width: 100%;\r\n  display: flex;\r\n  flex: 1; \r\n  flex-direction: row;\r\n} \r\n\r\n.flex_row_container{\r\n  display: flex;\r\n  flex-direction: row;\r\n  flex: 1; \r\n  width: 100%; \r\n  align-items: flex-start;\r\n    align-content: center;\r\n    justify-content: center;\r\n \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_overview_container": "dashboard_dashboard_overview_container__3lZmp",
	"featured": "dashboard_featured__3IuiE",
	"flex_row_container": "dashboard_flex_row_container__2_XhQ"
};
export default ___CSS_LOADER_EXPORT___;
