import React, { Component } from "react";
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class DateQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    classes = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '50ch',
            },
        },

    }));

    async handleChange(evt, questionFactId, ordinalEntry, value) {
        let key = questionFactId + '_' + ordinalEntry;

        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
            const result = await CustomDialog(
                <UpdateAuditDialog
                    {...this.props}
                    currentAnswer={SessionStateManager.getSessionValue(key)}
                    dateInputType={this.props.questionState.questionFactStates.length > 1 ? "date" : "month"}
                    dateQuestionFactId={questionFactId}
                />,
                {
                    className: styles.updateAuditDialog,
                    title: 'Update Submitted Value',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );

            if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                SessionStateManager.setSessionValue(key, result.newValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                this.setState({ sessionStateChanged: true });
            } else {
                evt.preventDefault();
            }
        } else {
            SessionStateManager.setSessionValue(key, value, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
            this.setState({ sessionStateChanged: true });
        }

        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }

    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            return (
                <FormControl component="fieldset">
                    <Grid container spacing={2} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap">
                        {this.props.questionState.questionFactStates.sort((a, b) => (a.sequenceNumberY > b.sequenceNumberY) ? 1 : -1).map((questionFactState) => {
                            let dateValue = SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0');
                            return <Grid container item xs={12} >
                                <TextField
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.dateInputBoxUpdated : styles.dateInputBox}
                                    id={(this.props.questionState.question.id) + '_' + (questionFactState.questionFactId)}
                                    label={questionFactState.columnDimensionStates.length > 0 ? questionFactState.columnDimensionStates[0].dimensionAlias : ''}
                                    format={this.props.questionState.questionFactStates.length > 1 ? "dd/MM/yyyy" : "MM/YYYY"}
                                    inputFormat={this.props.questionState.questionFactStates.length > 1 ? "dd/MM/yyyy" : "MM/YYYY"}
                                    defaultValue={this.props.questionState.questionFactStates.length > 1 ? "01/04/2017" : "01/2023"}
                                    value={(dateValue != null ? dateValue : '').split('/').reverse().join('-')}
                                    name={(this.props.questionState.question.id) + ''}
                                    type={this.props.questionState.questionFactStates.length > 1 ? "date" : "month"}
                                    onChange={(evt) => {
                                        this.handleChange(evt, questionFactState.questionFactId, 0, evt.target.value)
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    size="small"
                                    variant="outlined"
                                />
                            </Grid>
                        })}
                    </Grid>
                </FormControl>
            )
        }
    }
}
export default DateQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    {...props}
                    currentAnswer={props.currentAnswer}
                    dateInputType={props.dateInputType}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if(
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                        result.confirmed = true;
                        dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}