import { NotificationsNone } from "@material-ui/icons";
import {React, useState}  from 'react';
import { rndLogoInstance } from '@components/Globals';
import dsiTaxIncentive from '@images/TopBar/TaxIncentive.jpg';

import axios from "axios";
import { Redirect, Route } from 'react-router-dom';
import styles from "./topbar.module.css";

function parseJwt(token) {
  var base64Payload = token.split('.')[1];
  var payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
}

export default function RespondentTopbar(props) {

  const [organisationName, setOrganisationName] = useState(null);
  const [sectorName, setSectorName] = useState(null);

  let bearer = localStorage.getItem('Bearer');
  if (!bearer){
    window.location = "/login";

  }
  let payload = parseJwt(bearer);

  let orgID = payload.org_id;
  let sector_id = payload.sector_id;

  let organisationNameUrl = process.env.REACT_APP_SURVEY_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + "/questionnaireservice/questionnairetrackers/organisation/name/" + orgID;
  let sectorNameUrl = process.env.REACT_APP_SURVEY_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + "/questionnaireservice/questionnairetrackers/sector/name/" + sector_id;

  if (orgID) {

    axios.get(organisationNameUrl)
      .then(response => {
        setOrganisationName(response.data.name) ; 
      });
  }

  if (sector_id) {

    axios.get(sectorNameUrl)
      .then(response => {
        setSectorName(response.data.name) ; 
      });
  }




  return (

    <div className={styles.topbar}>
      <div className={styles.topbarWrapper}>
        <div className={styles.topLeft}>
          <span className={styles.logo}>
            <img src={rndLogoInstance} className={styles.hsrc__logo} alt="hsrc logo" />
          </span>
        </div>
        <div className={styles.topCenter}>
          <span className={styles.logo}>
            <a href="https://www.dst.gov.za/rdtax/index.php" target="_blank" rel="noreferrer"> 
            <img src={dsiTaxIncentive} className={styles.center__image} alt="DSI Tax incentive" />
            </a>
            
          </span>
        </div>
        {<div className={styles.topRight}>
          <div className={styles.topbarIconContainer}>
            {organisationName} <br /> {sectorName}
          </div>
        </div>}
      </div>
    </div>
  );
}
