import FieldValidator, {
    validateEmail,
    validateDomainEmail,
    validateName,
    validatePhoneNumber,
    validatePasswordStrength,
    hasRequiredLength,
    containsLowercase,
    containsUppercase,
    containsNumber,
    containsSpecialCharacter,
    lessThenOrEquals
} from './FieldValidator';

export default { 
    FieldValidator, 
    validateEmail,
    validateDomainEmail,
    validateName, 
    validatePhoneNumber, 
    validatePasswordStrength ,
    hasRequiredLength,
    containsLowercase,
    containsUppercase,
    containsNumber,
    containsSpecialCharacter,
    lessThenOrEquals
}