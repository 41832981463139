import React, { Component } from 'react';
import MUIDataTable from 'mui-datatables';
import Separator from '@components/Separator';

import styles from './tablewidget.module.css';
class TableWidget extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    let options = {
      selectableRows: "none",
      rowsPerPage: [this.props.rowsPerPage !== undefined && this.props.rowsPerPage !== null ? this.props.rowsPerPage : 5],
      rowsPerPageOptions: [5, 10, 25, 100],
      jumpToPage: false,
    };
    return (
      <React.Fragment>
        <div className={styles.featuredItem}>
          {this.props.title !== undefined && this.props.title !== null
            ? <div>
              <span className={styles.featuredTitleHeader}>{this.props.title}</span>
              {this.props.titleSeperator !== undefined && this.props.titleSeperator !== null && this.props.titleSeperator == false ? null : <Separator /> }
            </div>
            : <div />
          }
          <div className={styles.featuredTextContainer}>

            <MUIDataTable
              title={this.props.tableTitle !== undefined && this.props.tableTitle !== null ? this.props.tableTitle : ''}
              data={this.props.rows}
              columns={this.props.columns}
              options={(this.props.options !== undefined && this.props.options !== null) ? this.props.options : options}
            />
          </div>
        </div>
      </React.Fragment>

    );
  }
}

export default TableWidget;