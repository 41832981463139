import React, { Component } from 'react';
import axios from "axios";

const MESSAGING_SERVICE = process.env.REACT_APP_MESSAGING_MICROSERVICE_URL + process.env.REACT_APP_SERVICES_VERSION;

export default function MessagingService() {
}

export async function sendEmail(toRecipient, ccRecipient, subject, content, filedata, filename) {
  const url = MESSAGING_SERVICE + '/emailservice/send';

  let data = {
    "toRecipient": (toRecipient !== undefined && toRecipient != null) ? toRecipient : null,
    "ccRecipient": (ccRecipient !== undefined && ccRecipient != null) ? ccRecipient : null,
    "subject": (subject !== undefined && subject != null) ? subject : null,
    "content": (content !== undefined && content != null) ? content : null,
    "filedata": (filedata !== undefined && filedata != null) ? filedata : null,
    "filename": (filename !== undefined && filename != null) ? filename : null
  }


  const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };

  const body = JSON.stringify(data);

  const result = (async () => {
    const rest1 = await axios.post(url, body, { headers })
      .then(response => {
        return response
      })
      .catch((e) => {
        console.error('Error sending email!', e);
        return 'Error sending email!'
      });

      return rest1;
  })([]);
  return result
}

export async function sendRespondentCompletedEmail(toRecipient, ccRecipient, subject, content, filedata, filename) {
  const url = MESSAGING_SERVICE + '/emailservice/send/respondent/completed';

  let data = {
    "toRecipient": (toRecipient !== undefined && toRecipient != null) ? toRecipient : null,
    "ccRecipient": (ccRecipient !== undefined && ccRecipient != null) ? ccRecipient : null,
    "subject": (subject !== undefined && subject != null) ? subject : null,
    "content": (content !== undefined && content != null) ? content : null,
    "filedata": (filedata !== undefined && filedata != null) ? filedata : null,
    "filename": (filename !== undefined && filename != null) ? filename : null
  }


  const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };

  const body = JSON.stringify(data);

  const result = (async () => {
    const rest1 = await axios.post(url, body, { headers })
      .then(response => {
        return response
      })
      .catch((e) => {
        console.error('Error sending email!', e);
        return 'Error sending email!'
      });

      return rest1;
  })([]);
  return result
}


export async function sendLog(data) {
  const url = MESSAGING_SERVICE + '/messagingservice/log/';



  const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };

  const body = JSON.stringify(data);

  const result = (async () => {
    const rest1 = await axios.post(url, body, { headers })
      .then(response => {
        return response
      })
      .catch((e) => {
        console.error('Error sending log!', e);
        return 'Error sending log!'
      });

      return rest1;
  })([]);
  return result
}