import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">

          <center>
          Copyright © {new Date().getFullYear()} &nbsp; <strong>Centre for Science, Technology and Innovation Indicators, HSRC. &nbsp;</strong>  All rights reserved.

          </center>


      </footer>
    )
  }
}

export default Footer
