
import React, { Component } from 'react';
import axios from "axios";

const RESPONDENT_DASHBOARD_SERVICE = process.env.REACT_APP_RESPONDENT_DASHBOARD_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION;

export default function RespondentDashboardService() {
}



/**
 * getOrganisationSurveyStatus
 * @param {long, long} 
 * @returns {map}
 */
export async function getOrganisationSurveyStatus(orgId, surveyCycleId) {

    const url = RESPONDENT_DASHBOARD_SERVICE + `/respondentdashboardservice/surveystatus/organisation/${orgId}/surveycycle/${surveyCycleId}` ;
    const organisationStatus = axios.create({ baseURL: url });
    let internationalSourcesOfFundsData = [];

    const status = await organisationStatus.get('')
        .then(response => {
             const data = response.data;
            if (data.status === "SUCCESS") {
                data.response.success.forEach(record => {
                                internationalSourcesOfFundsData.push(record);
                            });
                        }
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    return internationalSourcesOfFundsData;
 }



 /**
 * getOrganisationInternationalSourcesofFunds
 * @param {long, long} 
 * @returns {map}
 */
export async function getOrganisationInternationalSourcesofFunds(orgId, surveyCycleId) {
    const url = RESPONDENT_DASHBOARD_SERVICE + `/respondentdashboardservice/internationalsourcesoffunds/organisation/${orgId}/surveycycle/${surveyCycleId}` ;
    const sourcesOfFunds = axios.create({ baseURL: url });

    const response = await sourcesOfFunds.get('')
        .then(response => {
            const data = response.data.response.success
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    return null;
 }