import React, { Component } from "react";
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class FutureRnDQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    classes = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '50ch',
            },
        },
    }));

    async handleChange(evt) {
        let key = evt.target.attributes.questionFactId.value + '_' + evt.target.attributes.ordinalEntry.value;
        let value = evt.target.value;

        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
            const result = await CustomDialog(
                <UpdateAuditDialog
                    currentAnswer={SessionStateManager.getSessionValue(key)}
                    booleanType={'radio'}
                />,
                {
                    className: styles.updateAuditDialog,
                    title: 'Update Submitted Value',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );

            if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                SessionStateManager.setSessionValue(key, result.newValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                this.setState({ sessionStateChanged: true });
            } else {
                evt.preventDefault();
            }
        } else {
            SessionStateManager.setSessionValue(key, value, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
            this.setState({ sessionStateChanged: true });
        }

        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            return (<div className={styles.futureRndQuestionContainer}>
                <table className='table'>
                    <thead>
                        <tr>
                            {this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1).map(questionFactState =>
                                <th class="col-date">{questionFactState.columnDimensionStates[0].dimensionAlias}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1).map(questionFactState => {
                                let notNull = SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') != null;
                                let isChecked = JSON.parse((SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') != null && SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') != '') ? SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') : 'false');

                                return <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.checkboxUpdated : null}
                                >
                                    <FormControl
                                        component="fieldset">
                                        <div class="radio-group">
                                            <span>
                                                <input
                                                    id={"radio_yes_q" + this.props.questionState.question.id + 'qf' + questionFactState.questionFactId}
                                                    class="radio"
                                                    type="checkbox"
                                                    checked={notNull && isChecked}
                                                    value="true"
                                                    onChange={(evt) => this.handleChange(evt)}
                                                    ordinalEntry={0}
                                                    questionId={this.props.questionState.question.id}
                                                    questionFactId={questionFactState.questionFactId}
                                                />
                                                Yes
                                            </span>
                                            <br></br>
                                            <span>
                                                <input
                                                    id={"radio_no_q" + this.props.questionState.question.id + 'qf' + questionFactState.questionFactId}
                                                    class="radio"
                                                    type="checkbox"
                                                    checked={notNull && !isChecked}
                                                    value="false"
                                                    onChange={(evt) => this.handleChange(evt)}
                                                    ordinalEntry={0}
                                                    questionId={this.props.questionState.question.id}
                                                    questionFactId={questionFactState.questionFactId}
                                                />
                                                No
                                            </span>
                                        </div>
                                    </FormControl>
                                </td>
                            })
                            }
                        </tr>
                    </tbody>
                </table>
            </div>)
        }
    }
}
export default FutureRnDQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    currentAnswer={props.currentAnswer}
                    booleanType={props.booleanType}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if (
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                            result.confirmed = true;
                            dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}