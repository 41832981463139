// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".institutions_container_institutions__37E2e{\r\n  display: flex;\r\n  flex:  5 1;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  align-content: center;\r\n  justify-content: center;\r\n  background-color: var(--hsrcBackgroundLight);\r\n  border-radius: 1rem;\r\n  padding: 2rem;\r\n  }\r\n\r\n.institutions_featured__2ij0h {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n} \r\n\r\n\r\n.institutions_container_row__38hky{\r\n  display: flex;\r\n  flex: 1 1; \r\n\r\n  flex-direction: column;\r\n  align-content: center;\r\n  align-items: flex-start;\r\n  align-content: flex-start;\r\n  justify-content: space-around;\r\n}", "",{"version":3,"sources":["webpack://src/pages/ResearcherDashboard/pages/Institutions/institutions.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAQ;EACR,sBAAsB;EACtB,uBAAuB;EACvB,qBAAqB;EACrB,uBAAuB;EACvB,4CAA4C;EAC5C,mBAAmB;EACnB,aAAa;EACb;;AAEF;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;AAChC;;;AAGA;EACE,aAAa;EACb,SAAO;;EAEP,sBAAsB;EACtB,qBAAqB;EACrB,uBAAuB;EACvB,yBAAyB;EACzB,6BAA6B;AAC/B","sourcesContent":[".container_institutions{\r\n  display: flex;\r\n  flex:  5;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  align-content: center;\r\n  justify-content: center;\r\n  background-color: var(--hsrcBackgroundLight);\r\n  border-radius: 1rem;\r\n  padding: 2rem;\r\n  }\r\n\r\n.featured {\r\n  width: 100%;\r\n  display: flex;\r\n  justify-content: space-between;\r\n} \r\n\r\n\r\n.container_row{\r\n  display: flex;\r\n  flex: 1; \r\n\r\n  flex-direction: column;\r\n  align-content: center;\r\n  align-items: flex-start;\r\n  align-content: flex-start;\r\n  justify-content: space-around;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_institutions": "institutions_container_institutions__37E2e",
	"featured": "institutions_featured__2ij0h",
	"container_row": "institutions_container_row__38hky"
};
export default ___CSS_LOADER_EXPORT___;
