import React, { Component, useState } from "react";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@mui/material/TextField';
import SpinnerModal from "@components/SpinnerModal";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from './logicalerrorsdialog.module.css';
import FieldworkManagementService from "@services/FieldworkManagementService";

class LogicalErrorsDialog extends Component {
    state = {
        loading: true,
        newValue: "",
        interactionTypes: [],
        logicalErrors: {
            id: null,
            questionnaireTrackerId: this.props.organisationStatus.questionnaireTrackerId,
            ommission: '',
            coding: '',
            range: '',
            summation: '',
        }
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        const errorsFromDB = await FieldworkManagementService.getLogicalErrorsByQuestionnaireTrackerId(this.props.organisationStatus.questionnaireTrackerId);
        if (errorsFromDB !== undefined && errorsFromDB !== null && errorsFromDB != '') {
            this.setState({ logicalErrors: errorsFromDB });
        }

        this.setState({ loading: false });
    }

    getContactNameByContactStateId(contactStateId) {
        let orgContacts = this.props.organisationStatus.organisationContactList;

        let contact = (contactStateId != null && orgContacts !== undefined && orgContacts != null && orgContacts.length > 0)
            ? orgContacts.filter(contact => contact.contactStateId == contactStateId)[0]
            : null;

        return contact != null ? (contact.title + ' ' + contact.firstname + ' ' + contact.lastname) : 'No selected contact';
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            let organisationContacts = [];

            this.props.organisationStatus.organisationContactList.map(item => {
                organisationContacts.push({ key: item.contactStateId, value: this.getContactNameByContactStateId(item.contactStateId) });
            });

            return <DialogWrapper {...this.props} onClick={(evt) => this.props.nextButton.onClick(evt, this.state.logicalErrors)} content={
                <React.Fragment>
                    {this.state.logicalErrors !== undefined && this.state.logicalErrors !== null
                        ? <div className={styles.featuredLogicalErrorsDialogItem} >
                            <TextField
                                className={styles.dialogInputs}
                                label="Ommission"
                                key={'ommission_textfield'}
                                id="standard-end-adornment"
                                InputProps={{
                                    startAdornment: <InputAdornment position={'start'}>{this.props.startEndornment}</InputAdornment>,
                                    endAdornment: <InputAdornment position={'end'}>{this.props.endEndornment}</InputAdornment>,
                                }}
                                type="text"
                                value={this.state.logicalErrors.ommission}
                                onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key)) { event.preventDefault(); } }}
                                onChange={(evt) => {
                                    let errors = this.state.logicalErrors;
                                    errors.ommission = evt.target.value;
                                    this.setState({ logicalErrors: errors });
                                }}
                                size="small"
                                variant="outlined"
                            />
                            <br /><br />
                            <TextField
                                className={styles.dialogInputs}
                                label="Coding"
                                key={'coding_textfield'}
                                id="standard-end-adornment"
                                InputProps={{
                                    startAdornment: <InputAdornment position={'start'}>{this.props.startEndornment}</InputAdornment>,
                                    endAdornment: <InputAdornment position={'end'}>{this.props.endEndornment}</InputAdornment>,
                                }}
                                type="text"
                                value={this.state.logicalErrors.coding}
                                onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key)) { event.preventDefault(); } }}
                                onChange={(evt) => {
                                    let errors = this.state.logicalErrors;
                                    errors.coding = evt.target.value;
                                    this.setState({ logicalErrors: errors });
                                }}
                                size="small"
                                variant="outlined"
                            />
                            <br /><br />
                            <TextField
                                className={styles.dialogInputs}
                                label="Range"
                                key={'range_textfield'}
                                id="standard-end-adornment"
                                InputProps={{
                                    startAdornment: <InputAdornment position={'start'}>{this.props.startEndornment}</InputAdornment>,
                                    endAdornment: <InputAdornment position={'end'}>{this.props.endEndornment}</InputAdornment>,
                                }}
                                type="text"
                                value={this.state.logicalErrors.range}
                                onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key)) { event.preventDefault(); } }}
                                onChange={(evt) => {
                                    let errors = this.state.logicalErrors;
                                    errors.range = evt.target.value;
                                    this.setState({ logicalErrors: errors });
                                }}
                                size="small"
                                variant="outlined"
                            />
                            <br /><br />
                            <TextField
                                className={styles.dialogInputs}
                                label="Summation"
                                key={'summation_textfield'}
                                id="standard-end-adornment"
                                InputProps={{
                                    startAdornment: <InputAdornment position={'start'}>{this.props.startEndornment}</InputAdornment>,
                                    endAdornment: <InputAdornment position={'end'}>{this.props.endEndornment}</InputAdornment>,
                                }}
                                type="text"
                                value={this.state.logicalErrors.summation}
                                onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key)) { event.preventDefault(); } }}
                                onChange={(evt) => {
                                    let errors = this.state.logicalErrors;
                                    errors.summation = evt.target.value;
                                    this.setState({ logicalErrors: errors });
                                }}
                                size="small"
                                variant="outlined"
                            />
                        </div>
                        : <div />
                    }
                </React.Fragment>
            }
            />
        }
    }

}
export default LogicalErrorsDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            props.onClick(evt);
                            dialog.close(true);
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}

