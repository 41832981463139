import React, { Component } from "react";
import axios from "axios";
import SessionStateManager from "@components/SessionStateManager";

const DATACAPTURE_SERVICE = process.env.REACT_APP_DATACAPTURE_MICROSERVICE_URL + process.env.REACT_APP_SERVICES_VERSION;

export default function ValidationsManager() {
}

/**
 * clear
 * @param {none} 
 * @returns {any}
 */
export function clear() {
    sessionStorage.clear();
}

/**
 * initialiseValidations
 * @param {long} organisationStateId - Organisation State ID
 * @returns {none}
 */
export async function initialiseValidations(organisationStateId) {

    const url = DATACAPTURE_SERVICE + '/datacapture/getValidationRules/organisationstate_id/' + organisationStateId;
    const validationRulesByOrganisationStateId = axios.create({ baseURL: url });
    const validationRules = await validationRulesByOrganisationStateId.get('')
        .then(response => {
            const data = response.data;

            return data;
        })
        .catch((e) => {
            console.error('Error loading survey facts', e);
        });

    if (validationRules != null) {
        let questionFactValidations = [];

        validationRules.filter(entry => (
            entry.questionValidationId != null &&
            entry.termsFunction != null &&
            entry.termsQuestionFactIds != null &&
            entry.compoundQuestionStateId != null &&
            entry.questionId != null &&
            entry.questionnairePartId != null))
            .map(item => {

                let validations = [];
                if (item.termsFunction == 'LESSTHANOREQUALS') {
                    validations.push({ 'lte': item.termsQuestionFactIds, 'validationId': item.questionValidationId });
                }

                if (item.termsFunction == 'GREATERTHAN') {
                    validations.push({ 'greater': item.termsQuestionFactIds, 'validationId': item.questionValidationId, 'providedValue': item.providedValue });
                }

                if (item.termsFunction == 'GREATERTHANOREQUALS') {
                    validations.push({ 'gte': item.termsQuestionFactIds, 'validationId': item.questionValidationId });
                }

                if (item.termsFunction == 'SUM') {
                    validations.push({ 'sum': item.termsQuestionFactIds, 'validationId': item.questionValidationId, 'providedValue': item.providedValue });
                }

                if (item.termsFunction == 'MULTIPLY') {
                    validations.push({ 'multiply': item.termsQuestionFactIds, 'validationId': item.questionValidationId });
                }

                if (item.termsFunction == 'EQUALS') {
                    validations.push({ 'equals': item.termsQuestionFactIds, 'validationId': item.questionValidationId });
                }

                const key = item.questionFactId;
                const questionId = item.questionId;
                const questionnairePartId = item.questionnairePartId;
                const compoundQuestionStateId = item.compoundQuestionStateId;
                let thisValidations = { key, questionId, questionnairePartId, compoundQuestionStateId, validations };
                questionFactValidations.push(thisValidations);

            });

        sessionStorage.setItem('questionFactValidations', JSON.stringify(questionFactValidations));
    }
}

/**
 * validateFact
 * @param {string} factToValidate - Key to validate
 * @returns {any}
 */
export function validateFact(factToValidate) {
    let result = true;
    let storedValidations = JSON.parse(sessionStorage.getItem('questionFactValidations'));
    let validations = (storedValidations != null && storedValidations !== undefined) ? storedValidations.filter(item => item.key == factToValidate).map(item => item.validations[0]) : {};

    if (validations != null && validations !== undefined && validations.length > 0) {

        validations.forEach(validationRule => {

            if (validationRule.lte !== undefined) {
                result = (result && lessThenOrEqual(validationRule.lte, factToValidate));
            }

            if (validationRule.gte !== undefined) {
                result = (result && greaterThenOrEqual(validationRule.gte, factToValidate));
            }

            if (validationRule.greater !== undefined && validationRule.providedValue !== undefined && validationRule.providedValue !== null) {
                let terms = validationRule.greater.split(', ');
                result = (result && termsGreaterThanProvidedValue(validationRule.providedValue, terms));
            }

            if (validationRule.sum !== undefined && (validationRule.providedValue === undefined || validationRule.providedValue == null)) {
                let terms = validationRule.sum.split(', ');
                result = (result && sumOfTerms(terms, factToValidate));
            }

            if (validationRule.sum !== undefined && validationRule.providedValue !== undefined && validationRule.providedValue !== null) {
                let terms = validationRule.sum.split(', ');
                result = (result && termsSumToProvidedValue(validationRule.providedValue, terms));
            }

            if (validationRule.multiply !== undefined) {
                let terms = validationRule.multiply.split(', ');
                result = (result && productOfTerms(terms, factToValidate));
            }

            if (validationRule.equals !== undefined) {
                result = (result && isEqualTo(validationRule.equals, factToValidate));
            }

        });

    }

    return result;
}

/**
 * getBrokenValidation
 * @param {string} factToValidate - Key to validate for broken validations
 * @returns {any}
 */
export function getBrokenValidations(factToValidate) {
    let result = [];
    let storedValidations = JSON.parse(sessionStorage.getItem('questionFactValidations'));
    let validations = (storedValidations != null && storedValidations !== undefined) ? storedValidations.filter(item => item.key == factToValidate).map(item => item.validations)[0] : {};

    if (validations === undefined || validations == null || validations.length <= 0) {
        // if this particular cell is not a key, then maybe it is a provided value
        let temps = (storedValidations != null && storedValidations !== undefined) ? storedValidations.filter(item => (item.key == null)).map(item => item.validations[0]) : {};
        validations = temps.filter(temp => (temp.sum !== undefined && temp.sum !== null) ? temp.sum.split(', ').includes('' + factToValidate) : (temp.greater !== undefined && temp.greater !== null) ? temp.greater.split(', ').includes('' + factToValidate) : false);
    }

    if (validations != null && validations !== undefined && validations.length > 0) {

        validations.forEach(validationRule => {

            if (validationRule.lte !== undefined && !lessThenOrEqual(validationRule.lte, factToValidate)) {
                result.push(validationRule.validationId);
            }

            if (validationRule.gte !== undefined && !greaterThenOrEqual(validationRule.gte, factToValidate)) {
                result.push(validationRule.validationId);
            }

            if (validationRule.sum !== undefined) {

                if (validationRule.providedValue !== undefined && validationRule.providedValue !== null) {
                    let terms = validationRule.sum.split(', ');
                    if (!termsSumToProvidedValue(validationRule.providedValue, terms)) {
                        result.push(validationRule.validationId);
                    }
                } else {
                    let terms = validationRule.sum.split(', ');
                    if (!sumOfTerms(terms, factToValidate)) {
                        result.push(validationRule.validationId);
                    }
                }
            }

            if (validationRule.greater !== undefined) {

                if (validationRule.providedValue !== undefined && validationRule.providedValue !== null) {
                    let terms = validationRule.greater.split(', ');
                    if (!termsGreaterThanProvidedValue(validationRule.providedValue, terms)) {
                        result.push(validationRule.validationId);
                    }
                } else {
                    let terms = validationRule.greater.split(', ');
                    if (!termsGreaterThan(terms, factToValidate)) {
                        result.push(validationRule.validationId);
                    }
                }
            }

            if (validationRule.multiply !== undefined) {
                let terms = validationRule.multiply.split(', ');
                if (!productOfTerms(terms, factToValidate)) {
                    result.push(validationRule.validationId);
                }
            }

            if (validationRule.equals !== undefined) {
                if (!isEqualTo(validationRule.equals, factToValidate)) {
                    result.push(validationRule.validationId);
                }
            }

        });

    }

    return result;
}

/**
 * updateDependencies
 * @param {string} questionFactId - questionFactId to check for dependencies
 * @returns {any}
 */
export function getValidationDependencies(questionFactId) {

    let storedValidations = JSON.parse(sessionStorage.getItem('questionFactValidations'));
    let dependencies = [];

    storedValidations.forEach(element => {
        let rules = element.validations;

        rules.forEach(rule => {
            if (rule.greater !== undefined) {

                rule.greater.split(', ').forEach(term => {
                    if (term == questionFactId) {
                        const key = element.key;
                        const questionId = element.questionId;
                        const questionnairePartId = element.questionnairePartId;
                        const compoundQuestionStateId = element.compoundQuestionStateId;
                        let thisOne = { key, rule, questionId, questionnairePartId, compoundQuestionStateId };
                        dependencies.push(thisOne);
                    }
                })
            }
            
            if (rule.sum !== undefined) {

                rule.sum.split(', ').forEach(term => {
                    if (term == questionFactId) {
                        const key = element.key;
                        const questionId = element.questionId;
                        const questionnairePartId = element.questionnairePartId;
                        const compoundQuestionStateId = element.compoundQuestionStateId;
                        let thisOne = { key, rule, questionId, questionnairePartId, compoundQuestionStateId };
                        dependencies.push(thisOne);
                    }
                })
            }

            if (rule.multiply !== undefined) {

                rule.multiply.split(', ').forEach(term => {
                    if (term == questionFactId) {
                        const key = element.key;
                        const questionId = element.questionId;
                        const questionnairePartId = element.questionnairePartId;
                        const compoundQuestionStateId = element.compoundQuestionStateId;
                        let thisOne = { key, rule, questionId, questionnairePartId, compoundQuestionStateId };
                        dependencies.push(thisOne);
                    }
                })
            }
        });
    });

    return dependencies;
}

function productOfTerms(terms, fact) {
    let product = 1;
    let factValue = Number(((SessionStateManager.getSessionValue(fact + '_0') != null) ? SessionStateManager.getSessionValue(fact + '_0') : 0).toFixed(2));

    terms.forEach(term => {
        product = Number((product * Number(((SessionStateManager.getSessionValue(term + '_0') != null) ? SessionStateManager.getSessionValue(term + '_0') : 0))).toFixed(2));
    })

    return factValue == product;
}

function termsGreaterThanProvidedValue(providedValue, terms) {
    let allGreaterThan = true;

    if (terms === undefined || terms === null || terms.length <= 0) {
        return false;
    }

    terms.forEach(term => {
        let rightVar = parseFloat((providedValue !== null) ? providedValue : 0);
        let leftVar = parseFloat((SessionStateManager.getSessionValue(term + '_0') != null) ? SessionStateManager.getSessionValue(term + '_0') : 0);

        allGreaterThan = allGreaterThan && ((isNaN(leftVar) ? 0 : Number(leftVar.toFixed(2))) > (isNaN(rightVar) ? 0 : Number(rightVar.toFixed(2))));
    })

    return allGreaterThan;
}

function termsGreaterThan(terms, questionFactId) {
    let allGreaterThan = true;

    if (terms === undefined || terms === null || terms.length <= 0) {
        return false;
    }

    terms.forEach(term => {
        let rightVar = parseFloat((SessionStateManager.getSessionValue(questionFactId + '_0') != null) ? SessionStateManager.getSessionValue(questionFactId + '_0') : 0);
        let leftVar = parseFloat((SessionStateManager.getSessionValue(term + '_0') != null) ? SessionStateManager.getSessionValue(term + '_0') : 0);

        allGreaterThan = allGreaterThan && ((isNaN(leftVar) ? 0 : Number(leftVar.toFixed(2))) > (isNaN(rightVar) ? 0 : Number(rightVar.toFixed(2))));
    })

    return allGreaterThan;

}

function termsSumToProvidedValue(providedValue, terms) {
    let sum = 0;
    terms.forEach(term => {
        let allOrdinalEntries = SessionStateManager.allEntriesByQuestionFactId(term);

        if (allOrdinalEntries !== undefined && allOrdinalEntries !== null && allOrdinalEntries.length > 1) {
            allOrdinalEntries.forEach(ordinalEntry => {
                sum = Number((sum + Number((SessionStateManager.getSessionValue(ordinalEntry.key) !== null) ? SessionStateManager.getSessionValue(ordinalEntry.key) : 0)).toFixed(2));
            });
        } else {
            sum = Number((sum + Number((SessionStateManager.getSessionValue(term + '_0') !== null) ? SessionStateManager.getSessionValue(term + '_0') : 0)).toFixed(2));
        }
    })

    return (isNaN(providedValue) ? 0 : Number(providedValue.toFixed(2))) == (isNaN(sum) ? 0 : Number(sum.toFixed(2)));
}

function sumOfTerms(terms, questionFactId) {
    let sum = 0;
    let factValue = parseFloat((SessionStateManager.getSessionValue(questionFactId + '_0') != null) ? SessionStateManager.getSessionValue(questionFactId + '_0') : 0);

    terms.forEach(term => {
        sum = Number((sum + Number((SessionStateManager.getSessionValue(term + '_0') != null) ? SessionStateManager.getSessionValue(term + '_0') : 0)).toFixed(2));
    })

    return (isNaN(factValue) ? 0 : Number(factValue.toFixed(2))) == (isNaN(sum) ? 0 : Number(sum.toFixed(2)));
}

function lessThenOrEqual(left, right) {
    let leftVar = parseFloat((SessionStateManager.getSessionValue(left + '_0') != null) ? SessionStateManager.getSessionValue(left + '_0') : 0);
    let rightVar = parseFloat((SessionStateManager.getSessionValue(right + '_0') != null) ? SessionStateManager.getSessionValue(right + '_0') : 0);

    return (isNaN(leftVar) ? 0 : Number(leftVar.toFixed(2))) <= (isNaN(rightVar) ? 0 : Number(rightVar.toFixed(2)));
}

function greaterThenOrEqual(left, right) {
    let leftVar = parseFloat((SessionStateManager.getSessionValue(left + '_0') != null) ? SessionStateManager.getSessionValue(left + '_0') : 0);
    let rightVar = parseFloat((SessionStateManager.getSessionValue(right + '_0') != null) ? SessionStateManager.getSessionValue(right + '_0') : 0);

    return (isNaN(leftVar) ? 0 : Number(leftVar.toFixed(2))) >= (isNaN(rightVar) ? 0 : Number(rightVar.toFixed(2)));
}

function isEqualTo(left, right) {
    let leftVar = parseFloat((SessionStateManager.getSessionValue(left + '_0') != null) ? SessionStateManager.getSessionValue(left + '_0') : 0);
    let rightVar = parseFloat((SessionStateManager.getSessionValue(right + '_0') != null) ? SessionStateManager.getSessionValue(right + '_0') : 0);

    return (isNaN(leftVar) ? 0 : leftVar) == (isNaN(rightVar) ? 0 : rightVar);
}

export function hasValueBeenUpdated(captureType, thisKey) {
    return (captureType == 'RESEARCHER_UPDATE'
        && SessionStateManager.getChangeListEntry(thisKey) !== undefined
        && SessionStateManager.getChangeListEntry(thisKey) != null);
}