import React, { Component } from 'react';
import axios from "axios";
import SupportAlert from '@components/SupportAlert';



const DATACAPTURE_SERVICE = process.env.REACT_APP_DATACAPTURE_MICROSERVICE_URL + process.env.REACT_APP_SERVICES_VERSION + '/datacapture';

export default function DataCaptureService() {
}

export function save(fact) {
  try {
    fetch(DATACAPTURE_SERVICE + '/fact/save', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(fact)
    }).then(() => {
    })
  } catch (ex) {
  }
}

export function saveTable(tableFacts) {
  try {
    fetch(DATACAPTURE_SERVICE + '/tablefacts/savelist', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tableFacts)
    }).then(() => {
    })
  } catch (ex) {
    // console.log(ex);
  }
}

export async function startCapture(data) {

  const url = DATACAPTURE_SERVICE + '/start';

  const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };

  const body = JSON.stringify(data);

  const result = await axios.post(url, body, { headers })
    .then(response => {
      return response.data.response.success
    })
    .catch((e) => {
      console.error('Error starting data-capture!', e);
      return 'Error starting data-capture!'
    });

  return result

}

export async function completeCapture(data, captureType) {
  const url = DATACAPTURE_SERVICE + '/complete/' + captureType;

  const headers = {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };

  const body = JSON.stringify(data);

  const result = await axios.post(url, body, { headers })
    .then(response => {
      return response.data.response.success
    })
    .catch((e) => {
      console.error('Error completing data-capture!', e);
      return 'Error completing data-capture!'
    });

  return result
}

export async function getDataCapturesByQuestionnaireTrackerId(questionnaireTrackerId) {
  const url = DATACAPTURE_SERVICE + '/status/' + questionnaireTrackerId;

  const dataCapturesByQuestionnaireTrackerIdURL = axios.create({ baseURL: url });

  const dataCaptures = await dataCapturesByQuestionnaireTrackerIdURL.get('')
    .then(response => {
      const data = response.data.response.success

      // Returning all data capture records for questionnaire_tracker
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (dataCaptures != null) {
    return dataCaptures;
  }

  return null;
}

export async function getAllSurveysByOrganisationIdAndSectorId(organisationId, sectorId) {

  const url = DATACAPTURE_SERVICE + '/getAllSurveys/organisationid/' + organisationId + '/sectorid/' + sectorId;

  const allSurveysURL = axios.create({ baseURL: url });

  const allSurveys = await allSurveysURL.get('')
    .then(response => {
      const data = response.data

      // Returning all survey cycles
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (allSurveys != null) {
    return allSurveys;
  }

  return null;
}

export async function previousSurveysBySectorId(sectorId, fromSurveyCycle, toSurveyCycle) {

  const url = DATACAPTURE_SERVICE + '/previousSurveys/sectorid/' + sectorId + '/fromsurveycycle/' + fromSurveyCycle + '/tosurveycycle/' + toSurveyCycle;

  const allSurveysURL = axios.create({ baseURL: url });

  const allSurveys = await allSurveysURL.get('')
    .then(response => {
      const data = response.data

      // Returning all survey cycles
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (allSurveys != null) {
    return allSurveys;
  }

  return null;
}

export async function getOrganisationStatusByOrganisationStateId(organisationStateId) {

  let url = DATACAPTURE_SERVICE + '/organisationstatus/organisationstate/' + organisationStateId;

  const organisationStatusURL = axios.create({ baseURL: url });

  const organisationStatus = await organisationStatusURL.get('')
    .then(response => {
      const data = response.data

      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (organisationStatus != null) {
    return organisationStatus;
  }

  return null;
}

export async function getOrganisationStatusesBySectorIdAndSurveyCycleId(sectorId, surveyCycleId) {

  let url = DATACAPTURE_SERVICE + '/organisationstatus';

  if ((sectorId != null && sectorId != '') || (surveyCycleId != null && surveyCycleId != '')) {
    url = (sectorId != null && sectorId != '') ? url + '/sector/' + sectorId : url;
    url = (surveyCycleId != null && surveyCycleId != '') ? url + '/surveycycle/' + surveyCycleId : url;
  }

  const organisationStatusURL = axios.create({ baseURL: url });

  const organisationStatuses = await organisationStatusURL.get('')
    .then(response => {
      const data = response.data

      // Returning the organisation by organisationId
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (organisationStatuses != null) {
    return organisationStatuses;
  }

  return null;
}

export async function getOrganisationStatusesBySectorIdAndSurveyCycleId_including_non_uom(sectorId, surveyCycleId) {

  let url = DATACAPTURE_SERVICE + '/organisationstatus/inclusive';

  if ((sectorId != null && sectorId != '') || (surveyCycleId != null && surveyCycleId != '')) {
    url = (sectorId != null && sectorId != '') ? url + '/sector/' + sectorId : url;
    url = (surveyCycleId != null && surveyCycleId != '') ? url + '/surveycycle/' + surveyCycleId : url;
  }

  const organisationStatusURL = axios.create({ baseURL: url });

  const organisationStatuses = await organisationStatusURL.get('')
    .then(response => {
      const data = response.data

      // Returning the organisation by organisationId
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (organisationStatuses != null) {
    return organisationStatuses;
  }

  return null;
}

export async function getOrganisationStatusBySurveyCycleIdAndOrganisationId(surveyCycleId, organisationId) {

  let url = DATACAPTURE_SERVICE + '/organisationstatus';

  if ((surveyCycleId != null && surveyCycleId != '') || (organisationId != null && organisationId != '')) {
    url = (surveyCycleId != null && surveyCycleId != '') ? url + '/surveycycle/' + surveyCycleId : url;
    url = (organisationId != null && organisationId != '') ? url + '/organisation/' + organisationId : url;
  }

  const organisationStatusURL = axios.create({ baseURL: url });

  const organisationStatus = await organisationStatusURL.get('')
    .then(response => {
      const data = response.data

      // Returning the organisation by organisationId
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (organisationStatus != null) {
    return organisationStatus;
  }

  return null;
}

export async function getAllOrganisationContactsByOrganisationStateId(organisationStateId) {

  let url = DATACAPTURE_SERVICE + '/organisationstatecontact/organisationstate/' + organisationStateId;

  const organisationContactsURL = axios.create({ baseURL: url });

  const organisationContacts = await organisationContactsURL.get('')
    .then(response => {
      const data = response.data

      // Returning the organisation by organisationId
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (organisationContacts != null) {
    return organisationContacts;
  }

  return null;
}

export async function getAllOrganisationContactsBySurveyCycleId(surveyCycleId) {

  let url = DATACAPTURE_SERVICE + '/organisationstatecontact/surveycycle/' + surveyCycleId;

  const organisationContactsURL = axios.create({ baseURL: url });

  const organisationContacts = await organisationContactsURL.get('')
    .then(response => {
      const data = response.data

      // Returning the organisation by organisationId
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (organisationContacts != null) {
    return organisationContacts;
  }

  return null;
}

export async function getAllOrganisationContactsByOrganisationIdAndSurveyCycleId(organisationId, surveyCycleId) {

  let url = DATACAPTURE_SERVICE + '/organisationstatecontact/organisation/' + organisationId + '/surveycycle/' + surveyCycleId;

  const organisationContactsURL = axios.create({ baseURL: url });

  const organisationContacts = await organisationContactsURL.get('')
    .then(response => {
      const data = response.data

      // Returning the organisation by organisationId
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (organisationContacts != null) {
    return organisationContacts;
  }

  return null;
}

export function setQuestionnaireTrackerFilledInBy(questionnaireTrackerId, filledInById) {

  let data = {
    "questionnaireTrackerId": questionnaireTrackerId,
    "filledInById": filledInById
  }

  try {
    fetch(DATACAPTURE_SERVICE + '/setquestionnaretrackerfilledinby', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    }).then(() => {
    })
  } catch (ex) {
    // console.log(ex);
  }
}

export function setOrganisationFieldworker(organisationStateId, fieldworkerId) {

  let data = {
    "organisationStateId": organisationStateId,
    "fieldworkerId": fieldworkerId
  }

  try {
    fetch(DATACAPTURE_SERVICE + '/setorganisationfieldworker', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data)
    }).then(() => {
    })
  } catch (ex) {
    // console.log(ex);
  }
}

export async function getCapturedQuestionsByDataCaptureId(dataCaptureId) {

  let url = DATACAPTURE_SERVICE + '/capturedquestion/datacapture/' + dataCaptureId;

  const capturedQuestionsURL = axios.create({ baseURL: url });

  const capturedQuestions = await capturedQuestionsURL.get('')
    .then(response => {
      const data = response.data

      // Returning the captured_questions by datacaptureId
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (capturedQuestions != null) {
    return capturedQuestions;
  }

  return null;
}

export async function getLookups() {

  let url = DATACAPTURE_SERVICE + '/getlookups';

  const lookupsURL = axios.create({ baseURL: url });

  const lookups = await lookupsURL.get('')
    .then(response => {
      const data = response.data

      // Returning the list of lookups
      return data;
    })
    .catch((e) => {
      console.error('There was an error!', e);
    });

  if (lookups != null) {
    return lookups;
  }

  return null;
}

export async function saveSurveyReassignment(data) {

  let url = DATACAPTURE_SERVICE + '/reassignDataCapture';

  try {
      fetch(url, {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
      }).then(() => {
      })
  } catch (ex) {
      SupportAlert.displayAlert('Network', 'An unexpected network error occured while saving this interaction. Please try again later.');
  }
}
