import React, { Component, useState } from "react";
import DataCaptureService from "@services/DataCaptureService";
import SpinnerModal from "@components/SpinnerModal";
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import styles from './DespatchDialog.module.css';
import MUIDataTable from 'mui-datatables';
import CSVGenerator from '@components/CSVGenerator';

class DespatchDialog extends Component {
    state = {
        loading: false,
        contactsLoading: false,
        organisationContacts: [],
        dispatchRecords: [],
    }

    constructor(props) {
        super(props);
    }

    DISPATCH_TYPE_ELECTRONIC = 5;
    DISPATCH_TYPE_POSTAL = 4;

    async componentDidMount() {
        this.setState({ loading: true });

        this.reloadOrganisationContacts(this.props.organisationStatus.organisationStateId);

        this.setState({ loading: false });
    }

    async reloadOrganisationContacts(organisationStateId) {
        this.setState({ contactsLoading: true });

        try {

            const contacts = new Array();
            this.setState({ organisationContacts: contacts });
            const contactStates = await DataCaptureService.getAllOrganisationContactsByOrganisationStateId(organisationStateId);
            this.setState({ organisationContacts: contactStates.filter(contact => contact.contactTypeId == 1) });

            let dispatchRecords = [];
            contactStates.filter(contact => contact.contactTypeId == 1)
                .map(contact => {
                    if (!dispatchRecords.some(record => (record.contactStateId == contact.contactStateId && record.dispatchType == this.DISPATCH_TYPE_ELECTRONIC))) {
                        dispatchRecords.push({
                            contactStateId: contact.contactStateId,
                            organisationStateId: contact.organisationStateId,
                            questionnaireTrackerId: this.props.organisationStatus.questionnaireTrackerId,
                            dispatchType: this.DISPATCH_TYPE_ELECTRONIC,
                            doDispatch: false
                        });
                    }

                    if (!dispatchRecords.some(record => (record.contactStateId == contact.contactStateId && record.dispatchType == this.DISPATCH_TYPE_POSTAL))) {
                        dispatchRecords.push({
                            contactStateId: contact.contactStateId,
                            organisationStateId: contact.organisationStateId,
                            questionnaireTrackerId: this.props.organisationStatus.questionnaireTrackerId,
                            dispatchType: this.DISPATCH_TYPE_POSTAL,
                            doDispatch: false
                        });
                    }
                });

            this.setState({ dispatchRecords: dispatchRecords });

            this.setState({ contactsLoading: false });

        } catch (ex) {
            SupportAlert.displayAlert('Network Error', 'An unexpected network error occured while loading the contacts list. Please try again later.');
        }

        this.setState({ contactsLoading: false });

    }

    async handleOnChange(evt, contactStateId, dispatchType, checked) {
        let dispatchRecords = this.state.dispatchRecords;
        dispatchRecords.filter(record => record.contactStateId == contactStateId && record.dispatchType == dispatchType)[0].doDispatch = checked;
        this.setState({ dispatchRecords: dispatchRecords });
    }

    despatchContactsTable() {

        let tableTitle = ('Organisation Contacts').toUpperCase();

        let tableColumns = [
            { label: "Contact Name", name: "contactName" },
            { label: "Despatch Type", name: "despatchType" },
            { label: "Email", name: "email" },
            { label: "Despatch Dates", name: "despatchDates" },
        ];


        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [10],
            rowsPerPageOptions: [10, 20, 50, 100],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        let despatchContactsTableData = [];

        if (this.state.organisationContacts !== undefined && this.state.organisationContacts !== null && this.state.organisationContacts.length > 0) {
            this.state.organisationContacts
                .filter(organisationContact => organisationContact.contactTypeId == 1)
                .map(contact => {
                    let row = {
                        contactName: <div className={styles.columncell}>
                            {contact.title + " " + contact.firstname + ' ' + contact.lastname}
                        </div>,
                        despatchType: <div className={styles.columncell}>
                            <Stack direction="column" spacing={0.5}>
                                <FormControl component="fieldset">
                                    <div className={styles.splitcolumn} >
                                        <input
                                            type="checkbox"
                                            className="radio"
                                            id={"checkbox_electronic" + contact.contactStateId}
                                            checked={this.state.dispatchRecords.filter(record => record.contactStateId == contact.contactStateId && record.dispatchType == this.DISPATCH_TYPE_ELECTRONIC)[0].doDispatch}
                                            onChange={(evt) => this.handleOnChange(evt, contact.contactStateId, this.DISPATCH_TYPE_ELECTRONIC, !(this.state.dispatchRecords.filter(record => record.contactStateId == contact.contactStateId && record.dispatchType == this.DISPATCH_TYPE_ELECTRONIC)[0].doDispatch))}
                                            disabled={((contact.email !== undefined && contact.email !== null && contact.email.trim() !== '') ? false : true)}

                                        />
                                        <label for={"checkbox_electronic" + contact.contactStateId}>{'Electronic'}</label>
                                    </div>
                                </FormControl>
                                <hr />
                                <FormControl component="fieldset">
                                    <div className={styles.splitcolumn} >
                                        <input
                                            type="checkbox"
                                            className="radio"
                                            id={"checkbox_postal" + contact.contactStateId}
                                            checked={this.state.dispatchRecords.filter(record => record.contactStateId == contact.contactStateId && record.dispatchType == this.DISPATCH_TYPE_POSTAL)[0].doDispatch}
                                            onChange={(evt) => this.handleOnChange(evt, contact.contactStateId, this.DISPATCH_TYPE_POSTAL, !(this.state.dispatchRecords.filter(record => record.contactStateId == contact.contactStateId && record.dispatchType == this.DISPATCH_TYPE_POSTAL)[0].doDispatch))}

                                        />
                                        <label for={"checkbox_postal" + contact.contactStateId}>{'Postal'}</label>
                                    </div>
                                </FormControl>
                            </Stack>
                        </div>,
                        email: <div className={styles.columncell}>
                            <Stack direction="column" spacing={0.5}>
                                <div className={styles.splitcolumn} >
                                    <label >{contact.email}</label>
                                </div>
                                <hr />
                                <div className={styles.splitcolumn} >
                                    <label >{
                                        contact.contactDetails.postalAddress.line1 + ', ' +
                                        contact.contactDetails.postalAddress.line2 + ', ' +
                                        contact.contactDetails.postalAddress.suburb + ', ' +
                                        contact.contactDetails.postalAddress.code
                                    }</label>
                                </div>
                            </Stack>
                        </div>,
                        despatchDates: <div className={styles.columncell}>
                            <Stack direction="column" spacing={0.5}>
                                <div className={styles.splitcolumn} >
                                    <label >
                                        {contact.questionnaireDispatches
                                            .filter(questionnaireDispatch => questionnaireDispatch.dispatchTypeId == this.DISPATCH_TYPE_ELECTRONIC)
                                            .map(item => moment(new Date(item.dispatchDate)).format('DD/MM/YYYY'))
                                            .sort((a, b) => (new Date(a) > new Date(b)) ? -1 : 1)
                                            .join(', ')}
                                    </label>
                                </div>
                                <hr />
                                <div className={styles.splitcolumn} >
                                    <label >
                                        {contact.questionnaireDispatches.
                                            filter(questionnaireDispatch => questionnaireDispatch.dispatchTypeId == this.DISPATCH_TYPE_POSTAL)
                                            .map(item => moment(new Date(item.dispatchDate)).format('DD/MM/YYYY'))
                                            .sort((a, b) => (new Date(a) > new Date(b)) ? -1 : 1)
                                            .join(', ')}
                                    </label>
                                </div>
                            </Stack>
                        </div>,
                    }
                    despatchContactsTableData.push(row);
                });
        }

        return <div className={styles.flex_row_container}>
            <div className={styles.featuredItem}>
                <MUIDataTable
                    title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                    data={despatchContactsTableData}
                    columns={tableColumns}
                    options={options}
                />
            </div>
        </div>
    }

    render() {
        if (this.state.loading === true || this.state.contactsLoading === true) {
            return <SpinnerModal settings={true} />
        } else {
            return <DialogWrapper
                {...this.props}
                onClick={(evt) => this.props.nextButton.onClick(evt, this.state.dispatchRecords.filter(record => record.doDispatch == true), this.props.organisationStatus.organisationStateId)}
                content={
                    <React.Fragment>
                        {this.despatchContactsTable()}
                    </React.Fragment>
                }
            />;
        }
    }
}
export default DespatchDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            props.onClick(evt);
                            dialog.close(true);
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}