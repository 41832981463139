import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import DropDownMenu from '@components/DropDownMenu';
import SpinnerModal from '@components/SpinnerModal';
import MUIDataTable from 'mui-datatables';
import DataCaptureService from '@services/DataCaptureService';
import ProfileManagementService from '@services/ProfileManagementService';
import SurveyService from '@services/SurveyService';
import Questionnaire from "@components/Questionnaire";
import ReactToPrint from 'react-to-print';
import { CustomDialog, useDialog } from 'react-st-modal';

import Chip from'@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';

import styles from './previoussurveyssetup.module.css';


class PreviousSurveysSetup extends Component {

    state = {
        loading: false,
        organisationsLoading: false,
        previousSurveysLoading: false,
        selectedSectorId: null,
        allSurveyCycles: [],
        allSectors: [],
        allOrganisationStatuses: [],
        allPreviousSurveys: [],
    }

    constructor() {
        super();
    }

    async componentDidMount() {
        this.setState({ loading: true });

        const sectors = await SurveyService.getAllSectors();
        sectors.map(item => this.state.allSectors.push({ key: item.id, value: item.name.trim() }));

        const surveyCycles = await SurveyService.getAllSurveyCycles();
        this.setState({ allSurveyCycles: surveyCycles.filter(surveyCycle => surveyCycle.id >= process.env.REACT_APP_BUS_PREVIOUS_SURVEYS_DISPLAY_FROM_SURVEY_CYCLE && surveyCycle.id <= process.env.REACT_APP_CURRENT_SURVEY_CYCLE) });

        // Other stuff to be loaded goes here

        this.setState({ loading: false });
    }

    onChangeSector = (evt) => {
        this.setState({ selectedSectorId: evt.target.value });
        this.reloadOrganisations(evt.target.value, this.props.surveyCycleId);
        this.reloadPreviousSurveys(evt.target.value);
    }

    async handleClickOnPreviousSurvey(evt, title, organisationStateId) {

        const res0 = await (async () => {

            const organisationStatus = await DataCaptureService.getOrganisationStatusByOrganisationStateId(organisationStateId);

            const result = await CustomDialog(<QuestionnaireConfirmationDialog filledInBy={37} organisationStatus={organisationStatus} title={title.replace(/\s/g, '_')} />, {
                className: styles.printviewDialog,
                title: 'Preview Submitted Questionnaire : ' + title,
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            });
        })([]);

    }

    async reloadOrganisations(sectorId, surveyCycleId) {
        this.setState({ organisationsLoading: true });

        try {
            sectorId = (sectorId !== undefined && sectorId != null && sectorId != '') ? sectorId : null;
            this.setState({ selectedSectorId: sectorId });

            const organisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId(sectorId, surveyCycleId);
            this.setState({ allOrganisationStatuses: organisationStatuses });

            this.setState({ organisationsLoading: false });
        } catch (ex) {
            // SupportAlert.displayAlert('Network Error', 'An unexpected network error occured while loading the organisations list. Please try again later.');
        }

        this.setState({ organisationsLoading: false });
    }

    async reloadPreviousSurveys(sectorId) {
        this.setState({ previousSurveysLoading: true });

        try {
            sectorId = (sectorId !== undefined && sectorId != null && sectorId != '') ? sectorId : null;
            this.setState({ selectedSectorId: sectorId });

            let fromSurveyCycle = 14;

            fromSurveyCycle = (sectorId == 1) ? process.env.REACT_APP_BUS_PREVIOUS_SURVEYS_DISPLAY_FROM_SURVEY_CYCLE : fromSurveyCycle;
            fromSurveyCycle = (sectorId == 2 || sectorId == 4 || sectorId == 5) ? process.env.REACT_APP_GOV_NPO_SCI_PREVIOUS_SURVEYS_DISPLAY_FROM_SURVEY_CYCLE : fromSurveyCycle;
            fromSurveyCycle = (sectorId == 3) ? process.env.REACT_APP_HE_PREVIOUS_SURVEYS_DISPLAY_FROM_SURVEY_CYCLE : fromSurveyCycle;

            const previousSurveys = await DataCaptureService.previousSurveysBySectorId(sectorId, fromSurveyCycle, process.env.REACT_APP_CURRENT_SURVEY_CYCLE);
            this.setState({ allPreviousSurveys: previousSurveys });

            this.setState({ previousSurveysLoading: false });
        } catch (ex) {
            // SupportAlert.displayAlert('Network Error', 'An unexpected network error occured while loading the organisations list. Please try again later.');
        }

        this.setState({ previousSurveysLoading: false });
    }

    getOrganisationQuestionnaireLinkByOrganisationId(organisationStatus, index) {

        let thisOrganisationsPreviousSurveys = this.state.allPreviousSurveys.filter(prev => prev.organisation_id == organisationStatus.organisationId);

        return (
            thisOrganisationsPreviousSurveys.filter(previousSurvey => previousSurvey.name == this.state.allSurveyCycles.sort((a, b) => a.id < b.id ? 1 : -1)[index].name).length <= 0
                ? "No Data"
                : thisOrganisationsPreviousSurveys.filter(previousSurvey => previousSurvey.name == this.state.allSurveyCycles.sort((a, b) => a.id < b.id ? 1 : -1)[index].name)
                    .map(survey => {
                        return <a onClick={evt => this.handleClickOnPreviousSurvey(evt, survey.name, survey.id)} className="text">
                            {survey.receiptNumber}
                        </a>
                    })
        )
    }


    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            let columns = [
                { label: "Organisation Name", name: "organisationName" },
            ];

            let count = 1;
            this.state.allSurveyCycles.sort((a, b) => a.id < b.id ? 1 : -1).slice(0, 6).map(surveyCycle => {
                columns.push({ label: surveyCycle.name.replace('R&D Survey ', ''), name: 'survey_' + count++ })
            });

            let tableData = [];

            this.state.allOrganisationStatuses
                .filter(orgStatus => orgStatus.sectorId == this.state.selectedSectorId)
                .sort((a, b) => a.organisationStateName > b.organisationStateName ? 1 : -1)
                .map(organisationStatus => {
                    let row = {
                        organisationName: organisationStatus.organisationStateName,
                         survey_1: (organisationStatus.datacapture1Completed != true || organisationStatus.datacapture2Completed != true) ? 
                         <Badge color="primary" variant="dot"> 
                            <Chip variant='outlined'  label= {this.getOrganisationQuestionnaireLinkByOrganisationId(organisationStatus, 0)} >
                           
                            </Chip>
                         </Badge>:   <Chip variant='outlined'  label= {this.getOrganisationQuestionnaireLinkByOrganisationId(organisationStatus, 0)} />,

                        survey_2: <Chip variant='outlined'  label= {this.getOrganisationQuestionnaireLinkByOrganisationId(organisationStatus, 1)} />,
                        survey_3: <Chip variant='outlined'  label= {this.getOrganisationQuestionnaireLinkByOrganisationId(organisationStatus, 2)} />,
                        survey_4: <Chip variant='outlined'  label= {this.getOrganisationQuestionnaireLinkByOrganisationId(organisationStatus, 3)} />,
                        survey_5: <Chip variant='outlined'  label= {this.getOrganisationQuestionnaireLinkByOrganisationId(organisationStatus, 4)} />,
                        survey_6: <Chip variant='outlined'  label= {this.getOrganisationQuestionnaireLinkByOrganisationId(organisationStatus, 5)} />,
                    }
                    tableData.push(row);
                });
            
            let options = {
                padding: 'none',
                size: 'small',
                rowsPerPage: [10],
                rowsPerPageOptions: [10, 20, 50, 100],
                jumpToPage: true,
                selectableRowsHideCheckboxes: true,
                // onRowClick: this.onInteractionClicked,
            };

            return (
                <div className={styles.previousSurveysSetupContainer}>
                    <div  className={styles.featuredDropDown}>
                        <span className={styles.featuredTitleHeader}><h2>{'Previous Surveys'}</h2></span>
                        <hr />
                        <Grid container item xs={15} >
                            <DropDownMenu
                                name={'sector'}
                                label={'Sector'}
                                value={this.state.selectedSectorId}
                                onChange={(evt) => this.onChangeSector(evt)}
                                showKeys={false}
                                menuOptions={this.state.allSectors.sort((a, b) => a.value > b.value ? 1 : -1)}
                                placeholder='Select Sector'
                                required
                            />
                        </Grid>
                        <br />

                    </div>

                    <div className={styles.featuredItem}>
                        {/* <span className={styles.featuredTitleHeader}><h2>{'Previous Surveys'}</h2></span>
                        <hr />
                        <Grid container item xs={2} >
                            <DropDownMenu
                                name={'sector'}
                                label={'Sector'}
                                value={this.state.selectedSectorId}
                                onChange={(evt) => this.onChangeSector(evt)}
                                showKeys={false}
                                menuOptions={this.state.allSectors.sort((a, b) => a.value > b.value ? 1 : -1)}
                                placeholder='Select Sector'
                                required
                            />
                        </Grid>
                        <br /> */}
                        <Grid container item xs={15} >
                            {(this.state.selectedSectorId != null && this.state.organisationsLoading == false)
                                ?
                                <div className={styles.flex_row_container}>
                                    <MUIDataTable
                                        title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{'Questionnaires per organisation'.toUpperCase()}</h1></div>}
                                        data={tableData}
                                        columns={columns}
                                        options={options}
                                    />
                                </div>
                                : (
                                    this.state.previousSurveysLoading === true || this.state.organisationsLoading === true
                                        ? <SpinnerModal settings={this.state.organisationsLoading} />
                                        : <div />
                                )
                            }
                        </Grid>
                    </div>
                </div>
            )
        }
    }
}

export default PreviousSurveysSetup;

function QuestionnaireConfirmationDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    return (
        <div>
            <div
                className={styles.confirmDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <Questionnaire
                    organisationStatus={props.organisationStatus}
                    organisationStateId={props.organisationStatus.organisationStateId}
                    capturedById={37}                   // this only provided for viewing purposes
                    filledInById={props.filledInBy}
                    captureType={'RESPONDENT'}          // this only provided for viewing purposes
                    printview={true}
                    previousSurvey={true}
                />
            </div>
            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        dialog.close(true);
                    }}
                >
                    Cancel
                </button>
                <ReactToPrint
                    documentTitle={props.title + '.pdf'}
                    content={() => componentRef}
                    removeAfterPrint={true}
                    onAfterPrint={() => {
                        dialog.close(true);
                    }}
                    trigger={() => <button
                        type="button"
                        className="btnNext"
                    >Download
                    </button>
                    }
                />
            </div>
        </div>
    );
}