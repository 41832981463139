import  {React, useState}  from 'react';
import styles from "./header.module.css";
import dsiTaxIncentive from '@images/TopBar/TaxIncentive.jpg';

import axios from "axios";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import Chip from '@material-ui/core/Chip' ;
import DataCaptureService from '@services/DataCaptureService'; 

function parseJwt(token) {
    var base64Payload = token.split('.')[1];
    var payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
  }
  
export default function Header() {

    const [organisationName, setOrganisationName] = useState(null);
  const [sectorName, setSectorName] = useState(null);

  let bearer = localStorage.getItem('Bearer');
  if (!bearer){
    window.location = "/login";
  }
  
  let payload = parseJwt(bearer);

  let orgID = payload.org_id;
  let sector_id = payload.sector_id;
  
  async function getOrgStatus(orgId) {
    let organisationStatus = await DataCaptureService.getOrganisationStatusBySurveyCycleIdAndOrganisationId(process.env.REACT_APP_CURRENT_SURVEY_CYCLE, orgId);

    if (organisationStatus !== undefined && organisationStatus !== null) {
      setOrganisationName(organisationStatus.organisationStateName);
    }
  }

  getOrgStatus(orgID);

  let sectorNameUrl = process.env.REACT_APP_SURVEY_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + "/questionnaireservice/questionnairetrackers/sector/name/" + sector_id;

  if (sector_id) {

    axios.get(sectorNameUrl)
      .then(response => {
        setSectorName(response.data.name) ; 
      });
  }


  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  

  const card = (
   <>
        <CardContent className={styles.card_content}>
       
        <Typography variant="h5" component="div">
        {organisationName}
        </Typography>
   
        <Typography variant="body2">
          
          {sectorName}
        </Typography>
      </CardContent>
      </>
     
      
    
  );
  


    return (
        <div className={styles.header_container}>
        <div className={styles.banner}>
            <span>
        <a href="https://www.dst.gov.za/rdtax/index.php" target="_blank" rel="noreferrer"> 
            <img src={dsiTaxIncentive} className={styles.center__image} alt="DSI Tax incentive" />
            </a>
            
          </span>
        </div>
        <div className={styles.card}>
        {/* <Card  className={styles.card_main}>{card}</Card>
        </div> */}
        <Chip label={organisationName} icon={<CorporateFareOutlinedIcon />}  variant='outlined' />
        </div>
        </div>
            )

}