import EmailValidator from 'email-validator';

export default function FieldValidator() {
}

/**
 * Validate an email address.
 * @param {string} email - The email address to validate.
 * @returns {boolean}
 */
export function validateEmail(email) {
    let validEmail = (email !== undefined && email !== null && email.trim() !== '' && EmailValidator.validate(email));
    // console.log('Email valid: ' + validEmail);
    return validEmail;
}

/**
 * Validate an email address as part of @hsrc.ac.za.
 * @param {string} email - The email address to validate.
 * @param {string} domain - The domain to validate against.
 * @returns {boolean}
 */
export function validateDomainEmail(email, domain) {
    if (domain != null && domain.length > 0) {
        return (validateEmail(email) && email.endsWith(domain));
    }
    return EmailValidator.validate(email);
}

/**
 * Validate a name/surname.
 * @param {string} myName - The name to validate.
 * @returns {boolean}
 */
export function validateName(myName) {
    if (myName != null && myName.length > 0) {
        return !(/\d/.test(myName)) && (myName.replace(/[0-9]/g, '').length > 0)
    }

    return false;
}

/**
 * TODO: Alternative phone number validation needs to be done here
 *       Currently looks for numbers 
 * Validate a phone number.
 * @param {string} phoneNumber - The phonenumber to validate.
 * @returns {boolean}
 */
export function validatePhoneNumber(phoneNumber) {
    return ((phoneNumber.replace(/\D/g, '').length >= 10) && (phoneNumber.replace(/\D/g, '').length < 16));
}

/**
 * Validate a password strength.
 * @param {string} password - The password to validate.
 * @returns {boolean}
 */
export function validatePasswordStrength(password) {
    return (hasRequiredLength(password) && containsLowercase(password) && containsUppercase(password) && containsNumber(password) && containsSpecialCharacter(password));
}

/**
 * Validate a password length.
 * @param {string} password - The password to validate.
 * @returns {boolean}
 */
export function hasRequiredLength(password) {
    return (password.length >= 8);
}

/**
 * Validate a password contains lowercase chars.
 * @param {string} password - The password to validate.
 * @returns {boolean}
 */
export function containsLowercase(password) {
    return (/[a-z]/.test(password));
}

/**
 * Validate a password contains uppercase chars.
 * @param {string} password - The password to validate.
 * @returns {boolean}
 */
export function containsUppercase(password) {
    return (/[A-Z]/.test(password));
}

/**
 * Validate a password contains numbers.
 * @param {string} password - The password to validate.
 * @returns {boolean}
 */
export function containsNumber(password) {
    return (/\d/.test(password));
}

/**
 * Validate a password contains special chars.[!, @, #, $, %, ^, &,, *]
 * @param {string} password - The password to validate.
 * @returns {boolean}
 */
export function containsSpecialCharacter(password) {
    return (/[\!|\@|\#|\$|\%|\^|\&|\*]/.test(password));
}

/**
 * Validate a value <= reference
 * @param {float} value - The value to validate.
 * @param {float} reference - The reference value.
 * @returns {boolean}
 */
export function lessThenOrEquals(value, reference) {
    return parseFloat(value) <= parseFloat(reference);
}
