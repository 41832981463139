import React from 'react';
import MultiColorTitle from "@components/MultiColorTitle";
import { Link } from 'react-router-dom';

import styles from './survey.module.css';

function Finished() {


    return <div className={styles.container_surveys_finished}>
        <div >
            <br></br>
            <text className="title__54px">
                <center>Thank you!</center>
            </text>
            <br>
            </br>
            <text className="text__18px">
                <center>Thank you for completing the survey.</center>
            </text>
            <br></br>
            <div style={{ marginBottom: 60, color: '#FF7F50' }} >
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSeD0P6kjxrWQ4FirIfsZmwWBjMBvIAcchyGakpozgEbGuYI0A/viewform?usp=sf_link'>
                    <p style={{ color: '#FF7F50' }}><center>Please take a moment to complete our feedback questionnaire.</center></p>
                </a>
            </div>
        </div>
    </div>
}

export default Finished;