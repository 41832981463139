import React, { Component } from 'react';
import axios from "axios";
import SupportAlert from '@components/SupportAlert';

const FIELDWORK_MANAGEMENT_SERVICE = process.env.REACT_APP_FIELDWORK_MANAGEMENT_MICROSERVICE_URL + process.env.REACT_APP_SERVICES_VERSION + '/fieldworkmanagement';

export default function FieldworkManagementService() {
}

export async function saveRdiMetadata(metadata) {

    let url = FIELDWORK_MANAGEMENT_SERVICE + '/save/rdimetadata';

    try {
        fetch(url, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(metadata)
        }).then(() => {
        })
    } catch (ex) {
        SupportAlert.displayAlert('Network', 'An unexpected network error occured while saving metadata. Please try again later.');
    }
}

export async function saveRdiRespondentInteraction(data) {

    let url = FIELDWORK_MANAGEMENT_SERVICE + '/save/rdirespondentinteraction';

    try {
        fetch(url, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }).then(() => {
        })
    } catch (ex) {
        SupportAlert.displayAlert('Network', 'An unexpected network error occured while saving this interaction. Please try again later.');
    }
}

export async function saveLogicalErrors(data) {
    let url = FIELDWORK_MANAGEMENT_SERVICE + '/save/rdilogicalerrors';

    try {
        fetch(url, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)
        }).then(() => {
        })
    } catch (ex) {
        SupportAlert.displayAlert('Network', 'An unexpected network error occured while saving your data. Please try again later.');
    }
}

export async function getLogicalErrorsByQuestionnaireTrackerId(questionnaireTrackerId) {

    let url = FIELDWORK_MANAGEMENT_SERVICE + '/rdilogicalerrors/questionnairetracker/' + questionnaireTrackerId;

    const logicalErrorsURL = axios.create({ baseURL: url });

    const logicalErrors = await logicalErrorsURL.get('')
        .then(response => {
            const data = response.data
            return data;
        })
        .catch((e) => {
            SupportAlert.displayAlert('Network', 'An unexpected network error occured while reading data. Please try again later.');
            // console.error('There was an error!', e);
        });

    if (logicalErrors != null) {
        return logicalErrors;
    }

    return null;
}

export async function getAllLogicalErrorsBySurveyCycle(surveyCycleId) {

    let url = FIELDWORK_MANAGEMENT_SERVICE + '/rdilogicalerrors/surveycycle/' + surveyCycleId;

    const logicalErrorsURL = axios.create({ baseURL: url });

    const logicalErrors = await logicalErrorsURL.get('')
        .then(response => {
            const data = response.data
            return data;
        })
        .catch((e) => {
            SupportAlert.displayAlert('Network', 'An unexpected network error occured while reading data. Please try again later.');
            // console.error('There was an error!', e);
        });

    if (logicalErrors != null) {
        return logicalErrors;
    }

    return null;
}

export async function getInteractionsByOrganisationStateId(organisationStateId) {

    let url = FIELDWORK_MANAGEMENT_SERVICE + '/rdirespondentinteractions/organisationstate/' + organisationStateId;

    const interactionHistoryURL = axios.create({ baseURL: url });

    const interactionHistory = await interactionHistoryURL.get('')
        .then(response => {
            const data = response.data
            return data;
        })
        .catch((e) => {
            SupportAlert.displayAlert('Network', 'An unexpected network error occured while reading data. Please try again later.');
            // console.error('There was an error!', e);
        });

    if (interactionHistory != null) {
        return interactionHistory;
    }

    return null;
}

export async function getInteractionsBySurveyCycleAndSurveysUser(surveyCycleId, surveysUserId) {

    let url = FIELDWORK_MANAGEMENT_SERVICE + '/rdirespondentinteractions/surveycycle/' + surveyCycleId + '/surveysuser/' + surveysUserId;

    const interactionHistoryURL = axios.create({ baseURL: url });

    const interactionHistory = await interactionHistoryURL.get('')
        .then(response => {
            const data = response.data
            return data;
        })
        .catch((e) => {
            SupportAlert.displayAlert('Network', 'An unexpected network error occured while reading data. Please try again later.');
            // console.error('There was an error!', e);
        });

    if (interactionHistory != null) {
        return interactionHistory;
    }

    return null;
}

export async function getInteractionsBySurveyCycle(surveyCycleId) {

    let url = FIELDWORK_MANAGEMENT_SERVICE + '/rdirespondentinteractions/surveycycle/' + surveyCycleId;

    const interactionHistoryURL = axios.create({ baseURL: url });

    const interactionHistory = await interactionHistoryURL.get('')
        .then(response => {
            const data = response.data
            return data;
        })
        .catch((e) => {
            SupportAlert.displayAlert('Network', 'An unexpected network error occured while reading data. Please try again later.');
            // console.error('There was an error!', e);
        });

    if (interactionHistory != null) {
        return interactionHistory;
    }

    return null;
}

export async function getFieldworkReportDataBySurveyCycle(surveyCycleId) {

    let url = FIELDWORK_MANAGEMENT_SERVICE + '/fieldworkreport/surveycycle/' + surveyCycleId;

    const fieldworkReportURL = axios.create({ baseURL: url });

    const fieldworkReportData = await fieldworkReportURL.get('')
        .then(response => {
            const data = response.data
            return data;
        })
        .catch((e) => {
            SupportAlert.displayAlert('Network', 'An unexpected network error occured while reading data. Please try again later.');
        });

    if (fieldworkReportData != null) {
        return fieldworkReportData;
    }

    return null;
}