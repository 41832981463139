import React, { Component } from "react";
import FieldworkManagementService from '@services/FieldworkManagementService';
import SurveyService from '@services/SurveyService';
import NumberTools from '@components/NumberTools';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function CSVGenerator() {
}

export async function exportFieldworkReportToXLS(evt) {
    evt.preventDefault();

    const ARROW_UP = 'UP';
    const ARROW_DOWN = 'DOWN';
    const ARROW_LEFT = 'NOT COMPARED';
    const ARROW_RIGHT = 'NO CHANGE';

    const surveyCycles = await (async () => {
        const cycles = await SurveyService.getAllSurveyCycles();
        let labelledCycles = [];
        cycles.filter(surveyCycle => surveyCycle.id <= process.env.REACT_APP_CURRENT_SURVEY_CYCLE).sort((a, b) => a.id < b.id ? 1 : -1).map(item => labelledCycles.push({ id: item.id, label: (item.referenceFromYear + '/' + item.referenceToYear) }));
        return labelledCycles.slice(0, 2);
    })([]);

    let currentSurveyCycle = surveyCycles.filter(surveyCycle => surveyCycle.id <= process.env.REACT_APP_CURRENT_SURVEY_CYCLE).sort((a, b) => a.id < b.id ? 1 : -1)[0];
    let previousSurveyCycle = surveyCycles.filter(surveyCycle => surveyCycle.id <= process.env.REACT_APP_CURRENT_SURVEY_CYCLE).sort((a, b) => a.id < b.id ? 1 : -1)[1];
    let currentSurveyCycleERDTitle = 'ERD ' + currentSurveyCycle.label + ' Rm';
    let previousSurveyCycleERDTitle = 'ERD ' + previousSurveyCycle.label + ' Rm';

    const reportRecords = await (async () => {
        const records = await FieldworkManagementService.getFieldworkReportDataBySurveyCycle(process.env.REACT_APP_CURRENT_SURVEY_CYCLE);
        return records;
    })([]);

    let columnNames = ['Date received',
        '1st Capture',
        '2nd Capture',
        previousSurveyCycleERDTitle,
        currentSurveyCycleERDTitle,
        'YoY ERD Change',
        'Sector',
        'Enterprise Name',
        'Survey Critical',
        'Researcher',
        'RDSMS Receipt #',
        'Thank you sent',
        'eSQ Filed',
        'Return Type',
        'Return Status',
        'Final Status',
        'Latest Update Date',
        'Interaction Status',
        'Interaction Comment',
        'Interaction Type',
        'Email Address',
        'Title',
        'First Name',
        'Last Name'
    ];
    let data = [];

    if (reportRecords !== undefined && reportRecords !== null && reportRecords.length > 0) {
        reportRecords.sort((a, b) => a.interactionId < b.interactionId ? 1 : -1).map((record) => {
            let capture1 = (record.datacapture1Completed == true) ? 'Completed' : (record.datacapture1CapturedById !== null && record.datacapture1CapturedById > 0) ? 'In Progress' : 'Not Captured';
            let capture2 = (record.datacapture2Completed == true) ? 'Completed' : (record.datacapture2CapturedById !== null && record.datacapture2CapturedById > 0) ? 'In Progress' : 'Not Captured';
            let currentExpenditure = (record.currentExpenditure !== undefined && record.currentExpenditure !== null && !isNaN(record.currentExpenditure)) ? NumberTools.transformToCurrency(parseFloat(record.currentExpenditure / 1000).toFixed(2)) : '';
            let previousExpenditure = (record.previousExpenditure !== undefined && record.previousExpenditure !== null && !isNaN(record.currentExpenditure)) ? NumberTools.transformToCurrency(parseFloat(record.previousExpenditure / 1000).toFixed(2)) : '';

            let yoyChange = ARROW_LEFT;

            if (record.currentExpenditure !== undefined &&
                record.currentExpenditure !== null &&
                record.currentExpenditure >= 0 &&
                record.previousExpenditure !== undefined &&
                record.previousExpenditure !== null &&
                record.previousExpenditure >= 0) {
                if (record.previousExpenditure > record.currentExpenditure) {
                    yoyChange = ARROW_DOWN;
                }

                if (record.previousExpenditure < record.currentExpenditure) {
                    yoyChange = ARROW_UP;
                }

                if (record.previousExpenditure == record.currentExpenditure) {
                    yoyChange = ARROW_RIGHT;
                }

            }

            data.push({
                dateReceived: moment(new Date(record.dateReceived)).format('DD/MM/YYYY'),
                captureOne: capture1,
                captureTwo: capture2,
                previousERD: previousExpenditure,
                currentERD: currentExpenditure,
                yoyERDchange: yoyChange,
                sector: record.sector,
                orgName: record.organisationStateName,
                surveyCritical: (record.surveyCritical == true) ? 'Yes' : 'No',
                fieldworker: record.researcher,
                receipt: record.receiptNumber,
                thankYouSent: (record.thankYouSent == true) ? 'Yes' : 'No',
                esqfiled: (record.esqFiled == true) ? 'Yes' : 'No',
                returnType: record.returnType,
                returnStatus: record.returnStatus,
                finalStatus: record.finalStatus,
                interactionDate: moment(new Date(record.interactionDate)).format('DD/MM/YYYY HH:mm'),
                status: record.interactionStatus,
                comment: record.comment.replace(',', ''),
                type: record.interactionType,
                email: record.email,
                title: record.title,
                firstname: record.firstname,
                surname: record.lastname
            });
        });
    }

    let filename = 'Fieldwork_Report_' + moment(new Date()).format('DDMMMYYYY');

    exportToXLS(columnNames, data, filename);

}

export async function exportTableToXLS({ columnNames, fileData, fileName }) {
    exportToXLS(columnNames, fileData, fileName.replaceAll(' ', '_'));
}

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const exportToXLS = (columnNames, fileData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(fileData);
    XLSX.utils.sheet_add_aoa(ws, [columnNames], { origin: "A1" });
    const wb = { Sheets: { 'Sheet 1': ws }, SheetNames: ["Sheet 1"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
}