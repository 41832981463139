import React, { Component } from "react";
import axios from "axios";
import { Alert } from 'react-st-modal';

const DEFAULT_TITLE = 'OOPS!';

const DEFAULT_MESSAGE = 'Something unexpected occurred. \n' +
    'Please follow these three steps: \n' +
    'Step 1: Click on this email address (innovation@hrsc.ac.za) to start a new email to us. \n' +
    'Step 2: In the email, please include the question number on the screen that presented a problem and your user login name: \n' +
    'Step 3: Send this to us at innovation@hrsc.ac.za';


export default function SupportAlert() {
}

/**
 * displayAlert
 * @param {title, message} 
 * @returns {none}
 */
export function displayAlert(title, message) {
    { Alert((message != null && message.trim() != '') ? message : DEFAULT_MESSAGE, (title != null && title.trim() != '') ? title : DEFAULT_TITLE) };
}

export async function displayConfirmationAlert(title, message) {

    const confirmed = await (async () => {
        const result = await Alert((message != null && message.trim() != '') ? message : DEFAULT_MESSAGE, (title != null && title.trim() != '') ? title : DEFAULT_TITLE);
        return true;
    })([]);

    return confirmed;
}
