import ProfileManagementService, {
    getOrganisationStatesBySectorIdAndSurveyCycleId,
    getAllOrganisations,
    getOrganisationById,
    getOrganisationContactStateDetailByEmailSectorSurveyCycle,
    getSurveysUserByEmail,
    getAllSurveysUsers,
    updateRealmUser,
    createRealmUser,
    deregisterRealmUser,
    getRealmUser,
    getAllRealmUsers,
    getUserByUsername,
    sendEmail,
    subscribeToNewsletter,
    sendNewOneTimePasswordForUserId
} from './ProfileManagementService';

export default { ProfileManagementService, getOrganisationStatesBySectorIdAndSurveyCycleId, getAllOrganisations, getOrganisationById, getOrganisationContactStateDetailByEmailSectorSurveyCycle, getSurveysUserByEmail, getAllSurveysUsers, updateRealmUser, createRealmUser, deregisterRealmUser, getRealmUser, getAllRealmUsers, getUserByUsername, sendEmail, subscribeToNewsletter, sendNewOneTimePasswordForUserId}