import FieldValidator from '@components/FieldValidator';
import Footer from "@components/Footer";
import MultiColorTitle from "@components/MultiColorTitle";
import NavigationBar from "@components/NavigationBar";
import Separator from "@components/Separator";
import Contact from "@components/Contact";
import done_all from '@images/done_all.png';
import cancel_button from '@images/Home/cancel_button.png';
import continue_available_button from '@images/Home/continue_available_button.png';
import continue_unavailable_button from '@images/Home/continue_unavailable_button.png';
import step1of2 from '@images/step1of2.png';
import step2of2 from '@images/step2of2.png';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import { Home } from "@material-ui/icons";
import styles from "./getstarted.module.css";
import DropDownMenu from '@components/DropDownMenu';
import ProfileManagementService from '@services/ProfileManagementService';
import pageHint from '@images/info.svg';
import HintCard from "@components/HintCard";

class GetStarted extends Component {

    state = {
        currentPage: 1,

        q1: true,
        q2: true,
        q3: true,
        q4: true,
        radio1: false,
        radio2: false,
        radio3: false,
        radio4: false,
        radio5: false,
        radio6: false,
        radio7: false,
        radio8: false,

        validEmail: false,
        validFirstName: false,
        validLastName: false,
        validCellphone: false,
        validWorkPhone: false,
        validSector: false,
        checkBoxChecked: '',

        emailAddress: '',
        firstName: '',
        lastName: '',
        cellphone: '',
        workPhone: '',
        designation: '',
        organisationName: '',
        sector: '',

        allSectors: []
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.state.allSectors.push({ key: 1, value: 'Business enterprise' });
        this.state.allSectors.push({ key: 2, value: 'Government' });
        this.state.allSectors.push({ key: 3, value: 'Higher education' });
        this.state.allSectors.push({ key: 4, value: 'Not-for-profit' });
        this.state.allSectors.push({ key: 5, value: 'Science Councils' });
    }

    handleClickEvent = (evt) => {

               if (evt.target.type !== "undefined" && evt.target.type === "checkbox") {

                   switch (evt.target.id) {
                        case 'radio1': {
                            this.setState({ radio1: true });
                            this.setState({ radio2: false });
                            break;
                        }
                        case 'radio2': {
                              this.setState({ radio1: false });
                              this.setState({ radio2: true });
                            break;
                        }
                        case 'radio3': {
                            this.setState({ radio3: true });
                            this.setState({ radio4: false });
                            break;
                        }
                        case 'radio4': {
                            this.setState({ radio3: false });
                            this.setState({ radio4: true });
                            break;
                        }
                        case 'radio5': {
                            this.setState({ radio5: true });
                            this.setState({ radio6: false });
                            break;
                        }
                        case 'radio6': {
                            this.setState({ radio5: false });
                            this.setState({ radio6: true });
                            break;
                        }
                        case 'radio7': {
                            this.setState({ radio7: true });
                            this.setState({ radio8: false });
                            break;
                        }
                        case 'radio8': {
                            this.setState({ radio7: false });
                            this.setState({ radio8: true });
                            break;
                        }
                   }
                }
    }

    handleChangedValue = (evt, value) => {
        switch (evt.target.name) {
            case 'q1': {
                this.setState({ q1: value });
                break;
            }
            case 'q2': {
                this.setState({ q2: value });
                break;
            }
            case 'q3': {
                this.setState({ q3: value });
                break;
            }
            case 'q4': {
                this.setState({ q4: value });
                break;
            }
            case 'emailAddress': {
                this.setState({ validEmail: FieldValidator.validateEmail(evt.target.value) });
                this.setState({ emailAddress: evt.target.value });
                break;
            }
            case 'firstName': {
                this.setState({ validFirstName: FieldValidator.validateName(evt.target.value) });
                this.setState({ firstName: evt.target.value });
                break;
            }
            case 'lastName': {
                this.setState({ validLastName: FieldValidator.validateName(evt.target.value) });
                this.setState({ lastName: evt.target.value });
                break;
            }
            case 'cellphone': {
                this.setState({ validCellphone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ cellphone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'workPhone': {
                this.setState({ validWorkPhone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ workPhone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'designation': {
                this.setState({ designation: evt.target.value });
                break;
            }
            case 'organisationName': {
                this.setState({ organisationName: evt.target.value });
                break;
            }
            case 'sector': {
                this.setState({ validSector: (evt.target.value != '') });
                this.setState({ sector: evt.target.value });
                break;
            }
            default: {
                break;
            }
        }
    }

    buttonDisabled() {
        return !(
            this.state.validEmail &&
            this.state.validFirstName &&
            this.state.validLastName &&
            this.state.validCellphone &&
            this.state.validWorkPhone &&
            this.state.validSector
        )
    }

    handleContinue = (evt) => {
        switch (this.state.currentPage) {
            case 1: this.setState({ currentPage: 2 }); break;
            case 2: {
                this.setState({ currentPage: 3 });
                this.sendEmailToHSRC();
                break;
            }
        }
    }


    async sendEmailToHSRC() {

        let message = "The following individual has submitted the following details on the Getting Started page: \n" +
            "Question 1 : " + this.state.q1 + ", \n" +
            "Question 2 : " + this.state.q2 + ", \n" +
            "Question 3 : " + this.state.q3 + ", \n" +
            "Question 4 : " + this.state.q4 + ", \n" +
            "Email : " + this.state.emailAddress + ", \n" +
            "Firstname : " + this.state.firstName + ", \n" +
            "Lastname : " + this.state.lastName + ", \n" +
            "Cell : " + this.state.cellphone + ", \n" +
            "Work Phone : " + this.state.workPhone + ", \n" +
            "Designation : " + this.state.designation + ", \n" +
            "Organisation Name : " + this.state.organisationName + ", \n" +
            "Sector : " + this.state.sector;

        await ProfileManagementService.sendEmail("innovation@hsrc.ac.za", "Getting Started Response Received", message);


    }

    showPageOne = () => {

        let hint1 =  "• Investigating electrical conduction in crystals is basic research; application of crystallography to the properties of alloys is applied research.\n" +
                     "• New chip designs involve development.\n" +
                     "• Investigating the limiting factors in chip element placement lies at the border between basic and applied research, and increasingly involves nanotechnology.\n" +
                     "• Much services R&D involves software development where the completion of the project is dependent on a scientific or technological advance and the aim of the project is the systematic resolution of a scientific or technological uncertainty.";

        let hint2 =  "• The provision of professional, technical, administrative or clerical support and/or assistance to personnel directly engaged in R&D \n" +
                     "• Management of personnel who are either directly engaged in R&D or are providing professional, technical or clerical support to those performing R&D \n" +
                     "• Software development where the aim of the project is the systematic resolution of a scientific or technological uncertainty \n" +
                     "• Research work in the biological, physical and social sciences, and the humanities \n" +
                     "• Social science research including economic, cultural, educational, psychological and sociological research \n" +
                     "• Research work in engineering and the medical sciences \n" +
                     "• R&D projects performed for other parties \n" +
                     "• Feedback R&D directed at solving problems occurring beyond the original R&D phase, for example technical problems arising during initial production runs.";
        return (
            <Container className={styles.container_getstarted}>
                <MultiColorTitle
                    title_font="title__42px"
                    first_color="bold__orange"
                    first_text="Express&nbsp;"
                    second_color="blue"
                    second_text="Interest"
                />
                <div style={{ maxWidth: "45%", textAlign: "center" }}>
                    <text className="text__18px">Does your organisation or business perform R&D? You can express interest to participate in the R&D Survey, and we’ll get back to you. First review the definition of R&D and its criteria below, then answer questions 1 to 4.</text>
                </div>
                <p />
                <p />
                <Container className={styles.container_getstarted_body}>
                    <p>
                        <MultiColorTitle
                            title_font="text__16px"
                            first_color="blue"
                            first_text="Step&nbsp;"
                            second_color="orange"
                            second_text="One&nbsp;"
                            third_color="blue"
                            third_text="of&nbsp;Two"
                        />
                    </p>
                    <p><p><img src={step1of2} alt="step1of2" /></p></p>
                    <p>
                        <MultiColorTitle
                            title_font="text__18px"
                            first_color="bold__blue"
                            first_text=''
                        />
                    </p>
                    <p>
                        <div style={{ maxWidth: "100%" }}>
                            <text className="text__18px__bold">Definition of R&D </text>
                        </div>

                        <div style={{ maxWidth: "62%" }}>
                            <text className="text__18px">"Creative and systematic work undertaken in order to increase the stock of knowledge – including knowledge of humankind, culture and society – and to devise new applications of available knowledge."</text>
                        </div>
                    </p>
                    <p>
                        <MultiColorTitle
                            title_font="text__18px"
                            first_color="bold__blue"
                            first_text=''
                        />
                    </p>
                    <p>
                        <div style={{ maxWidth: "100%" }}>
                            <text className="text__18px__bold">R&D activity must be:</text>
                                                                      <span className="pageHint">
                                                                        <img src={pageHint} />
                                                                        <span className="tooltiptext"><HintCard heading={"Examples of R&D types"} hintText={hint1}/></span>
                                                                      </span><div />
                        </div>
                        <div style={{ maxWidth: "100%" }}>
                            <text className="text__18px">- Novel or new</text>
                        </div>
                        <div style={{ maxWidth: "100%" }}>
                            <text className="text__18px">- Creative</text>
                        </div>
                        <div style={{ maxWidth: "100%" }}>
                            <text className="text__18px">- Systematic</text>
                        </div>
                        <div style={{ maxWidth: "100%" }}>
                            <text className="text__18px">- Transferable and/or reproducible</text>
                        </div>
                    </p>
                    <p>
                        <MultiColorTitle
                            title_font="text__18px"
                            first_color="bold__blue"
                            first_text=''
                        />
                    </p>
                    <p>
                        <div style={{ maxWidth: "50%" }}>
                            <text className="text__18px__bold">1. Does your organisation or company perform intramural (i.e. in-house) R&D?</text>
                                             <span className="pageHint">
                                                                                                <img src={pageHint} />
                                                                                                <span className="tooltiptext"><HintCard heading={"R&D includes - but not limited to:"} hintText={hint2}/></span>
                                                                                              </span><div />
                        </div>
                        <p />
                        <FormControl component="fieldset">
                            <div class="radio-group">
                                <input
                                    type="checkbox"
                                    id="radio1"
                                    name='q1'
                                    checked={this.state.radio1}
                                    onClick={evt => this.handleClickEvent(evt)}
                                    onChange={evt => this.handleChangedValue(evt, true)}
                                /> <label for="radio1">Yes &nbsp;&nbsp;&nbsp;</label>
                                <input
                                    type="checkbox"
                                    id="radio2"
                                    name='q1'
                                    checked={this.state.radio2}
                                    onClick={evt => this.handleClickEvent(evt)}
                                    onChange={evt => this.handleChangedValue(evt, false)}
                                /> <label for="radio2">No </label>
                            </div>
                        </FormControl>
                    </p>
                    <p>
                        <MultiColorTitle
                            title_font="text__18px"
                            first_color="bold__blue"
                            first_text=''
                        />
                    </p>
                    <p>
                        <div style={{ maxWidth: "50%" }}>
                            <text className="text__18px__bold">2. Is the R&D performed within the national territory of South Africa?</text>
                        </div>
                        <p />
                        <FormControl component="fieldset">
                            <div class="radio-group">
                                <input
                                    type="checkbox"
                                    id="radio3"
                                    name='q2'
                                    checked={this.state.radio3}
                                    onClick={evt => this.handleClickEvent(evt)}
                                    onChange={evt => this.handleChangedValue(evt, true)}
                                /> <label for="radio1">Yes &nbsp;&nbsp;&nbsp;</label>
                                <input
                                    type="checkbox"
                                    id="radio4"
                                    name='q2'
                                    checked={this.state.radio4}
                                    onClick={evt => this.handleClickEvent(evt)}
                                    onChange={evt => this.handleChangedValue(evt, false)}
                                /> <label for="radio2">No </label>
                            </div>
                        </FormControl>
                    </p>
                    <p>
                        <MultiColorTitle
                            title_font="text__18px"
                            first_color="bold__blue"
                            first_text=''
                        />
                    </p>
                    <p>
                        <div style={{ maxWidth: "50%" }}>
                            <text className="text__18px__bold">3. Did your company incur any of the following R&D expenditure in your most recent financial year: </text>
                            <text className="text__18px">Labour costs (researchers, research managers), capital expenditure (e.g. machinery, land, buildings, etc.)</text>
                        </div>
                        <p />
                        <FormControl component="fieldset">
                            <div class="radio-group">
                                <input
                                    type="checkbox"
                                    id="radio5"
                                    name='q3'
                                    checked={this.state.radio5}
                                    onClick={evt => this.handleClickEvent(evt)}
                                    onChange={evt => this.handleChangedValue(evt, true)}
                                /> <label for="radio1">Yes &nbsp;&nbsp;&nbsp;</label>
                                <input
                                    type="checkbox"
                                    id="radio6"
                                    name='q3'
                                    checked={this.state.radio6}
                                    onClick={evt => this.handleClickEvent(evt)}
                                    onChange={evt => this.handleChangedValue(evt, false)}
                                /> <label for="radio2">No </label>
                            </div>
                        </FormControl>
                    </p>
                    <p>
                        <MultiColorTitle
                            title_font="text__18px"
                            first_color="bold__blue"
                            first_text=''
                        />
                    </p>
                    <p>
                        <div style={{ maxWidth: "50%" }}>
                            <text className="text__18px__bold">4. Does your organisation plan to perform R&D in the future?</text>
                        </div>
                        <p />
                        <FormControl component="fieldset">
                            <div class="radio-group">
                                <input
                                    type="checkbox"
                                    id="radio7"
                                    name='q4'
                                    checked={this.state.radio7}
                                    onClick={evt => this.handleClickEvent(evt)}
                                    onChange={evt => this.handleChangedValue(evt, true)}
                                /> <label for="radio1">Yes &nbsp;&nbsp;&nbsp;</label>
                                <input
                                    type="checkbox"
                                    id="radio8"
                                    name='q4'
                                    checked={this.state.radio8}
                                    onClick={evt => this.handleClickEvent(evt)}
                                    onChange={evt => this.handleChangedValue(evt, false)}
                                /> <label for="radio2">No </label>
                            </div>
                        </FormControl>
                    </p>
                    <div style={{ maxWidth: "75%" }} className={styles.divider} />
                </Container>
            </Container>
        )

    }

    showPageTwo = () => {
        return (
            <Container className={styles.container_getstarted}>
                <MultiColorTitle
                    title_font="title__42px"
                    first_color="bold__orange"
                    first_text="Get&nbsp;"
                    second_color="blue"
                    second_text="Started"
                />
                <div style={{ maxWidth: "45%" }}>
                    <text className="text__18px">Fill in your details so we can get back to you.</text>
                </div>
                <p />
                <p />
                <Container className={styles.container_getstarted_body}>
                    <p>
                        <MultiColorTitle
                            title_font="text__16px"
                            first_color="blue"
                            first_text="Step&nbsp;"
                            second_color="orange"
                            second_text="Two&nbsp;"
                            third_color="blue"
                            third_text="of Two"
                        />
                    </p>
                    <p><img src={step2of2} alt="step2of2" /></p>
                    <p>
                        <MultiColorTitle
                            title_font="text__18px"
                            first_color="bold__blue"
                            first_text=''
                        />
                    </p>
                    <div className={styles.grid_container} >
                        <p>
                            <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                                <Grid container item xs={5} >
                                    <TextField
                                        error={this.state.firstName.length > 0 && !this.state.validFirstName}
                                        helperText={!FieldValidator.validateName(this.state.firstName) ? 'Invalid First Name' : null}
                                        className={styles.input_getstarted_details}
                                        require="required"
                                        name={'firstName'}
                                        type="text"
                                        placeholder={'First Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'First Name'}
                                        required
                                        value={this.state.firstName}
                                        onChange={(evt) => this.handleChangedValue(evt, '')}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={5} >
                                    <TextField
                                        error={this.state.lastName.length > 0 && !this.state.validLastName}
                                        helperText={!FieldValidator.validateName(this.state.lastName) ? 'Invalid Last Name' : null}
                                        className={styles.input_getstarted_details}
                                        require="required"
                                        name={'lastName'}
                                        type="text"
                                        placeholder={'Last Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Last Name'}
                                        required
                                        value={this.state.lastName}
                                        onChange={(evt) => this.handleChangedValue(evt, '')}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </p>
                        <p>
                            <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                                <Grid container item xs={5} >
                                    <TextField
                                        error={this.state.cellphone.length > 0 && !this.state.validCellphone}
                                        helperText={!this.state.validCellphone ? 'Invalid cellphone number' : null}
                                        className={styles.input_getstarted_details}
                                        require="required"
                                        name={'cellphone'}
                                        type="text"
                                        placeholder={'072 - 309 - 4059'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Cellphone'}
                                        required
                                        value={this.state.cellphone}
                                        onChange={(evt) => this.handleChangedValue(evt, '')}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={5} >
                                    <TextField
                                        error={this.state.workPhone.length > 0 && !this.state.validWorkPhone}
                                        helperText={!this.state.validWorkPhone ? 'Invalid work phone number' : null}
                                        className={styles.input_getstarted_details}
                                        require="required"
                                        name={'workPhone'}
                                        type="text"
                                        placeholder={'011 - 238 - 4859'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Work Telephone'}
                                        required
                                        value={this.state.workPhone}
                                        onChange={(evt) => this.handleChangedValue(evt, '')}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </p>
                        <p>
                            <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                                <Grid container item xs={5} >
                                    <TextField
                                        error={this.state.emailAddress.length > 0 && !this.state.validEmail}
                                        helperText={this.state.emailAddress.length > 0 && !this.state.validEmail ? 'Invalid email' : null}
                                        className={styles.input_getstarted_email}
                                        require="required"
                                        name={'emailAddress'}
                                        type="text"
                                        placeholder={'name@business.co.za'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Email Address'}
                                        required
                                        value={this.state.emailAddress}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={5} >
                                    <TextField
                                        className={styles.input_getstarted_details}
                                        name={'designation'}
                                        type="text"
                                        placeholder={'Director'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Designation'}
                                        value={this.state.designation}
                                        onChange={(evt) => this.handleChangedValue(evt, '')}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                            </Grid>
                        </p>
                        <p>
                            <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                                <Grid container item xs={5} >
                                    <DropDownMenu
                                        name={'sector'}
                                        label={'Sector'}
                                        onChange={(evt) => this.handleChangedValue(evt, '')}
                                        showKeys={false}
                                        menuOptions={this.state.allSectors.sort((a, b) => a.value > b.value ? 1 : -1)}
                                        required
                                    />
                                </Grid>
                                <Grid container item xs={5} >
                                    <TextField
                                        className={styles.input_getstarted_details}
                                        name={'organisationName'}
                                        type="text"
                                        placeholder={'Organisation name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Organisation Name'}
                                        value={this.state.organisationName}
                                        onChange={(evt) => this.handleChangedValue(evt, '')}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </p>
                    </div>
                    <div style={{ maxWidth: "75%" }} className="divider" />
                </Container>
            </Container>
        )
    }

    showThankyouPage = () => {
        return (
            <Container className={styles.container_thankyou}>
                <MultiColorTitle
                    title_font="title__42px"
                    first_color="bold__orange"
                    first_text="Thank you"
                />
                <MultiColorTitle
                    title_font="title__42px"
                    first_color="blue"
                    first_text="For Expressing Interest"
                />
                <MultiColorTitle
                    title_font="text__16px"
                    first_color="blue"
                    first_text="One of our researchers will review the information you have submitted, and let"
                />
                <MultiColorTitle
                    title_font="text__16px"
                    first_color="blue"
                    first_text="you know if your company is eligible to be included in the South African R&D"
                />
                <MultiColorTitle
                    title_font="text__16px"
                    first_color="blue"
                    first_text="Survey sample frame."
                />
                <br />
                <img src={done_all} alt="done_all" />
                <br />
                <br />
                <Link to='/'>
                    <button variant="contained" className="button__orange"  >
                        <Home className={styles.home_icon} />
                        Proceed to Home
                    </button>
                </Link>

            </Container>
        )
    }

    render() {
        try {
            return (
                <div>
                    <NavigationBar />
                    <br />
                    <Container className="container_getstarted">
                        {this.state.currentPage == 1 ? this.showPageOne() : <p />}
                        {this.state.currentPage == 2 ? this.showPageTwo() : <p />}
                        {this.state.currentPage == 3 ? this.showThankyouPage() : <p />}
                        {this.state.currentPage != 3 ?
                            <div className={styles.bottom_right_buttons}>
                                <Link to='/'><img style={{ width: 160, height: 70 }} src={cancel_button} alt="cancel_button" /></Link>
                                {(this.state.currentPage == 2 && this.buttonDisabled()) ?
                                    <Link className={styles.disabledCursor}><img style={{ paddingLeft: "2%", width: 245, height: 70 }} src={continue_unavailable_button} alt="continue_unavailable_button" /></Link>
                                    : <Link to={'/expressinterest'} onClick={(evt) => this.handleContinue(evt)}><img style={{ paddingLeft: "2%", width: 245, height: 70 }} src={continue_available_button} alt="continue_available_button" /></Link>
                                }
                            </div> : <div />
                        }
                    </Container>
                    <br />
                    <br />

                    <Separator />
                    <Contact />
                    <Separator />
                    <Footer />
                </div>
            );
        } catch (ex) {
            return <Redirect to="/pagenotfound" />
            // window.open("/pagenotfound", "_self");
        }
    }

}
export default GetStarted;