import React, { Component } from "react";
import SpinnerModal from '@components/SpinnerModal';
import SupportAlert from "@components/SupportAlert";
import Questionnaire from '@components/Questionnaire';
import { makeStyles } from '@material-ui/core/styles';
import DataCaptureService from '@services/DataCaptureService';
import MessagingService from '@services/MessagingService';
import SurveyService from '@services/SurveyService';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { CustomDialog, useDialog } from 'react-st-modal';

import Chip from '@material-ui/core/Chip';
import AssignmentIcon from "@mui/icons-material/Assignment"; 

import styles from './survey.module.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 500,
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

class Survey extends Component {
    state = {
        loading: true,
        payload: null,
        questionnaire: null,
        organisationStatus: null,
        organisationContact: null,
        questionnaireTracker: null,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        function parseJwt(token) {
            var base64Payload = token.split('.')[1];
            var payload = Buffer.from(base64Payload, 'base64');
            return JSON.parse(payload.toString());
        }

        let bearer = localStorage.getItem('Bearer');
        if (!bearer){
            window.location = "/login";
        
          }
          

        let payload = parseJwt(bearer);
        this.setState({ payload: payload });

        const thisOrganisationStatus = await (async () => {
            const organisationStatus = await DataCaptureService.getOrganisationStatusBySurveyCycleIdAndOrganisationId(process.env.REACT_APP_CURRENT_SURVEY_CYCLE, payload.org_id);
            this.setState({ organisationStatus: organisationStatus });
            return organisationStatus;
        })([]);
		
		if (thisOrganisationStatus !== undefined && thisOrganisationStatus !== null) {
            console.log('thisOrganisationStatus: ' + JSON.stringify(thisOrganisationStatus));

			const organisationContact = await (async () => {
				const organisationContacts = await DataCaptureService.getAllOrganisationContactsByOrganisationStateId(this.state.organisationStatus.organisationStateId);
				let contact = organisationContacts.filter(orgContact => ("" + orgContact.email).toLowerCase() == ("" + payload.preferred_username).toLowerCase())[0];
				this.setState({ organisationContact: contact });
				return contact;
			})([]);
			
			if (organisationContact !== undefined && organisationContact !== null) {

				const res2 = await (async () => {
					const survey = await SurveyService.getQuestionnaireBySurveyCycleIdAndSectorId(process.env.REACT_APP_CURRENT_SURVEY_CYCLE, payload.sector_id);
					this.setState({ questionnaire: survey });
				})([]);

				const res3 = await (async () => {
					await axios.get(process.env.REACT_APP_SURVEY_MICROSERVICE_URL + process.env.REACT_APP_SERVICES_VERSION + "/questionnaireservice/questionnairetrackers/questionnaire/" + this.state.questionnaire.id + "/surveycycle/" + process.env.REACT_APP_CURRENT_SURVEY_CYCLE + "/organisationstate/" + this.state.organisationStatus.organisationStateId + '/contact/' + organisationContact.contactId)
						.then(response => {
							const tracker = response.data[0];
							this.setState({ questionnaireTracker: tracker });
						})
				})([]);

				if (this.state.organisationStatus.datacapture1Id === undefined || this.state.organisationStatus.datacapture1Id == null) {
					const res4 = await (async () => {
						const data = {
							"completed": false,
							"capturedById": 37,
							"questionnaireTrackerId": this.state.questionnaireTracker.id
						};

						const dataCapture = await DataCaptureService.startCapture(data);
						let organisationStatus = this.state.organisationStatus;
						organisationStatus.datacapture1Id = dataCapture[0].id;
						organisationStatus.datacapture1CapturedById = 37;

						if (this.state.questionnaireTracker.id !== undefined && this.state.questionnaireTracker.id != null && this.state.organisationContact !== undefined && this.state.organisationContact !== null && this.state.organisationContact.contactStateId !== undefined && this.state.organisationContact.contactStateId != null) {
							DataCaptureService.setQuestionnaireTrackerFilledInBy(this.state.questionnaireTracker.id, this.state.organisationContact.contactStateId);
							organisationStatus.filledInBy = this.state.organisationContact.contactStateId;
						}

						this.setState({ organisationStatus: organisationStatus });
					})([]);
				}
				
			} else {
				const confirmed = await (async () => {
					const done = await SupportAlert.displayConfirmationAlert('Contact email not found', 'Something unexpected occurred. Please contact us at innovation@hsrc.ac.za and provide a screenshot of this message.')
					return done;
				})([]);
				
				if (confirmed == true) {     
					window.location = "/respondentdashboard"
				}
			}
		
		}

        this.setState({ loading: false });

    }

    async completeSurvey(orgStatus) {
        this.setState({ loading: true });
        const dataCapture = {
            "id": orgStatus.datacapture1Id,
            "completed": true,
            "capturedById": 37,
            "questionnaireTrackerId": this.state.questionnaireTracker.id
        };

        const func = (async () => {
            const datacapture = await DataCaptureService.completeCapture(dataCapture, 'RESPONDENT');
            this.state.organisationStatus.datacapture1Completed = true;
            this.state.organisationStatus.datacapture2Completed = true;
        })([]);

        const func0 = (async () => {
            const res = await MessagingService.sendRespondentCompletedEmail(
                this.state.organisationContact.email,
                orgStatus.fieldworkerEmail,
                process.env.REACT_APP_RESPONDENT_QUESTIONNAIRE_CONFIRM_EMAIL_TITLE,
                null,
                null,
                null
            );
        })([]);

        this.state.organisationStatus.datacapture1Completed = true;
        this.state.organisationStatus.datacapture2Completed = true;

        this.setState({ loading: false });
    }

    async handleOnQuestionnaireComplete(evt, orgStatus) {

        const result = await CustomDialog(<QuestionnaireConfirmationDialog organisationContact={this.state.organisationContact.contactStateId} organisationStatus={orgStatus} />, {
            className: styles.printviewDialog,
            title: 'Review & Confirm Questionnaire Submission',
            showCloseIcon: false,
            isFocusLock: true,
            isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away

        });

        if (result == true) {
            this.completeSurvey(orgStatus);
        } else {
            evt.preventDefault();
        }
    }

    render() {
        if (this.state.loading || this.state.organisationStatus == null || this.state.organisationStatus.datacapture1Id == null) {
            return <SpinnerModal settings={true} />
        } else {
            return <div className={styles.container_datacapture}>
                       <div className={styles.breadcrumbs}>
                    <Chip variant='outlined' icon={<AssignmentIcon />}
  label="Submit Survey"
/>
                    </div>
                <div className={styles.container_row}>
                    {(this.state.organisationStatus.datacapture1Completed == true && this.state.organisationStatus.datacapture2Completed == true)
                        ? <ThankYouNotice />
                        : <Questionnaire
                            organisationStatus={this.state.organisationStatus}
                            organisationStateId={this.state.organisationStatus.organisationStateId}
                            capturedById={37}
                            filledInById={this.state.organisationContact.contactStateId}
                            captureType={'RESPONDENT'}
                            printview={false}                                                // Set to false for navigation buttons
                            onQuestionnaireComplete={(evt) => this.handleOnQuestionnaireComplete(evt, this.state.organisationStatus)}
                            dataCaptureId={this.state.organisationStatus.datacapture1Id}
                        />}
                </div>
            </div>

        }
    }
}
export default Survey;

function QuestionnaireConfirmationDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    return (
        <div>
            <div
                className={styles.confirmDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <Questionnaire
                    organisationStatus={props.organisationStatus}
                    organisationStateId={props.organisationStatus.organisationStateId}
                    capturedById={37}
                    filledInById={props.organisationContact.contactStateId}
                    captureType={'RESPONDENT'}
                    printview={true}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        dialog.close(false);
                    }}
                >
                    Cancel
                </button>
                <ReactToPrint
                    documentTitle={process.env.REACT_APP_CURRENT_SURVEY_CYCLE_FROM_YEAR + '_' + process.env.REACT_APP_CURRENT_SURVEY_CYCLE_TO_YEAR +'_RnD_Survey.pdf'}
                    content={() => componentRef}
                    removeAfterPrint={true}
                    onAfterPrint={() => {
                        dialog.close(true);
                    }}
                    trigger={() => <button
                        type="button"
                        className={styles.confirmDownloadButton}
                    >Submit & Download
                    </button>
                    }
                />
            </div>
        </div>
    );
}

function ThankYouNotice() {
    return <div className={styles.container_surveys_finished}>
        <div >
            <br></br>
            <text className="title__54px">
                <center>Thank you!</center>
                <center>You have successfully submitted</center>
                <center>your R&D Survey data.</center>
            </text>
            <br>
            </br>
            <text className="text__18px">
                <center>We have notified you by email to confirm your data has been received. </center>
                <center>If you need to make any changes to your data, please contact us using the details provided in the email.</center>
            </text>
            <br></br>
            <div style={{ marginBottom: 60, color: '#FF7F50' }} >
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSeD0P6kjxrWQ4FirIfsZmwWBjMBvIAcchyGakpozgEbGuYI0A/viewform?usp=sf_link'>
                    <p style={{ color: '#FF7F50' }}>
                        <center>Please take a moment to rate this platform.</center></p>
                </a>
            </div>
        </div>
    </div>
}