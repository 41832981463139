import React, { Component } from "react";
import { Link } from 'react-router-dom';

import Obfuscate from 'react-obfuscate';


import styles from './footer.module.css';
import twitter_icon from "@images/Footer/twitter.svg";
import facebook_icon from "@images/Footer/facebook.svg";
import instagram_icon from "@images/Footer/instagram.svg";

class Footer extends Component {


    render() {

        return (
            <div className={styles.footer_block}>
                <div className={styles.copyright_block}>
                    <p>
                        Copyright © {new Date().getFullYear()} Centre for Science, Technology and Innovation Indicators, HSRC. <br />All rights reserved.
                    </p>

                </div>

                <div className={styles.contact_block}>
                    <p className={styles.contact_mail}>
                        Have any questions? Email:{' '}
                        <Obfuscate
                            email="innovation@hsrc.ac.za" />

                    </p>
                </div>

                <div className={styles.contact_block}>
                    <p>
                        <Link to='/faq' >Frequently Asked Questions</Link>
                    </p>
                </div>


                <div className={styles.social_icons} >
                    <a href="https://www.facebook.com/HSRCCeSTII" target="_blank">
                        <img src={facebook_icon} alt="" className={styles.social_icons_img} />
                    </a>

                    <a href="https://twitter.com/HSRC_CeSTII" target="_blank">
                        <img src={twitter_icon} alt="" className={styles.social_icons_img} />
                    </a>
                </div>

            </div>
        );
    }

}
export default Footer;