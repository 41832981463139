import React, { useState, Component } from 'react';
import Grid from '@material-ui/core/Grid';
import DropDownMenu from '@components/DropDownMenu';
import MUIDataTable from 'mui-datatables';
import DataCaptureService from '@services/DataCaptureService';
import ProfileManagementService from '@services/ProfileManagementService';
import FieldworkManagementService from '@services/FieldworkManagementService';
import SessionStateManager from '@components/SessionStateManager';
import SupportAlert from '@components/SupportAlert';
import InteractionLogDialog from '@components/InteractionLogDialog';
import InteractionHistoryDialog from '@components/InteractionHistoryDialog';
import LogicalErrorsDialog from '@components/LogicalErrorsDialog';
import SurveyService from '@services/SurveyService';
import styles from '../fieldwork.module.css';
import { CustomDialog, useDialog } from 'react-st-modal';
import SpinnerModal from '@components/SpinnerModal';
import Chip from '@material-ui/core/Chip';
import Stack from '@mui/material/Stack';

class FieldworkTracking extends Component {

    state = {

        loading: false,
        organisationsLoading: false,
        selectedSectorId: null,
        surveysUsers: null,
        allSectors: [],
        allOrganisationContacts: [],
        allOrganisationStatuses: [],

        surveyCycleId: process.env.REACT_APP_CURRENT_SURVEY_CYCLE,
        organisationStateId: null,
        organisationStatus: null,
    }

    constructor() {
        super();
    }

    parseJwt(token) {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }

    async componentDidMount() {
        this.setState({ loading: true });

        SessionStateManager.clear();

        const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
        this.setState({ surveysUsers: surveysUsers });

        const sectors = await SurveyService.getAllSectors();
        sectors.map(item => this.state.allSectors.push({ key: item.id, value: item.name.trim() }));

        const organisationContacts = await DataCaptureService.getAllOrganisationContactsBySurveyCycleId(this.state.surveyCycleId);
        this.setState({ allOrganisationContacts: organisationContacts });

        this.setState({ loading: false });
    }

    async reloadOrganisations(sectorId, surveyCycleId) {
        this.setState({ organisationsLoading: true });

        try {
            sectorId = (sectorId !== undefined && sectorId != null && sectorId != '') ? sectorId : null;
            this.setState({ selectedSectorId: sectorId });

            const organisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId(sectorId, surveyCycleId);
            this.setState({ allOrganisationStatuses: organisationStatuses });

            this.setState({ organisationsLoading: false });
        } catch (ex) {
            // SupportAlert.displayAlert('Network Error', 'An unexpected network error occured while loading the organisations list. Please try again later.');
        }

        this.setState({ organisationsLoading: false });
    }

    onChangeSector = (evt) => {
        this.setState({ selectedSectorId: evt.target.value });
        this.reloadOrganisations(evt.target.value, this.state.surveyCycleId);
    }

    getSurveysUserNameById(userId) {
        let name = (this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].firstname + ' ' + this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].lastname);
        name = userId == 37 ? 'RDI Respondent' : name;
        return name;
    }

    getContactNameByOrganisationAndContactState(organisationStateId, contactStateId) {
        let orgContacts = this.state.allOrganisationContacts.filter(contact => contact.organisationStateId == organisationStateId);

        let contact = (contactStateId != null && orgContacts !== undefined && orgContacts != null && orgContacts.length > 0)
            ? orgContacts.filter(contact => contact.contactStateId == contactStateId)[0]
            : null;

        return contact != null ? (contact.title + ' ' + contact.firstname + ' ' + contact.lastname) : 'No Contact Selected';
    }

    handleLogicalErrorsSave = (evt, logicalErrors) => {
        try {
            const func = (async () => {
                const res = await FieldworkManagementService.saveLogicalErrors(logicalErrors);
            })([]);
        } catch (ex) {
            SupportAlert.displayAlert('Network', 'An unexpected network error occured while saving your data. Please try again later.');
        }
    }

    handleInteractionLogSave = (evt, respondentInteraction, surveyCritical) => {
        try {
            respondentInteraction.interactionDate = (new Date()).toISOString();
            const func = (async () => {
                const res = await FieldworkManagementService.saveRdiRespondentInteraction(respondentInteraction);
            })([]);

            const meta = (async () => {
                let metadata = {
                    "id": null,
                    "organisationStateId": respondentInteraction.organisationStateId,
                    "surveyCritical": surveyCritical
                };

                const res = await FieldworkManagementService.saveRdiMetadata(metadata);

                let organisationStatuses = this.state.allOrganisationStatuses;
                organisationStatuses.filter((organisationStatus) => organisationStatus.organisationStateId == respondentInteraction.organisationStateId)[0].surveyCritical = surveyCritical;
                this.setState({ allOrganisationStatuses: organisationStatuses });

            })([]);

        } catch (ex) {
            SupportAlert.displayAlert('Network', 'An unexpected network error occured while saving this interaction. Please try again later.');
        }
    }

    async handleShowLogInteractionDialog(evt, organisationStatus) {
        const result = await CustomDialog(
            <InteractionLogDialog
                {...this.props}
                backButton={{ text: 'Cancel' }}
                nextButton={{ text: 'Save', onClick: this.handleInteractionLogSave }}
                organisationStatus={organisationStatus}
            />,
            {
                className: styles.interactionDialog,
                title: 'Respondent Interaction',
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }

    async handleShowViewInteractionHistoryDialog(evt, organisationStatus) {
        const result = await CustomDialog(
            <InteractionHistoryDialog
                {...this.props}
                backButton={{ text: 'Close' }}
                nextButton={{ text: 'Log new', onClick: evt => this.handleShowLogInteractionDialog(evt, organisationStatus) }}
                organisationStatus={organisationStatus}
            />,
            {
                className: styles.interactionHistoryDialog,
                title: 'Respondent Interaction History',
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }


    async handleShowLogicalErrorsDialog(evt, organisationStatus) {
        const result = await CustomDialog(
            <LogicalErrorsDialog
                {...this.props}
                backButton={{ text: 'Close' }}
                nextButton={{ text: 'Save', onClick: this.handleLogicalErrorsSave }}
                organisationStatus={organisationStatus}
            />,
            {
                className: styles.logicalErrorsDialog,
                title: 'Logical Errors',
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            let columns = [
                {
                    label: "Organisation State Id",
                    name: "organisationStateId",
                    options: {
                        display: false,
                    }
                },
                { label: "Organisation Name", name: "organisationName" },
                { label: "Receipt Number", name: "receiptNumber" },
                { label: "Survey Critical", name: "surveyCritical" },
                { label: "Assigned Fieldworker", name: "fieldworker" },

                { label: "Organisation Contact", name: "organisationContact" },

                { label: "Interactions", name: "interactions" },
                { label: "Logical Errors", name: "errors" },
            ];

            let tableData = [];

            if (this.state.allOrganisationStatuses !== undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length > 0) {
                this.state.allOrganisationStatuses
                    .filter(orgStatus => orgStatus.sectorId == this.state.selectedSectorId)
                    .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                    .sort((a, b) => (((a.parentOrganisationStateName !== undefined && a.parentOrganisationStateName !== null && a.parentOrganisationStateName !== '') ? a.parentOrganisationStateName + ' / ' : '') + a.organisationStateName).toUpperCase() > (((b.parentOrganisationStateName !== undefined && b.parentOrganisationStateName !== null && b.parentOrganisationStateName !== '') ? b.parentOrganisationStateName + ' / ' : '') + b.organisationStateName).toUpperCase() ? 1 : -1)
                    .map(organisationStatus => {
                        let organisationContactList = this.state.allOrganisationContacts.filter((organisationContact) => organisationContact.organisationStateId == organisationStatus.organisationStateId);
                        organisationStatus.organisationContactList = organisationContactList;

                        let organisationContacts = [];

                        organisationContactList.map(item => {
                            organisationContacts.push({ key: item.contactStateId, value: this.getContactNameByOrganisationAndContactState(organisationStatus.organisationStateId, item.contactStateId) });
                        });

                        let thisSurveysUsers = [];

                        this.state.surveysUsers.filter((surveysUser) => surveysUser.active == true).map(item => {
                            thisSurveysUsers.push({ key: item.id, value: this.getSurveysUserNameById(item.id) });
                        });

                        let selectedResearcher = (organisationStatus.fieldworkerId !== undefined && organisationStatus.fieldworkerId != null) ? this.getSurveysUserNameById(organisationStatus.fieldworkerId) : 'Not assigned';
                        organisationStatus.selectedResearcher = selectedResearcher;

                        let org_name = ((organisationStatus.parentOrganisationStateName !== undefined && organisationStatus.parentOrganisationStateName !== null && organisationStatus.parentOrganisationStateName !== '') ? organisationStatus.parentOrganisationStateName + ' / ' : '') + organisationStatus.organisationStateName;

                        let row = {
                            organisationStateId: organisationStatus.organisationStateId,
                            organisationName: org_name,
                            receiptNumber: ((organisationStatus.receiptNumber != null) ? organisationStatus.receiptNumber : 'Not received'),
                            fieldworker: (organisationStatus.fieldworkerId !== undefined && organisationStatus.fieldworkerId != null) ? this.getSurveysUserNameById(organisationStatus.fieldworkerId) : 'Not assigned',
                            surveyCritical: (organisationStatus.surveyCritical !== undefined && organisationStatus.surveyCritical !== null && organisationStatus.surveyCritical === true) ? 'Yes' : 'No',
                            organisationContact: this.getContactNameByOrganisationAndContactState(organisationStatus.organisationStateId, organisationStatus.filledInBy),
                            interactions: <div>
                                <Stack direction="column" spacing={0.5}>
                                    <Chip variant='outlined' label='View History'
                                        onClick={evt => this.handleShowViewInteractionHistoryDialog(evt, organisationStatus)} />
                                    <Chip variant='outlined' label='Log Interaction'
                                        onClick={evt => this.handleShowLogInteractionDialog(evt, organisationStatus)} />
                                </Stack>

                            </div>,
                            errors:
                                <Chip variant='outlined' label='Logical Errors'
                                    onClick={evt => this.handleShowLogicalErrorsDialog(evt, organisationStatus)} />
                            ,

                        }
                        tableData.push(row);
                    });
            }

            let options = {
                padding: 'none',
                size: 'small',
                rowsPerPage: [10],
                rowsPerPageOptions: [10, 20, 50, 100],
                jumpToPage: true,
                selectableRowsHideCheckboxes: true,
                // onRowClick: this.onInteractionClicked,
            };

            return (
                <div className={styles.container_fieldwork} >
                    <div className={styles.featuredDropDown}>
                        <span className={styles.featuredTitleHeader}><h2>{'Fieldwork Tracking'}</h2></span>
                        <hr />
                        <Grid container item xs={15}>
                            <DropDownMenu
                                name={'sector'}
                                label={'Sector'}
                                onChange={(evt) => this.onChangeSector(evt)}
                                showKeys={false}
                                menuOptions={this.state.allSectors.sort((a, b) => a.value > b.value ? 1 : -1)}
                                placeholder='Select Sector'
                                required
                            />
                        </Grid>
                        <br />
                    </div>
                    <div className={styles.featuredItem}>

                        <Grid container item xs={15} >
                            {(this.state.selectedSectorId != null && this.state.organisationsLoading == false)
                                ?
                                <div className={styles.flex_row_container}>
                                    <MUIDataTable
                                        title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{'Organisations'.toUpperCase()}</h1></div>}
                                        data={tableData}
                                        columns={columns}
                                        options={options}
                                    />
                                </div>
                                : (
                                    this.state.organisationsLoading == true
                                        ? <SpinnerModal settings={this.state.organisationsLoading} />
                                        : <div />
                                )
                            }
                        </Grid>
                    </div>
                </div>
            )
        }
    }
}
export default FieldworkTracking;
