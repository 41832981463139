import React, { Component, useState } from "react";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import NumberTools from "@components/NumberTools";
import DataCaptureService from "@services/DataCaptureService";
import DropDownMenu from "@components/DropDownMenu";
import styles from './updatedialog.module.css';

class UpdateDialog extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
        sourcesOfData: [],
        newValue: "",
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        const sourcesOfData = await DataCaptureService.getLookups();

        let filteredSourcesOfData = [];
        sourcesOfData.filter((lookup) => (lookup.type == 'IMPUTATION_TYPE' || lookup.type == 'DATA_SOURCE')).map(item => {
            filteredSourcesOfData.push({ key: item.id, value: item.name });
        });

        this.setState({ sourcesOfData: filteredSourcesOfData });

        this.setState({ newValue: this.props.currentAnswer });

        this.setState({ loading: false });
    }

    render() {
        return <React.Fragment>
            <div className={styles.featuredUpdateDialogItem}>
                <div className={styles.featuredItem} >
                    Current Value : <br /><br /> <div className={styles.currentAnswerOverlay}>
                        <DisplayCurrentAnswer
                            {...this.props}
                            value={this.props.currentAnswer}
                            currentAnswerView={true}
                            readOnly
                        />
                    </div>
                </div>
                <div className={styles.featuredItem} >
                    Updated Value : <br /><br /> <InputSelector
                        {...this.props}
                        value={this.state.newValue}
                        onChange={(evt) => {
                            this.setState({ newValue: evt.target.value });
                            this.props.onChangeNewValue(evt);
                        }}
                    />
                </div>
                <div className={styles.featuredItem}>
                    * Source of data : <br /><br /> <DropDownMenu
                        className={styles.updateDialogInputs}
                        name={'sourceOfData'}
                        onChange={(evt) => this.props.onChangeSourceOfData(evt, this.state.newValue)}
                        showKeys={false}
                        menuOptions={this.state.sourcesOfData.sort((a, b) => a.value > b.value ? 1 : -1)}
                        required
                    />
                </div>
                <div className={styles.featuredItem}>
                    * Reason : <br /><br /> <TextField
                        className={styles.updateDialogInputs}
                        key={'update_reason_textfield'}
                        id="filled-multiline-static"
                        multiline
                        rows={4}
                        require="required"
                        type="text"
                        onChange={(evt) => {
                            this.props.onChangeReason(evt, this.state.newValue)
                        }}
                        size="small"
                        variant="outlined"
                        autoFocus
                    />
                </div>
            </div>
        </React.Fragment>
    }

}
export default UpdateDialog;

function DisplayCurrentAnswer(props) {

    if (props.booleanType !== undefined && props.booleanType != null && props.booleanType == 'radio') {
        return <BooleanRadioInput {...props} />
    }

    if (props.booleanType !== undefined && props.booleanType != null && props.booleanType == 'checkbox') {
        return <BooleanCheckboxInput {...props} />
    }

    if (props.numericInput !== undefined && props.numericInput != null && props.numericInput == true) {
        return <NumericInput {...props} />
    }

    return <TextInput {...props} />
}

function InputSelector(props) {

    if (props.dateInputType !== undefined && props.dateInputType != null) {
        return <DateInput {...props} />
    }

    if (props.menuOptions !== undefined && props.menuOptions != null) {
        return <DropDownInput {...props} />
    }

    if (props.booleanType !== undefined && props.booleanType != null && props.booleanType == 'radio') {
        return <BooleanRadioInput {...props} />
    }

    if (props.booleanType !== undefined && props.booleanType != null && props.booleanType == 'checkbox') {
        return <BooleanCheckboxInput {...props} />
    }

    if (props.numericInput !== undefined && props.numericInput != null && props.numericInput == true) {
        return <NumericInput {...props} />
    }

    return <TextInput {...props} />
}

function DateInput(props) {
    const [selectedDate, setSelectedDate] = useState((props.currentAnswer != null ? props.currentAnswer : '').split('/').reverse().join('-'));

    return <FormControl component="fieldset">
        {props.questionState.questionFactStates.sort((a, b) => (a.sequenceNumberY > b.sequenceNumberY) ? 1 : -1).filter((qfs) => qfs.questionFactId == props.dateQuestionFactId).map((questionFactState) => {
            return <Grid container item xs={12} >
                <TextField
                    id={(props.questionState.question.id) + '_' + (questionFactState.questionFactId)}
                    label={questionFactState.columnDimensionStates.length > 0 ? questionFactState.columnDimensionStates[0].dimensionAlias : ''}
                    format={props.questionState.questionFactStates.length > 1 ? "dd/MM/yyyy" : "MM/YYYY"}
                    inputFormat={props.questionState.questionFactStates.length > 1 ? "dd/MM/yyyy" : "MM/YYYY"}
                    defaultValue={props.questionState.questionFactStates.length > 1 ? "01/04/2017" : "01/2023"}
                    value={selectedDate}
                    name={(props.questionState.question.id) + ''}
                    type={props.dateInputType}
                    onChange={(evt) => {
                        props.onChange(evt, evt.target.value);
                        props.onChangeNewValue(evt, evt.target.value);
                        setSelectedDate(evt.target.value);
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    size="small"
                    variant="outlined"
                />
            </Grid>
        })}
    </FormControl>
}
function DropDownInput(props) {
    return <DropDownMenu
        className={styles.updateDialogInputs}
        name={'dropdownmenu2'}
        value={props.value}
        onChange={(evt) => {
            props.onChange(evt, evt.target.value);
            props.onChangeNewValue(evt, evt.target.value);
        }}
        showKeys={true}
        menuOptions={props.menuOptions}
        required
    />
}

function BooleanCheckboxInput(props) {
    const [selected, setSelected] = useState((props.currentAnswer !== undefined && props.currentAnswer != null && 'true' == props.currentAnswer) ? true : false);

    return <FormControl component="fieldset">
        <div>
            <input
                type="checkbox"
                className="radio"
                id={"checkbox_yes"}
                value={!selected}
                checked={selected}
                onChange={(evt) => {
                    props.onChange(evt, !selected);
                    props.onChangeNewValue(evt, !selected)
                    setSelected(!selected);
                }}
            />
            {(props.checkboxLabel !== undefined && props.checkboxLabel !== null) ? <label for={"checkbox_yes"}>{props.checkboxLabel} &nbsp;&nbsp;&nbsp;</label> : <div />}
        </div>
    </FormControl>
}

function BooleanRadioInput(props) {
    const [selected, setSelected] = useState((props.currentAnswer !== undefined && props.currentAnswer != null && 'true' == props.currentAnswer) ? true : false);

    return <FormControl component="fieldset">
        <div class="radio-group">
            <input
                class="radio"
                type="checkbox"
                id={"checkbox_yes"}
                checked={selected}
                onChange={(evt) => {
                    setSelected(true);
                    props.onChange(evt, true);
                    props.onChangeNewValue(evt, true);
                }}
                value={true}
            />
            <label for={"checkbox_yes"}>Yes &nbsp;&nbsp;&nbsp;</label>
            <input
                class="radio"
                type="checkbox"
                id={"checkbox_no"}
                checked={!selected}
                onChange={(evt) => {
                    setSelected(false);
                    props.onChange(evt, false);
                    props.onChangeNewValue(evt, false)
                }}
                value={false}
            /> <label for={"checkbox_no"}>No </label>
        </div>
    </FormControl>
}

function TextInput(props) {
    return <TextField
        className={styles.updateDialogInputs}
        key={'update_value_textfield'}
        id="standard-end-adornment"
        InputProps={{
            startAdornment: <InputAdornment position={'start'}>{props.startEndornment}</InputAdornment>,
            endAdornment: <InputAdornment position={'end'}>{props.endEndornment}</InputAdornment>,
        }}
        require="required"
        type="text"
        value={props.value}
        onChange={(evt) => {
            props.onChange(evt, evt.target.value);
            props.onChangeNewValue(evt, evt.target.value);
        }}
        id="outlined-basic"
        size="small"
        variant="outlined"
    />
}

function NumericInput(props) {
    let isCurrency = (props.startEndornment !== undefined && props.startEndornment !== null && props.startEndornment == 'R');

    return <TextField
        className={styles.updateDialogInputs}
        key={'update_reason_textfield'}
        id="standard-end-adornment"
        InputProps={{
            startAdornment: <InputAdornment position={'start'}>{props.startEndornment}</InputAdornment>,
            endAdornment: <InputAdornment position={'end'}>{props.endEndornment}</InputAdornment>,
        }}
        require="required"
        type="text"
        value={!isCurrency ? props.value : NumberTools.transformToCurrency(props.value)}
        onChange={(evt) => {
            if (isCurrency) {
                evt.target.value = evt.target.value.toString().replace(/\s/g, '');
            }
            props.onChange(evt, evt.target.value);
            props.onChangeNewValue(evt, evt.target.value);
        }}
        onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key)) { event.preventDefault(); } }}
        id="outlined-basic"
        size="small"
        variant="outlined"
    />
}
