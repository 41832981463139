import React, { Component, useState } from "react";
import Grid from '@material-ui/core/Grid';
import DataCaptureService from "@services/DataCaptureService";
import ProfileManagementService from "@services/ProfileManagementService";
import SpinnerModal from "@components/SpinnerModal";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from './editrespondentdialog.module.css';
import SupportAlert from '@components/SupportAlert';
import TextField from '@material-ui/core/TextField';
import FieldValidator from '@components/FieldValidator';
import SurveyService from '@services/SurveyService';
import Alert from '@mui/material/Alert';

class EditRespondentDialog extends Component {
    state = {
        loading: false,

        firstName: '',
        lastName: '',
        designation: '',
        cellphone: '',
        workphone: '',

        validEmail: false,
        validFirstName: false,
        validLastName: false,
        validCellphone: true,
        validWorkPhone: true,

        allOrganisationContacts: [],

        allSectorsDropdown: [],
        allSurveyCyclesDropdown: [],

        selectedSurveyCycle: process.env.REACT_APP_CURRENT_SURVEY_CYCLE,

        myOrganisation: null,
		myOrganisationState: null,
        myRealmUser: null,
        myOrganisationContact: null,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ myRealmUser: this.props.realmUser });
        this.setState({ validEmail: FieldValidator.validateEmail(this.props.realmUser?.email) });
        this.setState({ firstName: this.props.realmUser?.firstName });
        this.setState({ validFirstName: FieldValidator.validateName(this.props.realmUser?.firstName) });
        this.setState({ lastName: this.props.realmUser?.lastName });
        this.setState({ validLastName: FieldValidator.validateName(this.props.realmUser?.lastName) });
        this.setState({ cellphone: this.props.realmUser?.attributes.cellphone[0] });
        this.setState({ workphone: this.props.realmUser?.attributes.workphone[0] });
        this.setState({ designation: this.props.realmUser?.attributes.designation[0] });

        this.setState({ allSurveyCyclesDropdown: this.props.allSurveyCyclesDropdown });
        
        this.setState({ allSectorsDropdown: this.props.allSectorsDropdown });

        const organisationContacts = await DataCaptureService.getAllOrganisationContactsByOrganisationIdAndSurveyCycleId(this.props.realmUser.attributes.org_id[0], process.env.REACT_APP_CURRENT_SURVEY_CYCLE);
        this.setState({ allOrganisationContacts: organisationContacts });

        const myOrganisationContact = organisationContacts?.filter(contact => contact.email.toLowerCase() === this.state.myRealmUser?.email.toLowerCase())[0];
        this.setState({ myOrganisationContact: myOrganisationContact });

        const organisationStatus = await DataCaptureService.getOrganisationStatusBySurveyCycleIdAndOrganisationId(process.env.REACT_APP_CURRENT_SURVEY_CYCLE, this.props.realmUser.attributes.org_id[0]);
        this.setState({ myOrganisationState: organisationStatus });

        const organisation = await ProfileManagementService.getOrganisationById(this.props.realmUser.attributes.org_id[0]);
        this.setState({ myOrganisation: organisation });

        this.setState({ loading: false });
    }

    clearDetails = () => {
        this.setState({ validEmail: false });
        this.setState({ firstName: '' });
        this.setState({ validFirstName: false });
        this.setState({ lastName: '' });
        this.setState({ validLastName: false });
        this.setState({ designation: '' });
    }

    handleChangedValue = (evt) => {
        switch (evt.target.name) {
            case 'firstName': {
                this.setState({ validFirstName: FieldValidator.validateName(evt.target.value) });
                this.setState({ firstName: evt.target.value });
                break;
            }
            case 'lastName': {
                this.setState({ validLastName: FieldValidator.validateName(evt.target.value) });
                this.setState({ lastName: evt.target.value });
                break;
            }
            case 'cellphone': {
                this.setState({ validCellphone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ cellphone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'workphone': {
                this.setState({ validWorkPhone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ workphone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'designation': {
                this.setState({ designation: evt.target.value });
                break;
            }
            default: {
                break;
            }
        }
    }

    getData() {

        let data = {
            userid: this.state.myRealmUser?.userid,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: null,
            enabled: this.state.myRealmUser?.enabled,
            email: this.state.myRealmUser?.email,
            attributes: {
                group_id: ["RESPONDENT"],
                source_of_data: [22],
                user_id: [parseInt(this.state.myOrganisationContact?.contactStateId)],
                sector_id: [parseInt(this.props.realmUser.attributes.sector_id[0])],
                survey_cycle_id: [parseInt(this.state.selectedSurveyCycle)],
                org_id: [parseInt(this.state.myRealmUser.attributes.org_id[0])],
                org_state_id: [this.state.myOrganisationContact?.organisationStateId],
                cellphone: [this.state.cellphone],
                workphone: [this.state.workphone],
                designation: [this.state.designation],
            },
            realmRoles: ['dsp_respondent'],
            clientRoles: {
                role: ['respondents']
            },

        };

        return data;

    }

    dialogBody() {
        return <div className={styles.flex_row_container}>
            {(this.props.actionDelete !== undefined && this.props.actionDelete !== null && this.props.actionDelete === true)
                ? <div className={styles.featuredItem}>
                    <Alert variant="outlined" severity="warning">{'You are about to deregister the following respondent. If this is correct, please click Confirm below.'}</Alert>
                </div>
                : null
            }
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"Organisation Details"}</text>
                </h1>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        disabled
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'surveyCycle'}
                                        type="text"
                                        placeholder={''}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Survey Cycle'}
                                        required
                                        value={this.state.allSurveyCyclesDropdown?.filter(surveyCycle => surveyCycle.key == this.state.selectedSurveyCycle)[0]?.value}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                    />
                                </Grid>
                                <Grid container item xs={6} >
                                    <TextField
                                        disabled
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'sector'}
                                        type="text"
                                        placeholder={''}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Sector'}
                                        required
                                        value={this.state.allSectorsDropdown?.filter(sector => sector.key == this.props.realmUser.attributes.sector_id[0])[0]?.value}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        disabled
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'organisation'}
                                        type="text"
                                        placeholder={''}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Organisation'}
                                        required
                                        value={this.state.myOrganisationState?.organisationStateName}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                    />
                                </Grid>
                                <Grid container item xs={6} >
                                    <TextField
                                        helperText={(this.state.myOrganisationContact === undefined || this.state.myOrganisationContact === null) ? 'Please verify this contact details on RDSMS' : null}
                                        error={(this.state.myOrganisationContact === undefined || this.state.myOrganisationContact === null)}
                                        disabled
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'contactState'}
                                        type="text"
                                        placeholder={'Contact Person'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'RDSMS Organisation Contact'}
                                        required
                                        value={(this.state.myOrganisationContact !== undefined && this.state.myOrganisationContact !== null) ? this.state.myOrganisationContact?.firstname + ' ' + this.state.myOrganisationContact?.lastname : ''}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
            </div>
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"Primary Respondent Details"}</text>
                </h1>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        disabled={this.props.actionDelete === true}
                                        error={this.state.firstName > 0 && !this.state.validFirstName}
                                        helperText={!FieldValidator.validateName(this.state.firstName) ? 'Invalid First Name' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'firstName'}
                                        type="text"
                                        placeholder={'First Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'First Name'}
                                        required
                                        value={this.state.firstName}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >
                                    <TextField
                                        disabled={this.props.actionDelete === true}
                                        error={this.state.lastName > 0 && !this.state.validLastName}
                                        helperText={!FieldValidator.validateName(this.state.lastName) ? 'Invalid Last Name' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'lastName'}
                                        type="text"
                                        placeholder={'Last Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Last Name'}
                                        required
                                        value={this.state.lastName}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        disabled={this.props.actionDelete === true}
                                        error={this.state.cellphone.length > 0 && !this.state.validCellphone}
                                        helperText={!this.state.validCellphone ? 'Invalid cellphone number' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'cellphone'}
                                        type="text"
                                        placeholder={'072 - 309 - 4059'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Cellphone'}
                                        required={false}
                                        value={this.state.cellphone}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >
                                    <TextField
                                        disabled={this.props.actionDelete === true}
                                        error={this.state.workphone.length > 0 && !this.state.validWorkPhone}
                                        helperText={!this.state.validWorkPhone ? 'Invalid work phone number' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'workphone'}
                                        type="text"
                                        placeholder={'011 - 238 - 4859'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Work Telephone'}
                                        required={false}
                                        value={this.state.workphone}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        disabled={this.props.actionDelete === true}
                                        className={styles.dialogInputs}
                                        name={'designation'}
                                        type="text"
                                        placeholder={'Designation'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Designation'}
                                        value={this.state.designation}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>


            </div>
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"Account Log in Details"}</text>
                </h1>
                <p>
                    <TextField
                        disabled
                        error={this.state.myRealmUser?.email.length >= 0 && !this.state.validEmail}
                        helperText={this.state.myRealmUser?.email.length >= 0 && !this.state.validEmail ? 'Invalid email' : null}
                        className={styles.dialogInputs}
                        require="required"
                        name={'emailAddress'}
                        type="text"
                        placeholder={'name@business.co.za'}
                        id="outlined-basic"
                        size="small"
                        label={'Email Address'}
                        required
                        value={this.state.myRealmUser?.email}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </p>
            </div>
        </div>
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} label={'Dialog loading ...'} />
        } else {
            return <DialogWrapper
                {...this.props}
                onClick={(evt) => this.props.nextButton.onClick(evt, this.getData())}
                validDetails={(this.state.validFirstName && this.state.validLastName && !(this.state.myOrganisationContact === undefined || this.state.myOrganisationContact === null)) || (this.props.actionDelete === true)}
                content={
                    <React.Fragment>
                        {this.dialogBody()}
                    </React.Fragment>
                }
            />
        }
    }

}
export default EditRespondentDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            if (!props.validDetails) {
                                SupportAlert.displayAlert('Updating user details failed', 'The RDSMS organisation contact, first name and last name are required fields. Please review supplied information carefully.');
                            } else {
                                props.onClick(evt);
                                dialog.close(true);
                            }
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}

