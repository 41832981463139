import React, { Component } from "react";
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import NumberTools from '@components/NumberTools';
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class BreakdownBySourcesOfFundsQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    async handleOnChange(evt) {
        let key = evt.target.attributes.questionFactId.value + '_' + evt.target.attributes.ordinalEntry.value;
        let value = evt.target.value;

        if (!NumberTools.isValidDecimalNumber(value)) {
            evt.preventDefault();
        } else {
            if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                const result = await CustomDialog(
                    <UpdateAuditDialog
                        currentAnswer={SessionStateManager.getSessionValue(key)}
                        numericInput={true}
                        startEndornment={'R'}
                    />,
                    {
                        className: styles.updateAuditDialog,
                        title: 'Update Submitted Value',
                        showCloseIcon: false,
                        isFocusLock: true,
                        isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                    }
                );

                if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                    SessionStateManager.setSessionValue(key, result.newValue.toString().replace(/\s/g, ''), this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                    this.setState({ sessionStateChanged: true });
                } else {
                    evt.preventDefault();
                }
            } else {
                SessionStateManager.setSessionValue(key, value.toString().replace(/\s/g, ''), this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
                this.setState({ sessionStateChanged: true });
            }

            if (this.props.onChange !== undefined && this.props.onChange != null) {
                this.props.onChange(evt);
            }
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            let maxRowDimensions = this.props.questionState.questionFactStates
                .filter((questionFactState) => !questionFactState.rowDimensionStates[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                .sort((a, b) => (a.rowDimensionStates.length < b.rowDimensionStates.length) ? 1 : -1)[0].rowDimensionStates.length;

            return <div className={styles.breakdownSOFQuestionContainer}>
                <table className='table' >
                    <thead>
                        <tr>
                            <th width='50%' colSpan={2}>External Sources</th>
                            <th width='50%'>R'000 (Excl. VAT)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.questionState.questionFactStates
                            .filter((questionFactState) => !questionFactState.rowDimensionStates[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                            .sort((a, b) => (a.sequenceNumberX > b.sequenceNumberX) ? 1 : -1)
                            .map((questionFactState) =>
                                <tr>
                                    {questionFactState.rowDimensionStates
                                        .sort((a, b) => (a.sequenceNumber > b.sequenceNumber) ? 1 : -1)
                                        .map((rowDimensionState) =>
                                            <td colSpan={questionFactState.rowDimensionStates.length === maxRowDimensions ? 1 : 2}>
                                                {rowDimensionState.dimensionAlias}
                                            </td>
                                        )
                                    }
                                    <td
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0')
                                            ? styles.tableInputBoxUpdated
                                            : styles.tableInputBox}
                                    >
                                        <div className={styles.flexCenterFitContent}>
                                            <span className="inputRandSign">R</span>
                                            <input
                                                className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0')
                                                    ? styles.tableInputBoxUpdated
                                                    : styles.tableInputBox}
                                                type="text"
                                                value={NumberTools.transformToCurrency(SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0'))}
                                                onClick={(evt) => {
                                                    if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                                                        this.handleOnChange(evt);
                                                    }
                                                }}
                                                onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key) || (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE')) { event.preventDefault(); } }}
                                                onChange={(evt) => this.handleOnChange(evt)}
                                                onBlur={this.handleOnBlur}
                                                questionId={this.props.questionState.question.id}
                                                ordinalEntry={0}
                                                questionFactId={questionFactState.questionFactId}
                                                placeholder={0}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        {this.props.questionState.questionFactStates
                            .filter((questionFactState) => questionFactState.rowDimensionStates[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                            .map((questionFactState) => {
                                let validationPassed = ValidationsManager.validateFact(questionFactState.questionFactId);
                                return <tr>
                                    <th colSpan="2">{questionFactState.rowDimensionStates[0].dimensionAlias}</th>
                                    <td
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0')
                                            ? styles.cellHintUpdated
                                            : styles.cellHint}
                                    >
                                        <div className={styles.flexCenterFitContent}>

                                            {(validationPassed == true) ? '' : <span className={styles.tooltiptext}>{'Total must sum up to Total R&D Expenditure(E)'}</span>}
                                            <span
                                                className="inputRandSign"
                                                className={(validationPassed === true) ? styles.normalText : styles.redText}
                                            >R</span>
                                            <input
                                                className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0')
                                                    ? ((validationPassed === true) ? styles.normalText : styles.redTextUpdated)
                                                    : ((validationPassed === true) ? styles.normalText : styles.redText)}
                                                id="grand_total_expenditure"
                                                type="text"
                                                value={NumberTools.transformToCurrency(SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0'))}
                                                questionId={this.props.questionState.question.id}
                                                ordinalEntry={0}
                                                questionFactId={questionFactState.questionFactId}
                                                placeholder={0}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        }
    }
}
export default BreakdownBySourcesOfFundsQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    {...props}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if (
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                            result.confirmed = true;
                            dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}


