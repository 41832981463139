import React, { Component, P } from 'react';
import { Link, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Lightbulb, Notes } from "@mui/icons-material";

import Separator from "@components/Separator";


import Topbar from '@components/Topbar';

import Logout from '@pages/Logout';


import Survey from '@pages/Survey';
// import Dashboard from './pages/Dashboard';
// import Research from './pages/Research';
// import RespondentProfile from './pages/Profile';
// import Footer from "./components/Footer";
// import Sidebar from './components/Sidebar';
// import PviousSurveys from './pages/PviousSurvey'

import styles from './surveyslanding.module.css';


class SurveyLanding extends Component {
  render() {
    let sector = localStorage.getItem('sectorName')

    const submitFunctionEnabled = process.env.REACT_APP_RESPONDENT_SUBMIT_FUNCTION === 'ENABLED';

    return (
      <div className={styles.container_surveys_landing}>



        <div className={styles.sections_definitions}>
          <h1 className='title__24px__orange'>The following definitions are important in the completion of the survey questionnaire:</h1>
          <h1 className='title__24px'>What is R&D?</h1>
          <Separator />
          <div className={styles.sections_definitions_left}>
            <h2 className='title__24px__orange'>
              Definition
            </h2>
            <p>
              This survey follows the approach of the Organisation for Economic Co-operation and Development (OECD),
              which defines research and experimental development (R&D) as:
              <br /><br />
              <strong>
                <h3>
                  “Creative and systematic work undertaken in order to increase the stock of knowledge – including knowledge of humankind, culture and society –
                  and to devise new applications of available knowledge.” </h3>
              </strong>

              <br /><br />

              The basic criterion for distinguishing R&D from related activities is the presence in R&D of an applicable element of novelty and the
              resolution of scientific and/or technological uncertainty, i.e.
              when the solution to a problem is not readily apparent to someone familiar with the commonly used knowledge and
              techniques in the area concerned.
              <br /><br />

              The R&D activity must be: <br />
              • Novel<br />
              • Creative<br />
              • Uncertain<br />
              • Systematic<br />
              • Transferable and/or reproducible.<br /><br />

              All five criteria are to be met, at least in principle, every time an R&D activity is
              undertaken whether on a continuous or occasional basis.<br /><br />


              <strong> <h3>Examples </h3></strong><br />
              • Investigating electrical conduction in crystals is basic research;
              application of crystallography to the properties of alloys is applied research.<br />
              • New chip designs involve development.<br />
              • Investigating the limiting factors in chip element
              placement lies at the border between basic and applied
              research, and increasingly involves nanotechnology.<br />
              • Much services R&D involves software development where
              the completion of the project is dependent on a scientific or technological advance and
              the aim of the project is the systematic resolution of a scientific or technological uncertainty.<br /><br />

              <strong><h3>Scope of survey</h3></strong><br />
              • The survey requests data on <b>intramural </b> R&D performed<br />
              by your organisation on the national territory of South Africa.<br />
              • Intramural (or in-house) R&D expenditures are all current<br />
              expenditures (including labour and other costs) plus gross fixed capital expenditures (such as for land, buildings, machinery and
              equipment) for R&D performed within a reporting unit during a specific reference period, whatever the source of funds.
              A reporting unit is a unit that supplies the data for a given survey instance.<br />

              • Part five asks some questions on extramural R&D. Extramural (or outsourced) R&D are the amounts of money spent on
              R&D that is performed outside a statistical unit.<br />
            </p>

            <p>
              <strong><h3>R&D includes – but is not limited to:</h3></strong> <br />
              • The provision of professional, technical, administrative or clerical support and/or assistance to personnel directly engaged in R&D <br />
              • Management of personnel who are either directly engaged in R&D or are providing professional, technical or clerical support to those
              performing R&D<br />
              • Software development where the aim of the project is the systematic resolution of a scientific or technological uncertainty<br />
              • Research work in the biological, physical and social sciences, and the humanities<br />
              • Social science research including economic, cultural, educational, psychological and sociological research<br />
              • Research work in engineering and the medical sciences<br />
              • R&D projects performed for other parties<br />
              • Feedback R&D directed at solving problems occurring
              beyond the original R&D phase, for example technical problems arising during initial production runs.<br /><br />

              <strong><h3>R&D excludes:</h3></strong><br />
              The following routine activities are excluded, except where they are an essential part of in-house R&D activity:<br />
              • Scientific and technical information services<br />
              • Engineering and technical services<br />
              • General purpose or routine data collection<br />
              • Standardisation and routine testing<br />
              • Feasibility studies (except into R&D projects)<br />
              • Specialised routine medical care, for example routine
              pathology services<br />
              • The commercial, legal and administrative aspects of
              patenting, copyrighting or licensing activities<br />
              • Routine computer programing, IT systems work or
              software maintenance where there are no technological
              uncertainties to be resolved<br />
              • Market <br />
              • Feasibility studies and pilot projects.<br />
            </p>

            {
              sector.toUpperCase().includes('EDUCATION') ?
                <p>
                  "The Classification of Borderline Institutions
                  Research institutes (such as specialised healthcare clinics or
                  “attached” research institutions) that are not directly concerned
                  with third level teaching, but whose activities, R&D or
                  otherwise, are all the same closely associated with the Higher
                  Education sector should be carefully considered: <br></br>
                  •    Entities initiated by a Higher Education Institution (HEI)
                  but subsequently becoming a not-for-profit or business
                  entity should be classified as such and surveyed by Not-for
                  Profit or Business sectors, even if there are close links with
                  a Higher Education Institution <br></br>
                  •    Staff and R&D expenditure should be reported where it was
                  incurred "<br></br>


                  "•    Staff members on the payroll of the HEI Institution (e.g.
                  department heads) should be reported by the HEI concerned <br></br>
                  •    Staff that appears on the payroll of the “borderline”
                  institution’ should be reported by the institution concerned
                  and not the HEI. <br></br>
                  •    The same applies to equipment and running costs. <br></br>
                  •    It would be appreciated if we were informed of all such
                  institutions to ensure that they are surveyed by the
                  appropriate sectors and to minimise double counting." <br></br>








                  "Provincial/Academic Hospitals
                  Higher Education Institutions are requested to report on all
                  academic and technical staff performing R&D, with joint
                  appointments between provincial/academic hospitals and the
                  HEI.  This includes headcount, FTE’s, labour costs, equipment
                  and running costs.<br></br>

                  It is understood that some of these costs may not be reflected in
                  the HEI’s HEMIS data or financial statements, but we request
                  that a best estimate be included where necessary. " <br></br>


                </p>
                : <p></p>
            }
          </div>

          <div>
            <Link to='/mandate'>
              <button variant="contained" className="button"> Back </button>
            </Link>
            <Link to={submitFunctionEnabled ? '/survey' : '/overview'}>
              <button variant="contained" className="button__orange"> Continue </button>
            </Link>
          </div>
        </div>




      </div>
    )
  }
}

export default SurveyLanding; 