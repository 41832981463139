import React, { useState, Component } from 'react';
import MUIDataTable from 'mui-datatables';
import DataCaptureService from '@services/DataCaptureService';
import ProfileManagementService from '@services/ProfileManagementService';
import SurveyService from '@services/SurveyService';
import CSVGenerator from '@components/CSVGenerator';
import SupportAlert from '@components/SupportAlert';
import AddRespondentDialog from '../../../components/UserManagement/AddRespondentDialog';
import EditRespondentDialog from '../../../components/UserManagement/EditRespondentDialog';
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../users.module.css';
import { PersonAdd } from "@material-ui/icons";
import SpinnerModal from '@components/SpinnerModal';
import Chip from '@material-ui/core/Chip';
import Stack from '@mui/material/Stack';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import LinearProgress from '@mui/material/LinearProgress';

class RespondentUsers extends Component {
    state = {
        loading: false,
        contactsLoading: false,
        realmUsers: [],
        allOrganisationStatuses: [],

        //these state variables are only loaded to be passed into dialogs for faster user experience
        allSurveyCyclesDropdown: [],
        allSectorsDropdown: [],
        lookUps: [],
    }

    constructor() {
        super();

    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.loadContactTypeLookUps();

        this.reloadRealmUsers();

        this.reloadOrganisationStatusses();

        this.reloadSurveyCycleDropdown();

        this.reloadSectorDropdown();

        this.setState({ loading: false });
    }

    async loadContactTypeLookUps() {
        const sourcesOfData = await DataCaptureService.getLookups();

        let filteredLookUps = [];
        sourcesOfData.filter((lookup) => (lookup.type == 'CONTACT_TYPE')).map(item => {
            filteredLookUps.push({ key: item.id, value: item.name });
        });
        
        this.setState({ lookUps: filteredLookUps });

    }

    async reloadSurveyCycleDropdown() {
        try {
            const surveyCycles = await SurveyService.getAllSurveyCycles();
            surveyCycles.map(item => this.state.allSurveyCyclesDropdown.push({ key: item.id, value: item.name.trim() }));
        } catch (ex) {
            console.log('Network error loading survey cycles');
        }
    }

    async reloadSectorDropdown() {
        try {
            const sectors = await SurveyService.getAllSectors();
            sectors.map(item => this.state.allSectorsDropdown.push({ key: item.id, value: item.name.trim() }));
        } catch (ex) {
            console.log('Network error loading sectors');
        }
    }

    async reloadOrganisationStatusses() {
        try {
            const organisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId_including_non_uom(null, process.env.REACT_APP_CURRENT_SURVEY_CYCLE);
            this.setState({ allOrganisationStatuses: organisationStatuses });
        } catch (ex) {
            console.log('Network error loading respondent users and organisations');
        }
    }

    async reloadRealmUsers() {
        try {
            const realmUsers = await ProfileManagementService.getAllRealmUsers();
            this.setState({ realmUsers: realmUsers.filter(realmUser => realmUser.attributes.group_id[0] == 'RESPONDENT') });
        } catch (ex) {
            console.log('Network error loading respondent users');
        }
    }

    async handleEnabledUpdate(evt, setEnabled, userId) {
        let realmUsers = this.state.realmUsers;

        let previousValue = realmUsers.filter((realmUser) => realmUser.userid == userId)[0].enabled;
        realmUsers.filter((realmUser) => realmUser.userid == userId)[0].enabled = setEnabled;
        this.setState({
            realmUsers: realmUsers
        });

        const restResponse = await (async () => {
            if (realmUsers.filter((realmUser) => realmUser.userid == userId)[0] !== undefined && realmUsers.filter((realmUser) => realmUser.userid == userId)[0] !== null) {
                realmUsers.filter((realmUser) => realmUser.userid == userId)[0].realmRoles = ['dsp_respondent'];
                realmUsers.filter((realmUser) => realmUser.userid == userId)[0].clientRoles = { role: ['respondents'] };
            }
            const result = await ProfileManagementService.updateRealmUser(realmUsers.filter((realmUser) => realmUser.userid == userId)[0]);
            return result;
        })([]);

        await (async () => {
            if (restResponse != 'User updated') {
                realmUsers.filter((realmUser) => realmUser.userid == userId)[0].enabled = previousValue;
                this.setState({
                    realmUsers: realmUsers
                });

                return await SupportAlert.displayAlert('Account status not updated. ', 'Account Update - Failed');
            }
        })([]);

    }

    async onRegisterRespondent(evt, realmUser) {

        this.setState({ contactsLoading: true });

        const finalResult = await (async () => {
            const result = await ProfileManagementService.createRealmUser(realmUser);
            return result;
        })([]);

        const confirmed = await (async () => {
            if (finalResult == 'Created') {
                this.reloadRealmUsers();
                return await SupportAlert.displayAlert('Respondent user created successfully.', 'Create Respondent - Success');
            } else {
                return await SupportAlert.displayAlert('Respondent user not created. ', 'Create Respondent - Failed');
            }
        })([]);

        this.setState({ contactsLoading: false });
    }

    async onUpdateRespondent(evt, updatedRealmUser) {

        const finalResult = await (async () => {
            const result = await ProfileManagementService.updateRealmUser(updatedRealmUser);
            return result;
        })([]);

        const confirmed = await (async () => {
            if (finalResult != 'User updated') {
                return await SupportAlert.displayAlert('Respondent user not updated. ', 'Update Respondent - Failed');
            } else {
                this.setState({ contactsLoading: true });
                await this.reloadRealmUsers();
            }
        })([]);

        this.setState({ contactsLoading: false });
    }

    async onDeregisterRespondent(evt, deregisterUser) {

        this.setState({ contactsLoading: true });

        const finalResult = await (async () => {
            const result = await ProfileManagementService.deregisterRealmUser(deregisterUser);
            return result;
        })([]);

        const confirmed = await (async () => {
            if (finalResult == 'Deleted') {

                const allRealmUsers = this.state.realmUsers;

                const usersNotDeregistered = allRealmUsers.filter((realmUser) => realmUser.userid !== deregisterUser.userid);
                this.setState({ realmUsers: usersNotDeregistered });

                return await SupportAlert.displayAlert('Respondent user deregistered successfully.', 'Deregister Respondent - Success');

            }
            else {

                return await SupportAlert.displayAlert('Respondent user not deregistered. ', 'Deregister Respondent - Failed');

            }
        })([]);

        this.setState({ contactsLoading: false });
    }

    async handleDeregisterDialog(evt, realmUser) {

        let title = 'Confirm Respondent Deregistration ';

        const result = await CustomDialog(
            <EditRespondentDialog
                {...this.props}
                backButton={{ text: 'Exit' }}
                nextButton={{ text: 'Confirm', onClick: (evt, data) => this.onDeregisterRespondent(evt, data) }}
                realmUser={realmUser}
                allSectorsDropdown={this.state.allSectorsDropdown}
                allSurveyCyclesDropdown={this.state.allSurveyCyclesDropdown}
                actionDelete={true}
            />,
            {
                className: styles.editRespondentDialog,
                title: title,
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }

    async handleUpdateUserDialog(evt, realmUser) {
        let title = 'Update Respondent Details ';
        const result = await CustomDialog(
            <EditRespondentDialog
                {...this.props}
                backButton={{ text: 'Cancel' }}
                nextButton={{ text: 'Update', onClick: (evt, data) => this.onUpdateRespondent(evt, data) }}
                realmUser={realmUser}
                allSectorsDropdown={this.state.allSectorsDropdown}
                allSurveyCyclesDropdown={this.state.allSurveyCyclesDropdown}
            />,
            {
                className: styles.editRespondentDialog,
                title: title,
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }

    async onAddRespondent(evt) {

        let title = 'Register Respondent User';

        const result = await CustomDialog(
            <AddRespondentDialog
                {...this.props}
                backButton={{ text: 'Cancel' }}
                nextButton={{ text: 'Register', onClick: (evt, data) => this.onRegisterRespondent(evt, data) }}
                realmUsers={this.state.realmUsers}
                allSurveyCyclesDropdown={this.state.allSurveyCyclesDropdown}
                allSectorsDropdown={this.state.allSectorsDropdown}
                lookUps={this.state.lookUps}
            />,
            {
                className: styles.addRespondentDialog,
                title: title,
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }

    realmUsersTable() {
        let tableTitle = 'Registered respondent users'.toUpperCase();

        let realmUsersTableData = [];

        let realmUsersColumns = [
            {
                label: "User ID", name: "userId"
                , options: {
                    display: false,
                }
            },
            { label: "Username/Email", name: "email" },
            { label: "Respondent name", name: "respondentName" },
            { label: "Organisation", name: "organisation" },
            { label: "Account status", name: "enabled" },
            { label: "Actions", name: "actions" },
        ];

        let rowId = 0;
        if (this.state.realmUsers !== undefined && this.state.realmUsers !== null && this.state.realmUsers.length >= 0
            && this.state.allOrganisationStatuses !== undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length >= 0) {

            this.state.realmUsers.sort((a, b) => a.email > b.email ? 1 : -1).map((realmUser) => {
                let orgStatus = this.state.allOrganisationStatuses.filter(orgStatus => orgStatus.organisationId == realmUser.attributes.org_id)[0];
                let uom = orgStatus?.unitOfMeasure === false ? ' (Non Unit of Measure)' : '';
                let row = {
                    userId: realmUser.userid,
                    organisation: (orgStatus !== undefined && orgStatus !== null && orgStatus.organisationStateName !== undefined && orgStatus.organisationStateName !== null) ? (orgStatus.organisationStateName + uom) : <LinearProgress variant='indeterminate' />,
                    email: realmUser.email,
                    respondentName: realmUser.firstName + ' ' + realmUser.lastName,
                    enabled: <div>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size='small'
                                        color='primary'
                                        sx={{ m: 1 }}
                                        checked={realmUser.enabled}
                                        onChange={(evt) => {
                                            this.handleEnabledUpdate(evt, !realmUser.enabled, realmUser.userid)
                                        }}
                                    />
                                }
                                label={realmUser.enabled ? "Enabled" : "Disabled"}
                            />
                        </FormControl>
                    </div>,
                    actions: <div>
                        <Stack direction="column" spacing={0.5}>
                            <Chip variant='outlined' label='Update Details'
                                onClick={evt => this.handleUpdateUserDialog(evt, realmUser)} />
                            <Chip variant='outlined' label='Deregister Respondent'
                                onClick={evt => {
                                    this.handleDeregisterDialog(evt, realmUser);
                                }} />
                        </Stack>
                    </div>
                }
                realmUsersTableData.push(row);

            });
        }

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [5],
            rowsPerPageOptions: [5, 10, 25, 100],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            row.data.shift();
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        return <div className={styles.flex_row_container}>
            <MUIDataTable
                title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                data={realmUsersTableData}
                columns={realmUsersColumns}
                options={options}
            />
        </div>
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} label={'Page loading ...'} />
        } else {
            return (
                <div className={styles.container_users} >
                    <div className={styles.featuredItem}>
                        <span className={styles.featuredTitleHeader}><h2>{'Respondent User Accounts'}</h2></span>
                        <hr />
                        <Grid container item xs={15} >
                            <Chip
                                variant="outlined"
                                icon={<PersonAdd />}
                                label="Register new respondent"
                                onClick={evt => {
                                    const func = (async () => {
                                        this.setState({ loading: true });
                                        await this.onAddRespondent(evt);
                                        this.setState({ loading: false });
                                    })([]);
                                }} />
                        </Grid>
                    </div>
                    <div className={styles.featuredItem}>
                        <Grid container item xs={15} >
                            {this.realmUsersTable()}
                        </Grid>
                    </div>
                </div>
            )
        }
    }
}
export default RespondentUsers;