import React, { Component, P } from 'react';
import { Link } from 'react-router-dom';
import SectorContact from '@components/SectorContact';
import SpinnerModal from '@components/SpinnerModal';
import SurveyService from '@services/SurveyService';
import styles from './mandate.module.css';
import RespondentDashboardService from '@services/RespondentDashboardService';

class Mandate extends Component {

  state = {
    loading: true,
  }

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.setState({ loading: true , 
                    status: ''
    });

    let bearer = localStorage.getItem('Bearer');
    if (!bearer){
      window.location = "/login";
  
    }

    function parseJwt(token) {
      var base64Payload = token.split('.')[1];
      var payload = Buffer.from(base64Payload, 'base64');
      return JSON.parse(payload.toString());
    }


    let payload = parseJwt(bearer);

    let organisationId = payload.org_id;
    let sectorId = payload.sector_id;
    localStorage.setItem('nil_return', false);
    
    const sector = await SurveyService.getSectorById(sectorId);

    localStorage.setItem('sectorName', sector.name);
    const surveyCycleId =  process.env.REACT_APP_CURRENT_SURVEY_CYCLE ; 
    const surveyStatus = await RespondentDashboardService.getOrganisationSurveyStatus(organisationId, surveyCycleId)
    
    this.setState({ loading: false, status: surveyStatus });
  }

  render() {
    let sector = localStorage.getItem('sectorName')

    if (this.state.loading === true) {
      return <SpinnerModal settings={true} />
    } else {
      return (
        <div className={styles.container_surveys_landing}>

          <div className={styles.sections_mandates}>
            <h1 className='title__24px__orange'>Mandate</h1>

            <p>
              The Centre for Science, Technology and Innovation Indicators (CeSTII), within the Human Sciences Research Council (HSRC),
              conducts the National Survey of Research & Experimental Development (R&D) Inputs {process.env.REACT_APP_CURRENT_SURVEY_CYCLE_YEAR_LABEL} Financial Year
              on behalf of the Department of Science and Innovation (DSI).
              <strong>The Survey is conducted in terms of the Statistics Act No. 6 of 1999.</strong>
              Organisations are therefore legally required to respond by providing accurate data on R&D performance.
              All data gathered for this survey are confidential.
              Only the survey team have access to individual organisation data.
              The HSRC and DSI will not disseminate any information identifiable with an organisation without their consent.
            </p>


            <h1 className='title__24px__orange'>Purpose and scope</h1>

            <p>
              The R&D survey collects data on the inputs into <strong>intramural (in-house) </strong>R&D activities performed in South Africa by all
              organisations (including Business, Government, Science Councils, Not-for-Profit and Higher Education).
              The data is used for planning and monitoring purposes and to support decisions that strengthen South Africa’s
              competitiveness. Previous survey results may be viewed at <a href="http://www.hsrc.ac.za/en/departments/cestii" target="_blank">http://www.hsrc.ac.za/en/departments/cestii</a>.
              This survey covers the
              {sector.toUpperCase().includes('EDUCATION') ?
                <strong> academic year 1 January to 31 December {process.env.REACT_APP_CURRENT_SURVEY_CYCLE_FROM_YEAR}.</strong>
              :sector.toUpperCase().includes('BUSINESS') ?
               
                <strong> financial year 1 March {process.env.REACT_APP_CURRENT_SURVEY_CYCLE_FROM_YEAR} to 28 February {process.env.REACT_APP_CURRENT_SURVEY_CYCLE_TO_YEAR} (or your nearest complete financial year).</strong>
              :
              <strong> financial year 1 April {process.env.REACT_APP_CURRENT_SURVEY_CYCLE_FROM_YEAR} to 31 March {process.env.REACT_APP_CURRENT_SURVEY_CYCLE_TO_YEAR} (or your nearest complete financial year).</strong>
            
              }
            </p>


            <h1 className='title__24px__orange'>Due date</h1>

            <p>
            {sector.toUpperCase().includes('EDUCATION') ?
               <span>
                Kindly complete and return this form as soon as possible, but no later than 1 DECEMBER {process.env.REACT_APP_CURRENT_SURVEY_CYCLE_TO_YEAR}.																			
                Return address: R&D Survey, PO Box 15200, Vlaeberg, 8018 OR: E-mail to addresses listed below				
               </span> 															          									
    				  :
             <span>  Kindly complete and return this questionnaire via email by _______________________ . Should you wish to post your questionnaire to us,
              please address your consignment to <strong>R&D Survey, Private Bag X9182, Cape Town, 8000.</strong> </span>
             }  
            </p>


            <h1 className='title__24px__orange'>Record keeping</h1>

            <p>
              <strong> <h2>PLEASE KEEP A COPY OF THIS QUESTIONNAIRE FOR YOUR RECORDS.</h2></strong>
            </p>

            <h1 className='title__24px__orange'>Assistance and feedback</h1>

            <p>
              If you need assistance please contact one of the survey team:
            </p>
            {/* put contact module here */}

            <SectorContact sector={sector} />

            <p>
              A feedback section is located on the back page of this questionnaire. We welcome your comments and suggestions.
            </p>
            <p>
              <b>National R&D Survey Project Leader
                <br>
                </br>Dr Nazeem Mustapha, Chief Research Specialist </b> <br>
              </br>nmustapha@hsrc.ac.za | Tel: 021 466 7887
            </p>

            <p>

            In order for HSRC-CeSTII to meet our obligations to produce national STI indicators on behalf of the DSI, we collect information 
about R&D from various organisation types: businesses, not-for-profit organisations, science councils, government departments 
and higher education institutions. We store this information in a secure database that is part of a data management system within 
the HSRC secure server environment. This information is retained in line with S14(1) and (2) of POPIA.

</p>
            <div>
           
                <Link to='/survey-landing'> 
                 <button variant="contained" className="button__orange"> Continue </button>
               </Link>
              
            </div>


          </div>

        </div>

      )
    }
  }
}

export default Mandate;