// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer_footer_block__VlCdg  {\r\n  display: flex;\r\n  flex-direction: row; \r\n  flex: 1 1; \r\n  justify-content: space-evenly; \r\n  align-items: flex-start; \r\n  align-content: center;\r\n    flex-wrap: nowrap;\r\n}\r\n.footer_contact_mail__1FseV:link {\r\n  text-decoration: underline;\r\n}\r\n.footer_copyright_block__2wI8u .footer_contact_block__SOkvm .footer_social_icons__2mzOk{\r\n  display: flex; \r\n  flex-direction: column; \r\n  flex: 1 1;\r\n  align-items: flex-start; \r\n  align-content: center;\r\n}\r\n\r\n.footer_social_icons_img__1JpWi{\r\n  margin-left: 10px; \r\n}", "",{"version":3,"sources":["webpack://src/components/Footer/footer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAQ;EACR,6BAA6B;EAC7B,uBAAuB;EACvB,qBAAqB;IACnB,iBAAiB;AACrB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAQ;EACR,uBAAuB;EACvB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".footer_block  {\r\n  display: flex;\r\n  flex-direction: row; \r\n  flex: 1 ; \r\n  justify-content: space-evenly; \r\n  align-items: flex-start; \r\n  align-content: center;\r\n    flex-wrap: nowrap;\r\n}\r\n.contact_mail:link {\r\n  text-decoration: underline;\r\n}\r\n.copyright_block .contact_block .social_icons{\r\n  display: flex; \r\n  flex-direction: column; \r\n  flex: 1 ;\r\n  align-items: flex-start; \r\n  align-content: center;\r\n}\r\n\r\n.social_icons_img{\r\n  margin-left: 10px; \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer_block": "footer_footer_block__VlCdg",
	"contact_mail": "footer_contact_mail__1FseV",
	"copyright_block": "footer_copyright_block__2wI8u",
	"contact_block": "footer_contact_block__SOkvm",
	"social_icons": "footer_social_icons__2mzOk",
	"social_icons_img": "footer_social_icons_img__1JpWi"
};
export default ___CSS_LOADER_EXPORT___;
