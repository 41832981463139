// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".addrespondentdialog_featuredItem__2lHFl {\r\n    flex: 1 1;\r\n    margin: 0px 20px;\r\n    padding: 30px;\r\n    border-radius: 10px;\r\n    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);\r\n}\r\n\r\n.addrespondentdialog_dialogInputs__4i_9s {\r\n    width: 100%;\r\n}", "",{"version":3,"sources":["webpack://src/pages/ResearcherDashboard/components/UserManagement/AddRespondentDialog/addrespondentdialog.module.css"],"names":[],"mappings":"AAAA;IACI,SAAO;IACP,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IAEnB,kDAAkD;AACtD;;AAEA;IACI,WAAW;AACf","sourcesContent":[".featuredItem {\r\n    flex: 1;\r\n    margin: 0px 20px;\r\n    padding: 30px;\r\n    border-radius: 10px;\r\n    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);\r\n    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);\r\n}\r\n\r\n.dialogInputs {\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"featuredItem": "addrespondentdialog_featuredItem__2lHFl",
	"dialogInputs": "addrespondentdialog_dialogInputs__4i_9s"
};
export default ___CSS_LOADER_EXPORT___;
