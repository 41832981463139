import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import SubSectionText from "@components/SubSectionText";
import Separator from "@components/Separator";
import Contact from "@components/Contact";
import InputBox from "@components/InputBox";
import Footer from "@components/Footer";
import NavigationBar from "@components/NavigationBar";

import styles from "./resetpassword.module.css";
import MultiColorTitle from "@components/MultiColorTitle";

import { Alert } from 'react-st-modal';
import axios from "axios";
import qs from "qs";


class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            errors: '',
        }
    }

    handleSubmit = event => {
        event.preventDefault();


        let data = qs.stringify({
            username: this.state.username,
        });

        axios.get(process.env.REACT_APP_PROFILE_MANAGEMENT_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + '/profilemanagement/user/password/reset/' + this.state.username).then(resp => {

            { Alert(resp.data, "Password Recovery") };
        }).catch(() => {
            { Alert('Password recovery failed. Please try again later.', 'Password Recovery') };
        });
    }

    handleNameChange = event => {
        this.setState({ username: event.target.value });
    }


    render() {
        return (
            <div>
                <NavigationBar />
                <Container className={styles.container_resetpassword}>
                    <br />
                    <br />
                    <div className={styles.resetpassword_body}>
                        <MultiColorTitle
                            title_font="title__42px"
                            first_color="orange"
                            first_text="Reset"
                            second_color="blue"
                            second_text="Password"
                        />
                        <div style={{ maxWidth: 750 }}>
                            <SubSectionText
                                text_font="text__18px"
                                text="Please enter your email address below and look out for an email with further instructions." />
                        </div>
                        <div>
                            <form onSubmit={this.handleSubmit}>
                                <br />
                                <input placeholder="Email Address" name="username" className="input__box"
                                    onChange={this.handleNameChange} required />
                                <br />

                                <div style={{ marginBottom: 10, marginTop: 20 }} >
                                    <input variant="contained" type="submit" value="Recover Password" className="button__orange" onClick={this.handleSubmit} />
                                    <text style={{ maxWidth: '65%' }} className="text__12px">
                                        {this.props.errors}
                                    </text>

                                </div>
                            </form>
                        </div>
                        <a href="/confidentiality"><text style={{ maxWidth: 485 }} className="text__14px" >By logging in, you agree to our </text><text style={{ maxWidth: 485 }} className="text__14px__orange" >terms of use.
                        </text></a>
                    </div>


                </Container>
                <Separator />
                <Contact />
                <Separator />
                <Footer />
            </div>
        );
    }

}
export default ResetPassword;