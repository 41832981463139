import React, { Component } from "react";
import SpinnerModal from '@components/SpinnerModal';
import CompoundQuestionState from './CompoundQuestionState';
import styles from './questionnaire.module.css';

class QuestionnairePart extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
        questionnairePart: null,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ questionnairePart: this.props.questionnairePart });

        // Stuff to be loaded goes here

        this.setState({ loading: false });
    }

    handleOnChange = (evt) => {
        this.setState({ sessionStateChanged: true });
        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        this.setState({ sessionStateChanged: true });
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            return (
                <div className={styles.pagebreak}>
                    <div >
                        <span className="partNumber">{this.state.questionnairePart.name}:</span>
                        <span className={styles.partTitle}> {this.state.questionnairePart.description}</span>

                        <br></br>

                        <span className="belowTitleText">Things to check: {this.state.questionnairePart.thingsToCheck}</span>

                        <br></br>
                        <br></br>

                        <div className="box">
                            <div className={this.state.questionnairePart.name.toUpperCase().includes('PART 1') ? styles.boxItemActive : styles.boxItem}>1</div>
                            <div className={this.state.questionnairePart.name.toUpperCase().includes('PART 2') ? styles.boxItemActive : styles.boxItem}>2</div>
                            <div className={this.state.questionnairePart.name.toUpperCase().includes('PART 3') ? styles.boxItemActive : styles.boxItem}>3</div>
                            <div className={this.state.questionnairePart.name.toUpperCase().includes('PART 4') ? styles.boxItemActive : styles.boxItem}>4</div>
                            <div className={this.state.questionnairePart.name.toUpperCase().includes('PART 5') ? styles.boxItemActive : styles.boxItem}>5</div>
                        </div>

                        <br></br>

                    </div>
                    <div >
                        {this.state.questionnairePart.compoundQuestionStates.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1).map(compoundQuestionState => {
                            return (
                                <CompoundQuestionState
                                    captureType={this.props.captureType}
                                    key={compoundQuestionState.sequenceNumber}
                                    compoundQuestionState={compoundQuestionState}
                                    sectorId={this.props.sectorId}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    questionnairePartId={this.props.questionnairePart.id}
                                    questionnairePart={this.state.questionnairePart}
                                />
                            )
                        })}
                    </div>
                    <hr />
                </div>
            )
        }
    }
}
export default QuestionnairePart;