import React, { useState, Component } from 'react';
import MUIDataTable from 'mui-datatables';
import DataCaptureService from '@services/DataCaptureService';
import ProfileManagementService from '@services/ProfileManagementService';
import CSVGenerator from '@components/CSVGenerator';
import SupportAlert from '@components/SupportAlert';
import AddResearcherDialog from '../../../components/UserManagement/AddResearcherDialog';
import EditResearcherDialog from '../../../components/UserManagement/EditResearcherDialog';
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../users.module.css';
import { PersonAdd } from "@material-ui/icons";
import SpinnerModal from '@components/SpinnerModal';
import Chip from '@material-ui/core/Chip';
import Stack from '@mui/material/Stack';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

class ResearcherUsers extends Component {
    state = {
        loading: false,
        realmUsersLoading: false,
        realmUsers: [],
        allOrganisationStatuses: [],
    }

    constructor() {
        super();

    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.reloadRealmUsers();

        this.reloadOrganisationStatusses();

        this.setState({ loading: false });
    }
    
    async reloadOrganisationStatusses() {
        this.setState({ organisationStatusesLoading: true });
        
        try {
            const organisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId_including_non_uom(null, process.env.REACT_APP_CURRENT_SURVEY_CYCLE);
            this.setState({ allOrganisationStatuses: organisationStatuses });
        } catch (ex) {
            console.log('Network error loading organisations');
        }

        this.setState({ organisationStatusesLoading: false });

    }

    async reloadRealmUsers() {
        this.setState({ realmUsersLoading: true });

        try {
            const realmUsers = await ProfileManagementService.getAllRealmUsers();
            this.setState({ realmUsers: realmUsers.filter(realmUser => realmUser.attributes.group_id[0] == 'RESEARCHER') });
        } catch (ex) {
            console.log('Network error loading researcher users');
        }

        this.setState({ realmUsersLoading: false });
    }

    async handleEnabledUpdate(evt, setEnabled, userId) {
        let realmUsers = this.state.realmUsers;

        let previousValue = realmUsers.filter((realmUser) => realmUser.userid == userId)[0].enabled;
        realmUsers.filter((realmUser) => realmUser.userid == userId)[0].enabled = setEnabled;
        this.setState({
            realmUsers: realmUsers
        });

        const restResponse = await (async () => {
            if (realmUsers.filter((realmUser) => realmUser.userid == userId)[0] !== undefined && realmUsers.filter((realmUser) => realmUser.userid == userId)[0] !== null) {
                realmUsers.filter((realmUser) => realmUser.userid == userId)[0].realmRoles = ['dsp_researcher'];
                realmUsers.filter((realmUser) => realmUser.userid == userId)[0].clientRoles = { role: ['researchers'] };
            }
            const result = await ProfileManagementService.updateRealmUser(realmUsers.filter((realmUser) => realmUser.userid == userId)[0]);
            return result;
        })([]);

        await (async () => {
            if (restResponse != 'User updated') {
                realmUsers.filter((realmUser) => realmUser.userid == userId)[0].enabled = previousValue;
                this.setState({
                    realmUsers: realmUsers
                });

                return await SupportAlert.displayAlert('Account status not updated. ', 'Account Update - Failed');
            }
        })([]);

    }

    async onRegisterResearcher(evt, realmUser) {

        this.setState({ realmUsersLoading: true });

        const finalResult = await (async () => {
            const result = await ProfileManagementService.createRealmUser(realmUser);
            return result;
        })([]);

        const confirmed = await (async () => {
            if (finalResult == 'Created') {
                this.reloadRealmUsers();
                return await SupportAlert.displayAlert('Researcher user created successfully.', 'Create Researcher - Success');
            } else {
                return await SupportAlert.displayAlert('Researcher user not created. ', 'Create Researcher - Failed');
            }
        })([]);

        this.setState({ realmUsersLoading: false });
    }

    async onUpdateResearcher(evt, updatedRealmUser) {

        const finalResult = await (async () => {
            const result = await ProfileManagementService.updateRealmUser(updatedRealmUser);
            return result;
        })([]);

        const confirmed = await (async () => {
            if (finalResult != 'User updated') {
                return await SupportAlert.displayAlert('Researcher user not updated. ', 'Update Researcher - Failed');
            } else {
                this.setState({ realmUsersLoading: true });
                await this.reloadRealmUsers();
            }
        })([]);

        this.setState({ realmUsersLoading: false });
    }

    async onDeregisterResearcher(evt, deregisteredUserData) {

        const finalResult = await (async () => {
            const result = await ProfileManagementService.deregisterRealmUser(deregisteredUserData);
            return result;
        })([]);

        const confirmed = await (async () => {
            if (finalResult == 'Deleted') {

                const allRealmUsers = this.state.realmUsers;

                const usersNotDeregistered = allRealmUsers.filter((realmUser) => realmUser.userid !== deregisteredUserData.userid);
                this.setState({ realmUsers: usersNotDeregistered });

                return await SupportAlert.displayAlert('Researcher user deregistered successfully.', 'Deregister Researcher - Success');

            }
            else {

                return await SupportAlert.displayAlert('Researcher user not deregistered. ', 'Deregister Researcher - Failed');

            }
        })([]);

    }

    async handleDeregisterDialog(evt, realmUser) {

        let title = 'Confirm Researcher Deregistration ';

        const result = await CustomDialog(
            <EditResearcherDialog
                {...this.props}
                backButton={{ text: 'Exit' }}
                nextButton={{ text: 'Confirm', onClick: (evt, data) => this.onDeregisterResearcher(evt, data) }}
                realmUser={realmUser}
                actionDelete={true}
            />,
            {
                className: styles.editResearcherDialog,
                title: title,
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }

    async handleUpdateUserDialog(evt, realmUser) {
        let title = 'Update Researcher Details ';
        const result = await CustomDialog(
            <EditResearcherDialog
                {...this.props}
                backButton={{ text: 'Cancel' }}
                nextButton={{ text: 'Update', onClick: (evt, data) => this.onUpdateResearcher(evt, data) }}
                realmUser={realmUser}
            />,
            {
                className: styles.editResearcherDialog,
                title: title,
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }

    async handleAddResearcherDialog(evt) {

        let title = 'Register Researcher User';

        const result = await CustomDialog(
            <AddResearcherDialog
                {...this.props}
                backButton={{ text: 'Cancel' }}
                nextButton={{ text: 'Register', onClick: (evt, data) => this.onRegisterResearcher(evt, data) }}
                realmUsers={this.state.realmUsers}
            />,
            {
                className: styles.addResearcherDialog,
                title: title,
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }

    realmUsersTable() {
        let tableTitle = 'Registered researcher users'.toUpperCase();

        let realmUsersTableData = [];

        let realmUsersColumns = [
            {
                label: "User ID", name: "userId"
                , options: {
                    display: false,
                }
            },
            { label: "Username/Email", name: "email" },
            { label: "Researcher name", name: "researcherName" },
            { label: "Account status", name: "enabled" },
            { label: "Actions", name: "actions" },
        ];

        if (this.state.realmUsers !== undefined && this.state.realmUsers !== null && this.state.realmUsers.length >= 0
            && this.state.allOrganisationStatuses !== undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length >= 0) {

            this.state.realmUsers.sort((a, b) => a.email > b.email ? 1 : -1).map((realmUser) => {
                let row = {
                    userId: realmUser.userid,
                    email: realmUser.email,
                    researcherName: realmUser.firstName + ' ' + realmUser.lastName,
                    enabled: <div>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size='small'
                                        color='primary'
                                        sx={{ m: 1 }}
                                        checked={realmUser.enabled}
                                        onChange={(evt) => {
                                            this.handleEnabledUpdate(evt, !realmUser.enabled, realmUser.userid)
                                        }}
                                    />
                                }
                                label={realmUser.enabled ? "Enabled" : "Disabled"}
                            />
                        </FormControl>
                    </div>,
                    actions: <div>
                        <Stack direction="column" spacing={0.5}>
                            <Chip variant='outlined' label='Update Details'
                                onClick={evt => this.handleUpdateUserDialog(evt, realmUser)} />
                            <Chip variant='outlined' label='Deregister Researcher'
                                onClick={evt => {
                                    this.handleDeregisterDialog(evt, realmUser);
                                }} />
                        </Stack>
                    </div>
                }
                realmUsersTableData.push(row);

            });
        }

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [5],
            rowsPerPageOptions: [5, 10, 25, 100],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            row.data.shift();
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        return <div className={styles.flex_row_container}>
            <MUIDataTable
                title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                data={realmUsersTableData}
                columns={realmUsersColumns}
                options={options}
            />
        </div>
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} label={'Page loading ...'} />
        } else {
            return (
                <div className={styles.container_users} >
                    <div className={styles.featuredItem}>
                        <span className={styles.featuredTitleHeader}><h2>{'Researcher User Accounts'}</h2></span>
                        <hr />
                        <Grid container item xs={15} >
                            <Chip
                                variant="outlined"
                                icon={<PersonAdd />}
                                label="Register new researcher"
                                onClick={evt => {
                                    const func = (async () => {
                                        this.setState({ loading: true });
                                        await this.handleAddResearcherDialog(evt);
                                        this.setState({ loading: false });
                                    })([]);
                                }} />
                        </Grid>
                    </div>
                    <div className={styles.featuredItem}>
                        <Grid container item xs={15} >
                            {this.realmUsersTable()}
                        </Grid>
                    </div>
                </div>
            )
        }
    }
}
export default ResearcherUsers;