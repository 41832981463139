import React, { Component } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';


import styles from './references.module.css';

import AUSTRALIA from "@images/References/AUSTRALIA.png"
import AUSTRIA from "@images/References/AUSTRIA.png"
import BELGIUM from "@images/References/BELGIUM.png"
import CANADA from "@images/References/CANADA.png"
import CHILE from "@images/References/CHILE.png"
import CZECH_REPUBLIC from "@images/References/CZECH_REPUBLIC.png"
import DENMARK from "@images/References/DENMARK.png"
import ESTONIA from "@images/References/ESTONIA.png"
import FINLAND from "@images/References/FINLAND.png"
import FRANCE from "@images/References/FRANCE.png"
import GERMANY from "@images/References/GERMANY.png"
import GREECE from "@images/References/GREECE.png"
import HUNGARY from "@images/References/HUNGARY.png"
import ICELAND from "@images/References/ICELAND.png"
import IRELAND from "@images/References/IRELAND.png"
import ISRAEL from "@images/References/ISRAEL.png"
import ITALY from "@images/References/ITALY.png"
import JAPAN from "@images/References/JAPAN.png"
import LUXEMBOURG from "@images/References/LUXEMBOURG.png"
import MEXICO from "@images/References/MEXICO.png"
import NETHERLANDS from "@images/References/NETHERLANDS.png"
import NEW_ZEALAND from "@images/References/NEW_ZEALAND.png"
import NORWAY from "@images/References/NORWAY.png"
import POLAND from "@images/References/POLAND.png"
import PORTUGAL from "@images/References/PORTUGAL.png"
import SLOVAKIA from "@images/References/SLOVAKIA.png"
import SLOVENIA from "@images/References/SLOVENIA.png"
import SOUTH_KOREA from "@images/References/SOUTH_KOREA.png"
import SPAIN from "@images/References/SPAIN.png"
import SWEDEN from "@images/References/SWEDEN.png"
import SWITZERLAND from "@images/References/SWITZERLAND.png"
import TURKEY from "@images/References/TURKEY.png"
import UK from "@images/References/UK.png"
import US from "@images/References/US.png"




class References extends Component {


    render() {
        return (
            <div className={styles.references_container}>

                <Box >
                    <Grid container spacing={2} direction="row">

                        <img className={styles.reference_logo} src={AUSTRALIA} />
                        <img className={styles.reference_logo} src={AUSTRIA} />
                        <img className={styles.reference_logo} src={BELGIUM} />
                        <img className={styles.reference_logo} src={CANADA} />
                        <img className={styles.reference_logo} src={CHILE} />
                        <img className={styles.reference_logo} src={CZECH_REPUBLIC} />
                        <img className={styles.reference_logo} src={DENMARK} />
                        <img className={styles.reference_logo} src={ESTONIA} />
                        <img className={styles.reference_logo} src={FINLAND} />

                        <img className={styles.reference_logo} src={FRANCE} />
                        <img className={styles.reference_logo} src={GERMANY} />
                        <img className={styles.reference_logo} src={GREECE} />
                        <img className={styles.reference_logo} src={HUNGARY} />
                        <img className={styles.reference_logo} src={ICELAND} />
                        <img className={styles.reference_logo} src={IRELAND} />
                        <img className={styles.reference_logo} src={ISRAEL} />
                        <img className={styles.reference_logo} src={ITALY} />
                        <img className={styles.reference_logo} src={JAPAN} />

                        <img className={styles.reference_logo} src={LUXEMBOURG} />
                        <img className={styles.reference_logo} src={MEXICO} />
                        <img className={styles.reference_logo} src={NETHERLANDS} />
                        <img className={styles.reference_logo} src={NEW_ZEALAND} />
                        <img className={styles.reference_logo} src={NORWAY} />
                        <img className={styles.reference_logo} src={POLAND} />
                        <img className={styles.reference_logo} src={PORTUGAL} />
                        <img className={styles.reference_logo} src={SLOVAKIA} />
                        <img className={styles.reference_logo} src={SLOVENIA} />
                        <img className={styles.reference_logo} src={SOUTH_KOREA} />
                        <img className={styles.reference_logo} src={SPAIN} />
                        <img className={styles.reference_logo} src={SWEDEN} />
                        <img className={styles.reference_logo} src={SWITZERLAND} />
                        <img className={styles.reference_logo} src={TURKEY} />
                        <img className={styles.reference_logo} src={UK} />
                        <img className={styles.reference_logo} src={US} />


                    </Grid>




                </Box>


            </div>
        )
    }
}
export default References;