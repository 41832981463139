import React, { Component } from "react";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import deleteButton from '@images/close.svg';
import addButton from '@images/addrow_icon.svg';
import NumberTools from "@components/NumberTools";
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class RnDOutsourcedQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
        outsourcedToValues: new Map(),
        outsourcedToRows: [],
        outsourcedToTotal: 0,
        rowFactIds: null,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    async handleOnChange(evt, questionFactId, ordinal, numericInput) {
        let key = questionFactId + '_' + ordinal;
        let value = evt.target.value;

        if (numericInput && !NumberTools.isValidDecimalNumber(value)) {
            evt.preventDefault();
        } else {
            if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                const result = await CustomDialog(
                    <UpdateAuditDialog
                        currentAnswer={SessionStateManager.getSessionValue(key)}
                        numericInput={numericInput}
                        startEndornment={numericInput ? 'R' : null}
                    />,
                    {
                        className: styles.updateAuditDialog,
                        title: 'Update Submitted Value',
                        showCloseIcon: false,
                        isFocusLock: true,
                        isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                    }
                );

                if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                    SessionStateManager.setSessionValue(key, (numericInput ? result.newValue.toString().replace(/\s/g, '') : result.newValue), this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                    this.setState({ sessionStateChanged: true });
                } else {
                    evt.preventDefault();
                }
            } else {
                SessionStateManager.setSessionValue(key, (numericInput ? value.toString().replace(/\s/g, '') : value), this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");

                for (let ord = 0; ord <= ordinal; ord++) {
                    this.state.rowFactIds.forEach(factId => {
                        let thisKey = factId + '_' + ord;
                        let thisValue = SessionStateManager.getSessionValue(thisKey);
                        thisValue = (thisValue !== undefined && thisValue != null) ? thisValue : '';
                        SessionStateManager.setSessionValue(thisKey, thisValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
                    });
                }

                this.setState({ sessionStateChanged: true });
            }
            if (this.props.onChange !== undefined && this.props.onChange != null) {
                this.props.onChange(evt);
            }
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    CONFIG_DEFAULT_MINIMUM_ROWS = 3;

    handleAddOutsourcedToRow = (evt, factIds) => {
        this.addOutsourcedToRow(factIds);
    };

    addOutsourcedToRow = (factIds) => {
        const item = {
            ordinalEntry: parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + this.state.outsourcedToRows.length),
            factIds: factIds
        };
        this.setState({
            outsourcedToRows: [...this.state.outsourcedToRows, item]
        });
    };

    handleRemoveOutsourcedToRow = (evt) => {
        if (this.state.outsourcedToRows.length > 0) {
            let rowToRemove = this.state.outsourcedToRows.sort((a, b) => a.ordinalEntry > b.ordinalEntry ? 1 : -1)[this.state.outsourcedToRows.length - 1];

            // Updating total by deleting all values for factIds of this row and re-calculating total
            rowToRemove.factIds.forEach(factId => {
                let thisKey = 'rnd_outsourced_' + rowToRemove.ordinalEntry + '_' + factId
                this.state.outsourcedToValues.delete(thisKey);
            });

            let sum = parseFloat(0);
            this.state.outsourcedToValues.forEach((value, key) => {
                sum = sum + parseFloat(value)
            });
            this.setState({ outsourcedToTotal: sum });

            // Updating DB by nullifying all facts saved against this row if any
            // this.nullifyFacts(rowToRemove.ordinalEntry, rowToRemove.factIds)

            rowToRemove.factIds.forEach(factId => {
                let thisKey = factId + '_' + rowToRemove.ordinalEntry;
                SessionStateManager.setSessionValue(thisKey, '', this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId);
                SessionStateManager.deleteSessionValue(thisKey);
            });
            this.setState({ sessionStateChanged: true });

            this.setState({
                outsourcedToRows: this.state.outsourcedToRows.sort((a, b) => a.ordinalEntry > b.ordinalEntry ? 1 : -1).slice(0, -1)
            });
        }
    };

    calculatePercentageTotal(valueFactId) {
        let sum = parseFloat(0);
        let activityCodes = SessionStateManager.allEntriesByQuestionFactId(valueFactId);

        activityCodes.map((item) => {
            sum = sum + parseFloat(item.value != null && item.value != '' && !isNaN(item.value) ? item.value : 0);
        });

        return sum;
    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            let outsourcedToFactId = this.props.questionState.questionFactStates.sort((a, b) => (a.sequenceNumberY > b.sequenceNumberY) ? 1 : -1)[0].questionFactId;
            let percentFactId = this.props.questionState.questionFactStates.sort((a, b) => (a.sequenceNumberY > b.sequenceNumberY) ? 1 : -1)[1].questionFactId;
            let rowFactIds = [outsourcedToFactId, percentFactId];
            if (this.state.rowFactIds == null) {
                this.setState({ rowFactIds: rowFactIds });
            }

            let initialRows = 0;

            let activitiesCount = SessionStateManager.countEntriesByQuestionFactId(outsourcedToFactId);
            initialRows = activitiesCount;

            let percentCount = SessionStateManager.countEntriesByQuestionFactId(percentFactId);
            initialRows = percentCount > initialRows ? percentCount : initialRows;

            let rowsToAdd = (this.CONFIG_DEFAULT_MINIMUM_ROWS < initialRows) ? (initialRows - this.CONFIG_DEFAULT_MINIMUM_ROWS - this.state.outsourcedToRows.length) : 0;

            for (var rows = 0; rows < rowsToAdd; rows++) {
                try {
                    this.addOutsourcedToRow(rowFactIds);
                } catch (e) { }
            }

            let defaultOrdinals = [];
            for (var ordinal = 0; ordinal < this.CONFIG_DEFAULT_MINIMUM_ROWS; ordinal++) {
                defaultOrdinals.push(ordinal);
            }

            return <div className={styles.RndOutsourcedQuestionContainer}>
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th width="70%">Outsourced To</th>
                            <th width="30%">Approximate Value R’000s (excl. VAT)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {defaultOrdinals.sort().map(ordinal => (
                            <tr>
                                <td>
                                    {(ordinal == 1)
                                        ? <img
                                            src={deleteButton}
                                            alt="delete row"
                                            onClick={this.handleRemoveOutsourcedToRow}
                                        />
                                        : (ordinal == 0)
                                            ? <img
                                                src={addButton}
                                                alt="add row"
                                                onClick={(evt) => this.handleAddOutsourcedToRow(evt, rowFactIds)}
                                            />
                                            : <div />
                                    }
                                </td>
                                <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, outsourcedToFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : null}
                                >
                                    <input
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, outsourcedToFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                        type="text"
                                        questionId={this.props.questionState.question.id}
                                        ordinalEntry={ordinal}
                                        questionFactId={outsourcedToFactId}
                                        value={SessionStateManager.getSessionValue(outsourcedToFactId + '_' + ordinal)}
                                        onChange={evt => this.handleOnChange(evt, outsourcedToFactId, ordinal, false)}
                                        onBlur={this.handleOnBlur}
                                        placeholder={'Outsourced To'}
                                    />
                                </td>
                                <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, percentFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : null}
                                >
                                    <div className={styles.flexCenterFitContent}>
                                        <span className="inputRandSign">R</span>
                                        <input
                                            className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, percentFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                            type="text"
                                            value={NumberTools.transformToCurrency(SessionStateManager.getSessionValue(percentFactId + '_' + ordinal))}
                                            onClick={(evt) => {
                                                if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                                                    this.handleOnChange(evt, percentFactId, ordinal, true);
                                                }
                                            }}
                                            onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key) || (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE')) { event.preventDefault(); } }}
                                            onChange={evt => this.handleOnChange(evt, percentFactId, ordinal, true)}
                                            onBlur={this.handleOnBlur}
                                            questionId={this.props.questionState.question.id}
                                            ordinalEntry={0}
                                            questionFactId={percentFactId}
                                            placeholder={0}
                                        />
                                    </div>
                                </td>
                            </tr>)
                        )}

                        {this.state.outsourcedToRows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td></td>
                                <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, outsourcedToFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.tableInputBoxUpdated : null}
                                >
                                    <input
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, outsourcedToFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                        type="text"
                                        questionId={this.props.questionState.question.id}
                                        ordinalEntry={parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)}
                                        questionFactId={outsourcedToFactId}
                                        value={SessionStateManager.getSessionValue(outsourcedToFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx))}
                                        onChange={evt => this.handleOnChange(evt, outsourcedToFactId, parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx), false)}
                                        onBlur={this.handleOnBlur}
                                        placeholder={'Outsourced to'}
                                    />
                                </td>
                                <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, percentFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.tableInputBoxUpdated : null}
                                >
                                    <div className={styles.flexCenterFitContent}>
                                        <span className="inputRandSign">R</span>
                                        <input
                                            className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, percentFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                            type="text"
                                            value={NumberTools.transformToCurrency(SessionStateManager.getSessionValue(percentFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)))}
                                            onClick={(evt) => {
                                                if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                                                    this.handleOnChange(evt, percentFactId, parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx), true);
                                                }
                                            }}
                                            onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key) || (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE')) { event.preventDefault(); } }}
                                            onChange={evt => this.handleOnChange(evt, percentFactId, parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx), true)}
                                            onBlur={this.handleOnBlur}
                                            questionId={this.props.questionState.question.id}
                                            ordinalEntry={0}
                                            questionFactId={percentFactId}
                                            placeholder={0}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td></td>
                            <td align='right' colSpan='1'>TOTAL</td>
                            <td>
                                <div className={styles.flexCenterFitContent}>
                                    <span className="inputRandSign">R</span>
                                    <input
                                        min='0'
                                        prefix='R'
                                        type="text"
                                        value={NumberTools.transformToCurrency(this.calculatePercentageTotal(percentFactId))}
                                        id="d8"
                                        name={this.props.questionState.question.id}
                                        readOnly
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
    }
}
export default RnDOutsourcedQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    {...props}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if (
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                            result.confirmed = true;
                            dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}
