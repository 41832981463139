import React, {Component} from "react";

  
import {hsrcLogoInstance } from '@components/Globals';

import styles from './contact.module.css' ; 

class Contact extends Component {
 

    render() {
        return (
            <div className={styles.container_contact}>
                <div className={styles.contact_logo}>
                     <img src={hsrcLogoInstance} className={styles.hsrc__logo} alt="hsrc logo"/>
                </div>

                <div className={styles.contact_info}>
<p>134 Pretorius Street<br />
                                Pretoria<br />
                                South Africa<br />
                                0002<br />
                                Tel: +27 (0)12 302 2000<br />
                                Fax: +27 (0)12 302 2001</p>
                </div>
           
            </div>
        );
    }

}
export default Contact;
