import React, { Component, useState } from "react";
import TextField from '@mui/material/TextField';
import DataCaptureService from "@services/DataCaptureService";
import ProfileManagementService from "@services/ProfileManagementService";
import DropDownMenu from "@components/DropDownMenu";
import SpinnerModal from "@components/SpinnerModal";
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from './ReAssignDialog.module.css';

class ReAssignDialog extends Component {
    state = {
        loading: true,
        surveysUsers: [],
        reassignSurvey: {
            id: this.props.organisationStatus.datacapture1Id,
            completed: false,
            capturedById: null,
            questionnaireTrackerId: this.props.organisationStatus.questionnaireTrackerId,
        },
        assignedResearcher: null, 
    }

    constructor(props) {
        super(props);
    }

    parseJwt(token) {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }

    async componentDidMount() {
        this.setState({ loading: true });

        let bearer = localStorage.getItem('Bearer');
        if (!bearer){
            window.location = "/login";
        
          }

        let payload = this.parseJwt(bearer);

    ;


        const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
        // surveysUsers.filter
        this.setState({ surveysUsers: surveysUsers });

        let selectedResearcher = (this.props.organisationStatus.fieldworkerId !== undefined 
            && this.props.organisationStatus.fieldworkerId != null) ? 
            this.getSurveysUserNameById(this.props.organisationStatus.fieldworkerId) : 'Not assigned';

        this.setState({assignedResearcher: selectedResearcher}) ; 

        this.setState({ loading: false });
    }

    getSurveysUserNameById(userId) {
        let name = (this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].firstname + ' ' + this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].lastname);
        name = userId == 37 ? 'RDI Respondent' : name;
        return name;
    }

    onChangeFieldworker = (evt) => {
        let reassignSurvey = this.state.reassignSurvey;
        reassignSurvey.capturedById = evt.target.value;
        this.setState({ reassignSurvey: reassignSurvey });
    }

    render() {
   
        let fieldWorkers = [];

        this.state.surveysUsers.map(item => {
            fieldWorkers.push({ key: item.id, value: this.getSurveysUserNameById(item.id) });
        });


        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            return  <DialogWrapper 
            {...this.props} 
            onClick={(evt) => this.props.nextButton.onClick(evt, this.state.reassignSurvey)} 
            content={
            <React.Fragment>
         
             <div className={styles.featuredPopupDialogItem}>
                        <div className={styles.featuredItem} >
                            <span><h1 className={styles.featuredTitleHeader}>{'Organisation Details'}</h1></span>
                            <div className={styles.item} >
                                {'Organisation name : ' + ((this.props.organisationStatus.parentOrganisationStateName !== undefined && this.props.organisationStatus.parentOrganisationStateName !== null && this.props.organisationStatus.parentOrganisationStateName !== '') ? this.props.organisationStatus.parentOrganisationStateName + ' / ' : '') + this.props.organisationStatus.organisationStateName}<br />
                                {'Receipt number : ' + (this.props.organisationStatus.receiptNumber !== undefined && this.props.organisationStatus.receiptNumber !== null ? this.props.organisationStatus.receiptNumber : 'Not received')}<br />
                                {'Researcher : ' + this.state.assignedResearcher}<br />
                            
                            </div>
                        </div>
                        <div className={styles.featuredItem} >
                            <span><h1 className={styles.featuredTitleHeader}>{'Assign 1st Capture'}</h1></span>
                            <div className={styles.item} >
                            <DropDownMenu
                                    name={'fieldworker'}
                                    label={'Fieldworker'}
                                    value={this.state.surveysUsers.id}
                                     onChange={(evt) => this.onChangeFieldworker(evt)}
                                    showKeys={false}
                                    menuOptions={fieldWorkers}
                                    placeholder='Not Selected'
                                    required
                                />
                            </div>
                        </div>
            </div>            
                
            </React.Fragment>
        }
        />
        }
  }
}
export default ReAssignDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                      {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            props.onClick(evt);
                            dialog.close(true);
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}