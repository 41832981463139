import React, { Component } from "react";
import deleteButton from '@images/close.svg';
import addButton from '@images/addrow_icon.svg';
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import styles from '../questionnaire.module.css';
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';

class SubsidiaryCompaniesQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
        subsidiaryValues: new Map(),
        subsidiaryRows: [],
        subsidiaryTotal: 0,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    async handleOnChange(evt, questionFactId, ordinal) {
        let key = questionFactId + '_' + ordinal;
        let value = evt.target.value;

        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
            const result = await CustomDialog(
                <UpdateAuditDialog
                    currentAnswer={SessionStateManager.getSessionValue(key)}
                />,
                {
                    className: styles.updateAuditDialog,
                    title: 'Update Submitted Value',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );

            if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                SessionStateManager.setSessionValue(key, result.newValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                this.setState({ sessionStateChanged: true });
            } else {
                evt.preventDefault();
            }

        } else {
            SessionStateManager.setSessionValue(key, value, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");

            for (let ord = 0; ord <= ordinal; ord++) {
                let thisKey = questionFactId + '_' + ord;
                let thisValue = SessionStateManager.getSessionValue(thisKey);
                thisValue = (thisValue !== undefined && thisValue != null) ? thisValue : '';
                SessionStateManager.setSessionValue(thisKey, thisValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
            }

            this.setState({ sessionStateChanged: true });
        }

        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    CONFIG_DEFAULT_MINIMUM_ROWS = 3;

    handleAddSubsidiaryRow = (evt, factIds) => {
        this.addSubsidiaryRow(factIds);
    };

    addSubsidiaryRow = (factIds) => {
        const item = {
            ordinalEntry: parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + this.state.subsidiaryRows.length),
            factIds: factIds
        };
        this.setState({
            subsidiaryRows: [...this.state.subsidiaryRows, item]
        });
    };

    handleRemoveSubsidiaryRow = (evt) => {

        if (this.state.subsidiaryRows.length > 0) {
            let rowToRemove = this.state.subsidiaryRows.sort((a, b) => a.ordinalEntry > b.ordinalEntry ? 1 : -1)[this.state.subsidiaryRows.length - 1];

            // Updating total by deleting all values for factIds of this row and re-calculating total
            rowToRemove.factIds.forEach(factId => {
                let thisKey = 'subsidiary_' + rowToRemove.ordinalEntry + '_' + factId
                this.state.subsidiaryValues.delete(thisKey);
            });

            // Updating DB by nullifying all facts saved against this row if any
            // this.nullifyFacts(rowToRemove.ordinalEntry, rowToRemove.factIds)

            rowToRemove.factIds.forEach(factId => {
                let thisKey = factId + '_' + rowToRemove.ordinalEntry;
                SessionStateManager.setSessionValue(thisKey, '', this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId);
                SessionStateManager.deleteSessionValue(thisKey);
            });
            this.setState({ sessionStateChanged: true });

            this.setState({
                subsidiaryRows: this.state.subsidiaryRows.sort((a, b) => a.ordinalEntry > b.ordinalEntry ? 1 : -1).slice(0, -1)
            });
        }
    };

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            let subsidiaryFactId = this.props.questionState.questionFactStates.sort((a, b) => (a.sequenceNumberY > b.sequenceNumberY) ? 1 : -1)[0].questionFactId;
            let rowFactIds = [subsidiaryFactId];

            let initialRows = 0;

            let activitiesCount = SessionStateManager.countEntriesByQuestionFactId(subsidiaryFactId);
            initialRows = activitiesCount;

            let rowsToAdd = (this.CONFIG_DEFAULT_MINIMUM_ROWS < initialRows) ? (initialRows - this.CONFIG_DEFAULT_MINIMUM_ROWS - this.state.subsidiaryRows.length) : 0;

            for (var rows = 0; rows < rowsToAdd; rows++) {
                try {
                    this.addSubsidiaryRow(rowFactIds);
                } catch (e) { }
            }

            let defaultOrdinals = [];
            for (var ordinal = 0; ordinal < this.CONFIG_DEFAULT_MINIMUM_ROWS; ordinal++) {
                defaultOrdinals.push(ordinal);
            }

            return <div className={styles.SubsidiaryCompaniesQuestionContainer}>
                <table className="table">
                    <thead>
                        <tr>
                            <th width='5%' ></th>
                            <th width='95%' >Subsidiary Companies</th>
                        </tr>
                    </thead>
                    <tbody>
                        {defaultOrdinals.sort().map(ordinal => (
                            <tr>
                                <td>
                                    {(ordinal == 1)
                                        ? <img
                                            src={deleteButton}
                                            alt="delete row"
                                            onClick={this.handleRemoveSubsidiaryRow}
                                        />
                                        : (ordinal == 0)
                                            ? <img
                                                src={addButton}
                                                alt="add row"
                                                onClick={(evt) => this.handleAddSubsidiaryRow(evt, rowFactIds)}
                                            />
                                            : <div />
                                    }
                                </td>
                                <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, subsidiaryFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : null}
                                >
                                    <input
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, subsidiaryFactId + '_' + ordinal) ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                        type="text"
                                        questionId={this.props.questionState.question.id}
                                        ordinalEntry={ordinal}
                                        questionFactId={subsidiaryFactId}
                                        value={SessionStateManager.getSessionValue(subsidiaryFactId + '_' + ordinal)}
                                        onChange={evt => this.handleOnChange(evt, subsidiaryFactId, ordinal)}
                                        onBlur={this.handleOnBlur}
                                        placeholder={'Subsidiary Company Name'}
                                    />
                                </td>
                            </tr>)
                        )}

                        {this.state.subsidiaryRows.map((item, idx) => (
                            <tr id="addr0" key={idx}>
                                <td></td>
                                <td
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, subsidiaryFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.tableInputBoxUpdated : null}
                                >
                                    <input
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, subsidiaryFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)) ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                        type="text"
                                        questionId={this.props.questionState.question.id}
                                        ordinalEntry={parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx)}
                                        questionFactId={subsidiaryFactId}
                                        value={SessionStateManager.getSessionValue(subsidiaryFactId + '_' + parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx))}
                                        onChange={evt => this.handleOnChange(evt, subsidiaryFactId, parseFloat(this.CONFIG_DEFAULT_MINIMUM_ROWS + idx))}
                                        onBlur={this.handleOnBlur}
                                        placeholder={'Subsidiary Company Name'}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        }
    }
}
export default SubsidiaryCompaniesQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    currentAnswer={props.currentAnswer}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if (
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                            result.confirmed = true;
                            dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}
