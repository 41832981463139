import React, { Component } from 'react';
import axios from "axios";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import styles from './overview.module.css';
import SpinnerModal from '@components/SpinnerModal';
import InfoTextWidget from '@components/InfoTextWidget';
import InfoNumberWidget from '@components/InfoNumberWidget';
import BarChartWidget from '@components/BarChartWidget';
import PieChartWidget from '@components/PieChartWidget';
import SurveyService from '@services/SurveyService';
import RespondentDashboardService from '@services/RespondentDashboardService';

import Chip from '@material-ui/core/Chip';
import HomeIcon from '@mui/icons-material/Home';


class Dashboard extends Component {

    state = {
        loading: false,
        areasOfInterest: [],
        sourcesOfFunds: [],
        capitalExpenditures: [],
        provincialExpenditures: [],
        internationalSourcesOfFunds: [],
        categoriesofRnDExpenditure: [],
        specificAreasOfInterest: [],
        multiDisciplinaryAreasOfInterest: [],
        surveyId: 0,
        internationalSourcesOfFundsJSON: [],
        respondentCapitalExpenditureJSON: [],
        provincialExpendituresJSON: [],
        categoriesofRnDExpenditureJSON: [],
        organisationName: null,
        headcounts: [],
        ftecounts: [],
        surveyCycles: [],
        surveyCycleId: process.env.REACT_APP_CURRENT_SURVEY_CYCLE,
    }

    constructor() {
        super();
    }

    handleSurveyCycleChange = (event) => {
        this.setState({ surveyCycleId: (event.target.value) });

        this.componentDidMount();

    };


    async componentDidMount() {
        this.setState({ loading: true });

        function parseJwt(token) {
            var base64Payload = token.split('.')[1];
            var payload = Buffer.from(base64Payload, 'base64');
            return JSON.parse(payload.toString());
        }

        let bearer = localStorage.getItem('Bearer');

        if (!bearer){
            window.location = "/login";
        
          }

          
        let payload = parseJwt(bearer);

        let organisationId = payload.org_id;
        
        let sectorId = payload.sector_id;

        const surveyCycleId = process.env.REACT_APP_CURRENT_SURVEY_CYCLE;
        localStorage.setItem('SurveyCycleId', surveyCycleId);

        const questionnaire = await SurveyService.getQuestionnaireBySurveyCycleIdAndSectorId(surveyCycleId, sectorId);
        localStorage.setItem('SurveyId', questionnaire.id);

        const internationalSourcesOfFundsData = await RespondentDashboardService.getOrganisationSurveyStatus(organisationId, this.state.surveyCycleId)
        this.setState({
                        internationalSourcesOfFundsJSON: internationalSourcesOfFundsData,
                    });
        let respondentInternationalSourcesOfFunds = process.env.REACT_APP_RESPONDENT_DASHBOARD_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + `/respondentdashboardservice/internationalsourcesoffunds/organisation/${organisationId}/surveycycle/${this.state.surveyCycleId}`
        let respondentInternationalSourcesOfFundsURL = axios.create({ baseURL: respondentInternationalSourcesOfFunds });

        respondentInternationalSourcesOfFundsURL.get('').then(
            resp => {
                const respData = resp.data;

                let internationalSourcesOfFundsData = [];
                if (respData.status === "SUCCESS") {
                    respData.response.success.forEach(record => {
                        internationalSourcesOfFundsData.push(record);
                    });
                }
                this.setState({
                    internationalSourcesOfFundsJSON: internationalSourcesOfFundsData,
                });
            }
        );

        let respondentCapitalExpenditure = process.env.REACT_APP_RESPONDENT_DASHBOARD_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + `/respondentdashboardservice/capitalexpenditures/organisation/${organisationId}/surveycycle/${this.state.surveyCycleId}`
        let respondentCapitalExpenditureURL = axios.create({ baseURL: respondentCapitalExpenditure });

        respondentCapitalExpenditureURL.get('').then(
            resp => {

                const respData = resp.data;

                let respondentCapitalExpenditureData = [];

                if (respData.status === "SUCCESS") {
                    respData.response.success.forEach(record => {

                        respondentCapitalExpenditureData.push(record);

                    });
                }
                this.setState({
                    respondentCapitalExpenditureJSON: respondentCapitalExpenditureData,

                });
            }
        );

        let respondentProvincialExpenditure = process.env.REACT_APP_RESPONDENT_DASHBOARD_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + `/respondentdashboardservice/provincialexpenditures/organisation/${organisationId}/surveycycle/${this.state.surveyCycleId}`
        let respondentProvincialExpenditureURL = axios.create({ baseURL: respondentProvincialExpenditure });

        respondentProvincialExpenditureURL.get('').then(
            resp => {
                const respData = resp.data;

                let provincialExpendituresData = [];

                if (respData.status === "SUCCESS") {
                    respData.response.success.forEach(record => {

                        provincialExpendituresData.push(record);
                    });
                }
                this.setState({
                    provincialExpendituresJSON: provincialExpendituresData,

                });
            }
        );

        let respondentCategoriesofRnDExpenditure = process.env.REACT_APP_RESPONDENT_DASHBOARD_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + `/respondentdashboardservice/categoriesofrndexpenditures/organisation/${organisationId}/surveycycle/${this.state.surveyCycleId}`
        let respondentCategoriesofRnDExpenditureURL = axios.create({ baseURL: respondentCategoriesofRnDExpenditure });

        respondentCategoriesofRnDExpenditureURL.get('').then(
            resp => {
                const respData = resp.data;
                let categoriesofRnDExpenditure = [];

                if (respData.status == "SUCCESS") {
                    respData.response.success.forEach(record => {
                        categoriesofRnDExpenditure.push(record);

                    });
                }

                this.setState({
                    categoriesofRnDExpenditureJSON: categoriesofRnDExpenditure,
                });
            }
        );

        let respondentHeadCounts = process.env.REACT_APP_RESPONDENT_DASHBOARD_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + `/respondentdashboardservice/headcounts/organisation/${organisationId}/surveycycle/${this.state.surveyCycleId}`
        let respondentHeadCountsURL = axios.create({ baseURL: respondentHeadCounts });

        respondentHeadCountsURL.get('').then(
            resp => {
                const respData = resp.data;
                let headcountsResponse = [];

                if (respData.status == "SUCCESS") {
                    if (respData.response.success != null) {
                        respData.response.success.forEach(record => {
                            headcountsResponse.push(record);

                        });
                    }
                }

                this.setState({
                    headcounts: headcountsResponse,
                });
            }
        );


        let respondentFTECounts = process.env.REACT_APP_RESPONDENT_DASHBOARD_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + `/respondentdashboardservice/fulltimeequivalents/organisation/${organisationId}/surveycycle/${this.state.surveyCycleId}`
        let respondentFTEURL = axios.create({ baseURL: respondentFTECounts });

        respondentFTEURL.get('').then(
            resp => {
                const respData = resp.data;
                let ftecountResponse = [];

                if (respData.status == "SUCCESS") {
                    if (respData.response.success != null) {
                        respData.response.success.forEach(record => {
                            ftecountResponse.push(record);

                        });
                    }
                }

                this.setState({
                    ftecounts: ftecountResponse,
                });
            }
        );

        
        let surveyCycles = process.env.REACT_APP_RESPONDENT_DASHBOARD_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + `/respondentdashboardservice/surveycycles`
        let surveyCyclesURL = axios.create({ baseURL: surveyCycles });

        surveyCyclesURL.get('').then(
            resp => {
                const respData = resp.data;
                let surveysResponse = [];

                if (respData.status == "SUCCESS") {
                    if (respData.response.success != null) {
                        respData.response.success.forEach(record => {
                            surveysResponse.push(record);

                        });
                    }
                }

                this.setState({
                    surveyCycles: surveysResponse,
                });
            }
        );

        let organisationNameUrl = process.env.REACT_APP_SURVEY_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + "/questionnaireservice/questionnairetrackers/organisation/name/" + organisationId;
        let sectorNameUrl = process.env.REACT_APP_SURVEY_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + "/questionnaireservice/questionnairetrackers/sector/name/" + sectorId;

        if (organisationId) {

            axios.get(organisationNameUrl)
                .then(response => {
                    localStorage.setItem('organisationName', response.data.name);
                });
        }

        if (sectorId) {

            axios.get(sectorNameUrl)
                .then(response => {
                    localStorage.setItem('sectorName', response.data.name);
                });
        }

        this.setState({ loading: false });
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {

  
            let surveyCycles = this.state.surveyCycles;

            return (
                <div className={styles.dashboard_overview_container}>
                    <div className={styles.breadcrumbs}>
                    <Chip variant='outlined' icon={<HomeIcon />}
  label="Data Overview"
/>
                    </div>
                    <div classNam={styles.flex_row_container}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="survey-cycle-select-label">Survey Cycle</InputLabel>
                            <Select
                                labelId="survey-cycle-select-label"
                                id="survey-cycle-select"
                                value={this.state.surveyCycleId}
                                label="Survey Cycle"
                                onChange={this.handleSurveyCycleChange}
                            >
                                {surveyCycles.map(surveyCycle => {
                                    return (
                                        <MenuItem value={surveyCycle.Name}>{surveyCycle.Value}</MenuItem>
                                    );


                                })}


                            </Select>
                        </FormControl>
                    </div>
                    <div className={styles.flex_row_container}>
                        <BarChartWidget
                            headerTitle="International Sources of Funds (%)"
                            data={this.state.internationalSourcesOfFundsJSON}
                            toolTip="Your organisation’s percentage breakdown of R&D funding by region of the world"
                            headerTitleYear=" "
                            dateToolTip="Charts display data only when survey for reference year ist submitted."
                            xAxis="Name"
                            dataKey1="Value"
                        />

                        <PieChartWidget
                            headerTitle="R&D Capital Expenditure (R)"
                            toolTip="Your organisation’s spend on key R&D capital items"
                            headerTitleYear=" "
                            dateToolTip="Charts display data only when survey for reference year is submitted."
                            data={this.state.respondentCapitalExpenditureJSON}
                            fillColor="orange"
                            dataKey="Value"
                            dataLabel="Name"
                        />
                    </div>

                    <div className={styles.flex_row_container}>
                    <BarChartWidget
                            headerTitle="Provincial Capital Expenditure (%)"
                            data={this.state.provincialExpendituresJSON}
                            toolTip="Your organisation’s geographic breakdown of R&D capital expenditure"
                            headerTitleYear=" "
                            dateToolTip=""
                            xAxis="Name"
                            dataKey1="Value"
                        />
                      
                      <BarChartWidget
                            headerTitle="Categories of R&D Expenditure (%)"
                            data={this.state.categoriesofRnDExpenditureJSON}
                            toolTip="Your organisation’s breakdown of R&D types"
                            headerTitleYear=" "
                            dateToolTip=""
                            xAxis="Name"
                            dataKey1="Value"
                        />

                       
                    </div>
                    <div className={styles.flex_row_container}>
                        <BarChartWidget
                            headerTitle="Head Counts"
                            data={this.state.headcounts}
                            toolTip="Your organisation’s headcount of R&D personnel including researchers, technicians and other support staff directly supporting R&D either part- or full-time."
                            headerTitleYear=" "
                            dateToolTip=""
                            xAxis="Name"
                            dataKey1="Value"
                        />

                        <BarChartWidget
                            headerTitle="Full-time Equivalent"
                            data={this.state.ftecounts}
                            toolTip="Your organisation’s full-time equivalent R&D personnel including researchers, technicians and other support staff directly supporting R&D"
                            headerTitleYear=" "
                            dateToolTip=""
                            xAxis="Name"
                            dataKey1="Value"
                        />
                    </div>
                </div>
            )
        }
    }
}




export default Dashboard

