// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".researcherdashboard_dashboard_container__KGumY{\r\n    display: flex;\r\n    align-content: flex-start; \r\n    flex-direction: row;\r\n    grid-gap: 4rem;\r\n    gap: 4rem;\r\n    align-items: flex-start;\r\n}\r\n\r\n.researcherdashboard_dashboard_content__vzzDS{\r\n    display: flex;\r\n    flex: 8 1;\r\n    flex-direction: column;\r\n    grid-gap: 0.65rem;\r\n    gap: 0.65rem;\r\n}\r\n\r\n.researcherdashboard_link__2SuJ7{\r\n    text-decoration: none;\r\n    color: inherit;\r\n}", "",{"version":3,"sources":["webpack://src/pages/ResearcherDashboard/researcherdashboard.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,cAAS;IAAT,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,SAAO;IACP,sBAAsB;IACtB,iBAAY;IAAZ,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".dashboard_container{\r\n    display: flex;\r\n    align-content: flex-start; \r\n    flex-direction: row;\r\n    gap: 4rem;\r\n    align-items: flex-start;\r\n}\r\n\r\n.dashboard_content{\r\n    display: flex;\r\n    flex: 8;\r\n    flex-direction: column;\r\n    gap: 0.65rem;\r\n}\r\n\r\n.link{\r\n    text-decoration: none;\r\n    color: inherit;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_container": "researcherdashboard_dashboard_container__KGumY",
	"dashboard_content": "researcherdashboard_dashboard_content__vzzDS",
	"link": "researcherdashboard_link__2SuJ7"
};
export default ___CSS_LOADER_EXPORT___;
