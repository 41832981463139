
import React from 'react';
import { Lightbulb, Notes } from "@mui/icons-material";
import Separator from "@components/Separator";


import styles from './instructionwidget.module.css';

export default function InstructionWidget(props) {



  return (
    <React.Fragment>
      <div className={styles.sections_definitions}>
        <div className={styles.sections_definitions_left}>
          <Notes style={{ color: '#FFD476' }} />

          <h2 className="title__16px__instruction"> Instruction </h2>
        </div>
        <Separator />
        <p className={styles.sections_text}>
          {props.text}
        </p>

      </div>
    </React.Fragment>
  )
}