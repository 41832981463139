import {React,useState} from "react";

import SpinnerModal from '@components/SpinnerModal';


import Questionnaire from "@components/Questionnaire";
import default_image from "@images/Research/default_image.png";
import styles from './previoussurvey.module.css';
import DataCaptureService from '@services/DataCaptureService';
import ReactToPrint from 'react-to-print';
import { CustomDialog, useDialog } from 'react-st-modal';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Box } from "@mui/material";


import Chip from '@mui/material/Chip';
import FolderIcon from '@mui/icons-material/Folder';

function PreviousSurvey() {

  const [previousSurveys, setPreviousSurveys] = useState(null);
  const [previousSurveysData, setPreviousSurveysData] = useState(null);
  const [selectedOrganisationStatus, setSelectedOrganisationStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  let surveyID = localStorage.getItem("SurveyId");
  let bearer = localStorage.getItem('Bearer');
  if (!bearer) {
    window.location = "/login";

  }

  function parseJwt(token) {
    var base64Payload = token.split('.')[1];
    var payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
  }

  let payload = parseJwt(bearer);
  let orgID = payload.org_id;
  let sector_id = payload.sector_id;

  async function fetchData() {
    try {
      const result = await DataCaptureService.getAllSurveysByOrganisationIdAndSectorId(orgID, sector_id);

      let fromSurveyCycle = process.env.REACT_APP_BUS_PREVIOUS_SURVEYS_DISPLAY_FROM_SURVEY_CYCLE;

      fromSurveyCycle = (sector_id == 1) ? process.env.REACT_APP_BUS_PREVIOUS_SURVEYS_DISPLAY_FROM_SURVEY_CYCLE : fromSurveyCycle;
      fromSurveyCycle = (sector_id == 2 || sector_id == 4 || sector_id == 5) ? process.env.REACT_APP_GOV_NPO_SCI_PREVIOUS_SURVEYS_DISPLAY_FROM_SURVEY_CYCLE : fromSurveyCycle;
      fromSurveyCycle = (sector_id == 3) ? process.env.REACT_APP_HE_PREVIOUS_SURVEYS_DISPLAY_FROM_SURVEY_CYCLE : fromSurveyCycle;

      if (result) {
        let previousSurveys = result.sort((a, b) => a.id > b.id ? -1 : 1).slice(0, parseInt(process.env.REACT_APP_RESPONDENT_PREVIOUS_SURVEYS_DISPLAY_COUNT))
          .filter(survey => (survey.surveyCycleId >= fromSurveyCycle)).filter(survey => parseInt(survey.surveyCycleId) !== parseInt(process.env.REACT_APP_CURRENT_SURVEY_CYCLE));                                // this line prevents any surveys before 2016/17

        const currentSurvey = result.filter(survey => parseInt(survey.surveyCycleId) === parseInt(process.env.REACT_APP_CURRENT_SURVEY_CYCLE))[0];
        if (currentSurvey !== undefined && currentSurvey !== null) {

          const organisationStateId = currentSurvey.id;

          const orgStatus = await (async () => {
            const organisationStatus = await DataCaptureService.getOrganisationStatusByOrganisationStateId(organisationStateId);
            return organisationStatus;
          })([]);

          if (orgStatus !== undefined && orgStatus !== null) {
            if (orgStatus.datacapture1Completed === true && orgStatus.datacapture2Completed === true) {
              previousSurveys.push(currentSurvey);
              previousSurveys.sort((a, b) => a.id > b.id ? -1 : 1)
            }
          }
        }

        setPreviousSurveys(previousSurveys);
        setPreviousSurveysData(previousSurveys);
      } else {
        setPreviousSurveysData(null);
      }

      setLoading(false);
    } catch (error) {
      setPreviousSurveysData(error);
      setLoading(false);
      console.error(error);
    }
  }

  async function handleClickOnPreviousSurvey(evt, title, organisationStateId) {

    const organisationContact = await (async () => {
      const organisationContacts = await DataCaptureService.getAllOrganisationContactsByOrganisationStateId(organisationStateId);
      let contact = organisationContacts.filter(orgContact => ("" + orgContact.email).toLowerCase() == ("" + payload.preferred_username).toLowerCase()).sort((a, b) => a.contactStateId > b.contactStateId ? 1 : -1)[0];
      return contact;
    })([]);


    const res0 = await (async () => {

      const organisationStatus = await DataCaptureService.getOrganisationStatusByOrganisationStateId(organisationStateId);

      setSelectedOrganisationStatus(organisationStatus);
      let defaultContactId = 37;

      const result = await CustomDialog(<QuestionnaireConfirmationDialog organisationContact={defaultContactId} organisationStatus={organisationStatus} title={title.replace(/\s/g, '_')} />, {
        className: styles.printviewDialog,
        title: 'Preview Submitted Questionnaire : ' + title,
        showCloseIcon: false,
        isFocusLock: true,
        isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
      });
    })([]);

  }

  if (previousSurveys === null && previousSurveysData === null) {
    fetchData();
  }


  if (loading) {
    return (
      <SpinnerModal settings={true} />
    );
  } else {

    if (previousSurveys) {

      return (

        <div className={styles.container_surveys}>
           <div className={styles.breadcrumbs}>
                    <Chip variant='outlined' icon={<FolderIcon />}
  label="Previous Surveys"
/>
                    </div>
          <div className={styles.surveys_content}>
            <Grid container rowSpacing={3} columnSpacing={3}>
              {previousSurveys.map((survey) => (
                <Grid item lg={7} key={survey.id}>
                  <Card className={styles.surveyCard}>
                    <CardMedia
                      component="img"
                      height="194"
                      image={default_image}
                      alt="Survey Response"
                      align="left"
                    />
                    <Box>
                      <CardContent>

                        <Typography variant="h5" component="div">
                          {survey.organisationName}
                        </Typography>

                        <Typography variant="body2">
                          {survey.name}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          onClick={(evt) => handleClickOnPreviousSurvey(evt, survey.name.replace(/\s/g, '_'), survey.id)}
                          variant="text"
                          size="small"
                          className={styles.download}>
                           {/* <BiDownload size={40}/> */}
                           Download</Button>
                      </CardActions>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>


          </div>
        </div>
      )
    } else {
      return (
        <div className={styles.container_surveys}>
          <div className={styles.surveys_content}>
            <h3>Organisation does not have previous survey data.</h3>
          </div>
        </div>
      )
    }

  }

}
export default PreviousSurvey;

function QuestionnaireConfirmationDialog(props) {
  const dialog = useDialog();

  let componentRef = null;
  return (
    <div>
      <div
        className={styles.confirmDialogOverlay}
        ref={(response) => (componentRef = response)}
      >
        <Questionnaire
          organisationStatus={props.organisationStatus}
          organisationStateId={props.organisationStatus.organisationStateId}
          capturedById={37}
          filledInById={props.organisationContact}
          captureType={'RESPONDENT'}
          printview={true}
          previousSurvey={true}
        />
      </div>
      <ReactToPrint
        documentTitle={props.title + '.pdf'}
        content={() => componentRef}
        removeAfterPrint={true}
        onAfterPrint={() => {
          dialog.close(true);
        }}
        trigger={() => <button
          type="button"
          className="btnBack"
        >Download
        </button>
        }
      />

    </div>
  );
}

