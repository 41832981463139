// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n\r\n.separator_separator__BZXhQ {\r\n  width: 96%;\r\n  height: 0.5px;\r\n  margin-top: 15px;\r\n  margin-bottom: 21px;\r\n  margin-left: 2%;\r\n  border: 0.5px solid var(--hsrcDarkBlue);\r\n  transform: rotate(180deg); }\r\n\r\n.separator_full__separator__gPkKe {\r\n  width: 100%;\r\n  height: 0.5px;\r\n  margin-top: 15px;\r\n  margin-bottom: 21px;\r\n  margin-left: 0%;\r\n  border: 0.5px solid var(--hsrcDarkBlue);\r\n  transform: rotate(180deg); }\r\n\r\n", "",{"version":3,"sources":["webpack://src/components/Separator/separator.module.css"],"names":[],"mappings":";;AAEA;EACE,UAAU;EACV,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,uCAAuC;EACvC,yBAAyB,EAAE;;AAE7B;EACE,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,uCAAuC;EACvC,yBAAyB,EAAE","sourcesContent":["\r\n\r\n.separator {\r\n  width: 96%;\r\n  height: 0.5px;\r\n  margin-top: 15px;\r\n  margin-bottom: 21px;\r\n  margin-left: 2%;\r\n  border: 0.5px solid var(--hsrcDarkBlue);\r\n  transform: rotate(180deg); }\r\n\r\n.full__separator {\r\n  width: 100%;\r\n  height: 0.5px;\r\n  margin-top: 15px;\r\n  margin-bottom: 21px;\r\n  margin-left: 0%;\r\n  border: 0.5px solid var(--hsrcDarkBlue);\r\n  transform: rotate(180deg); }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": "separator_separator__BZXhQ",
	"full__separator": "separator_full__separator__gPkKe"
};
export default ___CSS_LOADER_EXPORT___;
