import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class OwnershipPercentageQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    classes = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '50ch',
            },
        },

    }));

    async handleOnChange (evt) {
        let key = evt.target.attributes.questionFactId.value + '_' + evt.target.attributes.ordinalEntry.value;
        let value = evt.target.value;

        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
            const result = await CustomDialog(
                <UpdateAuditDialog
                    currentAnswer={SessionStateManager.getSessionValue(key)}
                />,
                {
                    className: styles.updateAuditDialog,
                    title: 'Update Submitted Value',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );

            if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                SessionStateManager.setSessionValue(key, result.newValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                this.setState({ sessionStateChanged: true });
            } else {
                evt.preventDefault();
            }
        } else {
            SessionStateManager.setSessionValue(key, value, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
            this.setState({ sessionStateChanged: true });
        }

        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            return <div className={styles.ownershipQuestionContainer}>
                <table className='table' >
                    <thead>
                        <tr>
                            <th width='70%' >{this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[0].columnDimensionStates[0].dimensionAlias}</th>
                            <th width='30%' >{this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[1].columnDimensionStates[0].dimensionAlias}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                            className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[0].questionFactId + '_0') ? styles.tableInputBoxUpdated : null}
                            >
                                <input
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[0].questionFactId + '_0') ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                    type="text"
                                    value={SessionStateManager.getSessionValue(this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[0].questionFactId + '_0')}
                                    onChange={(evt) => this.handleOnChange(evt)}
                                    onBlur={this.handleOnBlur}
                                    questionId={this.props.questionState.question.id}
                                    ordinalEntry={0}
                                    questionFactId={this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[0].questionFactId}
                                    placeholder={this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[0].columnDimensionStates[0].dimensionAlias}>
                                </input>
                            </td>
                            <td
                            className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[1].questionFactId + '_0') ? styles.tableInputBoxUpdated : null}
                            >
                                <input
                                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[1].questionFactId + '_0') ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                    type="text"
                                    value={SessionStateManager.getSessionValue(this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[1].questionFactId + '_0')}
                                    onClick={(evt) => {
                                        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                                            this.handleOnChange(evt);
                                        }
                                    }}
                                    onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key) || (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE')) { event.preventDefault(); } }}
                                    onChange={(evt) => this.handleOnChange(evt)}
                                    onBlur={this.handleOnBlur}
                                    questionId={this.props.questionState.question.id}
                                    ordinalEntry={0}
                                    questionFactId={this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)[1].questionFactId}
                                    placeholder="%"
                                >
                                </input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
    }
}
export default OwnershipPercentageQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    currentAnswer={props.currentAnswer}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if(
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                        result.confirmed = true;
                        dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}