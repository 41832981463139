import React, { Component } from 'react';
import axios from "axios";

const SURVEY_SERVICE = process.env.REACT_APP_SURVEY_MICROSERVICE_URL + process.env.REACT_APP_SERVICES_VERSION;

export default function SurveyService() {
}

/**
 * getQuestionnaireByOrganisationStateId
 * @param {long} 
 * @returns {map}
 */
export async function getQuestionnaireByOrganisationStateId(organisationStateId) {

    const url = SURVEY_SERVICE + '/questionnaireservice/expanded/questionnaire/organisationstate/' + organisationStateId;
    const questionnaireByOrganisationState = axios.create({ baseURL: url });

    const questionnaire = await questionnaireByOrganisationState.get('')
        .then(response => {
            const data = response.data.response.success

            // Returning the Questionnaire By OrganisationStateId
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (questionnaire != null) {
        return questionnaire;
    }

    return null;
}

/**
 * getQuestionnaireBySurveyCycleIdAndSectorId
 * @param {long, long} 
 * @returns {map}
 */
export async function getQuestionnaireBySurveyCycleIdAndSectorId(surveyCycleId, sectorId) {

    const url = SURVEY_SERVICE + '/questionnaireservice/questionnaires/surveycycle/' + surveyCycleId + '/sector/' + sectorId;
    const questionnaireBySurveyCycleAndSector = axios.create({ baseURL: url });

    const questionnaire = await questionnaireBySurveyCycleAndSector.get('')
        .then(response => {
            const data = response.data.response.success

            // Returning the SurveyCycle By OrganisationStateId
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (questionnaire != null) {
        return questionnaire;
    }

    return null;
}

/**
 * getQuestionnaireIdBySurveyCycleIdAndSectorId
 * @param {long, long} 
 * @returns {map}
 */
export async function getQuestionnaireIdBySurveyCycleIdAndSectorId(surveyCycleId, sectorId) {

    const url = SURVEY_SERVICE + '/questionnaireservice/questionnaireId/surveycycle/' + surveyCycleId + '/sector/' + sectorId;
    const questionnaireIdBySurveyCycleAndSector = axios.create({ baseURL: url });

    const questionnaireId = await questionnaireIdBySurveyCycleAndSector.get('')
        .then(response => {
            const data = response.data.response.success

            // Returning the SurveyCycle By OrganisationStateId
            return data.questionnaireId;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (questionnaireId !== null) {
        return questionnaireId;
    }

    return null;
}


/**
 * getSurveyCycleByOrganisationStateId
 * @param {long} organisationStateId - Organisation State ID
 * @returns {map}
 */
export async function getSurveyCycleByOrganisationStateId(organisationStateId) {

    const url = SURVEY_SERVICE + '/questionnaireservice/surveycycle/organisationstateid/' + organisationStateId;
    const surveyCycleByOrganisationStateId = axios.create({ baseURL: url });

    const surveyCycle = await surveyCycleByOrganisationStateId.get('')
        .then(response => {
            const data = response.data.response.success

            // Returning the SurveyCycle By OrganisationStateId
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (surveyCycle != null) {
        return surveyCycle;
    }

    return null;
}

/**
 * getSectorById
 * @param {long} sectorId - Sector ID
 * @returns {map}
 */
export async function getSectorById(sectorId) {

    const url = SURVEY_SERVICE + '/questionnaireservice/sector/' + sectorId;
    const sectorById = axios.create({ baseURL: url });

    const sector = await sectorById.get('')
        .then(response => {
            const data = response.data.response.success

            // Returning the sector by sectorId
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (sector != null) {
        return sector;
    }

    return null;
}

/**
 * getAllSectors
 * @param NONE
 * @returns {map}
 */
export async function getAllSectors() {

    const url = SURVEY_SERVICE + '/questionnaireservice/sector/all';
    const sectors_url = axios.create({ baseURL: url });

    const sectors = await sectors_url.get('')
        .then(response => {
            const data = response.data.response.success

            // Returning the sector by sectorId
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (sectors != null) {
        return sectors;
    }

    return null;
}

/**
 * getAllSurveyCycles
 * @param NONE
 * @returns {map}
 */
export async function getAllSurveyCycles() {

    const url = SURVEY_SERVICE + '/questionnaireservice/surveycycle/all';
    const cycles = axios.create({ baseURL: url });

    const surveyCycles = await cycles.get('')
        .then(response => {
            const data = response.data.response.success

            // Returning all survey cycles
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (surveyCycles != null) {
        return surveyCycles;
    }

    return null;
}

/**
 * getAllSurveyCycleById
 * @param NONE
 * @returns {map}
 */
export async function getSurveyCycleById(surveyCycleId) {

    const url = SURVEY_SERVICE + '/questionnaireservice/surveycycle/' + surveyCycleId;
    const cycles = axios.create({ baseURL: url });

    const surveyCycles = await cycles.get('')
        .then(response => {
            const data = response.data.response.success

            // Returning all survey cycles
            return data;
        })
        .catch((e) => {
            console.error('There was an error!', e);
        });

    if (surveyCycles != null) {
        return surveyCycles;
    }

    return null;
}

export async function saveRecordsDispatchList(body) {

    let url = SURVEY_SERVICE + '/questionnaireservice/questionnairedispatch/recorddispatch';

    const headers = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
    };

    const result = await axios.post(url, body, { headers })
        .then(response => {
            return response.data
        })
        .catch((e) => {
            console.error('There was an error!', e);
            return 'User update error'
        });

    return result
}
