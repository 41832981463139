import { React, Component } from "react";
import { Link } from 'react-router-dom';

import { Download, StickyNote2 } from '@mui/icons-material';



import Signup from "@components/Signup";
import Separator from "@components/Separator";
import Footer from "@components/Footer";
import NavigationBar from "@components/NavigationBar";
import Contact from "@components/Contact";
import { hsrcLogoInstance, statsSALogoInstance, dsiLogoInstance } from "@components/Globals";
import styles from "./about.module.css";
import {BrowserView, MobileView} from 'react-device-detect';
import AboutMobile from "./AboutMobile.js";

class About extends Component {

    render() {
        return (
                                    <>
                                    <BrowserView>
            <div>
                <NavigationBar />

                <div className={styles.container_about}>

                    <div style={{ marginBottom: 60 }}>
                        <h1 className="title__54px">

                            <text className="title__54px">National Survey </text>

                            <text className="title__54px__orange">Of Research & Experimental Development </text>

                        </h1>



                        <text className="text__18px">
                            The Centre for Science, Technology and Innovation Indicators (CeSTII), within the Human Sciences Research Council (HSRC), conducts the annual National Survey of Inputs into Research and Experimental Development (R&D) for the Department of Science and Innovation (DSI).


                        </text>
                    </div>

                    <div style={{ marginBottom: 60 }} >
                        <a href={'http://archivesite.hsrc.ac.za/uploads/pageContent/1047111/R&D_StatisticalReport2020-21_WEB%20[FINAL].pdf'} target="_blank" download>
                            <button variant="contained" className="button"
                                sx={{ verticalAlign: "bottom", fontSize: 25, marginRight: "5px" }}>
                                <Download />
                                Download Latest Survey report
                            </button>
                        </a>

                        <Link to='/expressinterest'>
                            <button variant="contained" className="button__orange">
                                <StickyNote2 sx={{ verticalAlign: "bottom", fontSize: 25, marginRight: "5px" }} />
                                Express Interest to Participate in R&D Survey
                            </button>
                        </Link>
                    </div>


                    <div className={styles.imageGroup_department_logos} style={{ marginBottom: 60 }} >
                        <img className={styles.logos} src={dsiLogoInstance} />
                        <img className={styles.logos} src={hsrcLogoInstance} />

                        <img className={styles.logos} src={statsSALogoInstance} />


                    </div>





                    <div className={styles.about_info}>

                        <div className={styles.about_info_text}>
                            <h1 className="title__32px">

                                <text className="title__32px">Authority & </text>
                                <text className="title__32px__orange">Confidentiality</text>

                            </h1>


                            <text className="text__18px">

                                The R&D Survey is conducted in terms of the Statistics Act No. 6 of 1999. Organisations are therefore legally required to respond by providing accurate data on R&D performance.
                                <br /><br />

                                All data gathered for this survey are confidential. Only the survey team have access to individual organisation data



                                <br /><br />

                                The HSRC and DSI will not disseminate any information identifiable with an organisation without their consent.
                                <br /><br />
                                <p>
                                    <Link to='/confidentiality' style={{ color: '#FF7F50', fontWeight: 'bold' }}>Confidentiality & Security</Link>
                                </p>
                            </text>

                        </div>
                        <div className={styles.about_info_text}>

                            <h1 className="title__32px">

                                <text className="title__32px">Purpose & </text>
                                <text className="title__32px__orange">Scope of Survey</text>

                            </h1>

                            <text className="text__18px">

                                The R&D Survey team collects data on the inputs into intramural (in-house) R&D activities performed in South Africa by all organisations (including business, government, science councils, not-for-profit and higher education).

                                <br /><br />
                                The data is used for planning and monitoring purposes and to support decisions that strengthen South Africa’s competitiveness.
                                <br /><br />

                                <p>
                                    <Link to='/faq' style={{ color: '#FF7F50', fontWeight:'bold' }}>Frequently Asked Questions</Link>
                                </p>
                            </text>
                        </div>

                    </div>

                    <div className={styles.about_signup_block}>
                        <h1 className="title__42px">
                            Find out More
                        </h1>
                        <Signup />
                    </div>


                </div>









                <Separator />
                <Contact />
                <Separator />
                <Footer />

            </div >
                        </BrowserView>
                        <MobileView>
                        {AboutMobile()}
                        </MobileView>
                        </>
        );
    }

}
export default About;