// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".references_references_container__1oUHg{\r\n    display: flex; \r\n    flex-direction: row;\r\n    align-items: flex-start ; \r\n    flex: 1 1; \r\n    align-items: flex-start;\r\n    align-content: flex-start;\r\n    justify-content: space-between;\r\n}\r\n\r\n\r\n\r\n.references_reference_logo__EmWet {\r\n    width: 184px;\r\n    height: 102px; }", "",{"version":3,"sources":["webpack://src/components/References/references.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,wBAAwB;IACxB,SAAQ;IACR,uBAAuB;IACvB,yBAAyB;IACzB,8BAA8B;AAClC;;;;AAIA;IACI,YAAY;IACZ,aAAa,EAAE","sourcesContent":[".references_container{\r\n    display: flex; \r\n    flex-direction: row;\r\n    align-items: flex-start ; \r\n    flex: 1 ; \r\n    align-items: flex-start;\r\n    align-content: flex-start;\r\n    justify-content: space-between;\r\n}\r\n\r\n\r\n\r\n.reference_logo {\r\n    width: 184px;\r\n    height: 102px; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"references_container": "references_references_container__1oUHg",
	"reference_logo": "references_reference_logo__EmWet"
};
export default ___CSS_LOADER_EXPORT___;
