import React, { useState, Component } from 'react';
import MUIDataTable from 'mui-datatables';
import DataCaptureService from '@services/DataCaptureService';
import SurveyService from '@services/SurveyService';
import DropDownMenu from '@components/DropDownMenu';
import CSVGenerator from '@components/CSVGenerator';
import SpinnerModal from '@components/SpinnerModal';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import DespatchDialog from '@components/DespatchDialog';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
    Telegram,
} from "@material-ui/icons";
import styles from './despatch.module.css';

class Despatch extends Component {
    state = {
        loading: false,
        organisationsLoading: false,
        allOrganisationStatuses: [],
        contributionTypes: [],
        organisationStatusTypes: [],
        allSectorsDropdown: [],
        selectedSectorId: null,
    }

    constructor() {
        super();
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.reloadSectorDropdown();

        const lookups = await DataCaptureService.getLookups();

        let contributionTypes = [];
        lookups.filter((lookup) => (lookup.type.includes('CONTRIBUTION_STATUS'))).map(item => {
            contributionTypes.push({ key: item.id, value: item.name });
        });
        this.setState({ contributionTypes: contributionTypes });

        let organisationStatusTypes = [];
        lookups.filter((lookup) => (lookup.type.includes('ORGANISATION_STATUS'))).map(item => {
            organisationStatusTypes.push({ key: item.id, value: item.name });
        });
        this.setState({ organisationStatusTypes: organisationStatusTypes });

        this.setState({ loading: false });
    }

    async reloadSectorDropdown() {
        this.setState({ loading: true });
        try {
            const sectors = await SurveyService.getAllSectors();
            sectors.map(item => this.state.allSectorsDropdown.push({ key: item.id, value: item.name.trim() }));
        } catch (ex) {
            console.log('Network error loading sectors');
        }
        this.setState({ loading: false });
    }

    async reloadOrganisationStatusses(sectorId) {
        this.setState({ organisationsLoading: true });
        try {
            const organisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId_including_non_uom(sectorId, process.env.REACT_APP_CURRENT_SURVEY_CYCLE);
            this.setState({ allOrganisationStatuses: organisationStatuses });
        } catch (ex) {
            console.log('Network error loading organisation statusses');
        }
        this.setState({ organisationsLoading: false });
    }

    onChangeSector = (evt) => {
        this.setState({ selectedSectorId: evt.target.value });
        this.reloadOrganisationStatusses(evt.target.value);
    }

    async onDespatchSave(evt, dispatchRecords, organisationStateId) {
        const finalResult = await (async () => {
            const result = await SurveyService.saveRecordsDispatchList(dispatchRecords);
            return result;
        })([]);

        const confirmed = await (async () => {
            if (finalResult !== undefined && finalResult !== null && finalResult == 'SUCCESS') {

                const organisationStatuses = this.state.allOrganisationStatuses;
                organisationStatuses.filter((orgStatus) => orgStatus.organisationStateId == organisationStateId)[0].dispatched = true;
                this.setState({ allOrganisationStatuses: organisationStatuses });

                return await SupportAlert.displayAlert('Record despatch successful.', 'Record Despatch - Success');

            } else {

                return await SupportAlert.displayAlert('Record despatch unsuccessful. Please reload page and try again.', 'Record Despatch - Failed');
            }
        })([]);
    }

    async handleShowDespatchDialog(evt, organisationStatus) {
        const result = await CustomDialog(
            <DespatchDialog
                {...this.props}
                backButton={{ text: 'Cancel' }}
                nextButton={{ text: 'Save', onClick: (evt, data, organisationStateId) => this.onDespatchSave(evt, data, organisationStateId) }}
                organisationStatus={organisationStatus}
            />,
            {
                className: styles.recordDespatchDialog,
                title: 'Record Despatch: ' + organisationStatus.organisationStateName,
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );

    }

    organisationsTable() {

        let tableTitle = ('Organisations in ' + process.env.REACT_APP_CURRENT_SURVEY_CYCLE_YEAR_LABEL + ' frame').toUpperCase();

        let organisationsTableData = [];

        let organisationColumns = [
            {
                label: "Organisation State ID", name: "organisationStateId"
                , options: {
                    display: false,
                }
            },
            { label: "Organisation Name", name: "organisationName" },
            { label: "Receipt Number", name: "receiptNumber" },
            { label: "Organisation Status", name: "organisationStatus" },
            { label: "Contribution Status", name: "contributionStatus" },
            { label: "Unit of Measure", name: "unitOfMeasure" },
            { label: "Despatched", name: "despatched" },
            { label: "Actions", name: "actions" },
        ];

        let rowId = 0;
        if (this.state.allOrganisationStatuses !== undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length >= 0) {
            let sectorOrganisations = this.state.allOrganisationStatuses.filter(orgStatus => orgStatus.sectorId == this.state.selectedSectorId);
            sectorOrganisations.sort((a, b) => a.organisationStateName > b.organisationStateName ? 1 : -1).map(organisationStatus => {

                let row = {
                    organisationStateId: organisationStatus.organisationStateId,
                    organisationName: organisationStatus.organisationStateName,
                    receiptNumber: organisationStatus.receiptNumber,
                    unitOfMeasure: (organisationStatus.unitOfMeasure !== undefined && organisationStatus.unitOfMeasure !== null && organisationStatus.unitOfMeasure === true)
                        ? <div className={styles.centeredIconLabel} >
                            <CheckCircleOutlineIcon
                                id={'uomCheckCircle_' + organisationStatus.organisationStateId}
                                style={{ color: '#4caf50' }}
                            />
                            <label
                                style={{ color: '#4caf50' }}
                                for={'uomCheckCircle_' + organisationStatus.organisationStateId}>
                                &nbsp;&nbsp;{'Yes'}
                            </label>
                        </div>
                        : <div className={styles.centeredIconLabel} >
                            <CancelOutlinedIcon
                                id={'uomCancelOutlineIcon_' + organisationStatus.organisationStateId}
                                style={{ color: '#ff3407' }}
                            />
                            <label
                                style={{ color: '#ff3407' }}
                                for={'uomCancelOutlineIcon_' + organisationStatus.organisationStateId}>
                                &nbsp;&nbsp;{'No'}
                            </label>
                        </div>,
                    organisationStatus: this.state.organisationStatusTypes.filter(type => type.key == organisationStatus.organisationStatus)[0]?.value,
                    contributionStatus: this.state.contributionTypes.filter(type => type.key == organisationStatus.contributionStatus)[0].value,
                    despatched: (organisationStatus.dispatched !== undefined && organisationStatus.dispatched !== null && organisationStatus.dispatched === true)
                        ? <div className={styles.centeredIconLabel} >
                            <CheckCircleOutlineIcon
                                id={'dispatchCheckCircle_' + organisationStatus.organisationStateId}
                                style={{ color: '#4caf50' }}
                            />
                            <label
                                style={{ color: '#4caf50' }}
                                for={'dispatchCheckCircle_' + organisationStatus.organisationStateId}>
                                &nbsp;&nbsp;{'Yes'}
                            </label>
                        </div>
                        : <div className={styles.centeredIconLabel} >
                            <CancelOutlinedIcon
                                id={'dispatchCancelOutlineIcon_' + organisationStatus.organisationStateId}
                                style={{ color: '#ff3407' }}
                            />
                            <label
                                style={{ color: '#ff3407' }}
                                for={'dispatchCancelOutlineIcon_' + organisationStatus.organisationStateId}>
                                &nbsp;&nbsp;{'No'}
                            </label>
                        </div>,
                    actions:
                        (organisationStatus.organisationStatus != 10 || (organisationStatus.unitOfMeasure !== undefined && organisationStatus.unitOfMeasure !== null && organisationStatus.unitOfMeasure === false))
                            ? <Chip
                                variant="outlined"
                                icon={<Telegram />}
                                label="Despatch"
                                disabled
                            />
                            : <Chip
                                variant="outlined"
                                icon={<Telegram />}
                                label="Despatch"
                                onClick={evt => this.handleShowDespatchDialog(evt, organisationStatus)}
                            />
                }
                organisationsTableData.push(row);
            });

        }

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [10],
            rowsPerPageOptions: [10, 20, , 50, 100, 200],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            row.data.shift();
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        return <div className={styles.flex_row_container}>
            <MUIDataTable
                title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                data={organisationsTableData}
                columns={organisationColumns}
                options={options}
            />
        </div>
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} label={'Page loading ...'} />
        } else {
            return (
                <div className={styles.container_despatch} >
                    <div className={styles.featuredItem}>
                        <span className={styles.featuredTitleHeader}><h2>{'Record Despatch'}</h2></span>
                        <hr />
                        <Grid container item xs={15} >
                            <DropDownMenu
                                name={'sector'}
                                label={'Sector'}
                                onChange={(evt) => this.onChangeSector(evt)}
                                showKeys={false}
                                menuOptions={this.state.allSectorsDropdown.sort((a, b) => a.value > b.value ? 1 : -1)}
                                placeholder='Select Sector'
                                required
                            />
                        </Grid>
                    </div>
                    <div className={styles.featuredItem}>
                        <Grid container item xs={15} >
                            {this.state.organisationsLoading === true
                                ? <SpinnerModal settings={true} label={'Organisations loading ...'} />
                                : this.organisationsTable()
                            }
                        </Grid>
                    </div>
                </div>
            )
        }
    }
}
export default Despatch;