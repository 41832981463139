import React, { Component, useState } from "react";
import TextField from '@mui/material/TextField';
import DataCaptureService from "@services/DataCaptureService";
import ProfileManagementService from "@services/ProfileManagementService";
import DropDownMenu from "@components/DropDownMenu";
import SpinnerModal from "@components/SpinnerModal";
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from './interactionlogdialog.module.css';

class InteractionLogDialog extends Component {
    state = {
        loading: true,
        interactionTypes: [],
        surveysUsers: [],
        surveyCritical: (this.props.organisationStatus.surveyCritical !== undefined && this.props.organisationStatus.surveyCritical !== null && this.props.organisationStatus.surveyCritical === true) ? true : false,
        respondentInteraction: {
            organisationStateId: this.props.organisationStatus.organisationStateId,
            contactId: null,
            interactionType: null,
            interactionStatus: '',
            returnType: '',
            returnStatus: '',
            finalStatus: '',
            esqFiled: false,
            thankYouSent: false,
            comment: '',
            interactionDate: null,
            originalFilename: null,
            mimeType: null,
            mediaContentId: null,
            loggedBy: null,
            interactedBy: null,
        }
    }

    constructor(props) {
        super(props);
    }

    parseJwt(token) {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }

    async componentDidMount() {
        this.setState({ loading: true });

        let bearer = localStorage.getItem('Bearer');
        if (!bearer){
            window.location = "/login";
        
          }

        let payload = this.parseJwt(bearer);

        let respondentInt = this.state.respondentInteraction;
        respondentInt.loggedBy = payload.user_id;
        respondentInt.interactedBy = payload.user_id;
        this.setState({ respondentInteraction: respondentInt });

        const sourcesOfData = await DataCaptureService.getLookups();

        const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
        this.setState({ surveysUsers: surveysUsers });

        let interactionTypes = [];
        sourcesOfData.filter((lookup) => (lookup.type.includes('_INTERACTION_') || lookup.type.includes('_RETURN_') || lookup.type.includes('FINAL_STATUS'))).map(item => {
            interactionTypes.push({ key: item.varenum, value: item.name });
        });

        this.setState({ interactionTypes: interactionTypes });

        this.setState({ loading: false });
    }

    onChangeInteractionContact = (evt) => {
        let interaction = this.state.respondentInteraction;
        interaction.contactId = evt.target.value;
        this.setState({ respondentInteraction: interaction });
    }

    onChangeInteractionType = (evt) => {
        let interaction = this.state.respondentInteraction;
        interaction.interactionType = evt.target.value;
        this.setState({ respondentInteraction: interaction });
    }

    onChangeInteractionStatus = (evt) => {
        let interaction = this.state.respondentInteraction;
        interaction.interactionStatus = evt.target.value;
        this.setState({ respondentInteraction: interaction });
    }

    onChangeComment = (evt) => {
        let interaction = this.state.respondentInteraction;
        interaction.comment = evt.target.value;
        this.setState({ respondentInteraction: interaction });
    }

    onChangeESQ = (evt) => {
        let interaction = this.state.respondentInteraction;
        interaction.esqFiled = evt.target.value;
        this.setState({ respondentInteraction: interaction });
    }

    onChangeReturnType = (evt) => {
        let interaction = this.state.respondentInteraction;
        interaction.returnType = evt.target.value;
        interaction.returnStatus = '';
        this.setState({ respondentInteraction: interaction });
    }

    onChangeReturnStatus = (evt) => {
        let interaction = this.state.respondentInteraction;
        interaction.returnStatus = evt.target.value;
        this.setState({ respondentInteraction: interaction });
    }

    onChangeFinalStatus = (evt) => {
        let interaction = this.state.respondentInteraction;
        interaction.finalStatus = evt.target.value;
        this.setState({ respondentInteraction: interaction });
    }

    onChangeThankYouSent = (evt) => {
        let interaction = this.state.respondentInteraction;
        interaction.thankYouSent = evt.target.value;
        this.setState({ respondentInteraction: interaction });
    }

    onSurveyCriticalChanged = (evt, newValue) => {
        this.setState({ surveyCritical: newValue });
    }

    getContactNameByContactStateId(contactStateId) {
        let orgContacts = this.props.organisationStatus.organisationContactList;

        let contact = (contactStateId != null && orgContacts !== undefined && orgContacts != null && orgContacts.length > 0)
            ? orgContacts.filter(contact => contact.contactStateId == contactStateId)[0]
            : null;

        return contact != null ? (contact.title + ' ' + contact.firstname + ' ' + contact.lastname) : 'No selected contact';
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            let organisationContacts = [];

            this.props.organisationStatus.organisationContactList.map(item => {
                if (!organisationContacts.some(element => element.key === item.contactId)) {
                    organisationContacts.push({ key: item.contactId, value: this.getContactNameByContactStateId(item.contactStateId) });
                }
            });

            let contact = this.props.organisationStatus !== undefined && this.props.organisationStatus !== null && this.props.organisationStatus.filledInBy !== undefined && this.props.organisationStatus.filledInBy !== null
                ? this.props.organisationStatus.organisationContactList.filter(contact => contact.contactStateId == this.props.organisationStatus.filledInBy)[0]
                : null;

            return <DialogWrapper {...this.props} onClick={(evt) => this.props.nextButton.onClick(evt, this.state.respondentInteraction, this.state.surveyCritical)} respondentInteraction={this.state.respondentInteraction} content={
                <React.Fragment>
                    <div className={styles.featuredPopupDialogItem}>
                        <div className={styles.featuredItem} >
                            <span><h1 className={styles.featuredTitleHeader}>{'Organisation Details'}</h1></span>
                            <div className={styles.featuredItem} >
                                {'Organisation name : ' + ((this.props.organisationStatus.parentOrganisationStateName !== undefined && this.props.organisationStatus.parentOrganisationStateName !== null && this.props.organisationStatus.parentOrganisationStateName !== '') ? this.props.organisationStatus.parentOrganisationStateName + ' / ' : '') + this.props.organisationStatus.organisationStateName}<br />
                                {'Receipt number : ' + (this.props.organisationStatus.receiptNumber !== undefined && this.props.organisationStatus.receiptNumber !== null ? this.props.organisationStatus.receiptNumber : 'Not received')}<br />
                                {'Researcher : ' + this.props.organisationStatus.selectedResearcher}<br />
                                <div>
                                    {<label for={"checkbox_survey_critical"}>{'Survey Critical Firm/Unit: '} &nbsp;&nbsp;</label>}
                                    <input
                                        type="checkbox"
                                        className="radio"
                                        id={"checkbox_survey_critical"}
                                        value={this.state.surveyCritical}
                                        checked={this.state.surveyCritical}
                                        onChange={(evt) => this.onSurveyCriticalChanged(evt, !this.state.surveyCritical)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.featuredItem} >
                            <span><h1 className={styles.featuredTitleHeader}>{'Respondent Details'}</h1></span>
                            <div className={styles.featuredItem} >
                                {'Selected Contact : ' + this.getContactNameByContactStateId(this.props.organisationStatus.filledInBy)}<br />
                                {'Email : ' + ((contact !== undefined && contact !== null) ? contact.email : 'Not available')}<br />
                            </div>
                        </div>
                        <div className={styles.featuredItem} >
                            <span><h1 className={styles.featuredTitleHeader}>{'Log Interaction'}</h1></span>
                            <div className={styles.featuredItem} >
                                <DropDownMenu
                                    name={'contact'}
                                    label={'Contact'}
                                    value={this.state.respondentInteraction.contactId}
                                    onChange={(evt) => this.onChangeInteractionContact(evt)}
                                    showKeys={false}
                                    menuOptions={organisationContacts}
                                    placeholder='Not Selected'
                                    required
                                />
                                <br /><br />
                                <DropDownMenu
                                    name={'interactiontype'}
                                    label={'Interaction Type'}
                                    placeholder='Not Selected'
                                    className={styles.dialogInputs}
                                    showKeys={false}
                                    menuOptions={this.state.interactionTypes.filter((item) => item.key.includes('RESPONDENT_INTERACTION_')).sort((a, b) => a.value > b.value ? 1 : -1)}
                                    required
                                    onChange={(evt) => this.onChangeInteractionType(evt)}
                                />
                                <br /><br />
                                <DropDownMenu
                                    name={'current_status'}
                                    label={'Current Status'}
                                    placeholder='Not Selected'
                                    className={styles.dialogInputs}
                                    showKeys={false}
                                    menuOptions={(this.state.respondentInteraction.interactionType !== undefined
                                        && this.state.respondentInteraction.interactionType !== null
                                        && this.state.respondentInteraction.interactionType != '')
                                        ? this.state.interactionTypes
                                            .filter((item) => item.key.includes(this.state.respondentInteraction.interactionType.replace('RESPONDENT_INTERACTION_', '') + '_STATUS_'))
                                            .sort((a, b) => a.value > b.value ? 1 : -1) : []}
                                    required
                                    onChange={(evt) => this.onChangeInteractionStatus(evt)}
                                />
                                <br /><br />
                                <DropDownMenu
                                    name={'return_type'}
                                    label={'Return Type'}
                                    placeholder='Not Selected'
                                    className={styles.dialogInputs}
                                    showKeys={false}
                                    menuOptions={this.state.interactionTypes.filter((item) => item.key.includes('INTERACTION_RETURN_')).sort((a, b) => a.value > b.value ? 1 : -1)}
                                    required
                                    onChange={(evt) => this.onChangeReturnType(evt)}
                                />
                                <br /><br />
                                <DropDownMenu
                                    name={'return_status'}
                                    label={'Return Status'}
                                    placeholder='Not Selected'
                                    className={styles.dialogInputs}
                                    showKeys={false}
                                    menuOptions={(this.state.respondentInteraction.returnType !== undefined
                                        && this.state.respondentInteraction.returnType !== null
                                        && this.state.respondentInteraction.returnType != '')
                                        ? this.state.interactionTypes
                                            .filter((item) => item.key.includes(this.state.respondentInteraction.returnType.replace('INTERACTION_RETURN_', '') + '_STATUS_'))
                                            .sort((a, b) => a.value > b.value ? 1 : -1) : []}
                                    required
                                    onChange={(evt) => this.onChangeReturnStatus(evt)}
                                /><br /><br />
                                <DropDownMenu
                                    name={'return_type'}
                                    label={'Final Status'}
                                    placeholder='Not Selected'
                                    className={styles.dialogInputs}
                                    showKeys={false}
                                    menuOptions={this.state.interactionTypes.filter((item) => item.key.includes('FINAL_STATUS_')).sort((a, b) => a.value > b.value ? 1 : -1)}
                                    required
                                    onChange={(evt) => this.onChangeFinalStatus(evt)}
                                />
                                <br /><br />
                                <TextField
                                    name={'comment'}
                                    className={styles.dialogInputs}
                                    id="outlined-multiline-static"
                                    label="Comment"
                                    multiline
                                    rows={4}
                                    defaultValue=" "
                                    required
                                    onChange={(evt) => this.onChangeComment(evt)}
                                />
                                <br /><br />
                                <div>
                                    <input
                                        type="checkbox"
                                        className="radio"
                                        id={"checkbox_esq"}
                                        value={!this.state.esqFiled}
                                        checked={this.state.esqFiled}
                                        onChange={(evt) => this.onChangeESQ(evt)}
                                    />
                                    {<label for={"checkbox_yes"}>{'eSQ Filed'} &nbsp;&nbsp;</label>}
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        className="radio"
                                        id={"checkbox_thank_you"}
                                        value={!this.state.esqFiled}
                                        checked={this.state.esqFiled}
                                        onChange={(evt) => this.onChangeThankYouSent(evt)}
                                    />
                                    {<label for={"checkbox_yes"}>{'Thank you sent'} &nbsp;&nbsp;</label>}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
            />
        }
    }

}
export default InteractionLogDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            if (
                                props.respondentInteraction.interactionType === undefined || props.respondentInteraction.interactionType == null || props.respondentInteraction.interactionType.trim() == ""
                                || props.respondentInteraction.contactId === undefined || props.respondentInteraction.contactId == null || props.respondentInteraction.contactId == ''
                                || props.respondentInteraction.interactionStatus === undefined || props.respondentInteraction.interactionStatus == null || props.respondentInteraction.interactionStatus.trim() == ""
                                || props.respondentInteraction.comment === undefined || props.respondentInteraction.comment == null || props.respondentInteraction.comment.trim() == ""
                            ) {
                                SupportAlert.displayAlert('Required', 'Contact, Interaction Type, Interaction Status and Comment are required fields.');
                            } else {
                                props.onClick(evt);
                                dialog.close(true);
                            }
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}