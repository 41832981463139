import styles from "./sidebar.module.css";
import { rndLogoInstance } from '@components/Globals';
import axios from "axios";
import { React, useState } from 'react';
import { BiUserCircle } from 'react-icons/bi';
import {
  Dashboard,
  Assignment,
  Folder,
  Description,
  Person,
  GetApp,
  ViewHeadlineSharp
} from "@material-ui/icons";
import { Button } from "@mui/material";
import { Divider, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import DataCaptureService from '@services/DataCaptureService';
import BusinessQuestionnaire from '@downloads/questionnaires/BusinessQuestionnaire.pdf';
import BusinessCodebook from '@downloads/questionnaires/BusinessCodebook.pdf';

import GovNpoSciQuestionnaire from '@downloads/questionnaires/GovNpoSciQuestionnaire.pdf';
import GovNpoSciCodebook from '@downloads/questionnaires/GovNpoSciCodebook.pdf';

import HEPublicQuestionnaire from '@downloads/questionnaires/HEPublicQuestionnaire.xls';
import HEPrivateQuestionnaire from '@downloads/questionnaires/HEPrivateQuestionnaire.xls';

function parseJwt(token) {
  var base64Payload = token.split('.')[1];
  var payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
}
export default function Sidebar() {

  const [organisationName, setOrganisationName] = useState(null);
  const [sectorName, setSectorName] = useState(null);
  const [expandedDownloads, setExpandedDownloads] = useState(false);
  const [lastDownloadTime, setLastDownloadTime] = useState(null);

  const BUS_SECTOR = 1;
  const GOV_SECTOR = 2;
  const HE_SECTOR = 3;
  const NPO_SECTOR = 4;
  const SCI_SECTOR = 5;

  const BUS_QUESTIONNAIRE_FILENAME = "R&D_Questionnaire_2023_24_Business_Sector.pdf";
  const BUS_CODEBOOK_FILENAME = "R&D_Codebook_2023-24_Business_Sector.pdf";

  const GOV_NPO_SCI_QUESTIONNAIRE_FILENAME = "R&D_Questionnaire_2023_24_GovNpoSci_Sectors.pdf";
  const GOV_NPO_SCI_CODEBOOK_FILENAME = "R&D_Codebook_2023_24_GovNpoSci_Sectors.pdf";

  const HE_PUBLIC_QUESTIONNAIRE_FILENAME = "R&D_Questionnaire_2023_24_Public_Higher_Education_Sector.xls";
  const HE_PRIVATE_QUESTIONNAIRE_FILENAME = "R&D_Questionnaire_2023_24_Private_Higher_Education_Sector.xls";
  const HE_CODEBOOK_FILENAME = "R&D_Codebook_2023_24_Higher_Education_Sector.pdf";

  let bearer = localStorage.getItem('Bearer');
  if (!bearer) {
    window.location = "/login";

  }
  let payload = parseJwt(bearer);

  let orgID = payload.org_id;
  let sector_id = payload.sector_id;

  let username = payload.name;

  async function getOrgStatus(orgId) {
    let organisationStatus = await DataCaptureService.getOrganisationStatusBySurveyCycleIdAndOrganisationId(process.env.REACT_APP_CURRENT_SURVEY_CYCLE, orgId);

    if (organisationStatus !== undefined && organisationStatus !== null) {
      setOrganisationName(organisationStatus.organisationStateName);
    }
  }

  getOrgStatus(orgID);
  let sectorNameUrl = process.env.REACT_APP_SURVEY_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + "/questionnaireservice/questionnairetrackers/sector/name/" + sector_id;

  if (sector_id) {

    axios.get(sectorNameUrl)
      .then(response => {
        setSectorName(response.data.name);
      });
  }

  const downloadFile = (data, filename) => {
    setLastDownloadTime(new Date().toLocaleString());

    try {
      const link = document.createElement('a');
      link.href = data;
      link.download = filename; // This will be the downloaded file name
      document.body.appendChild(link);

      setTimeout(() => {
        link.click();

        setTimeout(() => {
          document.body.removeChild(link);
        }, 100);

      }, 0);
    } catch (error) {
      console.error('Download failed:', error);
    }

  };

  const submitDisabledMessage = process.env.REACT_APP_RESPONDENT_SUBMIT_FUNCTION_DISABLED_MESSAGE;
  const isSubmitFunctionEnabled = process.env.REACT_APP_RESPONDENT_SUBMIT_FUNCTION === 'ENABLED';

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarWrapper}>
        <div >
          <span className={styles.logo}>
            <img src={rndLogoInstance} className={styles.hsrc__logo} alt="hsrc logo" />
          </span>
        </div>
        <Divider />
        <div className={styles.avatar}>
          <Person />
          {/* <BiUserCircle size={50}/>   */}
          <span>{username} </span>
          <span> {organisationName}</span>
        </div>

        <Divider />

        <div className={styles.sidebarMenu}>
          <ul className={styles.sidebarList}>
            <Link to="/overview" className="link">
              <li className={styles.sidebarListItem}>
                <Dashboard className={styles.sidebarIcon} />
                Data Overview
              </li>
            </Link>

            {(isSubmitFunctionEnabled)
              ? <Link to="/mandate" className="link">
                <li className={styles.sidebarListItem}>
                  <Assignment className={styles.sidebarIcon} />
                  Submit Survey
                </li>
              </Link>
              : <Tooltip title={isSubmitFunctionEnabled ? '' : submitDisabledMessage}>
                <Link className="disabled_link">
                  <li className={styles.sidebarListItemDisabled}>
                    <Assignment className={styles.sidebarIcon} />
                    Submit Survey
                  </li>
                </Link>
              </Tooltip>
            }

            <Link to="/previous-surveys" className="link">
              <li className={styles.sidebarListItem}>
                <Folder className={styles.sidebarIcon} />
                Previous Surveys             </li>
            </Link>

            <Link to="/respondentdashboardresearch" className="link">
              <li className={styles.sidebarListItem}>
                <Description className={styles.sidebarIcon} />
                Access Resources
              </li>
            </Link>

            <Link className="link" onClick={(evt) => setExpandedDownloads(!expandedDownloads)}>
              <li className={styles.sidebarListItem} >
                <ViewHeadlineSharp className={styles.sidebarIconParent} />
                Download Questionnaires
              </li>
            </Link>

            {(expandedDownloads === true)
              ? <ul className="sidebarSubList">

                {(sector_id == BUS_SECTOR)
                  ? <div>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();

                        downloadFile(BusinessQuestionnaire, BUS_QUESTIONNAIRE_FILENAME);

                      }}
                      className="sublink">
                      <li className={styles.sidebarSubListItem}>
                        <GetApp className={styles.sidebarSubIcon} />
                        Business Questionnaire
                      </li>
                    </Link>

                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();

                        downloadFile(BusinessCodebook, BUS_CODEBOOK_FILENAME);

                      }}
                      className="sublink">
                      <li className={styles.sidebarSubListItem}>
                        <GetApp className={styles.sidebarSubIcon} />
                        Codebook
                      </li>
                    </Link>
                  </div>
                  : null
                }

                {(sector_id == GOV_SECTOR || sector_id == NPO_SECTOR || sector_id == SCI_SECTOR)
                  ? <div>
                    <Link to="#" onClick={(e) => {
                      e.preventDefault();

                      downloadFile(GovNpoSciQuestionnaire, GOV_NPO_SCI_QUESTIONNAIRE_FILENAME);

                    }} className="sublink">
                      {(sector_id == GOV_SECTOR)
                        ? <li className={styles.sidebarSubListItem}>
                          <GetApp className={styles.sidebarSubIcon} />
                          Government Questionnaire
                        </li>
                        : null
                      }
                      {(sector_id == NPO_SECTOR)
                        ? <li className={styles.sidebarSubListItem}>
                          <GetApp className={styles.sidebarSubIcon} />
                          NPO Questionnaire
                        </li>
                        : null
                      }
                      {(sector_id == SCI_SECTOR)
                        ? <li className={styles.sidebarSubListItem}>
                          <GetApp className={styles.sidebarSubIcon} />
                          SCI Questionnaire
                        </li>
                        : null
                      }
                    </Link>

                    <Link to="#" onClick={(e) => {
                      e.preventDefault();

                      downloadFile(GovNpoSciCodebook, GOV_NPO_SCI_CODEBOOK_FILENAME);

                    }} className="sublink">
                      <li className={styles.sidebarSubListItem}>
                        <GetApp className={styles.sidebarSubIcon} />
                        Codebook
                      </li>
                    </Link>
                  </div>
                  : null
                }

                {(sector_id == HE_SECTOR)
                  ? <div>
                    <Link to="#" onClick={(e) => {
                      e.preventDefault();

                      downloadFile(HEPublicQuestionnaire, HE_PUBLIC_QUESTIONNAIRE_FILENAME);

                    }} className="sublink">
                      <li className={styles.sidebarSubListItem}>
                        <GetApp className={styles.sidebarSubIcon} />
                        Public HE Questionnaire
                      </li>
                    </Link>

                    <Link to="#" onClick={(e) => {
                      e.preventDefault();

                      downloadFile(HEPrivateQuestionnaire, HE_PRIVATE_QUESTIONNAIRE_FILENAME);

                    }} className="sublink">
                      <li className={styles.sidebarSubListItem}>
                        <GetApp className={styles.sidebarSubIcon} />
                        Private HE Questionnaire
                      </li>
                    </Link>

                    <Link to="#" onClick={(e) => {
                      e.preventDefault();

                      downloadFile(GovNpoSciCodebook, HE_CODEBOOK_FILENAME);

                    }} className="sublink">
                      <li className={styles.sidebarSubListItem}>
                        <GetApp className={styles.sidebarSubIcon} />
                        Codebook
                      </li>
                    </Link>
                  </div>
                  : null
                }

              </ul>
              : null
            }

            <Link to="/profile/respondent/account/view" className="link">
              <li className={styles.sidebarListItem}>
                <Person className={styles.sidebarIcon} />
                Profile
              </li>
            </Link>



            <Link to="/logout" className="link">
              <li className={styles.sidebarListItem}>
                <button class={styles.logout}>Logout</button>
              </li>
            </Link>

          </ul>
        </div>
      </div>


      <Divider />
      <div>
        {/* help text etc */}
      </div>

    </div>


  );
}