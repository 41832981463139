import React, { Component } from "react";
import axios from "axios";
import { v4 as uuid } from 'uuid';
import ValidationsManager from '@components/ValidationsManager';
import DataCaptureService from '@services/DataCaptureService';

const DATACAPTURE_SERVICE = process.env.REACT_APP_DATACAPTURE_MICROSERVICE_URL + process.env.REACT_APP_SERVICES_VERSION;

export default function SessionStateManager() {
}

/**
 * clear
 * @param {none} 
 * @returns {any}
 */
export function clear() {
    sessionStorage.clear();
}


/**
 * allEntriesByQuestionFactId
 * @param {string} key - Key to session value
 * @returns {[key, value]}
 */
export function allEntriesByQuestionFactId(key) {
    try {
        let storedQuestionAnswerFacts = JSON.parse(sessionStorage.getItem('questionAnswerFacts'));
        return storedQuestionAnswerFacts.filter(item => (item.key.startsWith(key + '_')));
    } catch (ex) {
    }

    return [];
}

/**
 * countEntriesByQuestionFactId
 * @param {string} key - Key to session value
 * @returns {int}
 */
export function countEntriesByQuestionFactId(key) {
    try {
        let storedQuestionAnswerFacts = JSON.parse(sessionStorage.getItem('questionAnswerFacts'));
        return storedQuestionAnswerFacts.filter(item => (item.key.startsWith(key + '_'))).length;
    } catch (ex) {
    }

    return 0;
}

/**
 * getSessionValue
 * @param {string} key - Key to session value
 * @returns {any}
 */
export function getSessionValue(key) {
    let storedQuestionAnswerFacts = JSON.parse(sessionStorage.getItem('questionAnswerFacts'));
    let value = (storedQuestionAnswerFacts != null && storedQuestionAnswerFacts !== undefined) ? storedQuestionAnswerFacts.filter(item => item.key == key).map(item => item.value)[0] : null;
    return value;
}

/**
 * setSessionValue
 * @param {string, any} key, value - Key to session value
 * @returns {none}
 */
export function setSessionValue(key, value, questionId, questionnairePartId, compoundQuestionStateId, sourceOfData, reason) {

    let storedQuestionAnswerFacts = JSON.parse(sessionStorage.getItem('questionAnswerFacts'));
    if (storedQuestionAnswerFacts === undefined || storedQuestionAnswerFacts == null) {
        storedQuestionAnswerFacts = [];
    }
    let item = storedQuestionAnswerFacts.filter((item) => item.key == key)[0];
    if (item === undefined || item == null) {
        item = {};
        item.key = key;
        item.value = value;
        item.questionId = questionId;
        item.questionnairePartId = questionnairePartId;
        item.compoundQuestionStateId = compoundQuestionStateId;
        storedQuestionAnswerFacts.push(item);
    } else {
        item.value = value;
        item.questionId = questionId;
        item.questionnairePartId = questionnairePartId;
        item.compoundQuestionStateId = compoundQuestionStateId;
        storedQuestionAnswerFacts.filter((item) => item.key == key)[0] = item;
    }
    sessionStorage.setItem('questionAnswerFacts', JSON.stringify(storedQuestionAnswerFacts));
    
    saveToChangeList(key, value, questionId, questionnairePartId, compoundQuestionStateId, sourceOfData, reason, ValidationsManager.getBrokenValidations(key.split('_')[0]));
    
    setSessionStateUpdateToken();

    processDependeciesRecursively(key, sourceOfData, reason);

    verifyBrokenValidationsInChangeList();

}

function verifyBrokenValidationsInChangeList() {
    try {
        let changeList = JSON.parse(sessionStorage.getItem('changeList'));

        if (changeList !== undefined && changeList != null && changeList.length > 0) {
            changeList.forEach((change) => {
                change.brokenValidationIds = ValidationsManager.getBrokenValidations(change.key.split('_')[0]);
            });
        }

        sessionStorage.setItem('changeList', JSON.stringify(changeList));
    } catch (ex) {
        // here we should write to our remote UI-logger
    }
}

export function getChangeListEntry(thisKey) {
    let entry = null;

    try {
        let changeList = JSON.parse(sessionStorage.getItem('changeList'));

        if (changeList !== undefined && changeList != null && changeList.length > 0) {
            entry  = changeList.filter(change => change.key == thisKey)[0];
        }

    } catch (ex) {
    }

    return entry;
}

export function persistChangeList(surveycycleId, sectorId, organisationStateId, questionnaireId, filledInById, capturedById, captureType, dataCaptureId) {
    try {
        let changeList = JSON.parse(sessionStorage.getItem('changeList'));

        if (changeList !== undefined && changeList != null && changeList.length > 0) {
            let factsToPersistList = [];
            changeList.forEach((change) => {
                let fact = {
                    "fact": (change.value !== undefined && change.value != null) ? change.value : "",
                    "ordinalEntry": change.key.split('_')[1],
                    "questionFactId": change.key.split('_')[0],
                    "questionId": change.questionId,
                    "questionnaireId": questionnaireId,
                    "organisationStateId": organisationStateId,
                    "surveycycleId": surveycycleId,
                    "filledInBy": filledInById,
                    "capturedBy": capturedById,
                    "captureType": captureType,
                    "sectorId": sectorId,
                    "dataCaptureId": dataCaptureId,
                    "questionnairePartId": change.questionnairePartId,
                    "compoundQuestionStateId": change.compoundQuestionStateId,
                    "sourceOfData": (change.sourceOfData !== undefined && change.sourceOfData != null) ? change.sourceOfData : "",
                    "reason": (change.reason !== undefined && change.reason != null) ? change.reason : "",
                    "brokenValidationIds": (change.brokenValidationIds !== undefined && change.brokenValidationIds != null) ? change.brokenValidationIds : [],
                }
                factsToPersistList.push(fact);
            });

            if (factsToPersistList.length > 0) {
                DataCaptureService.saveTable(factsToPersistList);
            }
        }

        changeList = [];
        sessionStorage.setItem('changeList', JSON.stringify(changeList));
    } catch (ex) {

    }
}

export function saveToChangeList(key, value, questionId, questionnairePartId, compoundQuestionStateId, sourceOfData, reason, brokenValidationIds) {
    let changeList = JSON.parse(sessionStorage.getItem('changeList'));
    if (changeList === undefined || changeList == null) {
        changeList = [];
    }

    let item = changeList.filter((item) => item.key == key)[0];
    if (item === undefined || item == null) {
        item = {};
        item.key = key;
        item.value = value;
        item.questionId = questionId;
        item.questionnairePartId = questionnairePartId;
        item.compoundQuestionStateId = compoundQuestionStateId;
        item.sourceOfData = sourceOfData;
        item.reason = reason;
        item.brokenValidationIds = brokenValidationIds;
        changeList.push(item);
    } else {
        item.value = value;
        item.questionId = questionId;
        item.questionnairePartId = questionnairePartId;
        item.compoundQuestionStateId = compoundQuestionStateId;
        item.sourceOfData = sourceOfData;
        item.reason = reason;
        item.brokenValidationIds = brokenValidationIds;
        changeList.filter((item) => item.key == key)[0] = item;
    }
    sessionStorage.setItem('changeList', JSON.stringify(changeList));

}

export function processDependeciesRecursively(key, sourceOfData, reason) {
    let dependencies = ValidationsManager.getValidationDependencies(key.split('_')[0]);

    if (dependencies !== undefined && dependencies != null && dependencies.length > 0) {
        dependencies.map(dependency => {
            if (dependency != null) {
                if (dependency.rule.sum !== undefined && (dependency.rule.providedValue === undefined || dependency.rule.providedValue == null)) {
                    let currentValue = parseFloat(getSessionValue(dependency.key + '_0') != null ? getSessionValue(dependency.key + '_0') : 0);

                    let sum = 0;
                    dependency.rule.sum.split(', ').forEach(term => {
                        let termValue = parseFloat((getSessionValue(term + '_0') != null) ? getSessionValue(term + '_0') : 0);
                        sum = sum + (termValue != null && !isNaN(termValue) ? termValue : 0);
                    })

                    // Recursion stop condition to prevent stack overflow
                    if (currentValue != sum) {
                        setSessionValue(dependency.key + '_0', Number((sum).toFixed(2)), dependency.questionId, dependency.questionnairePartId, dependency.compoundQuestionStateId, sourceOfData, reason);
                    }

                }

                if (dependency.rule.multiply !== undefined) {
                    let currentValue = parseFloat(getSessionValue(dependency.key + '_0') != null ? getSessionValue(dependency.key + '_0') : 0);

                    let product = 1;
                    dependency.rule.multiply.split(', ').forEach(term => {
                        let termValue = parseFloat((getSessionValue(term + '_0') != null) ? getSessionValue(term + '_0') : 0);
                        product = product * (termValue != null && !isNaN(termValue) ? termValue : 0);
                    })

                    // Recursion stop condition to prevent stack overflow
                    if (currentValue != product) {
                        setSessionValue(dependency.key + '_0', Number((product).toFixed(2)), dependency.questionId, dependency.questionnairePartId, dependency.compoundQuestionStateId, sourceOfData, reason);
                    }
                }
            }
        });
    }
}

/**
 * deleteSessionValue
 * @param {stringy} key - Key to session value
 * @returns {none}
 */
export function deleteSessionValue(deleted_key) {
    let storedQuestionAnswerFacts = JSON.parse(sessionStorage.getItem('questionAnswerFacts'));
    let excludingDeleted = storedQuestionAnswerFacts.filter((item) => item.key != deleted_key)
    sessionStorage.setItem('questionAnswerFacts', JSON.stringify(excludingDeleted));
    setSessionStateUpdateToken();
}

/**
 * initialiseSessionState
 * @param {long} organisationStateId - Organisation State ID
 * @returns {none}
 */
export async function initialiseSessionState(organisationStateId) {
    this.clear();

    const url = DATACAPTURE_SERVICE + '/datacapture/getSurveyFacts/organisationstate_id/' + organisationStateId;
    const surveyFactsByOrganisationStateId = axios.create({ baseURL: url });

    const surveyFacts = await surveyFactsByOrganisationStateId.get('')
        .then(response => {
            const data = response.data;

            return data;
        })
        .catch((e) => {
            console.error('Error loading survey facts', e);
        });

    if (surveyFacts != null) {
        let questionAnswerFacts = [];

        surveyFacts.map(item => {
            const key = (item.questionFactId + '_' + (item.ordinalEntry == null ? 0 : item.ordinalEntry));
            let value = getFactValue(item);
            let questionAnswerFact = { key, value };
            questionAnswerFacts.push(questionAnswerFact);
        });

        sessionStorage.setItem('questionAnswerFacts', JSON.stringify(questionAnswerFacts));
    }

    return surveyFacts;
}

export async function initialiseSessionStateByCapturedQuestions(organisationStateId, capturedQuestions) {
    this.clear();

    const url = DATACAPTURE_SERVICE + '/datacapture/getSurveyFacts/organisationstate_id/' + organisationStateId;
    const surveyFactsByOrganisationStateId = axios.create({ baseURL: url });

    const surveyFacts = await surveyFactsByOrganisationStateId.get('')
        .then(response => {
            const data = response.data;

            return data;
        })
        .catch((e) => {
            console.error('Error loading survey facts', e);
        });

    if (surveyFacts != null) {
        let questionAnswerFacts = [];

        surveyFacts.map(item => {
            const key = (item.questionFactId + '_' + (item.ordinalEntry == null ? 0 : item.ordinalEntry));
            let value = getFactValue(item);

            if (capturedQuestions !== undefined && capturedQuestions != null && capturedQuestions.filter((cq) => cq.status == 'NOT_CAPTURED' && cq.compoundQuestionStateId == item.compoundQuestionStateId && cq.questionnairePartId == item.questionnairePartId).length > 0) {
                value = null;
                questionAnswerFacts.push({ key, value });
                saveToChangeList(key, '', item.questionId, item.questionnairePartId, item.compoundQuestionStateId, null, null)
            }

            if (capturedQuestions !== undefined && capturedQuestions != null && capturedQuestions.filter((cq) => cq.status == 'CAPTURED' && cq.compoundQuestionStateId == item.compoundQuestionStateId && cq.questionnairePartId == item.questionnairePartId).length > 0) {
                questionAnswerFacts.push({ key, value });
                saveToChangeList(key, value, item.questionId, item.questionnairePartId, item.compoundQuestionStateId, null, null)
            }

        });

        sessionStorage.setItem('questionAnswerFacts', JSON.stringify(questionAnswerFacts));
    }

    return surveyFacts;
}

function getFactValue(surveyFact) {
    let value = null;

    switch (surveyFact.measureUiType.toUpperCase()) {
        case 'TEXT_DATE': {
            value = surveyFact.textMeasure != null && surveyFact.textMeasure.length > 0 ? surveyFact.textMeasure : "";
            break;
        }
        case 'TEXT': {
            value = surveyFact.textMeasure;
            break;
        }
        case 'CHOICE': {
            value = surveyFact.textMeasure;
            break;
        }
        case 'RADIO': {
            value = surveyFact.booleanMeasure;
            break;
        }
        case 'CHECKBOX': {
            value = surveyFact.booleanMeasure;
            break;
        }
        case 'FLOAT': {
            value = (surveyFact.multiplicativeFactor != null && surveyFact.multiplicativeFactor > 1) ? (parseFloat(surveyFact.floatMeasure) / parseFloat(surveyFact.multiplicativeFactor)) : parseFloat(surveyFact.floatMeasure);
            break;
        }
        case 'PERCENTAGE': {
            value = (surveyFact.multiplicativeFactor != null && surveyFact.multiplicativeFactor > 1) ? (parseFloat(surveyFact.floatMeasure) / parseFloat(surveyFact.multiplicativeFactor)) : parseFloat(surveyFact.floatMeasure);
            break;
        }
        default: {
            value = null;
            break;
        }
    }

    return value;

}

export function setSessionStateUpdateToken() {
    sessionStorage.setItem('sessionStateUpdateToken', uuid());
}

export function getSessionStateUpdateToken() {
    return sessionStorage.getItem('sessionStateUpdateToken');
}