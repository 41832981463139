import hsrcLogo from '@images/logos/hsrc_logo.png';
import rndLogo from '@images/logos/rnd_logo.png';

import dsiLogo from '@images/logos/dsi_logo.jpg';

import statsSALogo from '@images/logos/stats_sa_logo.jpg';

import get_started_today_button from '@images/get_started_today_button.svg';
import login_button from '@images/login_button.svg';
import download_button from "@images/Research/download_button.png";
import dashboardHome from "@images/Home/dashboard_home.png";
import burgerMenu from '@images/NavigationBar/burger_menu.png';
import rndMobileLogo from '@images/logos/hsrc_mobile.png';
import closeBurgerMenu from '@images/NavigationBar/close_burger_menu.png';

const hsrcLogoInstance = hsrcLogo;
const rndLogoInstance = rndLogo;
const rndMobileLogoInstance = rndMobileLogo;

const dsiLogoInstance = dsiLogo;
const statsSALogoInstance = statsSALogo;
const getStartedButton = get_started_today_button;
const loginButton = login_button;
const downloadButton = download_button;
const dashboardHomeImage = dashboardHome;
const burgerMenuInstance = burgerMenu;
const closeBurgerMenuInstance = closeBurgerMenu;

export { hsrcLogoInstance, rndLogoInstance, statsSALogoInstance, dsiLogoInstance, getStartedButton, loginButton, downloadButton, dashboardHomeImage, burgerMenuInstance, closeBurgerMenuInstance, rndMobileLogoInstance  };