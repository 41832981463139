import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

import SubSectionText from "@components/SubSectionText";
import Separator from "@components/Separator";
import Contact from "@components/Contact";
import InputBox from "@components/InputBox";
import Footer from "@components/Footer";
import NavigationBar from "@components/NavigationBar";

import styles from "./passwordrecovery.module.css";
import MultiColorTitle from "@components/MultiColorTitle";

import { Alert } from 'react-st-modal';
import axios from "axios";
import qs from "qs";


class PasswordRecovery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            password2: "",
            otp: "",
            errors: '',
        }
    }

    handleSubmit = event => {
        event.preventDefault();

        if (this.state.password === this.state.password2) {
            if (this.state.password.length < 8) {
                { Alert("Your new password must be at least 8 characters long.", "Create Password") };
            } else {

                let url = process.env.REACT_APP_PROFILE_MANAGEMENT_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + '/profilemanagement/user/validate/password/reset/otp/' + this.state.otp + '/username/' + this.state.username + '/newpassword/' + this.state.password;

                axios.post(url)
                    .then(response => {
                        { Alert("You’ve successfully created your password. Click OK to log in.", "Create Password") };

                        window.location.href = "/login";
                    }
                    ).catch(error => {
                        { Alert("We could not create your password. Please try again later.", "Create Password") };
                    });
            }
        } else {
            { Alert("Passwords do not match and must be at least 8 characters long.", "Create Password") };
        }
    }

    handleNameChange = event => {
        this.setState({ username: event.target.value });
    }

    handlePasswordChange = event => {
        this.setState({ password: event.target.value });
    }

    handleConfirmPasswordChange = event => {
        this.setState({ password2: event.target.value });
    }

    handleOtpChange = event => {
        this.setState({ otp: event.target.value });
    }

    render() {
        return (
            <div>
                <NavigationBar />
                <Container className={styles.container_resetpassword}>
                    <br />
                    <br />
                    <div className={styles.resetpassword_body}>
                        <MultiColorTitle
                            title_font="title__42px"
                            first_color="orange"
                            first_text="Create"
                            second_color="blue"
                            second_text="Password"
                        />
                        <div style={{ maxWidth: 750 }}>
                            <SubSectionText
                                text_font="text__18px"
                                text="Please enter the OTP sent to your email and your new password." />
                        </div>
                        <div>
                            <form onSubmit={this.handleSubmit}>
                                <br />
                                <input placeholder="Email Address" name="username" className="input__box"
                                    onChange={this.handleNameChange} required />
                                <br />

                                <br />
                                <input placeholder="OTP" name="otp" className="input__box"
                                    onChange={this.handleOtpChange} required />
                                <br />

                                <br />
                                <input type="password" placeholder="New Password" name="password" className="input__box"
                                    onChange={this.handlePasswordChange} required />
                                <br />

                                <br />
                                <input type="password" placeholder="Confirm New Password" name="password2" className="input__box"
                                    onChange={this.handleConfirmPasswordChange} required />
                                <br />

                                <div style={{ marginBottom: 10, marginTop: 20 }} >
                                    <input variant="contained" type="submit" value="Update Password" className="button__orange" onClick={this.handleSubmit} />
                                    <text style={{ maxWidth: '65%' }} className="text__12px">
                                        {this.props.errors}
                                    </text>
                                </div>
                            </form>
                        </div>
                        <a href="/confidentiality"><text style={{ maxWidth: 485 }} className="text__14px" >By logging in, you agree to our </text><text style={{ maxWidth: 485 }} className="text__14px__orange" >terms of use.
                        </text></a>
                    </div>


                </Container>
                <Separator />
                <Contact />
                <Separator />
                <Footer />
            </div>
        );
    }

}
export default PasswordRecovery;