import React, { Component, useState } from "react";
import DataCaptureService from "@services/DataCaptureService";
import SurveyService from "@services/SurveyService";
import ProfileManagementService from "@services/ProfileManagementService";
import FieldworkManagementService from "@services/FieldworkManagementService";
import SpinnerModal from "@components/SpinnerModal";
import MUIDataTable from 'mui-datatables';
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from './researcherviewdialog.module.css';
import moment from "moment";
import CSVGenerator from '@components/CSVGenerator';

class ResearcherViewDialog extends Component {
    state = {
        loading: true,
        surveyCycle: null,
        interactionTypes: [],
        interactionHistory: [],
        surveysUsers: [],
        allOrganisationStatuses: [],
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        const surveyCycles = await SurveyService.getSurveyCycleById(process.env.REACT_APP_CURRENT_SURVEY_CYCLE);
        this.setState({ surveyCycle: surveyCycles });

        const lookups = await DataCaptureService.getLookups();

        let interactionTypes = [];
        lookups.filter((lookup) => (lookup.type.includes('_INTERACTION_TYPE'))).map(item => {
            interactionTypes.push({ key: item.varenum, value: item.name.toUpperCase() });
        });
        this.setState({ interactionTypes: interactionTypes });

        const savedInteractionHistory = await FieldworkManagementService.getInteractionsBySurveyCycleAndSurveysUser(process.env.REACT_APP_CURRENT_SURVEY_CYCLE, this.props.fieldworkerId);
        if (savedInteractionHistory !== undefined && savedInteractionHistory !== null && savedInteractionHistory != '') {
            this.setState({ interactionHistory: savedInteractionHistory });
        }

        const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
        this.setState({ surveysUsers: surveysUsers });

        const organisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId(this.props.sectorId, process.env.REACT_APP_CURRENT_SURVEY_CYCLE);
        this.setState({ allOrganisationStatuses: organisationStatuses });

        this.setState({ loading: false });
    }

    getSurveysUserNameById(userId) {
        let name = (this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].firstname + ' ' + this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].lastname);
        return name;
    }

    fieldworkerMonthViewTable() {

        let fieldworkEffortTableData = [];

        let fieldworkerEffortsColumns = [
            { label: "MONTH", name: "month" },
        ];

        if (this.state.interactionTypes !== undefined && this.state.interactionTypes !== null && this.state.interactionTypes.length > 0) {
            this.state.interactionTypes.sort((a, b) => a.key > b.key ? 1 : -1).map((type) => {
                const column = { label: type.value, name: type.key };
                fieldworkerEffortsColumns.push(column);
            })
        }

        if (this.state.surveyCycle !== undefined && this.state.surveyCycle !== null) {
            const years = [this.state.surveyCycle.referenceToYear, this.state.surveyCycle.referenceToYear + 1];

            let call_count = 0;
            let captured_count = 0;
            let email_count = 0;
            let other_electronic_count = 0;
            let pdf_return_count = 0;
            let rdi_return_count = 0;
            let site_visit_count = 0;
            let virual_meeting_count = 0;

            years.map(year => {
                for (let month = 1; month <= 12; month++) {

                    let currentMonth = moment(new Date(year, month, 0)).format('MMM YYYY');

                    if (this.state.interactionHistory !== undefined && this.state.interactionHistory !== null && this.state.interactionHistory.length > 0) {
                        if (this.props.sectorId !== undefined && this.props.sectorId !== null && this.props.sectorId > 0) {

                            call_count = this.state.interactionHistory
                                .filter(interaction => (this.state.allOrganisationStatuses.filter(organisationState => organisationState.organisationStateId == interaction.organisationStateId && organisationState.sectorId == this.props.sectorId).length > 0))
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_CALL')
                                .length;

                            captured_count = this.state.interactionHistory
                                .filter(interaction => (this.state.allOrganisationStatuses.filter(organisationState => organisationState.organisationStateId == interaction.organisationStateId && organisationState.sectorId == this.props.sectorId).length > 0))
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_CAPTURED')
                                .length;

                            email_count = this.state.interactionHistory
                                .filter(interaction => (this.state.allOrganisationStatuses.filter(organisationState => organisationState.organisationStateId == interaction.organisationStateId && organisationState.sectorId == this.props.sectorId).length > 0))
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_EMAIL')
                                .length;

                            other_electronic_count = this.state.interactionHistory
                                .filter(interaction => (this.state.allOrganisationStatuses.filter(organisationState => organisationState.organisationStateId == interaction.organisationStateId && organisationState.sectorId == this.props.sectorId).length > 0))
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_OTHER')
                                .length;

                            pdf_return_count = this.state.interactionHistory
                                .filter(interaction => (this.state.allOrganisationStatuses.filter(organisationState => organisationState.organisationStateId == interaction.organisationStateId && organisationState.sectorId == this.props.sectorId).length > 0))
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_PDF_RETURNED')
                                .length;

                            rdi_return_count = this.state.interactionHistory
                                .filter(interaction => (this.state.allOrganisationStatuses.filter(organisationState => organisationState.organisationStateId == interaction.organisationStateId && organisationState.sectorId == this.props.sectorId).length > 0))
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_RDI_RETURNED')
                                .length;

                            site_visit_count = this.state.interactionHistory
                                .filter(interaction => (this.state.allOrganisationStatuses.filter(organisationState => organisationState.organisationStateId == interaction.organisationStateId && organisationState.sectorId == this.props.sectorId).length > 0))
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_SITE_VISIT')
                                .length;

                            virual_meeting_count = this.state.interactionHistory
                                .filter(interaction => (this.state.allOrganisationStatuses.filter(organisationState => organisationState.organisationStateId == interaction.organisationStateId && organisationState.sectorId == this.props.sectorId).length > 0))
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_VIRTUAL_MEETING')
                                .length;

                        } else {

                            call_count = this.state.interactionHistory
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_CALL')
                                .length;

                            captured_count = this.state.interactionHistory
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_CAPTURED')
                                .length;

                            email_count = this.state.interactionHistory
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_EMAIL')
                                .length;

                            other_electronic_count = this.state.interactionHistory
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_OTHER')
                                .length;

                            pdf_return_count = this.state.interactionHistory
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_PDF_RETURNED')
                                .length;

                            rdi_return_count = this.state.interactionHistory
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_RDI_RETURNED')
                                .length;

                            site_visit_count = this.state.interactionHistory
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_SITE_VISIT')
                                .length;

                            virual_meeting_count = this.state.interactionHistory
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_VIRTUAL_MEETING')
                                .length;

                        }

                    }

                    let row = {
                        month: currentMonth,
                        RESPONDENT_INTERACTION_CALL: call_count,
                        RESPONDENT_INTERACTION_CAPTURED: captured_count,
                        RESPONDENT_INTERACTION_EMAIL: email_count,
                        RESPONDENT_INTERACTION_OTHER: other_electronic_count,
                        RESPONDENT_INTERACTION_PDF_RETURNED: pdf_return_count,
                        RESPONDENT_INTERACTION_RDI_RETURNED: rdi_return_count,
                        RESPONDENT_INTERACTION_SITE_VISIT: site_visit_count,
                        RESPONDENT_INTERACTION_VIRTUAL_MEETING: virual_meeting_count,
                    }

                    fieldworkEffortTableData.push(row);
                }
            });
        }

        let tableTitle = ('Fieldwork Effort Per Month for ' + this.props.sectorName).toUpperCase();

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [12],
            rowsPerPageOptions: [12, 24],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            // onRowClick: this.onResearcherClicked,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };
        

        return <div className={styles.flex_row_container}>
            <div className={styles.featuredItem}>
                <MUIDataTable
                    title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                    data={fieldworkEffortTableData}
                    columns={fieldworkerEffortsColumns}
                    options={options}
                />
            </div>
        </div>
    }

    fieldworkerOrgBreakdownTable() {
        let fieldworkOrgBreakdownTableData = [];

        let fieldworkOrgBreakdownColumns = [
            { label: "ORGANISATION", name: "organisationName" },
            { label: "MONTH", name: "month" },
        ];

        if (this.state.interactionTypes !== undefined && this.state.interactionTypes !== null && this.state.interactionTypes.length > 0) {
            this.state.interactionTypes.sort((a, b) => a.key > b.key ? 1 : -1).map((type) => {
                const column = { label: type.value, name: type.key };
                fieldworkOrgBreakdownColumns.push(column);
            })
        }

        if (this.state.surveyCycle !== undefined && this.state.surveyCycle !== null) {
            const years = [this.state.surveyCycle.referenceToYear, this.state.surveyCycle.referenceToYear + 1];

            this.state.allOrganisationStatuses.sort((a, b) => a.organisationStateName > b.organisationStateName ? 1 : -1).map(organisationStatus => {

                let call_count = 0;
                let captured_count = 0;
                let email_count = 0;
                let other_electronic_count = 0;
                let pdf_return_count = 0;
                let rdi_return_count = 0;
                let site_visit_count = 0;
                let virual_meeting_count = 0;

                years.map(year => {
                    for (let month = 1; month <= 12; month++) {

                        let currentMonth = moment(new Date(year, month, 0)).format('MMM YYYY');

                        if (this.state.interactionHistory !== undefined && this.state.interactionHistory !== null && this.state.interactionHistory.length > 0) {
                            call_count = this.state.interactionHistory
                                .filter(interaction => interaction.organisationStateId == organisationStatus.organisationStateId)
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_CALL')
                                .length;

                            captured_count = this.state.interactionHistory
                                .filter(interaction => interaction.organisationStateId == organisationStatus.organisationStateId)
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_CAPTURED')
                                .length;

                            email_count = this.state.interactionHistory
                                .filter(interaction => interaction.organisationStateId == organisationStatus.organisationStateId)
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_EMAIL')
                                .length;

                            other_electronic_count = this.state.interactionHistory
                                .filter(interaction => interaction.organisationStateId == organisationStatus.organisationStateId)
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_OTHER')
                                .length;

                            pdf_return_count = this.state.interactionHistory
                                .filter(interaction => interaction.organisationStateId == organisationStatus.organisationStateId)
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_PDF_RETURNED')
                                .length;

                            rdi_return_count = this.state.interactionHistory
                                .filter(interaction => interaction.organisationStateId == organisationStatus.organisationStateId)
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_RDI_RETURNED')
                                .length;

                            site_visit_count = this.state.interactionHistory
                                .filter(interaction => interaction.organisationStateId == organisationStatus.organisationStateId)
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_SITE_VISIT')
                                .length;

                            virual_meeting_count = this.state.interactionHistory
                                .filter(interaction => interaction.organisationStateId == organisationStatus.organisationStateId)
                                .filter(interaction => moment(new Date(interaction.interactionDate)).format('MMM YYYY') == currentMonth)
                                .filter(interaction => interaction.interactionType == 'RESPONDENT_INTERACTION_VIRTUAL_MEETING')
                                .length;

                        }

                        let row = {
                            organisationName: organisationStatus.organisationStateName,
                            month: currentMonth,
                            RESPONDENT_INTERACTION_CALL: call_count,
                            RESPONDENT_INTERACTION_CAPTURED: captured_count,
                            RESPONDENT_INTERACTION_EMAIL: email_count,
                            RESPONDENT_INTERACTION_OTHER: other_electronic_count,
                            RESPONDENT_INTERACTION_PDF_RETURNED: pdf_return_count,
                            RESPONDENT_INTERACTION_RDI_RETURNED: rdi_return_count,
                            RESPONDENT_INTERACTION_SITE_VISIT: site_visit_count,
                            RESPONDENT_INTERACTION_VIRTUAL_MEETING: virual_meeting_count,
                        }

                        if (call_count + captured_count + other_electronic_count + email_count + pdf_return_count + rdi_return_count + site_visit_count + virual_meeting_count > 0) {
                            fieldworkOrgBreakdownTableData.push(row);
                        }
                    }
                });
            })
        }

        let tableTitle = ('Fieldwork Effort Per Organisation for ' + this.props.sectorName).toUpperCase();

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [10],
            rowsPerPageOptions: [10, 20, 50, 100],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            onRowClick: this.onResearcherClicked,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        return <div className={styles.flex_row_container}>
            <div className={styles.featuredItem}>
                <MUIDataTable
                    title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                    data={fieldworkOrgBreakdownTableData}
                    columns={fieldworkOrgBreakdownColumns}
                    options={options}
                />
            </div>
        </div>
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            return <DialogWrapper {...this.props} onClick={(evt) => this.props.nextButton.onClick(evt, this.state.organisationStatus)} content={
                <React.Fragment>
                    {this.fieldworkerMonthViewTable()}
                    {this.fieldworkerOrgBreakdownTable()}
                </React.Fragment>
            }
            />
        }
    }

}
export default ResearcherViewDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            props.onClick(evt);
                            dialog.close(true);
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}

