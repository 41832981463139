import React, { Component } from "react";

export default function NumberTools() {
}

export function transformToCurrency(value) {
    let decimalPointSeperator = '.';
    let thousandsSeperator = ' ';

    value = (value !== undefined && value !== null) ? value.toString().replace(/\s/g, '') : value;
    let newValue = (value !== undefined && value !== null && !isNaN(value)) ? value : null;

    if (!isNaN(newValue) && value !== undefined && value !== null && !isNaN(value)) {
        let valuePart = newValue.split(decimalPointSeperator)[0];
        let decimalPart = newValue.split(decimalPointSeperator)[1];
        const addThousandSeperator = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeperator);
        let transformedValue = addThousandSeperator(valuePart);
        transformedValue = addThousandSeperator(valuePart) + ((decimalPart !== undefined && decimalPart !== null) ? '.' + decimalPart : '');
        return transformedValue;
    } else {
        return null;
    }
}

export function isValidDecimalNumber(input) {
    let valid = false;
    valid = /^(\d+((\.)?(\d{1,2})?))?$/.test(input.replace(/\s/g, '')) && (input.replace(/[^\.]/g, "").length <= 1);
    return valid;
}