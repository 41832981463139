// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".support_support_container__9fXMv{\r\n    display: flex; \r\n    flex:1 1; \r\n    align-content: center; \r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://src/components/Support/support.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAM;IACN,qBAAqB;AACzB","sourcesContent":[".support_container{\r\n    display: flex; \r\n    flex:1; \r\n    align-content: center; \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"support_container": "support_support_container__9fXMv"
};
export default ___CSS_LOADER_EXPORT___;
