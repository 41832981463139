import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Separator from "@components/Separator";
import Topbar from '@components/Topbar';
import { RespondentProfile, ResearcherProfile, ViewProfile, CreateProfile, UpdateProfile } from './pages/Profile';
import Logout from '@pages/Logout';

import Footer from "./components/Footer";
import Sidebar from './components/Sidebar';

import PageNotFound from '@pages/PageNotFound';


import Dashboard from './pages/Dashboard';
import Institutions from './pages/Institutions';
import Datacapture from './pages/Datacapture';
import Despatch from './pages/Despatch';
import DataUpdate from './pages/DataUpdate';
import FieldworkTracking from './pages/Fieldwork/Tracking/FieldworkTracking';
import FieldworkReporting from './pages/Fieldwork/Reporting/FieldworkReporting';
import RespondentUsers from './pages/Users/RespondentUsers/RespondentUsers';
import ResearcherUsers from './pages/Users/ResearcherUsers/ResearcherUsers';
import PreviousSurveys from './pages/PreviousSurveys';
import SurveyStatus from './pages/SurveyStatus';

import styles from './researcherdashboard.module.css';

class ResearcherDashboard extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
      <div className={styles.dashboard_container}>
        <Router>
          <Sidebar />
       

          <div className={styles.dashboard_content}>
           

           
                <Route exact path="/researcherdashboard">
                 <Dashboard />
               </Route>
               <Route exact path="/datacapture">
                 <Datacapture />
               </Route>
               <Route exact path="/despatch">
                 <Despatch />
               </Route>
               <Route exact path="/dataupdate">
                 <DataUpdate />
               </Route>
               <Route exact path="/fieldworktracking">
                 <FieldworkTracking />
               </Route>
               <Route exact path="/fieldworkreporting">
                 <FieldworkReporting />
               </Route>
               <Route exact path="/previous-surveys">
                 <PreviousSurveys />
               </Route>
               <Route exact path="/institutions">
                 <Institutions />
               </Route>
           
               <Route exact path="/viewsurveystatus">
                 <SurveyStatus />
              </Route>
              <Route exact path="/user/management/respondents">
                <RespondentUsers />
              </Route>

              <Route exact path="/user/management/researchers">
                <ResearcherUsers />
              </Route>
               <Route exact path="/profile/researcher/account/view">
                 <ResearcherProfile group='researcher' detail='account' action='view' />
               </Route>
               <Route exact path="/profile/researcher/account/create">
                 <CreateProfile group='researcher' detail='account' action='create' />
               </Route>
               <Route exact path="/profile/researcher/account/update">
                 <UpdateProfile group='researcher' detail='account' action='update' />
               </Route>
               <Route exact path="/profile/researcher/organisation/view">
                 <ViewProfile group='researcher' detail='organisation' action='view' />
               </Route>
               <Route exact path="/profile/researcher/organisation/create">
                 <ResearcherProfile group='researcher' detail='organisation' action='create' />
               </Route>
               <Route exact path="/profile/researcher/organisation/update">
                 <UpdateProfile group='researcher' detail='organisation' action='update' />
               </Route>
               <Route exact path="/profile/respondent/account/create">
                 <RespondentProfile group='respondent' detail='account' action='create' />
               </Route>
               <Route exact path="/profile/respondent/organisation/create">
                 <RespondentProfile group='respondent' detail='organisation' action='create' />
               </Route>
               <Route exact path="/logout" >
                 <Logout />
               </Route>
             {/* /* default 404  */}
               {/* <Route component={PageNotFound} /> */}
          </div>     {/* dashboard content*/}

        </Router>
      </div>








      <div style={{ margin: "15%" }} />
      <Separator size="100%" />
      <Footer />
    </div>
   
    )
  }
}

export default ResearcherDashboard
