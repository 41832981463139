import React, { useState } from "react";

import Obfuscate from "react-obfuscate";


import Separator from "@components/Separator";
import Footer from "@components/Footer";
import Contact from "@components/Contact";


import styles from "./faq.module.css";
import NavigationBar from "@components/NavigationBar";

function FrequentlyAskedQuestions() {







  return (
    <div>
      <NavigationBar />
      <div className={styles.container_faq}>


        <h1 className="title__42px">
          <text className="title__42px"> Frequently </text>
          <text>&nbsp;</text>
          <text className="title__42px__orange">Asked Questions</text>
        </h1>


        <div>
          <p className="text__18px"> The R&D Survey questionnaire can be completed using our new online system or our Adobe Acrobat PDF/MS Excel forms, which can be returned via email or via the postal services.
            We’re committed to making your experience of the R&D Survey both easy and useful. Let us know if we achieved this goal, by writing to <Obfuscate email="innovation@hsrc.ac.za" /> </p>
        </div>

        <div className={styles.question_section} >


          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                What is the purpose of the R&D Survey?
              </summary>
              <p className="text__16px">
                Every year the R&D Survey collects data on financial and human resource inputs into the R&D activities of South African firms,
                universities, science councils, public research institutes, government departments, and NPOs.
                This data is used for planning, monitoring and benchmarking purposes.
                It is also used by government to inform decision - making on R&D and innovation policy.
                The data from the Survey is used for policy driven research and academic purposes.
                Individual data submissions gathered for the purposes of the survey are confidential and are never disseminated.
              </p>
            </details>
          </div>
          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                Who is responsible for conducting the Survey?
              </summary>
              <p className="text__16px">
                The Survey is administered by the Centre for Science, Technology and Innovation Indicators (CeSTII),
                for the Department of Science and Innovation (DSI), with quality assurance by Statistics South Africa (Stats SA).
                CeSTII is a specialised research unit of the Human Sciences Research Council (HSRC), and has conducted science, technology and innovation surveys in South Africa for years.


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                Is the Survey compulsory?
              </summary>
              <p className="text__16px">
                Yes. The R&D Survey is part of South Africa’s official statistics,
                as defined in the Statistics Act No. 6 of 1999 and, as such, your organisation is required to participate.


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                Are Survey results made publicly available?
              </summary>
              <p className="text__16px">
                Yes. The R&D Survey team produce two reports annually: a statistical report and a main analysis report.
                These are disseminated widely and are publically accessible on the HSRC website
                <a href="http://www.hsrc.ac.za/en/departments/CeSTii/reports-cestii" target="_blank"> &nbsp;(http://www.hsrc.ac.za/en/departments/CeSTii/reports-cestii)</a>
                and the website of the Department of Science and Innovation <a href="http://www.dst.gov.za/publications-policies/r-d-reports" target="_blank"> &nbsp;(http://www.dst.gov.za/publications-policies/r-d-reports) </a>.


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                How are the Survey reports used?
              </summary>
              <p className="text__16px">
                Survey reports provide users with a unique ability to benchmark by sector,
                nationally and globally.This assists policy makers to engage in responsive policy development and
                organisational planning, and promotes competitive advantage.
                Survey reports are also used to develop research policy to set priorities,
                determine government research funding investment, enhance science education and
                develop incentive schemes for R&D and innovation in the private sector.


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                Is data supplied to the Survey protected and securely stored?
              </summary>
              <p className="text__16px">
                All information supplied to the Survey is protected by the Statistics Act No. 6 of 1999.
                No individual data is shared with any party outside of the immediate survey team,
                and all data are stored on state-of-the art ICT infrastructure,
                protected by stringent data encryption policies.


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                Why was my organisation chosen to respond to the Survey?
              </summary>
              <p className="text__16px">
                Organisations (public and private) that are known to or likely to perform R&D activities are
                automatically included as respondents in the Survey.


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                My organisationis not engaged in R&D activities. Is our participation in the Survey still required?
              </summary>
              <p className="text__16px">
                Yes. If your organisation or business has not performed or funded R&D
                activities for the survey year in question, please complete Part 1 of the questionnaire.


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                My organisation outsources its R&D. Is our participation in the Survey still required?
              </summary>
              <p className="text__16px">
                Yes. Please participate by completing the information on Outsourcing in the questionnaire.


              </p>
            </details>
          </div>


          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                There is no dedicated department for R&D in my organisation. Is our participation in the Survey still required?
              </summary>
              <p className="text__16px">
                You do not need an R&D department to answer the questionnaire.
                If your R&D is spread across your organisation, please provide estimate answers.
                Our in-house experts can assist you in developing estimations.


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                Who within my organisation is responsible for completing the Survey questionnaire?
              </summary>
              <p className="text__16px">
                Questionnaires are typically directed to the senior management of an organisation,
                who may appoint a relevant contact person to provide the data or coordinate the process of
                data provision.Usually the heads of R&D departments, human resource managers as well as
                financial managers would be involved in completing the questionnaires.

              </p>
            </details>
          </div>


          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                How long does it take to complete the Survey questionnaire?              </summary>
              <p className="text__16px">
                This depends on the size of your organisation,
                the availability of information, as well as the complexity of your R&D operations and records.
                Responding is easier once your organisation has previously participated in this survey.


              </p>
            </details>
          </div>


          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                What should I do if the individuals within my organisation do not have access to some of the data requested?              </summary>
              <p className="text__16px">
                Provide your best estimate if accurate data are not available.
                The Organisation for Economic Co-operation and Development (OECD) recommends using estimated data
                when exact data is not available. CeSTII researchers are available to assist if required.


              </p>
            </details>
          </div>



          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                We need more time to complete the questionnaire. Can we request this?              </summary>
              <p className="text__16px">
                Please email the HSRC researcher whose details appear in the official email request for your organisation's participation in the R&D Survey.
                Alternatively, write to Dr Nazeem Mustapha, National R&D Survey Project Lead, <Obfuscate email="nmustapha@hsrc.ac.za" /> or call
                +27 (0)21 466 7887 to discuss your requirement.
              </p>
            </details>
          </div>


          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                How do I return the completed Adobe Acrobat PDF or MS Excel questionnaire?
              </summary>
              <p className="text__16px">
                If you choose not to submit your R&D data through this online platform, please return your completed questionnaire to your contact person at CeSTII whose details appear on the questionnaire cover.
                Postal returns should be addressed to: The R&D Survey Team, PO Box 15200, Vlaeberg, 8018.


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                How often is the Survey issued?              </summary>
              <p className="text__16px">
                The Survey is annual and you will be asked to complete the Survey every year.


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                What constitutes Research and Experimental Development (R&D)?              </summary>
              <p className="text__16px">
                The definition of R&D is adopted from the OECD, which defines R&D according to the Frascati Manual, 2015.
                Research: Creative work and original investigation undertaken on a systematic
                basis to gain new knowledge, including knowledge of humanity, culture and society.
                Development: Application of research findings or other scientific knowledge for the creation of new or
                significantly improved products or processes.
                The OECD’ s Frascati Manual is available on the OECD website
                <a href="https://www.oecd.org/sti/inno/frascati-manual.htm" target="_blank">
                  &nbsp; (https://www.oecd.org/sti/inno/frascati-manual.htm).
                </a>


              </p>
            </details>
          </div>

          <div className={styles.question_div} >
            <details>
              <summary className="text__18px">
                Is it possible to access the micro data on which the R&D Survey reports are based?
              </summary>
              <p className="text__16px">
                Micro data are confidential data which contain information about individual statistical units.
                The current framework enables access to aggregated data only.

              </p>
            </details>
          </div>




        </div>


      </div>




      <Separator size="100%" />
      <Contact />

      <Separator size="100%" />
      <Footer />
    </div>
  )


}
export default FrequentlyAskedQuestions;