import SessionStateManager, 
    { initialiseSessionState, 
      getSessionValue, 
      setSessionValue, 
      deleteSessionValue, 
      clear, 
      countEntriesByQuestionFactId, 
      allEntriesByQuestionFactId, 
      setSessionStateUpdateToken, 
      getSessionStateUpdateToken,
      saveToChangeList,
      getChangeListEntry,
      persistChangeList,
      initialiseSessionStateByCapturedQuestions
    } from './SessionStateManager';

export default { SessionStateManager, 
                 initialiseSessionState, 
                 initialiseSessionStateByCapturedQuestions,
                 getSessionValue, 
                 setSessionValue, 
                 deleteSessionValue, 
                 clear, 
                 countEntriesByQuestionFactId, 
                 allEntriesByQuestionFactId, 
                 setSessionStateUpdateToken, 
                 getSessionStateUpdateToken,
                 getChangeListEntry,
                 saveToChangeList,
                 persistChangeList
                }