import React, {Component} from "react";

  
import styles from './separator.module.css';

class Separator extends Component {
    

    render() {
        return (
            <div className={styles.full__separator}/>
        ); 
    }

}
export default Separator;