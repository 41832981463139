import styles from "./sidebar.module.css";
import { rndLogoInstance } from '@components/Globals';
import axios from "axios";
import { React, useState } from 'react';
import { BiUserCircle } from 'react-icons/bi';
import {
  Dashboard,
  Assignment,
  Folder,
  Description,
  Person
} from "@material-ui/icons";
import { Button } from "@mui/material";
import { Divider, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";



function parseJwt(token) {
  var base64Payload = token.split('.')[1];
  var payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
}
export default function Sidebar() {

  const [organisationName, setOrganisationName] = useState(null);
  const [sectorName, setSectorName] = useState(null);

  let bearer = localStorage.getItem('Bearer');
  if (!bearer) {
    window.location = "/login";

  }
  let payload = parseJwt(bearer);

  let orgID = payload.org_id;
  let sector_id = payload.sector_id;

  let username = payload.name;

  let organisationNameUrl = process.env.REACT_APP_SURVEY_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + "/questionnaireservice/questionnairetrackers/organisation/name/" + orgID;
  let sectorNameUrl = process.env.REACT_APP_SURVEY_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + "/questionnaireservice/questionnairetrackers/sector/name/" + sector_id;

  if (orgID) {

    axios.get(organisationNameUrl)
      .then(response => {
        setOrganisationName(response.data.name);
      });
  }

  if (sector_id) {

    axios.get(sectorNameUrl)
      .then(response => {
        setSectorName(response.data.name);
      });
  }


  const submitDisabledMessage = process.env.REACT_APP_RESPONDENT_SUBMIT_FUNCTION_DISABLED_MESSAGE;
  const isSubmitFunctionEnabled = process.env.REACT_APP_RESPONDENT_SUBMIT_FUNCTION === 'ENABLED';

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarWrapper}>
        <div >
          <span className={styles.logo}>
            <img src={rndLogoInstance} className={styles.hsrc__logo} alt="hsrc logo" />
          </span>
        </div>
        <Divider />
        <div className={styles.avatar}>
          <Person />
          {/* <BiUserCircle size={50}/>   */}
          <span>{username} </span>
          <span> {organisationName}</span>
        </div>

        <Divider />

        <div className={styles.sidebarMenu}>
          <ul className={styles.sidebarList}>
            <Link to="/overview" className="link">
              <li className={styles.sidebarListItem}>
                <Dashboard className={styles.sidebarIcon} />
                Data Overview
              </li>
            </Link>

            {(isSubmitFunctionEnabled)
              ? <Link to="/mandate" className="link">
                <li className={styles.sidebarListItem}>
                  <Assignment className={styles.sidebarIcon} />
                  Submit Survey
                </li>
              </Link>
              : <Tooltip title={isSubmitFunctionEnabled ? '' : submitDisabledMessage}>
                <Link className="disabled_link">
                  <li className={styles.sidebarListItemDisabled}>
                    <Assignment className={styles.sidebarIcon} />
                    Submit Survey
                  </li>
                </Link>
              </Tooltip>
            }

            <Link to="/previous-surveys" className="link">
              <li className={styles.sidebarListItem}>
                <Folder className={styles.sidebarIcon} />
                Previous Surveys             </li>
            </Link>

            <Link to="/respondentdashboardresearch" className="link">
              <li className={styles.sidebarListItem}>
                <Description className={styles.sidebarIcon} />
                Access Resources
              </li>
            </Link>

            <Link to="/profile/respondent/account/view" className="link">
              <li className={styles.sidebarListItem}>
                <Person className={styles.sidebarIcon} />
                Profile
              </li>
            </Link>



            <Link to="/logout" className="link">
              <li className={styles.sidebarListItem}>
                <button class={styles.logout}>Logout</button>
              </li>
            </Link>

          </ul>
        </div>
      </div>


      <Divider />
      <div>
        {/* help text etc */}
      </div>

    </div>


  );
}