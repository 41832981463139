import React, {useEffect, useState}  from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import spinner from '../../assets/images/loading-circle.gif';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));

function LoadingModal(props) {

    const classes = useStyles();
    const [open, setOpen] = useState(props.settings ? true : false); 
  
     
      return <div>

          <Modal
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.settings}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 10,
            }}
          >
            <img style={{marginLeft:0, width:130, heght:130}} src={spinner} alt="loading spinner"/>

          </Modal>
    </div>

}

export default LoadingModal;