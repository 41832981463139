import React, { Component } from "react";

import QuestionnairePart from './QuestionnairePart';
import QuestionnaireNavigationBar from './QuestionnaireNavigationBar';

import SpinnerModal from '@components/SpinnerModal';
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import SurveyService from '@services/SurveyService';
import DataCaptureService from "@services/DataCaptureService";
import styles from './questionnaire.module.css';

class Questionnaire extends Component {
    state = {
        loading: true,
        questionnaire: null,
        currentQuestionnairePartId: null,
        currentQuestionnairePart: null,
        capturedQuestions: [],
        validations: null,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        const questionnaire = await SurveyService.getQuestionnaireByOrganisationStateId(this.props.organisationStateId);

        this.setState({ questionnaire: questionnaire });

        if ((this.props.previousSurvey !== undefined && this.props.previousSurvey != null && this.props.previousSurvey == true
            && this.props.printview !== undefined && this.props.printview != null && this.props.printview == true && (this.props.captureType == 'RESPONDENT' || this.props.captureType == 'RESEARCHER_UPDATE'))
            || (this.props.printview !== undefined && this.props.printview != null && this.props.printview != true && this.props.captureType != 'RESEARCHER_SECOND')) {
            await SessionStateManager.initialiseSessionState(this.props.organisationStateId);
        }

        if (this.props.printview !== undefined && this.props.printview != null && this.props.printview != true && this.props.captureType == 'RESEARCHER_SECOND') {
            const capturedQuestions = await DataCaptureService.getCapturedQuestionsByDataCaptureId(this.props.dataCaptureId);
            this.setState({ capturedQuestions: capturedQuestions });
            await SessionStateManager.initialiseSessionStateByCapturedQuestions(this.props.organisationStateId, capturedQuestions);
        }

        const validations = await ValidationsManager.initialiseValidations(this.props.organisationStateId);
        this.setState({ validations: validations });

        if (this.state.currentQuestionnairePartId == null) {
            this.setState({ currentQuestionnairePartId: this.state.questionnaire.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1)[0].id });
        }

        if (this.state.currentQuestionnairePart == null) {
            this.setState({ currentQuestionnairePart: this.state.questionnaire.questionnaireParts.filter(questionnairePart => questionnairePart.id == this.state.currentQuestionnairePartId)[0] });
        }

        this.setState({ loading: false });
    }

    handleOnNext = (evt) => {
        this.setState({ loading: true });
        this.handleOnSave(evt);

        let thisValue = SessionStateManager.getSessionValue('954_0');
        if (this.props.captureType == 'RESPONDENT' && thisValue !== undefined && thisValue != null && (JSON.parse(thisValue) == true)) {
            if (this.props.onQuestionnaireComplete !== undefined && this.props.onQuestionnaireComplete != null) {
                this.props.onQuestionnaireComplete(evt);
            }
        } else {
            this.setState({ currentQuestionnairePartId: evt.target.value });
            this.setState({ currentQuestionnairePart: this.state.questionnaire.questionnaireParts.filter(questionnairePart => questionnairePart.id == this.state.currentQuestionnairePartId)[0] });
            this.setState({ sessionStateChanged: !this.state.sessionStateChanged });
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        this.setState({ loading: false });
    }

    handleOnChange = (evt) => {
        this.setState({ sessionStateChanged: true });
    }

    handleOnBlur = (evt) => {
        this.setState({ sessionStateChanged: true });
    }

    handleOnSave = (evt) => {
        SessionStateManager.persistChangeList(
            this.state.questionnaire.surveyCycle.id,
            this.state.questionnaire.sectors[0].id,
            this.props.organisationStateId,
            this.state.questionnaire.id,
            this.props.filledInById,
            this.props.capturedById,
            this.props.captureType,
            this.props.dataCaptureId
        );
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            return (
                <React.Fragment>
                    <div className={styles.featuredItem}>
                        <span className={styles.featuredTitleHeader}><h2>{this.state.questionnaire.name}</h2></span>
                        <hr />
                        <h5>
                            Organisation: {this.props.organisationStatus.organisationStateName}
                            <br />
                            Sector: {this.state.questionnaire.sectors[0].name}
                        </h5>
                        <hr />
                        {(this.props.printview !== undefined && this.props.printview == true)
                            ? <div >
                                {this.state.questionnaire.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1).map(questionnairePart => {
                                    return (
                                        <QuestionnairePart
                                            questionnairePart={questionnairePart}
                                            sectorId={this.state.questionnaire.sectors[0].id}
                                            onChange={this.handleOnChange}
                                            onBlur={this.handleOnBlur}
                                        />
                                    )
                                })}
                            </div>
                            : <div>
                                <div>
                                    {this.state.questionnaire.questionnaireParts.sort((a, b) => a.sequenceNumber > b.sequenceNumber ? 1 : -1).map(questionnairePart => {
                                        if (questionnairePart.id == this.state.currentQuestionnairePartId) {
                                            return <QuestionnairePart
                                                captureType={this.props.captureType}
                                                questionnairePart={questionnairePart}
                                                sectorId={this.state.questionnaire.sectors[0].id}
                                                onChange={this.handleOnChange}
                                                onBlur={this.handleOnBlur}
                                            />
                                        }
                                    })}
                                </div>
                                <div >
                                    <QuestionnaireNavigationBar
                                        captureType={this.props.captureType}
                                        questionnaireParts={this.state.questionnaire.questionnaireParts}
                                        currentQuestionnairePartId={this.state.currentQuestionnairePartId}
                                        onNext={(evt) => this.handleOnNext(evt)}
                                        onSubmit={this.props.onQuestionnaireComplete}
                                        onSave={(evt) => this.handleOnSave(evt)}
                                        onUpdateComplete={(evt) => this.handleOnSave(evt)}
                                        printview={this.props.printview}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </React.Fragment>
            )
        }
    }
}
export default Questionnaire;