import React, { Component } from 'react';
import axios from "axios";
import LoadingModal from '@components/LoadingModal';
import TableWidget from '@components/TableWidget';

import styles from './institutions.module.css';

const researcherBaseServiceURL =
  axios.create({
    baseURL: process.env.REACT_APP_RESEARCHER_DASHBOARD_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + '/researcherdashboardservice'
  });

class Institutions extends Component {
  state = {
    institutions: [],
  }

  constructor() {
    super();

    function parseJwt(token) {
      var base64Payload = token.split('.')[1];
      var payload = Buffer.from(base64Payload, 'base64');
      return JSON.parse(payload.toString());
    }

    let bearer = localStorage.getItem('Bearer');
    if (!bearer){
      window.location = "/login";
  
    }

    let payload = parseJwt(bearer);



    let researcherInstitutions = process.env.REACT_APP_RESEARCHER_DASHBOARD_MICROSERVICE_URL +  process.env.REACT_APP_SERVICES_VERSION + `/researcherdashboardservice/institutions`;
    let researcherInstitutionsURL = axios.create({ baseURL: researcherInstitutions });

    researcherInstitutionsURL.get('').then(
      resp => {
        const respData = resp.data;
        let institutionData = [];

        if (respData.status === "SUCCESS") {

          respData.response.success.forEach(record => {
            institutionData.push(record);
          });
        }

        this.setState({
          institutions: institutionData,
        });
      }
    );
  }

  render() {

    let listOfInstitutions = this.state.institutions;

    let columns = [
      { label: "Title", name: "Title" },
      { label: "First Name", name: "FirstName" },
      { label: "Last Name", name: "LastName" },
      { label: "Designation", name: "Designation" },
      { label: "Telephone", name: "Telephone" },
      { label: "Email", name: "Email" },
      { label: "Institution", name: "Institution" },
      { label: "Sector", name: "Sector" },
      { label: "Survey Cycle", name: "Survey" },
      { label: "Survey From Year", name: "SurveyFromYear" },
      { label: "Survey To Year", name: "SurveyToYear" },
    ];

    return (

      <div className={styles.container_institutions}>

        <div className={styles.container_row}>

          <TableWidget title="Organisations" rows={listOfInstitutions} columns={columns} />

        </div>

      </div>
    )
  }
}

export default Institutions