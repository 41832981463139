import DataCaptureService, {
    save,
    saveTable,
    startCapture,
    completeCapture,
    getAllSurveysByOrganisationIdAndSectorId,
    previousSurveysBySectorId,
    getDataCapturesByQuestionnaireTrackerId,
    getOrganisationStatusByOrganisationStateId,
    getOrganisationStatusesBySectorIdAndSurveyCycleId,
    getOrganisationStatusesBySectorIdAndSurveyCycleId_including_non_uom,
    getOrganisationStatusBySurveyCycleIdAndOrganisationId,
    getAllOrganisationContactsByOrganisationStateId,
    getAllOrganisationContactsBySurveyCycleId,
    getAllOrganisationContactsByOrganisationIdAndSurveyCycleId,
    setQuestionnaireTrackerFilledInBy,
    setOrganisationFieldworker,
    getCapturedQuestionsByDataCaptureId,
    getLookups,
    saveSurveyReassignment
} from './DataCaptureService';

export default {
    DataCaptureService,
    save,
    saveTable,
    startCapture,
    completeCapture,
    getAllSurveysByOrganisationIdAndSectorId,
    previousSurveysBySectorId,
    getDataCapturesByQuestionnaireTrackerId,
    getOrganisationStatusByOrganisationStateId,
    getOrganisationStatusesBySectorIdAndSurveyCycleId,
    getOrganisationStatusesBySectorIdAndSurveyCycleId_including_non_uom,
    getOrganisationStatusBySurveyCycleIdAndOrganisationId,
    getAllOrganisationContactsByOrganisationStateId,
    getAllOrganisationContactsBySurveyCycleId,
    getAllOrganisationContactsByOrganisationIdAndSurveyCycleId,
    setQuestionnaireTrackerFilledInBy,
    setOrganisationFieldworker,
    getCapturedQuestionsByDataCaptureId,
    getLookups,
    saveSurveyReassignment
}