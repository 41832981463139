// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\r\n.pagenotfound_container_pagenotfound__3pUOm {\r\n  width: 60%;\r\n  margin-top: 3%; \r\n  margin-left: 15%; \r\n  display: flex; \r\n  flex: 1 1; \r\n  flex-direction: column; \r\n  align-content: flex-center;\r\n  align-items: center;\r\n  align-self: center; }\r\n\r\n\r\n.pagenotfound_links__1uz-p {\r\n  font-family: Futura LT Book;\r\n  font-size: 18px;\r\n  line-height: 36px;\r\n  letter-spacing: 0.07em;\r\n  text-align: center;\r\n  color: #415167; }\r\n", "",{"version":3,"sources":["webpack://src/pages/PageNotFound/pagenotfound.module.css"],"names":[],"mappings":";AACA;EACE,UAAU;EACV,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,0BAA0B;EAC1B,mBAAmB;EACnB,kBAAkB,EAAE;;;AAGtB;EACE,2BAA2B;EAC3B,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc,EAAE","sourcesContent":["\r\n.container_pagenotfound {\r\n  width: 60%;\r\n  margin-top: 3%; \r\n  margin-left: 15%; \r\n  display: flex; \r\n  flex: 1; \r\n  flex-direction: column; \r\n  align-content: flex-center;\r\n  align-items: center;\r\n  align-self: center; }\r\n\r\n\r\n.links {\r\n  font-family: Futura LT Book;\r\n  font-size: 18px;\r\n  line-height: 36px;\r\n  letter-spacing: 0.07em;\r\n  text-align: center;\r\n  color: #415167; }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_pagenotfound": "pagenotfound_container_pagenotfound__3pUOm",
	"links": "pagenotfound_links__1uz-p"
};
export default ___CSS_LOADER_EXPORT___;
