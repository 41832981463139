import React, {Component} from "react";

  
//import './style.css';

class InputBox extends Component {
    

    render() {
        return (
            <div style={{marginTop:30}}> 
                <input className={this.props.type != 'password' ? 'input__box' : 'input__box__password'}></input>
            </div>
        );
    }

}
export default InputBox;