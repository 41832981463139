import React, {Component} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./style.css"

class MultiColorTitle extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
                <h1 className={this.props.title_font+"__"+this.props.first_color}>
                    <text className={this.props.title_font+"__"+this.props.first_color}>{this.props.first_text}</text>
                    <text>&nbsp;</text>
                    <text className={this.props.title_font+"__"+this.props.second_color}>{this.props.second_text}</text>
                    <text>&nbsp;</text>
                    <text className={this.props.title_font+"__"+this.props.third_color}>{this.props.third_text}</text>
                </h1>
        );
    }

}
export default MultiColorTitle;