import React, { Component, useState } from "react";
import Grid from '@material-ui/core/Grid';
import DataCaptureService from "@services/DataCaptureService";
import ProfileManagementService from "@services/ProfileManagementService";
import SpinnerModal from "@components/SpinnerModal";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from './addresearcherdialog.module.css';
import DropDownMenu from '@components/DropDownMenu';
import SupportAlert from '@components/SupportAlert';
import TextField from '@material-ui/core/TextField';
import FieldValidator from '@components/FieldValidator';
import SurveyService from '@services/SurveyService';

class AddResearcherDialog extends Component {
    state = {
        loading: false,
        organisationsLoading: false,
        contactsLoading: false,
        realmUsers: this.props.realmUsers,

        emailAddress: '',
        firstName: '',
        lastName: '',
        designation: '',
        role: '',
        cellphone: '',
        workPhone: '',

        validEmail: false,
        validFirstName: false,
        validLastName: false,
        validCellphone: true,
        validWorkPhone: true,

        allOrganisationContacts: [],

        allSurveysUsers: [],
        surveysUsersDropdown: [],
        selectedSurveysUser: null,
        currentSurveyCycle: process.env.REACT_APP_CURRENT_SURVEY_CYCLE,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ realmUsers: this.props.realmUsers });

        const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
        this.setState({ allSurveysUsers: surveysUsers });

        this.state.allSurveysUsers.filter((surveysUser) => surveysUser.active == true).map(item => {
            this.state.surveysUsersDropdown.push({ key: item.id, value: this.getSurveysUserNameById(item.id) });
        });

        this.setState({ loading: false });
    }

    getSurveysUserNameById = (userId) => {
        let name = (this.state.allSurveysUsers.filter(surveysUser => surveysUser.id == userId)[0].firstname + ' ' + this.state.allSurveysUsers.filter(surveysUser => surveysUser.id == userId)[0].lastname);
        return name;
    }

    userAlreadyExist = () => {
        return this.state.realmUsers.some(item => item.email.toLowerCase() === this.state.selectedSurveysUser.emailaddress.toLowerCase());
    }

    handleChangedValue = (evt) => {
        switch (evt.target.name) {
            case 'emailAddress': {
                this.setState({ validEmail: FieldValidator.validateEmail(evt.target.value) });
                this.setState({ emailAddress: evt.target.value });
                break;
            }
            case 'firstName': {
                this.setState({ validFirstName: FieldValidator.validateName(evt.target.value) });
                this.setState({ firstName: evt.target.value });
                break;
            }
            case 'lastName': {
                this.setState({ validLastName: FieldValidator.validateName(evt.target.value) });
                this.setState({ lastName: evt.target.value });
                break;
            }
            case 'cellphone': {
                this.setState({ validCellphone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ cellphone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'workPhone': {
                this.setState({ validWorkPhone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ workPhone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'designation': {
                this.setState({ designation: evt.target.value });
                break;
            }
            case 'surveysUser': {
                this.setState({ validSurveysUser: evt.target.value.trim().length > 0 });
                let surveysUser = this.state.allSurveysUsers.filter(surveysUser => surveysUser.id == evt.target.value)[0];
                if (surveysUser !== undefined && surveysUser !== null) {
                    this.setState({ selectedSurveysUser: surveysUser });
                    this.setState({ firstName: surveysUser?.firstname });
                    this.setState({ validFirstName: FieldValidator.validateName(surveysUser?.firstname) });
                    this.setState({ lastName: surveysUser?.lastname });
                    this.setState({ validLastName: FieldValidator.validateName(surveysUser?.lastname) });
                    this.setState({ emailAddress: surveysUser?.emailaddress });
                    this.setState({ validEmail: FieldValidator.validateDomainEmail(surveysUser?.emailaddress, '@hsrc.ac.za') });
                } else {
                    let nullUser = { "id": null, "firstname": "", "lastname": "", "active": null, "emailaddress": "", "password": "" }
                    this.setState({ selectedSurveysUser: nullUser });
                    this.setState({ emailAddress: '' });
                    this.setState({ validEmail: false });
                    this.setState({ firstName: '' });
                    this.setState({ validFirstName: false });
                    this.setState({ lastName: '' });
                    this.setState({ validLastName: false });
                    this.setState({ cellphone: '' });
                    this.setState({ workPhone: '' });
                    this.setState({ designation: '' });
                }
                break;
            }
            default: {
                break;
            }
        }
    }

    getData() {

        let data = {
            userid: '',
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: null,
            enabled: true,
            email: this.state.emailAddress,
            attributes: {
                group_id: ["RESEARCHER"],
                source_of_data: [22],
                user_id: [parseInt(this.state.selectedSurveysUser?.id)],
                sector_id: [parseInt(this.state.selectedSectorId)],
                survey_cycle_id: [parseInt(this.state.currentSurveyCycle)],
                org_id: [parseInt(this.state.selectedOrganisationId)],
                org_state_id: [this.state.selectedOrganisationStateId],
                cellphone: [this.state.cellphone],
                workphone: [this.state.workPhone],
                designation: [this.state.designation],
            },
            realmRoles: ['dsp_researcher', 'dsp_respondent'],
            clientRoles: {
                role: ['researchers']
            },

        };

        return data;

    }

    dialogBody() {

        return <div className={styles.flex_row_container}>
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"RDSMS Researcher"}</text>
                </h1>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <DropDownMenu
                                        name={'surveysUser'}
                                        label={'RDSMS User'}
                                        value={this.state.surveysUserId}
                                        onChange={this.handleChangedValue}
                                        showKeys={false}
                                        menuOptions={this.state.surveysUsersDropdown.sort((a, b) => a.value > b.value ? 1 : -1)}
                                        required
                                        helperText={(this.state.selectedSurveysUser !== null && this.userAlreadyExist()) ? 'User already registered on RDI' : null}
                                        error={(this.state.selectedSurveysUser !== null && this.userAlreadyExist())}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
            </div>
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"Researcher Details"}</text>
                </h1>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        error={this.state.selectedSurveysUser !== undefined && this.state.selectedSurveysUser !== null && this.state.selectedSurveysUser.firstname.length > 0 && !this.state.validFirstName}
                                        helperText={!FieldValidator.validateName(this.state.selectedSurveysUser?.firstname) ? 'Invalid First Name' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'firstName'}
                                        type="text"
                                        placeholder={'First Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'First Name'}
                                        required
                                        value={this.state.selectedSurveysUser?.firstname}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >
                                    <TextField
                                        error={this.state.selectedSurveysUser !== undefined && this.state.selectedSurveysUser !== null && this.state.selectedSurveysUser.lastname.length > 0 && !this.state.validLastName}
                                        helperText={!FieldValidator.validateName(this.state.selectedSurveysUser?.lastname) ? 'Invalid First Name' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'lastName'}
                                        type="text"
                                        placeholder={'Last Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Last Name'}
                                        required
                                        value={this.state.selectedSurveysUser?.lastname}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        error={this.state.cellphone.length > 0 && !this.state.validCellphone}
                                        helperText={!this.state.validCellphone ? 'Invalid cellphone number' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'cellphone'}
                                        type="text"
                                        placeholder={'072 - 309 - 4059'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Cellphone'}
                                        required={false}
                                        value={this.state.cellphone}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >
                                    <TextField
                                        error={this.state.workPhone.length > 0 && !this.state.validWorkPhone}
                                        helperText={!this.state.validWorkPhone ? 'Invalid work phone number' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'workPhone'}
                                        type="text"
                                        placeholder={'011 - 238 - 4859'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Work Telephone'}
                                        required={false}
                                        value={this.state.workPhone}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        className={styles.dialogInputs}
                                        name={'designation'}
                                        type="text"
                                        placeholder={'Designation'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Designation'}
                                        value={this.state.designation}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>


            </div>
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"Account Log in Details"}</text>
                </h1>
                <p>
                    <TextField
                        disabled
                        error={(this.state.selectedSurveysUser !== undefined && this.state.selectedSurveysUser !== null && this.state.selectedSurveysUser.emailaddress.length >= 0 && !this.state.validEmail)}
                        helperText={(this.state.selectedSurveysUser !== undefined && this.state.selectedSurveysUser !== null && this.state.selectedSurveysUser.emailaddress.length >= 0 && !this.state.validEmail) ? 'Invalid email address stored on RDSMS. Should be like ****@hsrc.ac.za' : null}
                        className={styles.dialogInputs}
                        require="required"
                        name={'emailAddress'}
                        type="text"
                        placeholder={'name@business.co.za'}
                        id="outlined-basic"
                        size="small"
                        label={'Email Address'}
                        required
                        value={this.state.selectedSurveysUser?.emailaddress}
                        onChange={this.handleChangedValue}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </p>
            </div>
        </div>
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} label={'Dialog loading ...'} />
        } else {
            return <DialogWrapper
                {...this.props}
                onClick={(evt) => this.props.nextButton.onClick(evt, this.getData())}
                validEmail={this.state.validEmail && (this.state.selectedContact !== null && !this.userAlreadyExist())}
                content={
                    <React.Fragment>
                        {this.dialogBody()}
                    </React.Fragment>
                }
            />
        }
    }

}
export default AddResearcherDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            if (
                                !props.validEmail
                            ) {
                                SupportAlert.displayAlert('User registeration failed', 'Invalid email address stored on RDSMS for this user or this user is already registered to use RDI. Please review supplied information carefully.');
                            } else {
                                props.onClick(evt);
                                dialog.close(true);
                            }
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}

