import React, { useState, Component } from 'react';
import MUIDataTable from 'mui-datatables';
import TableWidget from '@components/TableWidget';
import DataCaptureService from '@services/DataCaptureService';
import FieldworkManagementService from '@services/FieldworkManagementService';
import ProfileManagementService from '@services/ProfileManagementService';
import SessionStateManager from '@components/SessionStateManager';
import SectorViewDialog from '@components/SectorViewDialog';
import ResearcherViewDialog from '@components/ResearcherViewDialog';
import SurveyService from '@services/SurveyService';
import styles from '../fieldwork.module.css';
import { CustomDialog, useDialog } from 'react-st-modal';
import SpinnerModal from '@components/SpinnerModal';
import moment from 'moment';
import CSVGenerator from '@components/CSVGenerator';
import NumberTools from '@components/NumberTools';

class FieldworkReporting extends Component {

    state = {

        loading: false,
        organisationsLoading: false,
        selectedSectorId: null,
        surveysUsers: null,
        allSectors: [],
        allSurveyCycles: [],
        allOrganisationContacts: [],
        allOrganisationStatuses: [],
        previousYearOrganisationStatuses: [],
        interactionHistory: [],
        interactionTypes: [],
        logicalErrors: [],
        surveyCycleId: process.env.REACT_APP_CURRENT_SURVEY_CYCLE,
    }

    constructor(props) {
        super(props);
    }

    parseJwt(token) {
        var base64Payload = token.split('.')[1];
        var payload = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payload.toString());
    }

    async componentDidMount() {
        this.setState({ loading: true });

        SessionStateManager.clear();

        const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
        this.setState({ surveysUsers: surveysUsers });

        const sectors = await SurveyService.getAllSectors();
        sectors.map(item => this.state.allSectors.push({ key: item.id, value: item.name.trim() }));

        const surveyCycles = await SurveyService.getAllSurveyCycles();
        surveyCycles.filter(surveyCycle => surveyCycle.id <= process.env.REACT_APP_CURRENT_SURVEY_CYCLE).map(item => this.state.allSurveyCycles.push({ id: item.id, label: (item.referenceFromYear + '/' + item.referenceToYear) }));

        const organisationContacts = await DataCaptureService.getAllOrganisationContactsBySurveyCycleId(this.state.surveyCycleId);
        this.setState({ allOrganisationContacts: organisationContacts });

        const savedInteractionHistory = await FieldworkManagementService.getInteractionsBySurveyCycle(this.state.surveyCycleId);
        if (savedInteractionHistory !== undefined && savedInteractionHistory !== null && savedInteractionHistory !== '') {
            this.setState({ interactionHistory: savedInteractionHistory });
        }

        const sourcesOfData = await DataCaptureService.getLookups();

        let interactionTypes = [];
        sourcesOfData.filter((lookup) => (lookup.type.includes('_INTERACTION_'))).map(item => {
            interactionTypes.push({ key: item.varenum, value: item.name });
        });
        this.setState({ interactionTypes: interactionTypes });

        this.reloadOrganisations(this.state.selectedSectorId, this.state.surveyCycleId);

        this.loadLogicalErrors();

        this.setState({ loading: false });
    }

    async loadLogicalErrors() {
        this.setState({ organisationsLoading: true });

        await (async () => {
            const logicalErrors = await FieldworkManagementService.getAllLogicalErrorsBySurveyCycle(process.env.REACT_APP_CURRENT_SURVEY_CYCLE);
            this.setState({ logicalErrors: logicalErrors });
            return logicalErrors;
        })([]);

        this.setState({ organisationsLoading: false });
    }

    async reloadOrganisations(sectorId, surveyCycleId) {
        this.setState({ organisationsLoading: true });

        try {
            sectorId = (sectorId !== undefined && sectorId !== null && sectorId !== '') ? sectorId : null;
            this.setState({ selectedSectorId: sectorId });

            const organisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId(sectorId, surveyCycleId);
            this.setState({ allOrganisationStatuses: organisationStatuses });

            let previousSurveyCycle = this.state.allSurveyCycles.filter(sc => sc.id <= process.env.REACT_APP_CURRENT_SURVEY_CYCLE).sort((a, b) => a.id < b.id ? 1 : -1)[1];

            const previousOrganisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId(sectorId, previousSurveyCycle.id);
            this.setState({ previousYearOrganisationStatuses: previousOrganisationStatuses });

            this.setState({ organisationsLoading: false });
        } catch (ex) {
            // SupportAlert.displayAlert('Network Error', 'An unexpected network error occured while loading the list of organisations. Please contact support at echosupport@hsrc.ac.za.');
        }

        this.setState({ organisationsLoading: false });
    }

    getSurveysUserNameById(userId) {
        let name = (this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].firstname + ' ' + this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0].lastname);
        name = userId == 37 ? 'RDI Respondent' : name;
        return name;
    }

    calculateGerd() {
        let totalGerd = 0;

        this.state.allOrganisationStatuses
            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
            .filter(orgStatus => (orgStatus.expenditure !== undefined && orgStatus.expenditure !== null && orgStatus.expenditure > 0))
            .map(orgStatus => {
                totalGerd = totalGerd + (isNaN(orgStatus.expenditure) ? 0 : orgStatus.expenditure);
            });

        return totalGerd;
    }

    async onSectorClicked(rowData, rowMeta) {
        let sectorId = rowData[0];

        let title = 'Sector: ' + rowData[1];

        if (sectorId !== undefined && sectorId !== null && sectorId > 0) {
            const result = await CustomDialog(
                <SectorViewDialog
                    {...this.props}
                    backButton={{ text: 'Close' }}
                    sectorId={sectorId}
                    sectorName={rowData[1]}
                />,
                {
                    className: styles.sectorViewDialog,
                    title: title,
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );
        }
    }

    async onResearcherClicked(rowData, rowMeta) {
        let fieldworkerId = rowData[0];

        let title = 'Researcher: ' + rowData[1] + ' | Sector: All Sectors';

        const result = await CustomDialog(
            <ResearcherViewDialog
                {...this.props}
                backButton={{ text: 'Close' }}
                sectorId={null}
                sectorName={'all sectors'}
                fieldworkerId={fieldworkerId}
            />,
            {
                className: styles.sectorViewDialog,
                title: title,
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }

    async onInteractionClicked(rowData, rowMeta) {
        let interactionId = rowData[0];

        // Here is where we can load a popup to show inrteraction details not visible in the overview
        // eg.
        // const result = await CustomDialog(
        //     <InteractionViewDialog
        //         {...this.props}
        //         backButton={{ text: 'Close' }}
        //         interactionId={interactionId}
        //     />,
        //     {
        //         className: styles.interactionViewDialog,
        //         title: title,
        //         showCloseIcon: false,
        //         isFocusLock: true,
        //         isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
        //     }
        // );
    }

    interactionOverviewTable() {
        let interactionTableData = [];

        let interactionColumns = [
            {
                label: "id", name: "interactionId"
                , options: {
                    display: false,
                }
            },
            { label: "Researcher", name: "researcherName" },
            { label: "Firm/Unit", name: "organisationName" },
            { label: "Status", name: "interactionStatus" },
            { label: "Interaction Type", name: "interactionType" },
            { label: "Day", name: "interactionDay" },
            { label: "Date/Time", name: "interactionDate" },
        ];

        if (this.state.interactionHistory !== undefined && this.state.interactionHistory !== null && this.state.interactionHistory.length > 0) {
            let filteredInteractions = this.state.interactionHistory
                .filter(interaction => (interaction.interactionType.includes('RESPONDENT_INTERACTION_CALL')
                    || interaction.interactionType.includes('RESPONDENT_INTERACTION_EMAIL')
                    || interaction.interactionType.includes('RESPONDENT_INTERACTION_SITE_VISIT')
                    || interaction.interactionType.includes('RESPONDENT_INTERACTION_VIRTUAL_MEETING')
                ));

            filteredInteractions.sort((a, b) => a.id < b.id ? 1 : -1)
                .map((interaction) => {
                    if (this.state.allOrganisationStatuses != undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length > 0) {
                        let organisationStatus = this.state.allOrganisationStatuses.filter(organisationStatus => organisationStatus.organisationStateId == interaction.organisationStateId)[0];

                        if (organisationStatus != undefined && organisationStatus !== null) {
                            let org_name = ((organisationStatus.parentOrganisationStateName !== undefined && organisationStatus.parentOrganisationStateName !== null && organisationStatus.parentOrganisationStateName !== '') ? organisationStatus.parentOrganisationStateName + ' / ' : '') + organisationStatus.organisationStateName;

                            let row = {
                                interactionId: interaction.id,
                                researcherName: this.getSurveysUserNameById(interaction.interactedBy),
                                organisationName: org_name,
                                interactionStatus: this.state.interactionTypes.filter(i => i.key == interaction.interactionStatus)[0].value,
                                interactionType: this.state.interactionTypes.filter(i => i.key == interaction.interactionType)[0].value,
                                interactionDay: moment(new Date(interaction.interactionDate)).format('ddd'),
                                interactionDate: moment(new Date(interaction.interactionDate)).format('DD MMM YYYY HH:mm'),
                            }
                            interactionTableData.push(row);
                        }

                    }
                });
        }

        let tableTitle = 'Overview by Interactions'.toUpperCase();

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [5],
            rowsPerPageOptions: [5, 10, 25, 100],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            row.data.shift();
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        return <div className={styles.flex_row_container}>
            <MUIDataTable
                title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                data={interactionTableData}
                columns={interactionColumns}
                options={options}
            />
        </div>
    }

    logicalErrorsOverviewTable() {
        let logicalErrorTableData = [];

        let logicalErrorsColumns = [
            { label: "Firm/Unit", name: "organisationName" },
            { label: "Sector", name: "sectorName" },
            { label: "Ommission", name: "ommission" },
            { label: "Coding", name: "coding" },
            { label: "Range", name: "range" },
            { label: "Summation", name: "summation" },
        ];

        if (this.state.logicalErrors !== undefined && this.state.logicalErrors !== null && this.state.logicalErrors.length > 0) {
            this.state.logicalErrors.sort((a, b) => a.id < b.id ? 1 : -1)
                .map((logicalError) => {
                    if (this.state.allOrganisationStatuses != undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length > 0) {
                        let organisationStatus = this.state.allOrganisationStatuses.filter(organisationStatus => organisationStatus.questionnaireTrackerId == logicalError.questionnaireTrackerId)[0];
                        let org_name = ((organisationStatus.parentOrganisationStateName !== undefined && organisationStatus.parentOrganisationStateName !== null && organisationStatus.parentOrganisationStateName !== '') ? organisationStatus.parentOrganisationStateName + ' / ' : '') + organisationStatus.organisationStateName;

                        if (this.state.allSectors !== undefined && this.state.allSectors !== null && this.state.allSectors.length > 0) {
                            let sectorName = this.state.allSectors.filter(sector => sector.key == organisationStatus.sectorId)[0].value;

                            let row = {
                                organisationName: org_name,
                                sectorName: sectorName,
                                ommission: (!isNaN(parseFloat(logicalError.ommission)) ? logicalError.ommission : 0),
                                coding: (!isNaN(parseFloat(logicalError.coding)) ? logicalError.coding : 0),
                                range: (!isNaN(parseFloat(logicalError.range)) ? logicalError.range : 0),
                                summation: (!isNaN(parseFloat(logicalError.summation)) ? logicalError.summation : 0),
                            }
                            logicalErrorTableData.push(row);
                        }
                    }
                });
        }

        let tableTitle = 'Overview of Logical Errors'.toUpperCase();

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [5],
            rowsPerPageOptions: [5, 10, 25, 100],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        return <div className={styles.flex_row_container}>
            <MUIDataTable
                title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                data={logicalErrorTableData.sort((a, b) => a.organisationName > b.organisationName ? 1 : -1)}
                columns={logicalErrorsColumns}
                options={options}
            />
        </div>
    }

    sectorOverviewTable() {
        let totalGerd = this.calculateGerd();

        let sectorTableData = [];

        let sectorColumns = [
            {
                label: "id", name: "sectorId"
                , options: {
                    display: false,
                }
            },
            { label: "Sector", name: "sectorName" },
            { label: "In Field", name: "infieldCount" },
            { label: "Returned", name: "returnedCount" },
            { label: "Nil Returns", name: "nilReturnCount" },
            { label: "Responses (not nil)", name: "notNilCount" },
            { label: "% Nils vs Returns", name: "percentageNils" },
            { label: "R&D Expenditure (R`000)", name: "gerdValue" },
            { label: "% of GERD ( R " + NumberTools.transformToCurrency((totalGerd / 1000)) + ')', name: "percentageGerd" },
        ];

        let tableTitle = 'Returns counts & Expenditure per sector'.toUpperCase();

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [6],
            rowsPerPageOptions: [6, 10, 25, 100],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            onRowClick: this.onSectorClicked,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            row.data.shift();
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        if (this.state.allSectors !== undefined && this.state.allSectors !== null && this.state.allSectors.length > 0) {

            let infieldTotal = 0;
            let returnedTotal = 0;
            let nilTotal = 0;
            let notNilTotal = 0;
            let percentageNilTotal = 0;
            let gerdTotal = 0;
            let gerdPercentageTotal = 0;

            this.state.allSectors
                .sort((a, b) => a.key > b.key ? 1 : -1)
                .map((sector) => {
                    let infieldCount = 0;
                    let returnedCount = 0;
                    let nilReturnCount = 0;
                    let gerdSum = 0;

                    if (this.state.allOrganisationStatuses !== undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length > 0) {
                        infieldCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .length;

                        returnedCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .length;

                        nilReturnCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .filter(orgStatus => (orgStatus.nilReturn !== undefined && orgStatus.nilReturn !== null && orgStatus.nilReturn === true))
                            .length;

                        this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .filter(orgStatus => (orgStatus.expenditure !== undefined && orgStatus.expenditure !== null && orgStatus.expenditure > 0))
                            .map(orgStatus => {
                                gerdSum = gerdSum + (isNaN(orgStatus.expenditure) ? 0 : orgStatus.expenditure);
                            });
                    }

                    infieldTotal = infieldTotal + infieldCount;
                    returnedTotal = returnedTotal + returnedCount;
                    nilTotal = nilTotal + nilReturnCount;
                    notNilTotal = notNilTotal + (returnedCount - nilReturnCount);
                    percentageNilTotal = (returnedTotal > 0 ? ((100 * nilTotal) / returnedTotal) : 0);
                    gerdTotal = gerdTotal + gerdSum;
                    gerdPercentageTotal = gerdPercentageTotal + (totalGerd > 0 ? ((100 * gerdSum) / totalGerd) : 0);

                    let row = {
                        sectorId: sector.key,
                        sectorName: sector.value,
                        infieldCount: infieldCount,
                        returnedCount: returnedCount,
                        nilReturnCount: nilReturnCount,
                        notNilCount: (returnedCount - nilReturnCount),
                        percentageNils: (returnedCount > 0 ? ((100 * nilReturnCount) / returnedCount).toFixed(2) : 0) + ' %',
                        gerdValue: 'R ' + NumberTools.transformToCurrency(gerdSum / 1000),
                        percentageGerd: (totalGerd > 0 ? ((100 * gerdSum) / totalGerd) : 0).toFixed(2) + ' %',
                    }
                    sectorTableData.push(row);

                }
                );

            let row = {
                sectorId: 0,
                sectorName: 'Totals',
                infieldCount: infieldTotal,
                returnedCount: returnedTotal,
                nilReturnCount: nilTotal,
                notNilCount: notNilTotal,
                percentageNils: percentageNilTotal.toFixed(2) + ' %',
                gerdValue: 'R ' + NumberTools.transformToCurrency(gerdTotal / 1000),
                percentageGerd: gerdPercentageTotal.toFixed(2) + ' %',
            }
            sectorTableData.push(row);

        }

        return <div className={styles.flex_row_container}>
            <MUIDataTable
                title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                data={sectorTableData}
                columns={sectorColumns}
                options={options}
            />
        </div>
    }

    fieldworkerOverviewTable() {
        let totalGerd = this.calculateGerd();

        let fieldworkerTableData = [];

        let fieldworkerColumns = [
            {
                label: "Fieldworker ID", name: "fieldworkerId"
                , options: {
                    display: false,
                }
            },
            { label: "Researcher", name: "fieldworkerName" },
            { label: "In Field", name: "infieldCount" },
            { label: "Returned", name: "returnedCount" },
            { label: "Nil Returns", name: "nilReturnCount" },
            { label: "Responses (not nil)", name: "notNilCount" },
            { label: "% Nils vs Returns", name: "percentageNils" },
            { label: "R&D Expenditure (R`000)", name: "gerdValue" },
            { label: "% of GERD (R " + NumberTools.transformToCurrency(totalGerd / 1000) + ')', name: "percentageGerd" },
        ];

        let thisSurveysUsers = [];

        if (this.state.surveysUsers !== undefined && this.state.surveysUsers !== null && this.state.surveysUsers.length > 0) {
            this.state.surveysUsers.filter((surveysUser) => surveysUser.active == true).map(item => {
                thisSurveysUsers.push({ key: item.id, value: this.getSurveysUserNameById(item.id) });
            });

            thisSurveysUsers.map((surveysUser) => {
                if (surveysUser.key !== 37 && surveysUser.key !== 1) {
                    // Exclude super user and RDI online user
                    let infieldCount = 0;
                    let returnedCount = 0;
                    let nilReturnCount = 0;
                    let gerdSum = 0;

                    if (this.state.allOrganisationStatuses !== undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length > 0) {
                        infieldCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.fieldworkerId == surveysUser.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .length;

                        returnedCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.fieldworkerId == surveysUser.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .length;

                        nilReturnCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.fieldworkerId == surveysUser.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .filter(orgStatus => (orgStatus.nilReturn !== undefined && orgStatus.nilReturn !== null && orgStatus.nilReturn === true))
                            .length;

                        this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.fieldworkerId == surveysUser.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .filter(orgStatus => (orgStatus.expenditure !== undefined && orgStatus.expenditure !== null && orgStatus.expenditure > 0))
                            .map(orgStatus => {
                                gerdSum = gerdSum + (isNaN(orgStatus.expenditure) ? 0 : orgStatus.expenditure);
                            });
                    }

                    let row = {
                        fieldworkerId: surveysUser.key,
                        fieldworkerName: surveysUser.value,
                        infieldCount: infieldCount,
                        returnedCount: returnedCount,
                        nilReturnCount: nilReturnCount,
                        notNilCount: (returnedCount - nilReturnCount),
                        percentageNils: (returnedCount > 0 ? ((100 * nilReturnCount) / returnedCount) : 0).toFixed(2) + ' %',
                        gerdValue: 'R ' + NumberTools.transformToCurrency(gerdSum / 1000),
                        percentageGerd: (totalGerd > 0 ? ((100 * gerdSum) / totalGerd) : 0).toFixed(2) + ' %',
                    }
                    fieldworkerTableData.push(row);
                }

            });
        }

        let tableTitle = 'Returns count & expenditure per fieldworker'.toUpperCase();

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [5],
            rowsPerPageOptions: [5, 10, 25, 100],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            onRowClick: this.onResearcherClicked,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            row.data.shift();
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        return <div className={styles.flex_row_container}>
            <MUIDataTable
                title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                data={fieldworkerTableData}
                columns={fieldworkerColumns}
                options={options}
            />
        </div>
    }

    sectorComparisonYoYTable() {
        let sectorTableData = [];

        if (this.state.allSurveyCycles !== undefined && this.state.allSurveyCycles !== null && this.state.allSurveyCycles.length > 0) {
            let current = this.state.allSurveyCycles.filter(sc => sc.id <= process.env.REACT_APP_CURRENT_SURVEY_CYCLE).sort((a, b) => a.id < b.id ? 1 : -1)[0];
            let prev = this.state.allSurveyCycles.filter(sc => sc.id <= process.env.REACT_APP_CURRENT_SURVEY_CYCLE).sort((a, b) => a.id < b.id ? 1 : -1)[1];
            let previousSurveyCycleYear = prev.label;
            let currentSurveyCycleYear = current.label;

            let sectorColumns = [
                {
                    label: "SectorId", name: "sectorId"
                    , options: {
                        display: false,
                    }
                },
                { label: "Sector", name: "sectorName" },
                {
                    label: (previousSurveyCycleYear + " Frame"), name: "previousFrame"
                    , options: {
                        // display: false,      // uncommenting this hides the column
                    }
                },
                { label: (previousSurveyCycleYear + ' Returned SQs'), name: "previousReturned" },
                { label: (previousSurveyCycleYear + ' Percentage % Returns'), name: "previousPercentage" },
                {
                    label: (currentSurveyCycleYear + " Frame"), name: "currentFrame"
                    , options: {
                        // display: false,      // uncommenting this hides the column
                    }
                },
                { label: (currentSurveyCycleYear + ' Returned SQs'), name: "currentReturned"
                    // , options: {
                        // hint: "Frame: Dispatched + un-dispatched organisations"  // uncomment this to ad hint icon and hovertext
                        // display: false,      // uncommenting this hides the column
                    // }
                },
                { label: (currentSurveyCycleYear + ' Percentage % Returns'), name: "currentPercentage" },

            ];

            if (this.state.allSectors !== undefined && this.state.allSectors !== null && this.state.allSectors.length > 0) {
                let previousReturnedTotal = 0;
                let currentReturnedTotal = 0;
                let previousFrameTotal = 0;
                let currentFrameTotal = 0;

                this.state.allSectors
                    .sort((a, b) => a.key > b.key ? 1 : -1)
                    .map((sector) => {
                        let previousFrame = 0;
                        let previousReturned = 0;
                        let previousPercentage = 0;

                        let currentFrame = 0;
                        let currentReturned = 0;
                        let currentPercentage = 0;

                        if (this.state.previousYearOrganisationStatuses !== undefined && this.state.previousYearOrganisationStatuses !== null && this.state.previousYearOrganisationStatuses.length > 0) {

                            previousFrame = this.state.previousYearOrganisationStatuses
                                .filter(orgStatus => orgStatus.sectorId == sector.key)
                                .length;

                            previousReturned = this.state.previousYearOrganisationStatuses
                                .filter(orgStatus => orgStatus.sectorId == sector.key)
                                .filter(orgStatus => (orgStatus.receiptNumber !== undefined && orgStatus.receiptNumber !== null))
                                .filter(orgStatus => orgStatus.questionnairePartStatus == 'CHECKED_READY_FOR_CAPTURE')
                                .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                                .filter(orgStatus => (orgStatus.datacapture1CapturedById !== null && orgStatus.datacapture1CapturedById > 0))
                                .filter(orgStatus => (orgStatus.datacapture2CapturedById !== null && orgStatus.datacapture2CapturedById > 0))
                                .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                                .length;

                            previousPercentage = (previousReturned * 100) / previousFrame;
                        }

                        if (this.state.allOrganisationStatuses !== undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length > 0) {
                            currentFrame = this.state.allOrganisationStatuses
                                .filter(orgStatus => orgStatus.sectorId == sector.key)
                                .length;

                            currentReturned = this.state.allOrganisationStatuses
                                .filter(orgStatus => orgStatus.sectorId == sector.key)
                                .filter(orgStatus => (orgStatus.receiptNumber !== undefined && orgStatus.receiptNumber !== null))
                                .filter(orgStatus => orgStatus.questionnairePartStatus == 'CHECKED_READY_FOR_CAPTURE')
                                .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                                .filter(orgStatus => (orgStatus.datacapture1CapturedById !== null && orgStatus.datacapture1CapturedById > 0))
                                .filter(orgStatus => (orgStatus.datacapture2CapturedById !== null && orgStatus.datacapture2CapturedById > 0))
                                .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                                .length;

                            currentPercentage = (currentReturned * 100) / currentFrame;

                        }

                        previousFrameTotal = previousFrameTotal + previousFrame;
                        currentFrameTotal = currentFrameTotal + currentFrame;

                        previousReturnedTotal = previousReturnedTotal + previousReturned;
                        currentReturnedTotal = currentReturnedTotal + currentReturned;

                        let row = {
                            sectorId: sector.key,
                            sectorName: sector.value,
                            previousFrame: previousFrame,
                            currentFrame: currentFrame,
                            previousReturned: previousReturned,
                            previousPercentage: previousPercentage.toFixed(2) + ' %',
                            currentReturned: currentReturned,
                            currentPercentage: currentPercentage.toFixed(2) + ' %',
                        }
                        sectorTableData.push(row);

                    });

                let row = {
                    sectorId: 0,
                    sectorName: 'Total',
                    previousFrame: previousFrameTotal,
                    currentFrame: currentFrameTotal,
                    previousReturned: previousReturnedTotal,
                    currentReturned: currentReturnedTotal,
                }
                sectorTableData.push(row);

            }

            let tableTitle = 'Year on year comparison per sector'.toUpperCase();

            let options = {
                padding: 'none',
                size: 'small',
                rowsPerPage: [6],
                rowsPerPageOptions: [6, 10, 25, 100],
                jumpToPage: true,
                selectableRowsHideCheckboxes: true,
                // onRowClick: this.onInteractionClicked,
                textLabels: {
                    toolbar: {
                        downloadCsv: "Export to excel",
                    }
                },
                onDownload: (buildHead, buildBody, columns, data) => {
                    const func = (async () => {
                        await CSVGenerator.exportTableToXLS({
                            fileName: tableTitle,
                            columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                            fileData: data.map(row => {
                                row.data.shift();
                                return row.data;
                            })
                        })
                    })([]);
                    return false;
                },
            };

            return <div className={styles.flex_row_container}>
                <MUIDataTable
                    title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                    data={sectorTableData}
                    columns={sectorColumns}
                    options={options}
                />
            </div>
        }
    }

    sectorCaptureStatusOverviewTable() {
        let sectorTableData = [];

        let sectorColumns = [
            {
                label: "SectorId", name: "sectorId"
                , options: {
                    display: false,
                }
            },
            { label: "Sector", name: "sectorName" },
            { label: "Frame Units", name: "frameCount" },
            { label: "RDI Returns In Progress", name: "rdiIncompleteCount" },
            { label: "RDI Returns Completed", name: "rdiCompleteCount" },
            { label: "PDF Returns In Progress", name: "pdfIncompleteCount" },
            { label: "PDF Returns Completed", name: "pdfCompleteCount" },
            { label: "Capture completed", name: "capturedCount" },
            { label: "Not received back", name: "notReceivedCount" },
        ];

        if (this.state.allSectors !== undefined && this.state.allSectors !== null && this.state.allSectors.length > 0) {
            let frameTotal = 0;
            let rdiCompleteTotal = 0;
            let rdiIncompleteTotal = 0;
            let pdfCompleteTotal = 0;
            let pdfIncompleteTotal = 0;
            let capturedTotal = 0;
            let notReceivedTotal = 0;

            this.state.allSectors
                .sort((a, b) => a.key > b.key ? 1 : -1)
                .map((sector) => {
                    let frameCount = 0;
                    let rdiCompleteCount = 0;
                    let rdiIncompleteCount = 0;
                    let pdfCompleteCount = 0;
                    let pdfIncompleteCount = 0;
                    let checkedCount = 0;
                    let capturedCount = 0;
                    let notReadyCount = 0;
                    let notReceivedCount = 0;

                    if (this.state.allOrganisationStatuses !== undefined && this.state.allOrganisationStatuses !== null && this.state.allOrganisationStatuses.length > 0) {
                        frameCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .length;

                        rdiCompleteCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => (orgStatus.receiptNumber !== undefined && orgStatus.receiptNumber !== null))
                            .filter(orgStatus => orgStatus.questionnairePartStatus == 'CHECKED_READY_FOR_CAPTURE')
                            .filter(orgStatus => orgStatus.datacapture1CapturedById == 37)
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .length;
                        
                        rdiIncompleteCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => (orgStatus.receiptNumber !== undefined && orgStatus.receiptNumber !== null))
                            .filter(orgStatus => orgStatus.questionnairePartStatus == 'CHECKED_READY_FOR_CAPTURE')
                            .filter(orgStatus => orgStatus.datacapture1CapturedById == 37)
                            .filter(orgStatus => (orgStatus.datacapture1Completed === null || !orgStatus.datacapture1Completed))
                            .length;
                        
                        pdfCompleteCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => (orgStatus.receiptNumber !== undefined && orgStatus.receiptNumber !== null))
                            .filter(orgStatus => orgStatus.questionnairePartStatus == 'CHECKED_READY_FOR_CAPTURE')
                            .filter(orgStatus => orgStatus.datacapture1CapturedById != 37)
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .length;
                        
                        pdfIncompleteCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => (orgStatus.receiptNumber !== undefined && orgStatus.receiptNumber !== null))
                            .filter(orgStatus => orgStatus.questionnairePartStatus == 'CHECKED_READY_FOR_CAPTURE')
                            .filter(orgStatus => orgStatus.datacapture1CapturedById != 37)
                            .filter(orgStatus => (!orgStatus.datacapture1Completed || !orgStatus.datacapture2Completed))
                            .length;

                        checkedCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => (orgStatus.receiptNumber !== undefined && orgStatus.receiptNumber !== null))
                            .filter(orgStatus => orgStatus.questionnairePartStatus == 'CHECKED_READY_FOR_CAPTURE')
                            .filter(orgStatus => orgStatus.datacapture1Id === null || !orgStatus.datacapture1Completed)
                            .length;

                        capturedCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => (orgStatus.receiptNumber !== undefined && orgStatus.receiptNumber !== null))
                            .filter(orgStatus => orgStatus.questionnairePartStatus == 'CHECKED_READY_FOR_CAPTURE')
                            .filter(orgStatus => orgStatus.datacapture1Completed && orgStatus.datacapture2Completed)
                            .length;

                        notReadyCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                            .filter(orgStatus => (orgStatus.receiptNumber !== undefined && orgStatus.receiptNumber !== null))
                            .filter(orgStatus => orgStatus.questionnairePartStatus !== 'CHECKED_READY_FOR_CAPTURE')
                            .length;
                        
                        let receivedCount = this.state.allOrganisationStatuses
                            .filter(orgStatus => orgStatus.sectorId == sector.key)
                            .filter(orgStatus => (orgStatus.receiptNumber !== undefined && orgStatus.receiptNumber !== null))
                            .length;
                        
                        notReceivedCount = frameCount - receivedCount;

                    }

                    frameTotal = frameTotal + frameCount;
                    rdiCompleteTotal = rdiCompleteTotal + rdiCompleteCount;
                    rdiIncompleteTotal = rdiIncompleteTotal + rdiIncompleteCount;
                    pdfCompleteTotal = pdfCompleteTotal + pdfCompleteCount;
                    pdfIncompleteTotal = pdfIncompleteTotal + pdfIncompleteCount;
                    capturedTotal = capturedTotal + capturedCount;
                    notReceivedTotal = notReceivedTotal + notReceivedCount;

                    let row = {
                        sectorId: sector.key,
                        sectorName: sector.value,
                        frameCount: frameCount,
                        rdiCompleteCount: rdiCompleteCount,
                        rdiIncompleteCount: rdiIncompleteCount,
                        pdfCompleteCount: pdfCompleteCount,
                        pdfIncompleteCount: pdfIncompleteCount,
                        capturedCount: capturedCount,
                        notReceivedCount: notReceivedCount,
                    }
                    sectorTableData.push(row);

                });

            let row = {
                sectorId: 0,
                sectorName: 'Grand Total',
                frameCount: frameTotal,
                rdiCompleteCount: rdiCompleteTotal,
                rdiIncompleteCount: rdiIncompleteTotal,
                pdfCompleteCount: pdfCompleteTotal,
                pdfIncompleteCount: pdfIncompleteTotal,
                capturedCount: capturedTotal,
                notReceivedCount: notReceivedTotal,
            }
            sectorTableData.push(row);

        }

        let tableTitle = 'Returns received/checked/completed per sector'.toUpperCase();

        let options = {
            padding: 'none',
            size: 'small',
            rowsPerPage: [6],
            rowsPerPageOptions: [6, 10, 25, 100],
            jumpToPage: true,
            selectableRowsHideCheckboxes: true,
            // onRowClick: this.onResearcherClicked,
            textLabels: {
                toolbar: {
                    downloadCsv: "Export to excel",
                }
            },
            onDownload: (buildHead, buildBody, columns, data) => {
                const func = (async () => {
                    await CSVGenerator.exportTableToXLS({
                        fileName: tableTitle,
                        columnNames: columns.filter(column => column.display !== 'false').map(item => item.label),
                        fileData: data.map(row => {
                            row.data.shift();
                            return row.data;
                        })
                    })
                })([]);
                return false;
            },
        };

        return <div className={styles.flex_row_container}>
            <MUIDataTable
                title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{tableTitle}</h1></div>}
                data={sectorTableData}
                columns={sectorColumns}
                options={options}
            />
        </div>
    }

    render() {
        if (this.state.loading === true || this.state.organisationsLoading === true) {
            return <SpinnerModal settings={true} label={'Sorry for the wait. This page does load longer...'} />
        } else {
            return (
                <div className={styles.reporting_overview_container}>
                    <div className={styles.featuredItem}>
                        <span className={styles.featuredTitleHeader}><h2>{'Fieldwork Reporting'}</h2></span>
                        <hr />
                        {this.sectorComparisonYoYTable()}
                        <br />
                        {this.sectorOverviewTable()}
                        <br />
                        {this.sectorCaptureStatusOverviewTable()}
                        <br />
                        {this.fieldworkerOverviewTable()}
                        <br />
                        {this.interactionOverviewTable()}
                        <br />
                        {this.logicalErrorsOverviewTable()}
                    </div>
                </div>
            )
        }
    }
}
export default FieldworkReporting;
