import SurveyService, {
    getSectorById,
    getAllSectors,
    getAllSurveyCycles,
    getSurveyCycleById,
    getSurveyCycleByOrganisationStateId,
    getQuestionnaireBySurveyCycleIdAndSectorId,
    getQuestionnaireByOrganisationStateId,
    saveRecordsDispatchList
} from './SurveyService';

export default { SurveyService, getSectorById, getAllSectors, getAllSurveyCycles, getSurveyCycleById, getSurveyCycleByOrganisationStateId, getQuestionnaireBySurveyCycleIdAndSectorId, getQuestionnaireByOrganisationStateId, saveRecordsDispatchList }