import React, { Component, useState } from "react";
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class CollaborativeRnDQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    async handleOnChange(evt) {
        let key = evt.target.attributes.questionFactId.value + '_' + evt.target.attributes.ordinalEntry.value;

        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
            const result = await CustomDialog(
                <UpdateAuditDialog
                    currentAnswer={SessionStateManager.getSessionValue(key)}
                    booleanType={'checkbox'}
                />,
                {
                    className: styles.updateAuditDialog,
                    title: 'Update Submitted Value',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );

            if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                SessionStateManager.setSessionValue(key, result.newValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                this.setState({ sessionStateChanged: true });
            } else {
                evt.preventDefault();
            }
        } else {
            SessionStateManager.setSessionValue(key, (JSON.parse(evt.target.value)), this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
            this.setState({ sessionStateChanged: true });
        }

        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            let tableTitle = 'Rest of the world consisting of...(tick as appropriate)';
            let tableQuestionFactStates = this.props.questionState.questionFactStates.sort((a, b) => a.sequenceNumberX > b.sequenceNumberX ? 1 : -1);
            let columnLabels = tableQuestionFactStates
                .filter(questionFactState => questionFactState.sequenceNumberX === 'a')
                .sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1)
                .map(questionFactState => questionFactState.columnDimensionStates[0].dimensionAlias);

            let rowIndexes = tableQuestionFactStates
                .filter(questionFactState => questionFactState.sequenceNumberY === 'b')
                .sort((a, b) => a.sequenceNumberX > b.sequenceNumberX ? 1 : -1).map(item => item.sequenceNumberX);

            return <div className={styles.collaborativeRnDQuestionContainer}>
                <table className="table">
                    <thead>
                        <th colSpan={1 + columnLabels.length}>{tableTitle}</th>
                    </thead>
                    <thead >
                        <th width='15%'>Tick as appropriate</th>
                        {columnLabels.map(label =>
                            <th width='8%'>{label}</th>
                        )}
                    </thead>
                    <tbody>
                        {rowIndexes
                            .map(rowIndex => {
                                let rowQuestionFactStates = tableQuestionFactStates
                                    .filter(questionFactState => questionFactState.sequenceNumberX === rowIndex)
                                    .sort((a, b) => a.sequenceNumberY > b.sequenceNumberY ? 1 : -1);

                                return <tr>
                                    <th className="col-1">{rowQuestionFactStates[0].rowDimensionStates[0].dimensionAlias}</th>
                                    {rowQuestionFactStates
                                        .map(questionFactState => {
                                            let notNull = (SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') !== undefined &&
                                                SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') != null);
                                            let isChecked = JSON.parse((SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') != null
                                                && SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') != '')
                                                ? SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') : 'false');

                                            return <td
                                                style={{ textAlign: 'center', verticalAlign: 'middle' }}
                                                className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableInputBoxUpdated : null}
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="checkbox"
                                                    value={!(notNull && isChecked)}
                                                    checked={notNull && isChecked}
                                                    onChange={(evt) => this.handleOnChange(evt)}
                                                    ordinalEntry={0}
                                                    questionFactId={questionFactState.questionFactId}
                                                    questionId={this.props.questionState.question.id}

                                                />
                                            </td>
                                        })
                                    }
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        }
    }
}
export default CollaborativeRnDQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    const [result, setResult] = useState({});

    let componentRef = null;
    result.confirmed = false;
    result.newValue = props.currentAnswer;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    {...props}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                        setResult(result);
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                        setResult(result);
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value;
                        setResult(result);
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        setResult(result);
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if (
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                            result.newValue = result.newValue;
                            result.confirmed = true;
                            dialog.close(JSON.parse(JSON.stringify(result)));
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}