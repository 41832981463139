import React, { Component } from "react";
import ScrollableAnchor from 'react-scrollable-anchor';


import SubSectionText from "@components/SubSectionText";
import MultiColorTextLink from "@components/MultiColorTextLink";
import ResearchPaper from "@components/ResearchPaper";
import Separator from "@components/Separator";
import Footer from "@components/Footer";
import NavigationBar from "@components/NavigationBar";
import Contact from "@components/Contact";


import nav_research_open from "@images/Research/nav_research_open.png";
import nav_videos_closed from "@images/Research/nav_videos_closed.png";

import report0 from "@images/Research/report0.png";
import report1 from "@images/Research/report1.png";
import report2 from "@images/Research/report2.png";
import report3 from "@images/Research/report3.png";
import report4 from "@images/Research/report4.png";
import report5 from "@images/Research/report5.png";
import report6 from "@images/Research/report6.png";
import report7 from "@images/Research/report7.png";
import report8 from "@images/Research/report8.png";
import report9 from "@images/Research/report9.png";

import policy_brief0 from "@images/Research/policy_brief0.png";
import policy_brief1 from "@images/Research/policy_brief1.png";
import policy_brief2 from "@images/Research/policy_brief2.png";
import policy_brief3 from "@images/Research/policy_brief3.png";
import policy_brief4 from "@images/Research/policy_brief4.png";
import policy_brief5 from "@images/Research/policy_brief5.png";
import policy_brief6 from "@images/Research/policy_brief6.png";
import policy_brief7 from "@images/Research/policy_brief7.png";
import policy_brief8 from "@images/Research/policy_brief8.png";

import fact_sheet24 from "@images/Research/fact_sheet24.png";
import fact_sheet25 from "@images/Research/fact_sheet25.png";
import fact_sheet26 from "@images/Research/fact_sheet26.png";
import fact_sheet27 from "@images/Research/fact_sheet27.png";
import fact_sheet28 from "@images/Research/fact_sheet28.png";
import fact_sheet29 from "@images/Research/fact_sheet29.png";
import fact_sheet30 from "@images/Research/fact_sheet30.png";
import fact_sheet31 from "@images/Research/fact_sheet31.png";
import fact_sheet32 from "@images/Research/fact_sheet32.png";
import fact_sheet33 from "@images/Research/fact_sheet33.png";
import fact_sheet34 from "@images/Research/fact_sheet34.png";
import fact_sheet35 from "@images/Research/fact_sheet35.png";
import fact_sheet36 from "@images/Research/fact_sheet36.png";
import fact_sheet37 from "@images/Research/fact_sheet37.png";
import fact_sheet38 from "@images/Research/fact_sheet38.png";
import fact_sheet39 from "@images/Research/fact_sheet39.png";
import fact_sheet40 from "@images/Research/fact_sheet40.png";
import fact_sheet41 from "@images/Research/fact_sheet41.png";


import styles from './profileresearch.module.css';

class Research extends Component {

    render() {
        let reportsPublishByText = 'Published by the HSRC on behalf of the Department of Science and Innovation.'
        let policyBriefsPublishByText = 'Published by the HSRC.'
        let factSheetsPublishByText = 'Published by CeSTII.'

        return (
            <div className={styles.container_research}>


                <div className={styles.research_content}>
                    <SubSectionText
                        title_font="title__24px"
                        text_font="text__18px"
                        title="Insight for decision makers"
                        text="The HSRC and CeSTII study R&D in South African institutions, informing decision makers in SA and abroad. Access our latest statistical research reports, policy briefs, and fact sheets here." />
                    <ScrollableAnchor id={'reports'} >
                        <SubSectionText
                            title_font="title__24px"
                            title="Reports" />
                    </ScrollableAnchor>
                    <ResearchPaper
                            img_src={report9}
                            title_font="title__16px__bold"
                            text_font="text__16px"
                            title="R&D Survey Statistical Report 2020/21 (January 2023)"
                            text="Key findings and data tables from South Africa’s 2020/21 R&D Survey."
                            text_2={reportsPublishByText}
                            link_url='http://archivesite.hsrc.ac.za/uploads/pageContent/1047111/R&D_StatisticalReport2020-21_WEB%20[FINAL].pdf'
                        />
                        <div className={styles.divider} />
                        <ResearchPaper
                            img_src={report8}
                            title_font="title__16px__bold"
                            text_font="text__16px"
                            title="R&D Survey Main Report 2019/20 (September 2022)"
                            text="Analysis and commentary of the results of South Africa’s 2019/20 R&D Survey"
                            text_2={reportsPublishByText}
                            link_url='http://archivesite.hsrc.ac.za/uploads/pageContent/1046536/R&D_MainReport2019-20_WEB.pdf'
                        />
                        <div className={styles.divider} />
                    <ResearchPaper
                        img_src={report0}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="R&D Survey Statistical Report 2019/20 (December 2021)"
                        text="Key findings and data tables from South Africa’s 2018/19 R&D Survey."
                        text_2={reportsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/1046484/RD_StatisticalReport2019-20_%20WEB.pdf'
                    />

                    <div className={styles.divider} />
                    <ResearchPaper
                        img_src={report1}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="R&D Survey Main Report 2018/19 (July 2021)"
                        text="Analysis and commentary on the results of South Africa’s 2018/19 R&D Survey."
                        text_2={reportsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/1045172/R&D_MainReport2018-19_WEB.pdf'
                    />

                    <div className={styles.divider} />
                    <ResearchPaper
                        img_src={report1}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="R&D Survey Statistical Report 2018/19 (February 2021)"
                        text="Key findings and data tables from South Africa’s 2018/19 R&D Survey."
                        text_2={reportsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/1045172/R&D_StatisticalReport2018-19_WEB.pdf'
                    />

                    <div className={styles.divider} />
                    <ResearchPaper
                        img_src={report2}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="R&D Survey Statistical Report 2017/18 (October 2019)"
                        text="Key findings and data tables from South Africa’s 2017/18 R&D Survey."
                        text_2={reportsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/9473/SA%20R&D%20SURVEY%20STATISTICAL%20REPORT%20201718_WEB.pdf'
                    />

                    <div className={styles.divider} />
                    <ResearchPaper
                        img_src={report3}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="R&D Survey Main Report 2017/18 (released 27 August 2020)"
                        text="Analysis and commentary on the results of South Africa’s 2017/18 R&D Survey."
                        text_2={reportsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/9473/R&D%20SURVEY%20MAIN%20REPORT%20201718_WEB.pdf'
                    />

                    <div className={styles.divider} />
                    <ResearchPaper
                        img_src={report4}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="R&D Survey Statistical Report 2016/17 (October 2018)"
                        text="Key findings and data tables from South Africa’s 2016/17 R&D Survey."
                        text_2={reportsPublishByText}
                        link_url='https://www.dst.gov.za/images/2018/SA-RD-SURVEY-STATISTICAL-REPORT-201617_WEB.pdf'
                    />

                    <div className={styles.divider} />
                    <ResearchPaper
                        img_src={report5}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="R&D Main Report 2016/17 (February 2019)"
                        text="Analysis and commentary on the results of South Africa’s 2016/17 R&D Survey."
                        text_2={reportsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/9472/R&D%20SURVEY%20MAIN%20REPORT%20201617_WEB.pdf'
                    />

                    <div className={styles.divider} />
                    <ResearchPaper
                        img_src={report6}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="R&D Survey Main Report 2015/16 (December 2017)"
                        text="Analysis and commentary on the results of South Africa’s 2015/16 R&D Survey."
                        text_2={reportsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/8055/SA%20R&D%20SURVEY%20MAIN%20ANALYSIS%20REPORT%20201516_WEB.pdf'
                    />

                    <div className={styles.divider} />
                    <ResearchPaper
                        img_src={report7}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="R&D Survey Statistical Report 2015/16 (November 2017)"
                        text="Key findings and data tables from South Africa’s 2015/16 R&D Survey."
                        text_2={reportsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/8753/SA%20R&D%20SURVEY%20STATISTICAL%20REPORT%20201516_WEB.pdf'
                    />
                    <text className="text__16px">
                        <a href="http://archivesite.hsrc.ac.za/en/departments/CeSTii/reports-cestii">
                            <text className="text__16px__orange">Access the full report series (2001-2020) here.</text>
                        </a>
                        <text>&nbsp;</text>

                    </text>


                    <div style={{ margin: "5%" }} />

                    <ScrollableAnchor id={'policybriefs'}>
                        <SubSectionText
                            title_font="title__24px"
                            title="Policy Briefs" style={{ marginBottom: 15 }}
                        />
                    </ScrollableAnchor>

                    <ResearchPaper
                        img_src={policy_brief6}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Adoption and diffusion of advanced ICTs in South Africa's agricultural sector: Policy issues and implications"
                        text="South African agriculture is starting to adopt and use sophisticated information and communication technologies with positive innovation outcomes."
                        text_2="By Y. Buchana, M. M. Sithole, P. Majokweni (April 2022)"
                        text_3={policyBriefsPublishByText}
                        link_url='https://repository.hsrc.ac.za/bitstream/handle/20.500.11910/19377/9812325.pdf?sequence=1&isAllowed=y'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={policy_brief7}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Policy levers to boost innovation and productivity in South African manufacturing firms"
                        text="The expansion of existing innovation incentives is urgently needed to help reverse the decline in South Africa’s manufacturing sector"
                        text_2="By A. Kahn (March 2022)"
                        text_3={policyBriefsPublishByText}
                        link_url='https://repository.hsrc.ac.za/bitstream/handle/20.500.11910/19274/9812307.pdf?sequence=1&isAllowed=y'
                    />
                    <div className={styles.divider} />


                    <ResearchPaper
                        img_src={policy_brief8}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="From crisis to survival: How informal enterprises harness the power of innovation"
                        text="Understanding how successful informal enterprises evolve into sustainable, profitable businesses can inform effective policy and support a flourishing informal business sector."
                        text_2="By N. Mustapha, I. Petersen, G. Kruss, N Van Rheede (March 2022)"
                        text_3={policyBriefsPublishByText}
                        link_url='https://repository.hsrc.ac.za/bitstream/handle/20.500.11910/19275/9812306.pdf?sequence=1&isAllowed=y'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={policy_brief0}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="R&D the biggest business casualty of Covid-19?"
                        text="Reduced spending on R&D has negatively impacted economic growth as the economy struggles to recover from Covid-19."
                        text_2="By A. Katongo, N. Mustapha (June 2021)"
                        text_3={policyBriefsPublishByText}
                        link_url='https://repository.hsrc.ac.za/bitstream/handle/20.500.11910/16260/12075.pdf?sequence=1&isAllowed=y'
                    />

                    <div className={styles.divider} />
                    <ResearchPaper
                        img_src={policy_brief1}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="How well is R&D oriented to human development in South Africa?"
                        text="New STI indicators are needed to achieve inclusive and sustainable human development for South Africa."
                        text_2="By G. Kruss, MM. Sithole & Y. Buchana (February 2020)"
                        text_3={policyBriefsPublishByText}
                        link_url='http://repository.hsrc.ac.za/bitstream/handle/20.500.11910/15153/11208.pdf?sequence=1&isAllowed=y'
                    />

                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={policy_brief2}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Investment in public agricultural research in South Africa: A critical review of the evidence"
                        text="Agriculture plays a crucial role in South Africa’s economic and social development, but governments in India, China and Brazil invest more in agricultural research than South Africa does."
                        text_2="By M.M. Sithole, G. Kruss, N. Vlotman, C. Moses & N. Le Roux (March 2019)"
                        text_3={policyBriefsPublishByText}
                        link_url='https://sabusinessinnovationsurvey.files.wordpress.com/2019/08/10787.pdf'
                    />

                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={policy_brief3}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Social sciences and humanities R&D investment in South Africa: Current priorities, future needs"
                        text="Institutional R&D planners and national policymakers must find a better balance between current priorities and future needs."
                        text_2="G. Ralphs & N. Molotja (March 2019)"
                        text_3={policyBriefsPublishByText}
                        link_url='http://www.hsrc.ac.za/en/research-data/view/9855'
                    />

                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={policy_brief4}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="The future of state-owned enterprises in South Africa: Why R&D matters"
                        text="State-owned enterprises in South Africa are crucial to industrial development. But their future depends on their ability to support the economy while remaining efficient and internationally competitive."
                        text_2="N. Mustapha, G. Kruss & G. Ralphs (March 2018)"
                        text_3={policyBriefsPublishByText}
                        link_url='http://www.hsrc.ac.za/en/research-data/ktree-doc/19724'
                    />

                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={policy_brief5}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Promoting a science, technology and innovation policy for inclusive development in South Africa"
                        text="South Africa is at the cutting edge of innovation policy in proposing to develop a coherent, comprehensive cross-government national strategy for innovation for inclusive development (IID)."
                        text_2="G Kruss, I Petersen, J Rust & A Teal (March 2017)"
                        text_3={policyBriefsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/8380/03_HSRC%20POLICY%20BRIEF%20SciTech%20Innovation.pdf'
                    />


                    <text className="text__16px">
                  
                        <text>&nbsp;</text>
                        <a href="http://archivesite.hsrc.ac.za/en/departments/CeSTii/briefs-and-fact-sheets">
                            <text className="text__16px__orange"> Access more HSRC policy briefs here.</text>
                        </a>
                        <text>&nbsp;</text>

                    </text>


                    <div style={{ margin: "5%" }} />

                    <ScrollableAnchor id={'factsheets'}>
                        <SubSectionText
                            title_font="title__24px"
                            title="Fact Sheets"
                            style={{ marginBottom: 15 }} />
                    </ScrollableAnchor>

                    <ResearchPaper
                        img_src={fact_sheet41}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 41: R&D Spending in the Mining Sector 2018/19"
                        text="Mining contributed R1.209 billion to South African R&D, predominantly in the engineering science research field.  "
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2041%20-%20MINING%20R&D%20SPENDING%20IN%20SOUTH%20AFRICA%20201819.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet40}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 40: Digital Technologies in South African Agribusinesses 2016-2018"
                        text="The adoption of digital technologies by agribusiness has been moderate, but some sub-sectors have benefitted from the robust uptake of digital technology."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2040%20-%20DIGITAL%20TECHNOLOGIES%20IN%20SA%20AGRIBUSINESSES2.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet39}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 39: Higher Education R&D 2019/20	"
                        text="Higher education accounts for two-thirds of all R&D personnel and was responsible for 41.4% of R&D spending in South Africa, overtaking business."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2039%20-%20R&D201920%20HE%20AT%20A%20GLANCE2.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet38}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 38: NPO R&D 2019/20"
                        text="R&D expenditure by the not-for-profit sector been increasing since 2010/11 with a major focus on health R&D in communicable diseases."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2038%20-%20R&D201920%20NPO%20SECTOR%20AT%20A%20GLANCE.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet37}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 37: Government R&D 2019/20"
                        text="Government expenditure on R&D decreased, with nearly half allocated to research institutes."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2037%20-%20R&D201920%20GOVERNMENT%20AT%20A%20GLANCE.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet36}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 36: Science Councils R&D 2019/20"
                        text="Expenditure on R&D increased in the science councils, focussing on national priority areas including economic development, health and education."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2036%20-%20R&D201920%20SCIENCE%20COUNCILS%20AT%20A%20GLANCE.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet35}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 35: Business R&D 2019/20"
                        text="Business sector R&D supports South Africa’s economic growth, GDP and competitiveness, but is decreasing year-on-year."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2035%20-%20R&D201920%20BUSINESS%20AT%20A%20GLANCE.pdf'
                    />
                    <div className={styles.divider} />


                    <ResearchPaper
                        img_src={fact_sheet34}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 34: Manufacturing sector R&D at a glance, 2014/15 - 2018/19"
                        text="The steady decline of South Africa’s manufacturing sector over the last few years mirrors a decline in R&D."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2034%20-%20MANUFACTURING%20R&D%20201415%20-%20201819.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet33}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 33: Agricultural R&D 2013/14 - 2017/18"
                        text="Investment in agricultural R&D can boost productivity, food security and sustainable development in developing countries like South Africa. "
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2033%20-%20AGRICULTURAL%20R&D%20201314%20-%20201718.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet32}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 32: Green R&D 2017/18"
                        text="Green R&D that seeks to reduce environmental impacts and better manage resources is increasing in South Africa, but is it enough?"
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2032%20-%20GREEN%20R&D%202017183.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet31}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 31: Namibia South Africa Innovation Activities and Outcomes"
                        text="Investment in innovation can boost the economies of Namibia and South Africa: neighbouring states that share the challenge of decelerating economic growth."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2031%20-%20NAMIBIA%20SOUTH%20AFRICA%20INNOVATION.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet30}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 30: Higher Education R&D 2018/19"
                        text="One-third of South Africa’s annual R&D spending is in the higher education sector. In 2017/18 this amounted to R13 billion."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2030%20-%20R&D201819%20HE%20AT%20A%20GLANCE.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet29}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 29: NPO R&D 2018/19"
                        text="Health commands the dominant share of R&D available to South Africa’s not-for-profit sector, with 75% focused on diseases like HIV/Aids, TB and Malaria."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2029%20-%20R&D201819%20NPO%20SECTOR%20AT%20A%20GLANCE.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet28}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 28: Government R&D 2018/19"
                        text="Government both funds and performs R&D in South Africa, mainly through higher education institutions and science councils."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2028%20-%20R&D201718%20GOVERNMENT%20AT%20A%20GLANCE.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet27}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 27: Science Councils R&D 2018/19"
                        text="R&D expenditure for science councils declined by 13.8% in 2018/19 with the focus on national priorities including health, education and agriculture. "
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2027%20-%20R&D201819%20SCIENCE%20COUNCILS%20AT%20A%20GLANCE.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet26}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 26: Business R&D 2018/19"
                        text="Business spent R1,5 billion less on R&D in 2018/19 than in 2017/18. The lion’s share of this spend was geared to solve problems at the firm level."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2026%20-%20R&D201819%20BUSINESS%20AT%20A%20GLANCE.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet25}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 25: COVID:19 Impacts on South African R&D and Innovation"
                        text="R&D spend is one indicator of the resilience of a business. Despite the unprecedented challenge of COVID-19, businesses in South Africa continued to invest in R&D."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2025%20-%20COVID-19IMPACTONBUSINESS20202.pdf'
                    />
                    <div className={styles.divider} />

                    <ResearchPaper
                        img_src={fact_sheet24}
                        title_font="title__16px__bold"
                        text_font="text__16px"
                        title="Fact Sheet No. 24: Health R&D at a glance 2017/18"
                        text="Achieving better health outcomes is one of South Africa’s most pressing developmental priorities for R&D, intensified by the coronavirus pandemic."
                        text_2={factSheetsPublishByText}
                        link_url='http://www.hsrc.ac.za/uploads/pageContent/12008/FACT%20SHEET%20NO%2024%20-%20HEALTHR&D2017182.pdf'
                    />
                    <div className={styles.divider} />

                    <text className="text__16px">
             
                        <text>&nbsp;</text>
                        <a href="http://archivesite.hsrc.ac.za/en/departments/CeSTii/briefs-and-fact-sheets">
                            <text className="text__16px__orange"> Access the full fact sheet series (2017-), visit CeSTII’s website here.</text>
                        </a>
                        <text>&nbsp;</text>

                    </text>


                </div>

            </div>

        );
    }

}
export default Research;

