import CSVGenerator,
{
    exportFieldworkReportToXLS,
    exportTableToXLS
} from './CSVGenerator';

export default {
    CSVGenerator,
    exportFieldworkReportToXLS,
    exportTableToXLS
}