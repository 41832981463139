import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Separator from "@components/Separator";


import RespondentTopbar from '@components/RespondentTopbar';

import Logout from '@pages/Logout';


import Survey from '@pages/Survey';

import Dashboard from './pages/Dashboard';
import Research from './pages/Research';
import RespondentProfile from './pages/Profile';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import PreviousSurveys from './pages/PreviousSurvey';
import Header from './components/Header';


import SurveyLanding from '@pages/SurveyLanding';
import Mandate from '@pages/Mandate';
import Finished from '@pages/Survey/Finished';
import PageNotFound from '@pages/PageNotFound';



import styles from './respondentdashboard.module.css';


class RespondentDashboard extends Component {
  render() {

    const submitFunctionEnabled = process.env.REACT_APP_RESPONDENT_SUBMIT_FUNCTION === 'ENABLED';

    return (
      <div>
        <div className={styles.dashboard_container}>
          <Router>
            <Sidebar />
         

            <div className={styles.dashboard_content}>
              <Header />

              <Route exact path="/respondentdashboard">
                <Dashboard />
              </Route>

              <Route exact path="/overview">
                <Dashboard />
              </Route>
              
              <Route exact path="/survey">
                {submitFunctionEnabled ? <Survey /> : <Dashboard />}
              </Route>

              <Route exact path="/survey-landing">
                {submitFunctionEnabled ? <SurveyLanding /> : <Dashboard />}
              </Route>

              <Route exact path="/mandate">
                {submitFunctionEnabled ? <Mandate /> : <Dashboard />}
              </Route>

              <Route exact path="/finished" >
                <Finished />
              </Route>

              <Route exact path="/previous-surveys">
                <PreviousSurveys />
              </Route>

              <Route exact path="/respondentdashboardresearch">
                <Research />
              </Route>

              <Route exact path="/profile/respondent/account/view">
                <RespondentProfile group='respondent' detail='account' action='view' />
              </Route>

              <Route exact path="/profile/respondent/organisation/view">
                <RespondentProfile group='respondent' detail='organisation' action='view' />
              </Route>

              <Route exact path="/profile/respondent/account/update">
                <RespondentProfile group='respondent' detail='account' action='update' />
              </Route>

              <Route exact path="/profile/respondent/organisation/update">
                <RespondentProfile group='respondent' detail='organisation' action='update' />
              </Route>
              <Route exact path="/error" >
                <PageNotFound />
              </Route>
              <Route exact path="/logout" >
                <Logout />
              </Route>

            </div>     {/* dashboard content*/}

          </Router>
        </div>








        <div style={{ margin: "15%" }} />
        <Separator size="100%" />
        <Footer />
      </div>

    )
  }
}

export default RespondentDashboard
