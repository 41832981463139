import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {BrowserView, MobileView} from 'react-device-detect';
import { rndLogoInstance, burgerMenuInstance, closeBurgerMenuInstance } from '@components/Globals';
import Separator from "@components/Separator";


import styles from './navigation.module.css';
import mobileNavigationBar from './MobileNavigationBar.js';

function NavigationBar (props) {


    return (
    <>
    <BrowserView>
        <div>
        <nav className={styles.nav}>
          <label for="nav__checkbox" className={styles.nav__toggle}>
            <img src={burgerMenuInstance} className={styles.hamburger} />
            <img src={closeBurgerMenuInstance} className={styles.close} />
          </label>

          <ul className={styles.nav__menu} >
            <li>
            <Link className={styles.link} to='/'><img src={rndLogoInstance} className={styles.hsrc__logo} alt="hsrc logo" /></Link>
            </li>
            <li><a href="/">Home</a></li>
            <li><a href="/about">The R&D Survey</a></li>
            <li><a href="/research">R&D Research</a></li>
            <li><a href="/faq">FAQ</a></li>
            <li><a target="_blank" href="/blog">Innovation Surveys</a></li>
            <li class="buttons">
              <Link to='/expressinterest'>
              <button class="button">Express Interest</button>
              </Link>
              <Link to='/login'>
              <button class="button__orange">Log In</button>
              </Link>
            </li>
          </ul>
        </nav>
        </div>
    </BrowserView>
    <MobileView>
        {mobileNavigationBar()}
    </MobileView>
    </>
    );
}
export default NavigationBar;