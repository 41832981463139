import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Separator from "@components/Separator";
import styles from './mobilenavigationbar.module.css';
import { rndMobileLogoInstance, burgerMenuInstance, closeBurgerMenuInstance } from '@components/Globals';
import { useState, useEffect } from "react";

function MobileNavigationBar () {

     const [menuState, setMenuState] = useState('none');

     const expandMenu = () => {

          let x = document.getElementById("myLinks");

           if (x.style.display === "block") {
                {setMenuState('none')}
                x.style.display = "none";
           } else if(x.style.display === 'none'){
                {setMenuState('block')}
                x.style.display = "block";
           }
      }

    return (
        <div className={styles.mobileContainer}>
          <div className={styles.topnav}>
          <div className={styles.active}><img src={rndMobileLogoInstance} className={styles.hsrc__logo} alt="hsrc logo" /></div>
          <div className={styles.burgerIcon} >
            <img src={burgerMenuInstance} className={styles.hamburger} onClick={expandMenu}/>
           </div>
          <div id="myLinks" style={{display: menuState}}>
            <a href="/">Home</a>
            <a href="/about">The R&D Survey</a>
            <a href="/research">R&D Research</a>
            <a href="/faq">FAQ</a>
            <a target="_blank" href="/blog">Innovation Surveys</a>
            <a href="/expressinterest">Express Interest</a>
            <a href="/login">Log In</a>
          </div>

        </div>
       </div>
    );
}
export default MobileNavigationBar;