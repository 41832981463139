import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import SpinnerModal from '@components/SpinnerModal';
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class TextQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    classes = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '50ch',
            },
        },

    }));

    async handleChange(evt, questionFactId, ordinalEntry, value) {
        let key = questionFactId + '_' + ordinalEntry;

        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
            const result = await CustomDialog(
                <UpdateAuditDialog
                    currentAnswer={SessionStateManager.getSessionValue(key)}
                />,
                {
                    className: styles.updateAuditDialog,
                    title: 'Update Submitted Value',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );

            if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                SessionStateManager.setSessionValue(key, result.newValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                this.setState({ sessionStateChanged: true });
            } else {
                evt.preventDefault();
            }
        } else {
            SessionStateManager.setSessionValue(key, value, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
            this.setState({ sessionStateChanged: true });
        }

        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            return (
                <TextField
                    className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, this.props.questionState.questionFactStates[0].questionFactId + '_0') ? styles.textInputBoxUpdated : styles.textInputBox}
                    key={'textfield_' + this.props.questionState.questionFactStates[0].questionFactId}
                    id="standard-end-adornment"
                    InputProps={{
                        startAdornment: <InputAdornment position={'start'}>{this.props.startEndornment}</InputAdornment>,
                        endAdornment: <InputAdornment position={'end'}>{this.props.endEndornment}</InputAdornment>,
                    }}
                    require="required"
                    questionId={(this.props.questionState.question.id) + ''}
                    questionFactId={this.props.questionState.questionFactStates[0].questionFactId}
                    type="text"
                    value={SessionStateManager.getSessionValue(this.props.questionState.questionFactStates[0].questionFactId + '_0')}
                    onChange={(evt) => this.handleChange(evt, this.props.questionState.questionFactStates[0].questionFactId, 0, evt.target.value)}
                    placeholder={this.props.placeholder}
                    id="outlined-basic"
                    size="small"
                    variant="outlined"
                />
            )
        }
    }
}
export default TextQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    currentAnswer={props.currentAnswer}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if (
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                            result.confirmed = true;
                            dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}