import NumberTools,
{
    transformToCurrency,
    isValidDecimalNumber
} from './NumberTools';

export default {
    NumberTools,
    transformToCurrency,
    isValidDecimalNumber
}