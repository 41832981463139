import { React, Component, useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  useLocation,
} from "react-router-dom";
// import { ReactKeycloakProvider } from '@react-keycloak/web';
// import keycloak from '@src/keycloak' ; 

import PrivateRoute from '@components/PrivateRoute';

import PageNotFound from '@pages/PageNotFound';
import Home from '@pages/Home';
import RespondentDashboard from '@pages/RespondentDashboard';
import ResearcherDashboard from '@pages/ResearcherDashboard';

import Login from '@pages/Login';
import About from '@pages/About';
import Research from '@pages/Research';
import FrequentlyAskedQuestions from '@pages/FrequentlyAskedQuestions';
import ConfidentialityQuestions from '@pages/ConfidentialityQuestions';
import GetStarted from '@pages/GetStarted';
import ResetPassword from '@pages/ResetPassword';
import CreatePassword from '@pages/CreatePassword';
import Finished from '@pages/Survey/Finished';
import PasswordRecovery from '@pages/PasswordRecovery';
import UseRedirectToHttps from '@components/RedirectComponent';
import '@styles/style.css';


class App extends Component {


  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            {/* public routes */}
            <Route path="/" exact component={Home} />
            <Route path="/login" exact component={LoginWithParams} />
            <Route path="/about" exact component={About} />
            <Route path="/research" exact component={Research} />
            <Route path="/faq" exact component={FrequentlyAskedQuestions} />
            <Route path="/blog" exact component={() => {
              window.location.href = "https://hsrc.ac.za/divisions/centre-for-science-technology-and-innovation-indicators/";
              return null;
            }} />
            <Route path="/confidentiality" exact component={ConfidentialityQuestions} />
            <Route path="/expressinterest" exact component={GetStarted} />
            <Route path="/resetpassword" exact component={ResetPassword} />
            <Route path="/createpassword" exact component={CreatePassword} />
            {/* <Route path="/finished" exact component={Finished} /> */}
            <Route path="/password-recovery" exact component={PasswordRecovery} />

            {/* //protected routes */}
            <Route roles={['dsp_respondent', 'dsp_researcher']} path="/respondentdashboard" component={RespondentDashboard} />

            <Route roles={['dsp_respondent']} path="/profile/respondent/account/view" component={RespondentDashboard} />
            <Route roles={['dsp_respondent']} path="/profile/respondent/account/update" component={RespondentDashboard} />

            <Route roles={['dsp_researcher']} path="/researcherdashboard" component={ResearcherDashboard} />
            <Route roles={['dsp_researcher']} path="/profile/researcher/account/view" component={ResearcherDashboard} />
            <Route roles={['dsp_researcher']} path="/profile/researcher/account/update" component={ResearcherDashboard} />
            <Route roles={['dsp_researcher']} path="/profile/respondent/account/create" component={ResearcherDashboard} />
            <Route roles={['dsp_researcher']} path="/profile/researcher/account/create" component={ResearcherDashboard} />
            <Route roles={['dsp_researcher']} path="/user/management" component={ResearcherDashboard} />

            {/* default 404 */}

            <Route component={PageNotFound} />

          </Switch>

        </BrowserRouter>
      </div>
    );
  }
}

export default App;

export function LoginWithParams() {

  const search = useLocation().search;

  let prepop = new URLSearchParams(search).get('prepop');
  let username = new URLSearchParams(search).get('username');

  if (prepop !== undefined && prepop != null && prepop == 'true') {
    return <Login prepop={JSON.parse(prepop)} username={username} />;
  } 
  return <Login />

}