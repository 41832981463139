import React, { Component } from "react";
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberTools from "@components/NumberTools";
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import styles from '../questionnaire.module.css';

class ReadonlyTotalQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            return <div className={styles.labourCostTotalQuestionContainer}>
                <table className='table' >
                    <tbody>
                        <tr>
                            <th width='40%'>{this.props.questionState.questionFactStates[0].rowDimensionStates[0].dimensionAlias}</th>
                            <td
                                className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, this.props.questionState.questionFactStates[0].questionFactId + '_0')
                                    ? styles.tableTotalCellUpdated
                                    : null}
                                width='60%'>
                                <div className={styles.flexCenterFitContent}>
                                    <span className="inputRandSign">R</span>
                                    <input
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, this.props.questionState.questionFactStates[0].questionFactId + '_0')
                                            ? styles.tableTotalCellUpdated
                                            : styles.tableTotalCell}
                                        id='labour_cost_total_c'
                                        type="text"
                                        value={NumberTools.transformToCurrency(SessionStateManager.getSessionValue(this.props.questionState.questionFactStates[0].questionFactId + '_0'))}
                                        questionId={this.props.questionState.question.id}
                                        questionFactId={this.props.questionState.questionFactStates[0].questionFactId}
                                        readOnly
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        }
    }
}
export default ReadonlyTotalQuestion;
