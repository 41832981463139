import React, { Component } from 'react';
import UserProfile from "@components/UserProfile";
import styles from './profile.module.css';

class RespondentProfile extends Component {
  state = {
    userRole :"",
    userProfile: "",

  }
  constructor() {
    super();

    function parseJwt(token) {
      var base64Payload = token.split('.')[1];
      var payload = Buffer.from(base64Payload, 'base64');
      return JSON.parse(payload.toString());
    }


    let bearer = localStorage.getItem('Bearer');
    if (!bearer){
      window.location = "/login";
  
    }
    
    let payload = parseJwt(bearer);
    this.state.userProfile = payload.given_name;
    this.state.userRole = payload.group_id;
  }

  render() {
    return (
      <div className={styles.container_profile}>
        <UserProfile profileGroup={this.props.group} profileDetail={this.props.detail} profileAction={this.props.action} />
      </div>
    )
  }
}

export default RespondentProfile


