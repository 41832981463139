import FieldValidator from '@components/FieldValidator';
import MultiColorTitle from '@components/MultiColorTitle';
import ProfileManagementService from '@services/ProfileManagementService';
import DataCaptureService from '@services/DataCaptureService';
import SurveyService from '@services/SurveyService';
import LoadingModal from '@components/LoadingModal';
import DropDownMenu from '@components/DropDownMenu';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import styles from './userprofile.module.css';
import { IconButton } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SupportAlert from '@components/SupportAlert';
import { Alert } from 'react-st-modal';

class RespondentProfile extends Component {
    state = {
        loading: false,
        organisationsLoading: false,
        contactsLoading: false,
        errors: '',

        validEmail: false,
        validFirstName: false,
        validLastName: false,
        validCellphone: false,
        validWorkPhone: false,
        validRole: false,
        validOrganisationId: false,
        validSectorId: false,
        validSurveyCycleId: false,
        validContactStateId: false,

        realmId: '',
        emailAddress: '',
        title: '',
        firstName: '',
        lastName: '',
        cellphone: '',
        workPhone: '',
        designation: '',
        role: '',

        sectorId: '',
        surveyCycleId: process.env.REACT_APP_CURRENT_SURVEY_CYCLE,
        organisationId: '',
        organisationStateId: '',
        contactStateId: '',
        registeredName: '',
        organisationType: '',
        fiscalYear: '',
        physicalAddress: '',
        postalCode: '',
        province: '',

        allOrganisations: [],
        allSectors: [],
        allSurveyCycles: [],
        allOrganisationStates: [],
        allOrganisationContacts: [],
    }

    constructor(props) {
        super(props);

        this.validRole = this.validRole.bind(this);
        this.handleSubmitCreateAccount = this.handleSubmitCreateAccount.bind(this);
        this.handleSubmitUpdateAccount = this.handleSubmitUpdateAccount.bind(this);

    }
	
	async getOrganisationStateName(orgId) {
        let organisationStatus = await DataCaptureService.getOrganisationStatusBySurveyCycleIdAndOrganisationId(process.env.REACT_APP_CURRENT_SURVEY_CYCLE, orgId);

        if (organisationStatus !== undefined && organisationStatus !== null) {
            this.state.registeredName = organisationStatus.organisationStateName;
        }
    }

    async componentDidMount() {

        this.setState({ loading: true });


        function parseJwt(token) {
            var base64Payload = token.split('.')[1];
            var payload = Buffer.from(base64Payload, 'base64');
            return JSON.parse(payload.toString());
        }

        let bearer = localStorage.getItem('Bearer');
        if (!bearer){
            window.location = "/login";
        
          }

        let payload = parseJwt(bearer);

        this.state.role = payload.group_id.toUpperCase();

        if (this.props.profileAction.toLowerCase() != 'create') {
            this.state.validRole = (this.state.role == 'RESPONDENT')
            this.state.realmId = payload.sub
            this.state.emailAddress = payload.email

            const realmUser = await ProfileManagementService.getRealmUser(this.state.realmId);

            if (realmUser != null) {

                this.setState({
                    validEmail: FieldValidator.validateEmail(realmUser.email),
                    validFirstName: FieldValidator.validateName(realmUser.firstName),
                    validLastName: FieldValidator.validateName(realmUser.lastName),
                    validCellphone: FieldValidator.validatePhoneNumber((realmUser.attributes.cellphone != null && realmUser.attributes.cellphone.length > 0) ? realmUser.attributes.cellphone[0] : ''),
                    validWorkPhone: FieldValidator.validatePhoneNumber((realmUser.attributes.workphone != null && realmUser.attributes.workphone.length > 0) ? realmUser.attributes.workphone[0] : ''),

                    emailAddress: realmUser.email,
                    firstName: realmUser.firstName,
                    lastName: realmUser.lastName,
                    cellphone: (realmUser.attributes.cellphone != null && realmUser.attributes.cellphone.length > 0) ? realmUser.attributes.cellphone[0] : '',
                    workPhone: (realmUser.attributes.workphone != null && realmUser.attributes.workphone.length > 0) ? realmUser.attributes.workphone[0] : '',
                    designation: (realmUser.attributes.designation != null && realmUser.attributes.designation.length > 0) ? realmUser.attributes.designation[0] : '',
                })

            }

            let organisationId = payload.org_id;
            this.setState({ organisationId: organisationId });

            let sectorId = payload.sector_id;
            this.setState({ sectorId: sectorId });

            let surveyCycleId = process.env.REACT_APP_CURRENT_SURVEY_CYCLE;
            this.setState({ surveyCycleId: surveyCycleId });

            const organisationContactState = await ProfileManagementService.getOrganisationContactStateDetailByEmailSectorSurveyCycle(this.state.emailAddress, sectorId, surveyCycleId);
            if (organisationContactState != null) {
                let cell = '';
                if (organisationContactState.telephone.split(',').filter(item => item.includes('Cell')).length > 0)
                    cell = organisationContactState.telephone.split(',').filter(item => item.includes('Cell'))[0].replace(/\D/g, '')

                let phone = '';
                if (organisationContactState.telephone.split(',').filter(item => item.includes('Telephone')).length > 0)
                    phone = organisationContactState.telephone.split(',').filter(item => item.includes('Telephone'))[0].replace(/\D/g, '')

                this.setState({
                    title: organisationContactState.title,
                    cellphone: cell,
                    workPhone: phone,
                    designation: organisationContactState.designation,
                })

                if (this.state.emailAddress != null && !this.state.emailAddress.length > 0) {
                    this.state.emailAddress = organisationContactState.email;
                }

                if (this.state.firstName != null && !this.state.firstName.length > 0) {
                    this.state.firstName = organisationContactState.firstname;
                }

                if (this.state.lastName != null && !this.state.lastName.length > 0) {
                    this.state.lastName = organisationContactState.lastname;
                }

                this.state.validEmail = FieldValidator.validateEmail(this.state.emailAddress);
                this.state.validFirstName = FieldValidator.validateName(this.state.firstName);
                this.state.validLastName = FieldValidator.validateName(this.state.lastName);
                this.state.validCellphone = FieldValidator.validatePhoneNumber(this.state.cellphone);
                this.state.validWorkPhone = FieldValidator.validatePhoneNumber(this.state.workPhone);
                this.state.validRole = this.validRole(this.state.role);

                this.state.organisationId = organisationContactState.organisationId;
            }

            const organisation = await ProfileManagementService.getOrganisationById(this.state.organisationId);
            if (organisation != null) {
                this.getOrganisationStateName(this.state.organisationId);
                this.state.physicalAddress = '' + organisation.contactDetails.physicalAddress.line1 + ', ' + organisation.contactDetails.physicalAddress.line2;
                this.state.province = '' + organisation.contactDetails.physicalAddress.suburb;
                this.state.postalCode = '' + organisation.contactDetails.physicalAddress.code;
            }

            const organisationContacts = await DataCaptureService.getAllOrganisationContactsBySurveyCycleId(surveyCycleId);
            this.setState({ allOrganisationContacts: organisationContacts });

            const sector = await SurveyService.getSectorById(sectorId);
            if (sector != null) {
                this.state.organisationType = '' + sector.name;
            }

            this.state.realmId = payload.sub;
            this.state.role = payload.group_id.toUpperCase();

        }

        if (this.props.profileAction.toLowerCase() == 'create') {
            this.state.validRole = (this.state.role == 'RESEARCHER')

            const sectors = await SurveyService.getAllSectors();
            sectors.map(item => this.state.allSectors.push({ key: item.id, value: item.name.trim() }));

            const surveyCycles = await SurveyService.getAllSurveyCycles();
            surveyCycles.map(item => this.state.allSurveyCycles.push({ key: item.id, value: item.name.trim() }));
        }

        this.setState({ loading: false });
    }

    buttonDisabled() {

        if (this.props.profileAction.toLowerCase() == 'create') {
            return !(
                this.state.validRole &&
                this.state.validEmail &&
                this.state.validFirstName &&
                this.state.validLastName &&
                this.state.validOrganisationId &&
                this.state.validSectorId &&
                this.state.validSurveyCycleId &&
                this.state.validContactStateId
            )
        } else {
            return !(
                this.state.validEmail &&
                this.state.validFirstName &&
                this.state.validLastName
            )
        }
    }

    validRole(role) {
        return role.toUpperCase() == 'RESPONDENT'
    }

    clearState() {
        this.setState({ validEmail: false });
        this.setState({ validFirstName: false });
        this.setState({ validLastName: false });
        this.setState({ validCellphone: false });
        this.setState({ validWorkPhone: false });
        this.setState({ validOrganisationId: false });
        this.setState({ validSectorId: false });
        this.setState({ validSurveyCycleId: false });
        this.setState({ validContactStateId: false });

        this.setState({ emailAddress: '' });
        this.setState({ title: '' });
        this.setState({ firstName: '' });
        this.setState({ lastName: '' });
        this.setState({ cellphone: '' });
        this.setState({ workPhone: '' });
        this.setState({ designation: '' });

        this.setState({ sectorId: '' });
        this.setState({ surveyCycleId: process.env.REACT_APP_CURRENT_SURVEY_CYCLE });
        this.setState({ organisationId: '' });
        this.setState({ organisationStateId: '' });
        this.setState({ contactStateId: '' });
    }

    async reloadOrganisations(sectorId, surveyCycleId) {
        this.setState({ organisationsLoading: true });

        try {

            const orgs = new Array();
            this.setState({ allOrganisations: orgs });

            const organisationStates = await (async () => {
                const organisationStates = await ProfileManagementService.getOrganisationStatesBySectorIdAndSurveyCycleId(sectorId, surveyCycleId);
                return organisationStates;
            })([]);
            this.setState({ allOrganisationStates: organisationStates });
            
            organisationStates
                .filter(organisationState => organisationState.unitOfMeasure == true)
                .filter(organisationState => organisationState.organisationStatusId == 10)
                .map(organisationState => {
                    let orgName = organisationState.name.trim();
                    if (organisationState.parentId !== null) {
                        let parent = organisationStates.filter(orgState => orgState.id == organisationState.parentId)[0];
                        if (parent !== undefined && parent !== null) {
                            orgName = parent.name + ' / ' + orgName;

                            if (parent !== null && parent.parentId !== null) {
                                let grandparent = organisationStates.filter(orgState => orgState.id == parent.parentId)[0];
                                if (grandparent !== undefined && grandparent !== null) {
                                    orgName = grandparent.name + ' / ' + orgName;
                                }
                            }
                        }
                    }
                    orgs.push({ key: organisationState.id, value: orgName.trim() });
                });
            this.setState({ allOrganisations: orgs.sort((a, b) => a.value > b.value ? 1 : -1) });

        } catch (ex) {
            SupportAlert.displayAlert('Network Error', 'An unexpected error occured while loading the organisations list. Please try again later.');
        }
        this.setState({ organisationsLoading: false });

    }

    async reloadOrganisationContacts(organisationStateId) {
        this.setState({ contactsLoading: true });

        try {

            const contacts = new Array();
            this.setState({ allOrganisationContacts: contacts });
            const contactStates = await DataCaptureService.getAllOrganisationContactsByOrganisationStateId(organisationStateId);
            this.setState({ allOrganisationContacts: contactStates });
            contactStates
                .filter(contactState => contactState.organisationStateId == organisationStateId)
                .map(contactState =>
                    contacts.push({ key: contactState.contactStateId, value: (contactState.title + ' ' + contactState.firstname + ' ' + contactState.lastname) })
                );
            this.setState({ allOrganisationContacts: contacts.sort((a, b) => a.value > b.value ? 1 : -1) });

        } catch (ex) {
            SupportAlert.displayAlert('Network Error', 'An unexpected network error occured while loading the contacts list. Please try again later.');
        }
        this.setState({ contactsLoading: false });

    }

    handleChangedValue = (evt) => {
        switch (evt.target.name) {
            case 'emailAddress': {
                this.setState({ validEmail: FieldValidator.validateEmail(evt.target.value) });
                this.setState({ emailAddress: evt.target.value });
                break;
            }
            case 'firstName': {
                this.setState({ validFirstName: FieldValidator.validateName(evt.target.value) });
                this.setState({ firstName: evt.target.value });
                break;
            }
            case 'lastName': {
                this.setState({ validLastName: FieldValidator.validateName(evt.target.value) });
                this.setState({ lastName: evt.target.value });
                break;
            }
            case 'cellphone': {
                this.setState({ validCellphone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ cellphone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'workPhone': {
                this.setState({ validWorkPhone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ workPhone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'designation': {
                this.setState({ designation: evt.target.value });
                break;
            }
            case 'organisation': {
                this.setState({ validOrganisationId: evt.target.value.trim().length > 0 });
                this.setState({ organisationStateId: parseFloat(evt.target.value) });
                let orgId = this.state.allOrganisationStates.filter(organisationState => organisationState.id == evt.target.value)[0].organisationId;
                this.setState({ organisationId: parseFloat(orgId) });

                this.reloadOrganisationContacts(parseFloat(evt.target.value));
                break;
            }
            case 'contactState': {
                this.setState({ validContactStateId: evt.target.value.trim().length > 0 });
                this.setState({ contactStateId: parseFloat(evt.target.value) });
                break;
            }
            case 'sector': {
                this.setState({ validSectorId: evt.target.value.trim().length > 0 });
                this.setState({ sectorId: evt.target.value });

                this.reloadOrganisations(evt.target.value, this.state.surveyCycleId);
                break;
            }
            case 'surveyCycle': {
                this.setState({ validSurveyCycleId: evt.target.value.trim().length > 0 });
                this.setState({ surveyCycleId: evt.target.value });

                this.reloadOrganisations(this.state.sectorId, evt.target.value);
                break;
            }
            default: {
                break;
            }
        }
    }

    async handleSubmitUpdateAccount(event) {

        event.preventDefault();

        let body = {
            userid: this.state.realmId,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: null,
            email: this.state.emailAddress,
            attributes: {
                cellphone: [this.state.cellphone],
                workphone: [this.state.workPhone],
                designation: [this.state.designation]
            },
            roles: ['dsp_respondent']
        };

        const result = await ProfileManagementService.updateRealmUser(body);

        if (result == 'User updated') {
            window.open('/profile/respondent/account/view', '_self')
        } else {
            this.state.errors = result;
        }

    }

    async handleSubmitCreateAccount(event) {

        event.preventDefault();

        this.setState({ loading: true });

        let body = {
            userid: '',
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: null,
            email: this.state.emailAddress,
            attributes: {
                group_id: ["RESPONDENT"],
                source_of_data: [22],
                user_id: [this.state.contactStateId],
                sector_id: [this.state.sectorId],
                survey_cycle_id: [this.state.surveyCycleId],
                org_id: [this.state.organisationId],
                org_state_id: [this.state.organisationStateId],
                cellphone: [this.state.cellphone],
                workphone: [this.state.workPhone],
                designation: [this.state.designation],
                created_by_realm_id: [this.state.realmId]
            },
            roles: ['dsp_respondent']
        };

        const result = await ProfileManagementService.createRealmUser(body);

        if (result == 'Created') {
            this.clearState();
            { Alert('Respondent user created successfully.', 'Create Respondent - Success') };
        } else {
            { Alert('Respondent user not created. ' + result, 'Create Respondent - Failed') };
        }
        this.setState({ loading: false });

    }

    createAccount = () => {
        return (
            <div className={styles.tab_column_container}>
                <p className="description text-center">
                    <MultiColorTitle title_font="text__12px"
                        first_color="bold__blue"
                        first_text="Account Log in Details"
                    />
                </p>
                <p>
                    <TextField
                        error={this.state.emailAddress.length > 0 && !this.state.validEmail}
                        helperText={this.state.emailAddress.length > 0 && !this.state.validEmail ? 'Invalid email' : null}
                        className={styles.input_user_profile_account}
                        require="required"
                        name={'emailAddress'}
                        type="text"
                        placeholder={'name@business.co.za'}
                        id="outlined-basic"
                        size="small"
                        label={'Email Address'}
                        required
                        value={this.state.emailAddress}
                        onChange={this.handleChangedValue}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </p>
                <p className="description text-center">
                    <MultiColorTitle title_font="text__12px"
                        first_color="bold__blue"
                        first_text="Details of primary respondent"
                    />
                </p>
                <div className={styles.grid_container} >
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.firstName.length > 0 && !this.state.validFirstName}
                                    helperText={!FieldValidator.validateName(this.state.firstName) ? 'Invalid First Name' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'firstName'}
                                    type="text"
                                    placeholder={'First Name'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'First Name'}
                                    required
                                    value={this.state.firstName}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.lastName.length > 0 && !this.state.validLastName}
                                    helperText={!FieldValidator.validateName(this.state.lastName) ? 'Invalid Last Name' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'lastName'}
                                    type="text"
                                    placeholder={'Last Name'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Last Name'}
                                    required
                                    value={this.state.lastName}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </p>
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.cellphone.length > 0 && !this.state.validCellphone}
                                    helperText={!this.state.validCellphone ? 'Invalid cellphone number' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'cellphone'}
                                    type="text"
                                    placeholder={'072 - 309 - 4059'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Cellphone'}
                                    required
                                    value={this.state.cellphone}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.workPhone.length > 0 && !this.state.validWorkPhone}
                                    helperText={!this.state.validWorkPhone ? 'Invalid work phone number' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'workPhone'}
                                    type="text"
                                    placeholder={'011 - 238 - 4859'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Work Telephone'}
                                    required
                                    value={this.state.workPhone}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </p>
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <TextField
                                    className={styles.input_user_profile_details}
                                    name={'designation'}
                                    type="text"
                                    placeholder={'Designation'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Designation'}
                                    value={this.state.designation}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                <DropDownMenu
                                    name={'sector'}
                                    label={'Sector'}
                                    onChange={(evt) => this.handleChangedValue(evt)}
                                    showKeys={false}
                                    menuOptions={this.state.allSectors.sort((a, b) => a.value > b.value ? 1 : -1)}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </p>
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <DropDownMenu
                                    name={'surveyCycle'}
                                    label={'Survey Cycle'}
                                    onChange={(evt) => this.handleChangedValue(evt)}
                                    showKeys={false}
                                    menuOptions={this.state.allSurveyCycles.sort((a, b) => a.key > b.key ? 1 : -1)}
                                    required
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                {(this.state.organisationsLoading != null && this.state.organisationsLoading == true) ?
                                    <TextField
                                        className={styles.input_user_profile_details}
                                        require="required"
                                        name={'organisation'}
                                        type="text"
                                        placeholder={''}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Organisation'}
                                        required
                                        value={'Loading ...'}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                    />
                                    : <DropDownMenu
                                        name={'organisation'}
                                        label={'Organisation'}
                                        onChange={(evt) => this.handleChangedValue(evt)}
                                        showKeys={false}
                                        menuOptions={this.state.allOrganisations.sort((a, b) => a.value > b.value ? 1 : -1)}
                                        required
                                        busyLoading={this.state.organisationsLoading}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </p>
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                {(this.state.contactsLoading != null && this.state.contactsLoading == true) ?
                                    <TextField
                                        className={styles.input_user_profile_details}
                                        require="required"
                                        name={'contactState'}
                                        type="text"
                                        placeholder={'Last Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Organisation Contact'}
                                        required
                                        value={'Loading ...'}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        readOnly
                                    />
                                    : <DropDownMenu
                                        name={'contactState'}
                                        label={'RDSMS Organisation Contact'}
                                        onChange={(evt) => this.handleChangedValue(evt)}
                                        showKeys={false}
                                        menuOptions={this.state.allOrganisationContacts.sort((a, b) => a.value > b.value ? 1 : -1)}
                                        required
                                        busyLoading={this.state.contactsLoading}
                                    />
                                }
                            </Grid>
                            <Grid container item xs={5} >

                            </Grid>
                        </Grid>
                    </p>

                    <hr style={{ marginBottom: 20, marginTop: 50 }} />

                    <p>
                        <div style={{ marginBottom: 10, marginTop: 20 }}>
                            <input
                                className={styles.profile_button}
                                variant="contained"
                                type="submit"
                                size="large"
                                value="Create"
                                color={this.buttonDisabled() ? 'primary' : 'secondary'}
                                onClick={this.handleSubmitCreateAccount}
                                disabled={this.buttonDisabled()}
                            />
                        </div>
                    </p>
                </div>
            </div>
        )
    }

    updateAccount = () => {
        return (
            <div className={styles.tab_column_container}>
                <p className="description text-center">
                    <MultiColorTitle title_font="text__12px"
                        first_color="bold__blue"
                        first_text="Account Log in Details"
                    />
                </p>
                <p>
                    <TextField
                        error={this.state.emailAddress != null && this.state.emailAddress.length > 0 && !this.state.validEmail}
                        helperText={this.state.emailAddress != null && this.state.emailAddress.length > 0 && !this.state.validEmail ? 'Invalid email' : null}
                        className={styles.input_user_profile_account}
                        require="required"
                        name={'emailAddress'}
                        type="text"
                        placeholder={'name@business.co.za'}
                        id="outlined-basic"
                        size="small"
                        disabled
                        label={'Email Address'}
                        required
                        value={this.state.emailAddress}
                        onChange={this.handleChangedValue}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </p>
                <p className="description text-center">
                    <MultiColorTitle title_font="text__12px"
                        first_color="bold__blue"
                        first_text="Details of primary person completing the form"
                    />
                </p>
                <div className={styles.grid_container} >
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.firstName != null && this.state.firstName.length > 0 && !this.state.validFirstName}
                                    helperText={!FieldValidator.validateName(this.state.firstName) ? 'Invalid First Name' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'firstName'}
                                    type="text"
                                    placeholder={'First Name'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'First Name'}
                                    required
                                    value={this.state.firstName}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.lastName.length > 0 && !this.state.validLastName}
                                    helperText={!FieldValidator.validateName(this.state.lastName) ? 'Invalid Last Name' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'lastName'}
                                    type="text"
                                    placeholder={'Last Name'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Last Name'}
                                    required
                                    value={this.state.lastName}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </p>
                    <p>
                        <Grid container spacing={1} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.cellphone.length > 0 && !this.state.validCellphone}
                                    helperText={!this.state.validCellphone ? 'Invalid cellphone number' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'cellphone'}
                                    type="text"
                                    placeholder={'072 - 309 - 4059'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Cellphone'}
                                    required
                                    value={this.state.cellphone}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid container item xs={5} >
                                <TextField
                                    error={this.state.workPhone.length > 0 && !this.state.validWorkPhone}
                                    helperText={!this.state.validWorkPhone ? 'Invalid work phone number' : null}
                                    className={styles.input_user_profile_details}
                                    require="required"
                                    name={'workPhone'}
                                    type="text"
                                    placeholder={'011 - 238 - 4859'}
                                    id="outlined-basic"
                                    size="small"
                                    label={'Work Telephone'}
                                    required
                                    value={this.state.workPhone}
                                    onChange={this.handleChangedValue}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </p>
                    <hr style={{ marginBottom: 20, marginTop: 50 }} />

                    <p>
                        <div style={{ marginBottom: 10, marginTop: 20 }}>
                            <input
                                variant="contained"
                                type="submit"
                                size="large"
                                value="Update"
                                color={this.buttonDisabled() ? 'primary' : 'secondary'}
                                className={styles.profile_button}
                                onClick={this.handleSubmitUpdateAccount}
                                disabled={this.buttonDisabled()}
                            />
                            <text style={{ maxWidth: 485 }} className="text__12px">
                                {this.state.errors}
                            </text>
                        </div>
                    </p>
                </div>
            </div>

        )
    }

    updateOrganisation = () => {
        return this.viewOrganisation();
    }

    viewAccount = () => {
        return (
            <div className={styles.tab_column_container}>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Title:"
                        second_color="blue"
                        second_text={this.state.title}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="First name:"
                        second_color="blue"
                        second_text={this.state.firstName}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Last Name:"
                        second_color="blue"
                        second_text={this.state.lastName}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Email Address:"
                        second_color="blue"
                        second_text={this.state.emailAddress}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Cellphone:"
                        second_color="blue"
                        second_text={this.state.cellphone}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Work Telephone:"
                        second_color="blue"
                        second_text={this.state.workPhone}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Designation:"
                        second_color="blue"
                        second_text={this.state.designation}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Role:"
                        second_color="blue"
                        second_text={this.state.role}
                    />
                </p>
            </div >
        )
    }

    viewOrganisation = () => {
        return (
            <div>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Registered Name Of Company:"
                        second_color="blue"
                        second_text={this.state.registeredName}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Type of Organisation:"
                        second_color="blue"
                        second_text={this.state.organisationType}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Fiscal year:"
                        second_color="blue"
                        second_text={this.state.fiscalYear}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Physical Address:"
                        second_color="blue"
                        second_text={this.state.physicalAddress}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Province:"
                        second_color="blue"
                        second_text={this.state.province}
                    />
                </p>
                <p>
                    <MultiColorTitle title_font="text__16px"
                        first_color="bold__blue"
                        first_text="Postal Code:"
                        second_color="blue"
                        second_text={this.state.postalCode}
                    />
                </p>
            </div >
        )
    }

    render() {
        if (this.state.loading === true) {
            return <LoadingModal settings={true} />
        } else {
            switch (this.props.profileAction + '_' + this.props.profileDetail) {
                case 'create_account': return this.createAccount()
                // case 'create_organisation': return this.createOrganisation()
                case 'update_account': return this.updateAccount()
                case 'update_organisation': return this.updateOrganisation() // Note: Organisation view shown for update
                case 'view_account': return this.viewAccount()
                case 'view_organisation': return this.viewOrganisation()
                default: return <div>RespondentProfile: Unknown Action/Detail</div>
            }
        }
    }
}
export default RespondentProfile