import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Edit } from "@material-ui/icons";
import MultiColorTitle from '@components/MultiColorTitle';
import ResearcherProfile from './ResearcherProfile';
import RespondentProfile from './RespondentProfile';
import LoadingModal from '@components/LoadingModal';
import PageNotFound from '@pages/PageNotFound';
import styles from './userprofile.module.css';

export default class UserProfile extends Component {

    state = {
        loading: false,
        profileAction: "",
        profileGroup: "",
        profileDetail: "",
        pageTitle: "",
    };

    constructor(props) {
        super(props);

        this.setActionDetail = this.setActionDetail.bind(this);

        if (props.profileGroup != null && props.profileAction != null && props.profileDetail != null) {
            this.state.profileGroup = props.profileGroup;
            this.state.profileAction = props.profileAction;
            this.state.profileDetail = props.profileDetail;

            this.state.pageTitle = this.state.profileAction.charAt(0).toUpperCase() + this.state.profileAction.slice(1).toLowerCase();
        }

    }

    setActionDetail(evt, action, detail) {
        const title = this.state.profileAction.charAt(0).toUpperCase() + this.state.profileAction.slice(1).toLowerCase();

        this.setState({ title: title });
        this.setState({ profileAction: action });
        this.setState({ profileDetail: detail });
    };

    switchContent() {
        switch (this.state.profileGroup) {
            case 'researcher': return (
                <ResearcherProfile
                    profileAction={this.state.profileAction}
                    profileDetail={this.state.profileDetail}
                />
            );
            case 'respondent': return (
                <RespondentProfile
                    profileAction={this.state.profileAction}
                    profileDetail={this.state.profileDetail}
                />
            );
            default: return <PageNotFound />;
        }
    };

    render() {
        if (this.state.loading === true) {
            return <LoadingModal settings={true} />
        } else {
            return (
                <div className={styles.profile_body}>
                    <div className={styles.profileTitle_row_container}>
                        {this.state.profileAction == 'create'
                            ? <MultiColorTitle title_font="title__42px"
                                first_color="bold__orange"
                                first_text={this.state.profileAction.charAt(0).toUpperCase() + this.state.profileAction.slice(1).toLowerCase()}
                                second_color="blue"
                                second_text={this.state.profileGroup.charAt(0).toUpperCase() + this.state.profileGroup.slice(1).toLowerCase()}
                                third_color="bold__orange"
                                third_text="Profile" />
                            : <MultiColorTitle title_font="title__42px"
                                first_color="bold__orange"
                                first_text={this.state.profileAction.charAt(0).toUpperCase() + this.state.profileAction.slice(1).toLowerCase()}
                                second_color="blue"
                                second_text="Profile" />
                        }
                    </div>
                    <div className={styles.tab_row_container}>
                        <p>
                            <Grid container spacing={2} direction="row" justify={'flex-start'} justify-content="space-between" wrap="nowrap" >
                                <Grid container item xs={4} >
                                    {this.state.profileDetail === 'account'
                                        ? <MultiColorTitle title_font="text__18px"
                                            first_color="bold__orange"
                                            first_text="Account Details"
                                        />
                                        : <Link className="nav-link" onClick={(evt) => this.setActionDetail(evt, this.state.profileAction, 'account')} to={'/profile/' + this.state.profileGroup + '/account/' + this.state.profileAction}>
                                            <text className={styles.sidebar_link}>Account Details</text>
                                        </Link>
                                    }
                                </Grid>
                                <Grid container item xs={5} >
                                    {this.state.profileDetail == 'account'
                                        ? this.state.profileAction != 'create'
                                            ? <Link className="nav-link" onClick={(evt) => this.setActionDetail(evt, this.state.profileAction, 'organisation')} to={'/profile/' + this.state.profileGroup + '/organisation/view'}>
                                                <text className={styles.sidebar_link}>Organisation Details</text>
                                            </Link>
                                            : <div />
                                        : <MultiColorTitle title_font="text__18px"
                                            first_color="bold__orange"
                                            first_text="Organisation Details"
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </p>
                    </div>
                    {this.switchContent()}
                </div>
            )
        }
    }
}



