import styles from "./infonumber.module.css";
import { ArrowDownward } from "@material-ui/icons";
import Separator from "@components/Separator";
import pageHint from '@images/info.svg';



export default function InfoTextWidget(props) {
  return (


    <div className={styles.featuredItem}>

      <span className={styles.featuredTitleHeader}>{props.headerTitle}
        <span className="pageHint">
          <img src={pageHint} />
          <span className="tooltiptext">{props.toolTip}</span>
        </span>
      </span>

      <Separator />
      <span className={styles.featuredChangedRate}>
        -11.4 <ArrowDownward className="featuredIcon negative" />
      </span>
      <span className={styles.featuredTitle}>{props.title}</span>
      <br />
      <span className={styles.featuredSub}>{props.subTitle}</span>

      <div className={styles.featuredTextContainer}>
        <span className={styles.featuredText}>{props.content}</span>

      </div>




    </div>

  );
}