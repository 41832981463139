import React from 'react';
import Separator from "@components/Separator";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styles from "./getstarted.module.css";

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function HintCard(props) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent >
        {(props.heading !== undefined && props.heading != null && props.heading.length > 0) ?
          <div><div className={styles.hintTitle}>
            {props.heading}
          </div>
            <Separator />
          </div>
          : <div />
        }
        <Typography variant="body2">
          <div className={styles.hintText}>
            {props.hintText}
          </div>
        </Typography>
      </CardContent>
    </Card>
  );
}