import React, { useState, Component } from 'react';
import styles from './spinnermodal.module.css';

class SpinnerModal extends Component {
    render() {
        return <div className={styles.container_spinnermodal}>
            <div className={styles.container_row}>
                <div style={{ display: (this.props.settings === true) ? 'flex' : 'none' }} className={styles.modal} >
                    <div className={styles.modal_content}>
                        <div className={styles.loader}>
                        </div>
                        {(this.props.label !== undefined && this.props.label !== null) ? <div>&nbsp;  {this.props.label}</div> : null}
                    </div>
                </div>
            </div>
        </div>
    }
}
export default SpinnerModal;