import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class ExpenditureLocationQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    async handleOnChange(evt) {
        let key = evt.target.attributes.questionFactId.value + '_' + evt.target.attributes.ordinalEntry.value;
        let value = evt.target.value;

        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
            const result = await CustomDialog(
                <UpdateAuditDialog
                    currentAnswer={SessionStateManager.getSessionValue(key)}
                    numericInput={true}
                />,
                {
                    className: styles.updateAuditDialog,
                    title: 'Update Submitted Value',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );

            if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                SessionStateManager.setSessionValue(key, result.newValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                this.setState({ sessionStateChanged: true });
            } else {
                evt.preventDefault();
            }
        } else {
            SessionStateManager.setSessionValue(key, value, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
            this.setState({ sessionStateChanged: true });
        }

        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            return <div className={styles.expenditureLocationQuestionContainer}>
                <table className="table">
                    <thead>
                        <tr>
                            <th width='60%'>Province</th>
                            <th width='40%'>% of expenditure</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.questionState.questionFactStates
                            .filter((questionFactState) => !questionFactState.rowDimensionStates[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                            .sort((a, b) => (a.sequenceNumberX > b.sequenceNumberX) ? 1 : -1)
                            .map((questionFactState) =>
                                <tr>
                                    {questionFactState.rowDimensionStates
                                        .sort((a, b) => (a.sequenceNumber > b.sequenceNumber) ? 1 : -1)
                                        .map((rowDimensionState) =>
                                            <td>{rowDimensionState.dimensionAlias}</td>
                                        )
                                    }
                                    <td
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableInputBoxUpdated : null}
                                    >
                                        <div className={styles.percentage}>
                                            <input
                                                className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableInputBoxUpdated : styles.tableInputBox}
                                                type="text"
                                                questionId={this.props.questionState.question.id}
                                                ordinalEntry={0}
                                                questionFactId={questionFactState.questionFactId}
                                                placeholder='0'
                                                value={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0')}
                                                onClick={(evt) => {
                                                    if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
                                                        this.handleOnChange(evt);
                                                    }
                                                }}
                                                onKeyPress={(event) => { if (!/[0-9|\.]/.test(event.key) || (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE')) { event.preventDefault(); } }}
                                                onChange={(evt) => this.handleOnChange(evt)}
                                                onBlur={this.handleOnBlur}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        {this.props.questionState.questionFactStates
                            .filter((questionFactState) => questionFactState.rowDimensionStates[0].dimensionAlias.toUpperCase().includes('TOTAL'))
                            .map((questionFactState) =>
                                <tr>
                                    <th>{questionFactState.rowDimensionStates[0].dimensionAlias.replace('Total', 'TOTAL')}</th>
                                    <td
                                        className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, questionFactState.questionFactId + '_0') ? styles.tableTotalCellUpdated : null}
                                    >
                                        <div className={styles.percentage}>
                                            <TextField
                                                questionId={this.props.questionState.question.id}
                                                error={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') != 100}
                                                helperText={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0') != 100 ? 'Total must sum to 100%' : null}
                                                type="number"
                                                id="outlined-error-helper-text"
                                                size="normal"
                                                value={SessionStateManager.getSessionValue(questionFactState.questionFactId + '_0')}
                                                ordinalEntry={0}
                                                questionFactId={questionFactState.questionFactId}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                placeholder='0'
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        }
    }
}
export default ExpenditureLocationQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    {...props}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if (
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                            result.confirmed = true;
                            dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}
