// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile_container_profile__1rvs6 {\r\n  display: flex;\r\n  flex:  5 1;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  align-content: flex-start;\r\n  \r\n  justify-content: space-around;\r\n  background-color: var(--hsrcBackgroundLight);\r\n  border-radius: 1rem;\r\n  padding: 2rem;\r\n}", "",{"version":3,"sources":["webpack://src/pages/RespondentDashboard/pages/Profile/profile.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,UAAQ;EACR,sBAAsB;EACtB,uBAAuB;EACvB,yBAAyB;;EAEzB,6BAA6B;EAC7B,4CAA4C;EAC5C,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".container_profile {\r\n  display: flex;\r\n  flex:  5;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  align-content: flex-start;\r\n  \r\n  justify-content: space-around;\r\n  background-color: var(--hsrcBackgroundLight);\r\n  border-radius: 1rem;\r\n  padding: 2rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_profile": "profile_container_profile__1rvs6"
};
export default ___CSS_LOADER_EXPORT___;
