import React, { Component } from "react";
import { Email } from "react-obfuscate-email";

import SpinnerModal from '@components/SpinnerModal';
import Separator from "@components/Separator";
import Footer from "@components/Footer";
import FieldValidator from "@components/FieldValidator";
import Contact from "@components/Contact";
import NavigationBar from "@components/NavigationBar";
import ProfileManagementService from "@services/ProfileManagementService";
import styles from "./login.module.css";
import axios from "axios";
import qs from "qs";
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Alert } from 'react-st-modal';

function parseJwt(token) {
    var base64Payload = token.split('.')[1];
    var payload = Buffer.from(base64Payload, 'base64');
    return JSON.parse(payload.toString());
}

class Login extends Component {

    state = {
        loading: false,
        userId: "",
        username: "",
        password: "",
        access_token: '',
        refresh_token: '',
        errors: '',
        showModal: false,
        showPassword: false,
        otpEmailSent: false,
        validUsername: false,
        prepopulated: false,
    };

    constructor(props) {
        super(props);

        localStorage.clear();
        sessionStorage.clear();

        this.validateUsername = this.validateUsername.bind(this);
        this.handleContinue = this.handleContinue.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        if (this.props.prepop !== undefined && this.props.prepop != null && this.props.prepop == true
            && this.props.username !== undefined && this.props.username != null) {
            this.setState({ otpEmailSent: true });
            this.setState({ prepopulated: true });
            this.setState({ validUsername: true });
            this.setState({ username: this.props.username });
        }

        this.setState({ loading: false });
    }

    async validateUsername() {
        try {
            const user = await ProfileManagementService.getUserByUsername(this.state.username);

            if (user !== undefined && user != null && user.userid != null) {
                this.setState({ validUsername: user.userid != null });
                this.setState({ userId: user.userid });
                return (user.userid != null);
            } else {
                let text = 'The user [ ' + this.state.username + ' ] is not registered on the RDI Surveys platform.\nPlease review your username.';
                { Alert(text, 'Login - Username') };
            }
        } catch (e) {
            { Alert('We have encountered and unexpected connection error. Please try again later.', 'Connection error') };
        }

        return false;
    }

    async handleContinue(event) {
        this.setState({ loading: true });

        const answ = await this.validateUsername();

        if (this.state.validUsername) {
            const func = (async () => {
                const res = await ProfileManagementService.sendNewOneTimePasswordForUserId(this.state.userId);
            })([]);
            this.setState({ otpEmailSent: true });
        } else {
            event.preventDefault();

        }

        this.setState({ loading: false });
    }

    handleSubmit = event => {
        event.preventDefault();

        let data = qs.stringify({
            grant_type: 'password',
            client_id: 'dsp-ui-application',
            username: this.state.username,
            password: this.state.password,

        });


        axios.post(process.env.REACT_APP_PROFILE_MANAGEMENT_MICROSERVICE_URL + process.env.REACT_APP_SERVICES_VERSION + "/profilemanagement/user/login", {
            username: this.state.username,
            password: this.state.password
        }).then(response => {
            if (response.status === 200) {
                this.setState({ access_token: response.data.access_token });
                localStorage.setItem('Bearer', response.data.access_token);
                localStorage.setItem('Token', response.data);
                let payload = parseJwt(this.state.access_token);

                if (payload.group_id === "RESEARCHER") {
                    window.location = "/researcherdashboard" //This line of code will redirect you once the submission is succeed
                } else if (payload.group_id === "RESPONDENT") {
                    window.location = "/respondentdashboard" //This line of code will redirect you once the submission is succeed
                } else {
                    { Alert('We have experienced an error while trying to log you in, please contact innovation@hsrc.ac.za ', 'Login Error') };
                    this.setState({ errors: "Invalid user credentials" });
                }
            } else if (response.status === 401) {
                { Alert('Invalid user credentials', 'Login') };
                this.setState({ errors: "Invalid user credentials" });
            } else {
                { Alert('Login error', 'Login') };
                this.setState({ errors: "Invalid user credentials" });
            }
        }).catch(error => {
            //response status is an error code
            if (error !== undefined && error != null
                && error.response !== undefined && error.response != null
                && error.response.status !== undefined && error.response.status != null
                && error.response.status === 412) {
                { Alert('Your one-time password has expired', 'OTP Expired') };
                this.setState({ errors: "One-Time Password has expired" });
            } else {
                Alert('Invalid user credentials', 'Login')
                this.setState({ errors: "Invalid user credentials" });
            };
        });

    }

    handleOnChange = (evt) => {

        if (FieldValidator.validateEmail(evt.target.value)) {
            this.setState({ errors: '' });
        } else {
            this.setState({ errors: 'Invalid email address, please verify your input.' });
        }
        this.setState({ username: evt.target.value.replace(/\s/g, '').toLowerCase() });

        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleChangePassword = event => {
        this.setState({ password: event.target.value });
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            return (
                <div>
                    <NavigationBar />

                    <div className={styles.flexr}>
                        <div className={styles.container}>
                            <div className={styles.flexr}>
                                <div className="login-wrapper">
                                    <div className={styles.logintext}>

                                        <h1 className="title__42px">
                                            {this.state.otpEmailSent
                                                ? <text className="title__42px__orange__bold">
                                                    Check&nbsp;
                                                    <text className="title__42px">
                                                        Your Email
                                                    </text>
                                                </text>
                                                : <text className="title__42px__orange__bold">
                                                    Log&nbsp;
                                                    <text className="title__42px">
                                                        In
                                                    </text>
                                                </text>
                                            }
                                        </h1>
                                        <br />
                                        {this.state.otpEmailSent
                                            ? <h5>We have sent a secure one-time password that will sign you in.</h5>
                                            : <h5>Trouble logging in? Write to <Email email="innovation@hsrc.ac.za" subject="Trouble logging in to R&D Survey"><span className={styles.emailLink}>innovation@hsrc.ac.za</span></Email></h5>
                                        }
                                        <br />
                                        <form onSubmit={this.state.otpEmailSent ? this.handleSubmit : this.handleContinue} className={styles.flexc}>

                                            <div className={styles.inputbox}>
                                                <div className={styles.input}>
                                                    <input
                                                        value={this.state.username.toLowerCase()}
                                                        type='text'
                                                        id='username'
                                                        name='username'
                                                        className={styles.text}
                                                        placeholder={'Email Address'}
                                                        onChange={evt => {
                                                            this.handleOnChange(evt);
                                                        }}
                                                        readOnly={this.state.prepopulated || this.state.otpEmailSent}
                                                    />
                                                </div>

                                            </div>

                                            {this.state.otpEmailSent
                                                ? <div className={styles.inputbox}>
                                                    <div className={styles.input}>
                                                        <input
                                                            type={this.state.showPassword ? "" : "password"}
                                                            name="password"
                                                            placeholder="One Time Password (OTP)"                        
                                                            onChange={this.handleChangePassword}
                                                        />
                                                        <span
                                                            className={styles.passwordToggle}
                                                            onClick={() => this.setState({ showPassword: !this.state.showPassword })}>{this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </span>
                                                    </div>
                                                </div>
                                                : <div />
                                            }

                                            <div class="check">
                                                {this.state.errors}
                                                <span></span>
                                            </div>

                                            {this.state.otpEmailSent
                                                ? <div className={styles.buttonDiv}>
                                                    <h5>
                                                        <a href="" onClick={this.handleContinue} ><span className={styles.emailLink}>Re-send password</span></a>
                                                    </h5>
                                                </div>
                                                : <div />
                                            }

                                            <Separator />
                                            {this.state.otpEmailSent
                                                ? <div className={styles.buttonDiv}>
                                                    <button name="dd" type="submit" value="Log In" onClick={this.handleSubmit}>Log In</button>
                                                    {this.props.errors}
                                                </div>
                                                : <div className={styles.buttonDiv}>
                                                    <button name="dd" type="submit" value="Continue" onClick={this.handleContinue}>Continue</button>
                                                    {/* {this.props.errors} */}
                                                </div>
                                            }
                                        </form>
                                        <br />
                                        <br />

                                        <a href="/confidentiality">
                                            <h1 className="text__14px">
                                                <SecurityTwoToneIcon />

                                                <text className="text__14px" style={{ paddingLeft: 4 }}>Learn more about the security&nbsp;</text>
                                                <text className="text__14px__orange">of your data.</text>
                                            </h1>
                                        </a>
                                        <div className={styles.divider} />



                                        <div className={styles.question_div}>

                                            <text className="text__14px">Any questions?&nbsp;</text>

                                            <Email email="innovation@hsrc.ac.za" subject="R&D Survey Enquiry" ><text className="text__14px__orange">Send us a message</text></Email>

                                        </div>
                                        <br />
                                        <a className="div-anchor" href="/faq">
                                            <h1 className="text__14px" style={{ fontWeight: 600 }}>
                                                <text className="text__14px">Learn more about this platform in our&nbsp;</text>
                                                <text className="text__14px__orange">Frequently Asked Questions</text>
                                            </h1>
                                        </a>


                                        {/* </form> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Separator />
                    <Contact />
                    <Separator />
                    <Footer />
                </div>
            )
        }
    }

}

export default Login;