import React, { Component } from "react";
import SpinnerModal from '@components/SpinnerModal';
import ActivitySICQuestion from "./questions/ActivitySICQuestion";
import BooleanRadioQuestion from "./questions/BooleanRadioQuestion";
import BreakdownBySourcesOfFundsQuestion from "./questions/BreakdownBySourcesOfFundsQuestion";
import CapitalExpenditureQuestion from "./questions/CapitalExpenditureQuestion";
import CollaborativeRnDQuestion from "./questions/CollaborativeRnDQuestion";
import DateQuestion from "./questions/DateQuestion";
import ExpenditureLocationQuestion from "./questions/ExpenditureLocationQuestion";
import ForeignSourcesOfFundsQuestion from "./questions/ForeignSourcesOfFundsQuestion";
import FutureRnDQuestion from "./questions/FutureRnDQuestion";
import FTEQuestion from "./questions/FTEQuestion";
import HeadCountQuestion from "./questions/HeadCountQuestion";
import HELabourCostQuestion from "./questions/HELabourCostQuestion";
import HEPostgradFTEQuestion from "./questions/HEPostgradFTEQuestion";
import HEPostgradHeadCountQuestion from "./questions/HEPostgradHeadCountQuestion";
import InHouseExpenditureQuestion from "./questions/InHouseExpenditureQuestion";
import MultiDisciplinaryAreasQuestion from "./questions/MultiDisciplinaryAreasQuestion";
import NationalPriorityAreaQuestion from "./questions/NationalPriorityAreaQuestion";
import NumericQuestion from "./questions/NumericQuestion";
import OwnershipPercentageQuestion from "./questions/OwnershipPercentageQuestion";
import ReadonlyTotalQuestion from "./questions/ReadonlyTotalQuestion";
import RFCodesQuestion from "./questions/RFCodesQuestion";
import RnDOutsourcedQuestion from "./questions/RnDOutsourcedQuestion";
import BooleanCheckboxQuestion from "./questions/BooleanCheckboxQuestion";
import SEOCodesQuestion from "./questions/SEOCodesQuestion";
import SICQuestion from "./questions/SICQuestion";
import SubsidiaryCompaniesQuestion from "./questions/SubsidiaryCompaniesQuestion";
import TextQuestion from "./questions/TextQuestion";

class QuestionFactory extends Component {
    state = {
        loading: true,
    }

    constructor(props) {
        super(props);
    }

    handleOnChange = (evt) => {
        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }
    }

    handleOnBlur = (evt) => {
        if (this.props.onBlur !== undefined && this.props.onBlur != null) {
            this.props.onBlur(evt);
        }
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            switch (this.props.questionState.question.questionMeasure.measureType) {
                case 'BOOLEAN': {
                    // Render yes/no checkboxes
                    if (this.props.questionState.questionMeasureUIType == 'CHECKBOX') {

                        if (this.props.questionState.question.questionType == 'SHORTINPUTS'
                            && this.props.questionState.name.toUpperCase().includes('COLLABORATIVE')) {

                            return <CollaborativeRnDQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        } else {
                            if (
                                this.props.questionState.name.toUpperCase().includes('Outsourced R&D performed'.toUpperCase())
                                || this.props.questionState.name.toUpperCase().includes('Collaborative R&D with persons/organisations outside your organisation'.toUpperCase())
                                || this.props.questionState.name.toUpperCase().includes('Is this a Private or Public'.toUpperCase())
                                || this.props.questionState.name.toUpperCase().includes('enhanced tax allowance'.toUpperCase())
                                || (this.props.questionState.name.toUpperCase().includes('R&D Performed'.toUpperCase())
                                    && !this.props.questionState.name.toUpperCase().includes('(Outsourced)'.toUpperCase())
                                )
                            ) {
                                return <BooleanRadioQuestion
                                    captureType={this.props.captureType}
                                    questionState={this.props.questionState}
                                    orientation={'horisontal'}
                                    onChange={this.handleOnChange}
                                    questionnairePartId={this.props.questionnairePartId}
                                    compoundQuestionStateId={this.props.compoundQuestionStateId}
                                />
                            } else {
                                return <BooleanCheckboxQuestion
                                    captureType={this.props.captureType}
                                    questionState={this.props.questionState}
                                    orientation={'horisontal'}
                                    onChange={this.handleOnChange}
                                    questionnairePartId={this.props.questionnairePartId}
                                    compoundQuestionStateId={this.props.compoundQuestionStateId}
                                />
                            }
                        }
                    }

                    if (this.props.questionState.questionMeasureUIType == 'RADIO') {

                        if (this.props.questionState.questionFactStates.length == 1) {
                            return <BooleanRadioQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                orientation={'horisontal'}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }

                        // Render radio group of yes/no buttons
                        return <FutureRnDQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }
                }
                case 'TEXT': {
                    // Render only plain text input boxes
                    if (this.props.questionState.questionMeasureUIType == 'TEXT'
                        && this.props.questionState.question.questionType != 'EXPANDABLE'
                        && (this.props.questionState.text != null && !this.props.questionState.text.includes('mm/yy')
                            || this.props.questionState.name != null && this.props.questionState.name.toUpperCase().includes('Trading as'.toUpperCase()))) {

                        return <TextQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            startEndornment={''}
                            endEndornment={''}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    // Render date pickers
                    if (this.props.questionState.questionMeasureUIType == 'TEXT'
                        && this.props.questionState.question.questionType != 'EXPANDABLE'
                        && (this.props.questionState.name != null && this.props.questionState.name.toUpperCase().includes('Financial year for which you are reporting'.toUpperCase())
                            || this.props.questionState.name != null && this.props.questionState.name.toUpperCase().includes('if yes state period'.toUpperCase()))
                    ) {

                        return <DateQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    if (this.props.questionState.questionMeasureUIType == 'TEXT'
                        && this.props.questionState.question.questionType == 'EXPANDABLE') {

                        if (this.props.questionState.name.toUpperCase().includes('R&D outsourced inside South Africa by company and value'.toUpperCase())
                            || this.props.questionState.name.toUpperCase().includes('R&D outsourced by company and value'.toUpperCase())) {

                            return <RnDOutsourcedQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                sectorId={this.props.sectorId}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }
                    }

                    if (this.props.questionState.questionMeasureUIType == 'TEXT'
                        && this.props.questionState.question.questionType == 'EXPANDABLE') {

                        if (this.props.questionState.name.toUpperCase().includes('Several Companies'.toUpperCase())) {

                            return <SubsidiaryCompaniesQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }
                    }
                }
                case 'FLOAT': {
                    if ((this.props.questionState.questionMeasureUIType == 'FLOAT') && this.props.questionState.questionFactStates.length == 1) {
                        // Render plain input box with % end-adornment
                        if (this.props.questionState.name.toUpperCase().includes('Higher Education Vote Split'.toUpperCase())
                            || this.props.questionState.name.toUpperCase().includes('Own Funds Split'.toUpperCase())
                            || this.props.questionState.name.toUpperCase().includes('Percentage of time contract'.toUpperCase())
                        ) {

                            return <NumericQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                startEndornment={''}
                                endEndornment={'%'}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }

                        // Render plain input box with R start-endornment
                        if (this.props.questionState.question.name.toUpperCase().includes('REVENUE')
                            || this.props.questionState.question.name.toUpperCase().includes('LAND')
                            || this.props.questionState.question.name.toUpperCase().includes('MONETARY')
                            || this.props.questionState.question.name.toUpperCase().includes('Other Current Expenditure on R and D'.toUpperCase())
                            || (this.props.questionState.name.toUpperCase().includes('Total Other Current Expenditure'.toUpperCase())
                                && !this.props.questionState.name.toUpperCase().includes('Total Other Current Expenditure (D)'.toUpperCase()))
                            || this.props.questionState.name.toUpperCase().includes('R&D outsourced inside South Africa'.toUpperCase())
                            || this.props.questionState.name.toUpperCase().includes('R&D outsourced outside South Africa'.toUpperCase())
                            || this.props.questionState.question.name.toUpperCase().includes('SPECIFIC CATEGORIES')) {

                            return <NumericQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                startEndornment={'R'}
                                endEndornment={''}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }

                        // Render read only Total values
                        if (this.props.questionState.name.toUpperCase().includes('LABOUR')
                            || this.props.questionState.name.toUpperCase().includes('Total cost of R&D personnel'.toUpperCase())
                            || this.props.questionState.name.toUpperCase().includes('Expenditure(A + B + C + D)'.toUpperCase())
                            || this.props.questionState.name.toUpperCase().includes('Total Breakdown of R&D expenditure'.toUpperCase())
                            || this.props.questionState.name.toUpperCase().includes('Subtotal (External Sources)'.toUpperCase())
                            || this.props.questionState.name.toUpperCase().includes('Total Other Current Expenditure (D)'.toUpperCase())) {

                            return <ReadonlyTotalQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }

                        // Render read only Total values
                        if (this.props.questionState.name.toUpperCase().includes('General University Fund Calculation'.toUpperCase())) {

                            return <NumericQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                startEndornment={'R'}
                                endEndornment={''}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }

                        // Render any other numeric input box which requires no adornemnts
                        return <NumericQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            startEndornment={''}
                            endEndornment={''}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    if (this.props.questionState.questionFactStates.length == 1
                        && this.props.questionState.name.toUpperCase().includes('Expenditure(A + B + C + D)'.toUpperCase())) {

                        return <ReadonlyTotalQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }


                    if (this.props.questionState.questionMeasureUIType == 'FLOAT'
                        && this.props.questionState.questionFactStates.length == 2) {
                        // Render Percentage Ownership Questions
                        if ((this.props.questionState.text != null && this.props.questionState.text.toUpperCase().includes('% ownership'.toUpperCase()))
                            || (this.props.questionState.text != null && this.props.questionState.text.toUpperCase().includes('percentage ownership'.toUpperCase()))
                            || this.props.questionState.name.toUpperCase().includes('% ownership'.toUpperCase())
                            || this.props.questionState.name.toUpperCase().includes('percentage ownership'.toUpperCase())) {

                            return <OwnershipPercentageQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }

                        if (this.props.questionState.name.toUpperCase().includes('LABOUR COSTS')) {

                            return <HELabourCostQuestion
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }

                        if (this.props.questionState.name.toUpperCase().includes('Outsourced to Outside South Africa'.toUpperCase())) {

                            return <CapitalExpenditureQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }
                    }

                    if (this.props.questionState.questionMeasureUIType == 'FLOAT' && (this.props.questionState.questionFactStates.length == 2 || this.props.questionState.questionFactStates.length == 3)) {
                        // Render Capital Expenditure Questions
                        if (
                            this.props.questionState.name.toUpperCase().includes('Capital Expenditure on R and D'.toUpperCase())
                        ) {
                            return <CapitalExpenditureQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }
                    }

                    if (this.props.questionState.question.questionType == 'SHORTINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('BREAKDOWN OF R&D EXPENDITURE')) {

                        return <BreakdownBySourcesOfFundsQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    if (this.props.questionState.question.questionType == 'SHORTINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('FOREIGN SOF')
                        || this.props.questionState.question.questionType == 'SHORTINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('Outsourced outside South Africa'.toUpperCase())) {

                        return <ForeignSourcesOfFundsQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            sectorId={this.props.sectorId}
                            onChange={this.handleOnChange}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    // Render Provincial Expenditure Location table
                    if (this.props.questionState.questionMeasureUIType == 'FLOAT'
                        && this.props.questionState.question.questionType == 'LONGINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('EXPENDITURE LOCATION')) {

                        return <ExpenditureLocationQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnBlur}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    // Render National Priority Areas table
                    if (this.props.questionState.questionMeasureUIType == 'FLOAT'
                        && this.props.questionState.question.questionType == 'LONGINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('NATIONAL PRIORITY AREAS')) {

                        return <NationalPriorityAreaQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnBlur}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    // Render Multi Disciplinary Areas table
                    if (this.props.questionState.questionMeasureUIType == 'FLOAT'
                        && this.props.questionState.question.questionType == 'LONGINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('MULTI')
                        && this.props.questionState.name.toUpperCase().includes('DISCIPLIN')) {

                        return <MultiDisciplinaryAreasQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnBlur}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    // Render In-House R&D Expenditure table
                    if (this.props.questionState.questionMeasureUIType == 'FLOAT'
                        && this.props.questionState.question.questionType == 'LONGINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('IN-HOUSE')
                        && this.props.questionState.name.toUpperCase().includes('EXPENDITURE')) {

                        return <InHouseExpenditureQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnBlur}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    // Render the HeadCount tables
                    if (this.props.questionState.questionMeasureUIType == 'FLOAT'
                        && this.props.questionState.question.questionType == 'SHORTINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('HEADCOUNT')
                        && !this.props.questionState.name.toUpperCase().includes('POSTGRAD')) {

                        return <HeadCountQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnBlur}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    // Render the Postgrad HeadCount tables
                    if (this.props.questionState.questionMeasureUIType == 'FLOAT'
                        && this.props.questionState.question.questionType == 'SHORTINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('HEADCOUNT')
                        && this.props.questionState.name.toUpperCase().includes('POSTGRAD')) {

                        return <HEPostgradHeadCountQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnBlur}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    // Render the Postgrad FTE tables
                    if (this.props.questionState.questionMeasureUIType == 'FLOAT'
                        && this.props.questionState.question.questionType == 'SHORTINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('PERCENTAGE')
                        && this.props.questionState.name.toUpperCase().includes('POSTGRAD')
                        && this.props.questionState.name.toUpperCase().includes('COSTS')) {

                        return <HEPostgradFTEQuestion
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            onBlur={this.handleOnBlur}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    if (this.props.questionState.questionMeasureUIType == 'FLOAT'
                        && this.props.questionState.question.questionType == 'SHORTINPUTS'
                        && this.props.questionState.name.toUpperCase().includes('Full-time Equivalents'.toUpperCase())) {

                        return <FTEQuestion
                            sectorId={this.props.sectorId}
                            captureType={this.props.captureType}
                            questionState={this.props.questionState}
                            onChange={this.handleOnChange}
                            questionnairePartId={this.props.questionnairePartId}
                            compoundQuestionStateId={this.props.compoundQuestionStateId}
                        />
                    }

                    if (this.props.questionState.question.questionType == 'EXPANDABLE') {

                        if (this.props.questionState.name.toUpperCase().includes('PRINCIPAL ACTIVIT')
                            && this.props.questionState.name.toUpperCase().includes('SIC CODES')
                            && this.props.sectorId == 1) {

                            return <ActivitySICQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }

                        if (!this.props.questionState.name.toUpperCase().includes('PRINCIPAL ACTIVIT')
                            && this.props.questionState.name.toUpperCase().includes('SIC')
                            && this.props.sectorId == 1) {

                            return <SICQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }

                        if (this.props.questionState.name.toUpperCase().includes('RESEARCH FIELD')) {

                            return <RFCodesQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                sectorId={this.props.sectorId}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }

                        if (this.props.questionState.name.toUpperCase().includes('SEO')) {

                            return <SEOCodesQuestion
                                captureType={this.props.captureType}
                                questionState={this.props.questionState}
                                sectorId={this.props.sectorId}
                                onChange={this.handleOnChange}
                                questionnairePartId={this.props.questionnairePartId}
                                compoundQuestionStateId={this.props.compoundQuestionStateId}
                            />
                        }
                    }
                }
                default: {
                    return <div />
                }
            }
        }
    }
}
export default QuestionFactory;