import React, { Component } from "react";
import FormControl from '@material-ui/core/FormControl';
import SessionStateManager from "@components/SessionStateManager";
import ValidationsManager from "@components/ValidationsManager";
import UpdateDialog from '@components/UpdateDialog';
import SupportAlert from "@components/SupportAlert";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from '../questionnaire.module.css';

class BooleanCheckboxQuestion extends Component {
    state = {
        loading: true,
        sessionStateChanged: false,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ loading: false });
    }

    async handleOnChange(evt) {
        let key = evt.target.attributes.questionFactId.value + '_' + evt.target.attributes.ordinalEntry.value;
        let value = evt.target.value;

        if (this.props.captureType !== undefined && this.props.captureType != null && this.props.captureType == 'RESEARCHER_UPDATE') {
            const result = await CustomDialog(
                <UpdateAuditDialog
                    currentAnswer={SessionStateManager.getSessionValue(key)}
                    booleanType={'checkbox'}
                    checkboxLabel={this.props.questionState.questionFactStates[0].rowDimensionStates[0] !== undefined
                        && this.props.questionState.questionFactStates[0].rowDimensionStates[0] != null
                        && this.props.questionState.questionFactStates[0].rowDimensionStates[0].dimensionAlias !== undefined
                        && this.props.questionState.questionFactStates[0].rowDimensionStates[0].dimensionAlias != null
                        && this.props.questionState.questionFactStates[0].rowDimensionStates[0].dimensionAlias != ''
                        ? this.props.questionState.questionFactStates[0].rowDimensionStates[0].dimensionAlias
                        : 'Yes'}
                />,
                {
                    className: styles.updateAuditDialog,
                    title: 'Update Submitted Value',
                    showCloseIcon: false,
                    isFocusLock: true,
                    isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
                }
            );

            if (result !== undefined && result != null && result.confirmed !== undefined && result.confirmed != null && result.confirmed == true) {
                SessionStateManager.setSessionValue(key, result.newValue, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, result.sourceOfData, result.reason);
                this.setState({ sessionStateChanged: true });
            } else {
                evt.preventDefault();
            }
        } else {
            SessionStateManager.setSessionValue(key, value, this.props.questionState.question.id, this.props.questionnairePartId, this.props.compoundQuestionStateId, "", "");
            this.setState({ sessionStateChanged: true });
        }

        if (this.props.onChange !== undefined && this.props.onChange != null) {
            this.props.onChange(evt);
        }

    }

    render() {
        if (this.state.loading === true) {
            return <p>Loading...</p>
        } else {
            let notNull = SessionStateManager.getSessionValue(this.props.questionState.questionFactStates[0].questionFactId + '_0') != null;
            let isChecked = JSON.parse((SessionStateManager.getSessionValue(this.props.questionState.questionFactStates[0].questionFactId + '_0') != null
                && SessionStateManager.getSessionValue(this.props.questionState.questionFactStates[0].questionFactId + '_0') != '')
                ? SessionStateManager.getSessionValue(this.props.questionState.questionFactStates[0].questionFactId + '_0') : 'false');

            return <FormControl component="fieldset">
                <div
                className={ValidationsManager.hasValueBeenUpdated(this.props.captureType, this.props.questionState.questionFactStates[0].questionFactId + '_0') ? styles.checkboxUpdated : styles.checkbox}
                >
                    <input
                        type="checkbox"
                        className="radio"
                        id={"checkbox_yes_q" + this.props.questionState.question.id + 'qf' + this.props.questionState.questionFactStates[0].questionFactId}
                        value={!(notNull && isChecked)}
                        checked={notNull && isChecked}
                        onClick={(evt) => this.handleOnChange(evt)}
                        ordinalEntry={0}
                        questionFactId={this.props.questionState.questionFactStates[0].questionFactId}
                        questionId={this.props.questionState.question.id}

                    />
                    <label for={"checkbox_yes_q" + this.props.questionState.question.id}>{
                        this.props.questionState.questionFactStates[0].rowDimensionStates[0] !== undefined
                            && this.props.questionState.questionFactStates[0].rowDimensionStates[0] != null
                            && this.props.questionState.questionFactStates[0].rowDimensionStates[0].dimensionAlias !== undefined
                            && this.props.questionState.questionFactStates[0].rowDimensionStates[0].dimensionAlias != null
                            && this.props.questionState.questionFactStates[0].rowDimensionStates[0].dimensionAlias != ''
                            ? this.props.questionState.questionFactStates[0].rowDimensionStates[0].dimensionAlias
                            : 'Yes'
                    }</label>
                </div>
            </FormControl>
        }
    }
}
export default BooleanCheckboxQuestion;

function UpdateAuditDialog(props) {
    const dialog = useDialog();

    let componentRef = null;
    let result = {};
    result.confirmed = false;

    return (
        <div>
            <div
                className={styles.updateAuditDialogOverlay}
                ref={(response) => (componentRef = response)}
            >
                <UpdateDialog
                    {...props}
                    onChangeSourceOfData={(evt, newValue) => {
                        result.newValue = newValue;
                        result.sourceOfData = evt.target.value;
                    }}
                    onChangeReason={(evt, newValue) => {
                        result.newValue = newValue;
                        result.reason = evt.target.value
                    }}
                    onChangeNewValue={(evt) => {
                        result.newValue = evt.target.value
                    }}
                />
            </div>

            <div>
                <button
                    type="button"
                    className="btnBack"
                    onClick={(evt) => {
                        result.confirmed = false;
                        dialog.close(result);
                    }}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btnNext"
                    onClick={(evt) => {
                        if(
                            result.sourceOfData === undefined || result.sourceOfData == null || result.sourceOfData.trim() == "" ||
                            result.reason === undefined || result.reason == null || result.reason.trim() == ""
                        ) {
                            SupportAlert.displayAlert('Required', 'Source of data and Reason are required fields.');
                        } else {
                        result.confirmed = true;
                        dialog.close(result);
                        }
                    }}
                >
                    Continue
                </button>
            </div>
        </div>
    );
}
