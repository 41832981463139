// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logout_container_logout__1encw {\r\n  display: flex;\r\n    flex:  8 1;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    align-content: center;\r\n    justify-content: center;\r\n    border-left: solid 1.5px;\r\n}\r\n\r\n\r\n\r\n\r\n.logout_divider__3fnqk {\r\n  width: 100%;\r\n  height: 0px;\r\n  margin-top: 15px;\r\n  margin-bottom: 15px;\r\n  border: 1px solid #ECEFF4; }\r\n\r\n", "",{"version":3,"sources":["webpack://src/pages/Logout/logout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;IACX,UAAQ;IACR,sBAAsB;IACtB,mBAAmB;IACnB,qBAAqB;IACrB,uBAAuB;IACvB,wBAAwB;AAC5B;;;;;AAKA;EACE,WAAW;EACX,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB,EAAE","sourcesContent":[".container_logout {\r\n  display: flex;\r\n    flex:  8;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    align-content: center;\r\n    justify-content: center;\r\n    border-left: solid 1.5px;\r\n}\r\n\r\n\r\n\r\n\r\n.divider {\r\n  width: 100%;\r\n  height: 0px;\r\n  margin-top: 15px;\r\n  margin-bottom: 15px;\r\n  border: 1px solid #ECEFF4; }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_logout": "logout_container_logout__1encw",
	"divider": "logout_divider__3fnqk"
};
export default ___CSS_LOADER_EXPORT___;
