import React, { Component, useState } from "react";
import Grid from '@material-ui/core/Grid';
import SpinnerModal from "@components/SpinnerModal";
import { CustomDialog, useDialog } from 'react-st-modal';
import styles from './editresearcherdialog.module.css';
import SupportAlert from '@components/SupportAlert';
import TextField from '@material-ui/core/TextField';
import FieldValidator from '@components/FieldValidator';
import Alert from '@mui/material/Alert';

class EditResearcherDialog extends Component {
    state = {
        loading: false,

        firstName: '',
        lastName: '',
        cellphone: '',
        workPhone: '',
        designation: '',

        validEmail: false,
        validFirstName: false,
        validLastName: false,
        validCellphone: true,
        validWorkPhone: true,

        selectedSurveysUser: null,
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        this.setState({ selectedSurveysUser: this.props.realmUser });
        this.setState({ validEmail: FieldValidator.validateDomainEmail(this.props.realmUser?.email, '@hsrc.ac.za') });
        this.setState({ firstName: this.props.realmUser?.firstName });
        this.setState({ validFirstName: FieldValidator.validateName(this.props.realmUser?.firstName) });
        this.setState({ lastName: this.props.realmUser?.lastName });
        this.setState({ validLastName: FieldValidator.validateName(this.props.realmUser?.lastName) });
        this.setState({ cellphone: this.props.realmUser?.attributes.cellphone[0] });
        this.setState({ workphone: this.props.realmUser?.attributes.workphone[0] });
        this.setState({ designation: this.props.realmUser?.attributes.designation[0] });

        this.setState({ loading: false });
    }

    handleChangedValue = (evt) => {
        switch (evt.target.name) {
            case 'firstName': {
                this.setState({ validFirstName: FieldValidator.validateName(evt.target.value) });
                this.setState({ firstName: evt.target.value });
                break;
            }
            case 'lastName': {
                this.setState({ validLastName: FieldValidator.validateName(evt.target.value) });
                this.setState({ lastName: evt.target.value });
                break;
            }
            case 'cellphone': {
                this.setState({ validCellphone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ cellphone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'workPhone': {
                this.setState({ validWorkPhone: FieldValidator.validatePhoneNumber(evt.target.value) });
                this.setState({ workPhone: evt.target.value.replace(/\D/g, '') });
                break;
            }
            case 'designation': {
                this.setState({ designation: evt.target.value });
                break;
            }
            default: {
                break;
            }
        }
    }

    getData() {

        let data = {
            userid: this.state.selectedSurveysUser?.userid,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: null,
            enabled: this.state.selectedSurveysUser?.enabled,
            email: this.state.selectedSurveysUser?.email,
            attributes: {
                group_id: ["RESEARCHER"],
                cellphone: [this.state.cellphone],
                workphone: [this.state.workPhone],
                designation: [this.state.designation],
            },
            realmRoles: ['dsp_researcher', 'dsp_respondent'],
            clientRoles: {
                role: ['researchers']
            },

        };

        return data;

    }

    dialogBody() {

        return <div className={styles.flex_row_container}>
            {(this.props.actionDelete !== undefined && this.props.actionDelete !== null && this.props.actionDelete === true)
                ? <div className={styles.featuredItem}>
                    <Alert variant="outlined" severity="warning">{'You are about to deregister the following researcher. If this is correct, please click Confirm below.'}</Alert>
                </div>
                : null
            }
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"RDSMS Researcher"}</text>
                </h1>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        disabled
                                        name={'surveysUser'}
                                        label={'RDSMS User'}
                                        id="outlined-basic"
                                        size="small"
                                        value={(this.state.selectedSurveysUser !== undefined && this.state.selectedSurveysUser !== null) ? (this.state.selectedSurveysUser.firstName + ' ' +this.state.selectedSurveysUser.lastName) : 'No researcher selected'}
                                        required
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
            </div>
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"Researcher Details"}</text>
                </h1>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        disabled={this.props.actionDelete === true}
                                        error={this.state.firstName > 0 && !this.state.validFirstName}
                                        helperText={!FieldValidator.validateName(this.state.firstName) ? 'Invalid First Name' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'firstName'}
                                        type="text"
                                        placeholder={'First Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'First Name'}
                                        required
                                        value={this.state.firstName}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >
                                    <TextField
                                        disabled={this.props.actionDelete === true}
                                        error={this.state.lastName > 0 && !this.state.validLastName}
                                        helperText={!FieldValidator.validateName(this.state.lastName) ? 'Invalid Last Name' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'lastName'}
                                        type="text"
                                        placeholder={'Last Name'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Last Name'}
                                        required
                                        value={this.state.lastName}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
                
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        disabled={this.props.actionDelete === true}
                                        error={this.state.cellphone.length > 0 && !this.state.validCellphone}
                                        helperText={!this.state.validCellphone ? 'Invalid cellphone number' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'cellphone'}
                                        type="text"
                                        placeholder={'072 - 309 - 4059'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Cellphone'}
                                        required={false}
                                        value={this.state.cellphone}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >
                                    <TextField
                                        disabled={this.props.actionDelete === true}
                                        error={this.state.workPhone.length > 0 && !this.state.validWorkPhone}
                                        helperText={!this.state.validWorkPhone ? 'Invalid work phone number' : null}
                                        className={styles.dialogInputs}
                                        require="required"
                                        name={'workPhone'}
                                        type="text"
                                        placeholder={'011 - 238 - 4859'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Work Telephone'}
                                        required={false}
                                        value={this.state.workPhone}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>
                <p>
                    <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                        <Grid container item xs={12}>
                            <Grid container spacing={3} direction="row" justify-content="space-between" wrap="nowrap">
                                <Grid container item xs={6}>
                                    <TextField
                                        disabled={this.props.actionDelete === true}
                                        className={styles.dialogInputs}
                                        name={'designation'}
                                        type="text"
                                        placeholder={'Designation'}
                                        id="outlined-basic"
                                        size="small"
                                        label={'Designation'}
                                        value={this.state.designation}
                                        onChange={this.handleChangedValue}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={6} >

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </p>


            </div>
            <div className={styles.featuredItem}>
                <h1 className={'text__16px__bold__blue'}>
                    <text className={'text__16px__bold__blue'}>{"Account Log in Details"}</text>
                </h1>
                <p>
                    <TextField
                        disabled
                        error={(this.state.selectedSurveysUser !== undefined && this.state.selectedSurveysUser !== null && !this.state.validEmail)}
                        helperText={(this.state.selectedSurveysUser !== undefined && this.state.selectedSurveysUser !== null && !this.state.validEmail) ? 'Invalid email address stored on RDSMS. Should be like ****@hsrc.ac.za' : null}
                        className={styles.dialogInputs}
                        require="required"
                        name={'emailAddress'}
                        type="text"
                        placeholder={'name@business.co.za'}
                        id="outlined-basic"
                        size="small"
                        label={'Email Address'}
                        required
                        value={this.state.selectedSurveysUser?.email}
                        onChange={this.handleChangedValue}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </p>
            </div>
        </div>
    }

    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} label={'Dialog loading ...'} />
        } else {
            return <DialogWrapper
                {...this.props}
                onClick={(evt) => this.props.nextButton.onClick(evt, this.getData())}
                validDetails={(this.props.actionDelete === true) || (this.state.validEmail && this.state.selectedSurveysUser !== null)}
                content={
                    <React.Fragment>
                        {this.dialogBody()}
                    </React.Fragment>
                }
            />
        }
    }

}
export default EditResearcherDialog;

function DialogWrapper(props) {
    const dialog = useDialog();

    return (
        <div>
            <br />
            <div>
                {props.content}
            </div>
            <div>
                {props.backButton !== undefined && props.backButton !== null
                    ? <button type="button" className="btnBack"
                        onClick={() => {
                            dialog.close(false);
                        }}
                    >
                        {props.backButton.text}
                    </button>
                    : <div />
                }
                {props.nextButton !== undefined && props.nextButton !== null
                    ? <button type="button" className="btnNext"
                        onClick={(evt) => {
                            if (!props.validDetails) {
                                SupportAlert.displayAlert('Updating user details failed', 'The RDSMS Reseacher, first name and last name are required fields. Please review supplied information carefully.');
                            } else {
                                props.onClick(evt);
                                dialog.close(true);
                            }
                        }}
                    >
                        {props.nextButton.text}
                    </button>
                    : <div />
                }
            </div>
        </div>
    );
}

