import React, { Component } from "react";



class SubSectionText extends Component {


    render() {
        return (
            <div style={{ marginBottom: '2%' }}>
                <h1 className={this.props.title_font}>{this.props.title}</h1>
                <text className={this.props.text_font}>
                    {this.props.text}
                </text>
            </div>
        );
    }

}
export default SubSectionText;