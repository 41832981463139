// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile_container_profile__1aCj8 {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 8 1;\r\n  align-items: flex-start;\r\n  align-content: flex-start;\r\n  justify-content: flex-start;\r\n  width: 75%;\r\n  border-left: solid 1.5px;\r\n}\r\n\r\n\r\n", "",{"version":3,"sources":["webpack://src/pages/ResearcherDashboard/pages/Profile/profile.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAO;EACP,uBAAuB;EACvB,yBAAyB;EACzB,2BAA2B;EAC3B,UAAU;EACV,wBAAwB;AAC1B","sourcesContent":[".container_profile {\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex: 8;\r\n  align-items: flex-start;\r\n  align-content: flex-start;\r\n  justify-content: flex-start;\r\n  width: 75%;\r\n  border-left: solid 1.5px;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_profile": "profile_container_profile__1aCj8"
};
export default ___CSS_LOADER_EXPORT___;
