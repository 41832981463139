import styles from "./sidebar.module.css";
import {
  Dashboard,
  Contacts,
  Assignment,
  AssignmentLateSharp,
  People,
  Person,
  ViewHeadlineSharp,
  PollSharp,
  TrackChanges,
  Business,
  HistorySharp,
  Telegram,
  CloudDownloadSharp} from "@material-ui/icons";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import CSVGenerator from "@components/CSVGenerator";
import { Divider } from "@mui/material";
import { rndLogoInstance } from '@components/Globals';



function parseJwt(token) {
  var base64Payload = token.split('.')[1];
  var payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
}
export default function Sidebar() {

  const [loading, setLoading] = useState(false);
  const [expandedFieldwork, setExpandedFieldwork] = useState(false);
  const [expandedUserManagement, setExpandedUserManagement] = useState(false);

  let bearer = localStorage.getItem('Bearer');
  if (!bearer){
    window.location = "/login";

  }
  let payload = parseJwt(bearer);

  let orgID = payload.org_id;
  let sector_id = payload.sector_id;

  let username = payload.name;

  

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarWrapper}>
      <div >
          <span className={styles.logo}>
            <img src={rndLogoInstance} className={styles.hsrc__logo} alt="hsrc logo" />
          </span>
        </div>
        <Divider/>
        <div className={styles.avatar}>
     <Person />
        
         <span>{username} </span>
       </div>

       <Divider/>

        <div className={styles.sidebarMenu}>
          <ul className="sidebarList">
            <Link to="/researcherdashboard" className="link">
              <li className={styles.sidebarListItem}>
                <Dashboard className={styles.sidebarIcon} />
                Dashboard Overview
              </li>
            </Link>

            <Link to="/despatch" className="link">
              <li className={styles.sidebarListItem}>
                <Telegram className={styles.sidebarIcon} />
                Record Despatch
              </li>
            </Link>

            <Link to="/datacapture" className="link">
              <li className={styles.sidebarListItem}>
                <Assignment className={styles.sidebarIcon} />
                Data Capture
              </li>
            </Link>

            <Link to="/dataupdate" className="link">
              <li className={styles.sidebarListItem}>
                <AssignmentLateSharp className={styles.sidebarIcon} />
                Data Update
              </li>
            </Link>

            <Link className="link" onClick={(evt) => setExpandedFieldwork(!expandedFieldwork)}>
              <li className={styles.sidebarListItem} >
                <ViewHeadlineSharp className={styles.sidebarIconParent} />
                Fieldwork Management
              </li>
            </Link>

            {expandedFieldwork === true
              ? <ul className="sidebarSubList">
                <Link to="/fieldworktracking" className="sublink">
                  <li className={styles.sidebarSubListItem}>
                    <TrackChanges className={styles.sidebarSubIcon} />
                    Fieldwork Tracking
                  </li>
                </Link>
                <Link to="/fieldworkreporting" className="sublink">
                  <li className={styles.sidebarSubListItem}>
                    <PollSharp className={styles.sidebarSubIcon} />
                    Fieldwork Reporting
                  </li>
                </Link>

                <Link className="sublink" onClick={evt => {
                  const func = (async () => {
                    setLoading(true);
                    await CSVGenerator.exportFieldworkReportToXLS(evt);
                    setLoading(false);
                  })([]);
                }}>
                  <li className={styles.sidebarSubListItem}>
                    <CloudDownloadSharp className={styles.sidebarSubIcon} />
                    {(loading == true) ?
                      'Working on it ...'
                      : 'Download Report'
                    }
                  </li>
                </Link>
              </ul>
              : null
            }

            <Link to="/previous-surveys" className="link">
              <li className={styles.sidebarListItem}>
                <HistorySharp className={styles.sidebarIcon} />
                Previous Surveys
              </li>
            </Link>

            <Link to="/institutions" className="link">
              <li className={styles.sidebarListItem}>
                <Business className={styles.sidebarIcon} />
                Institutions
              </li>
            </Link>

            <Link className="link" onClick={(evt) => setExpandedUserManagement(!expandedUserManagement)}>
              <li className={styles.sidebarListItem}>
                <Contacts className={styles.sidebarIconParent} />
                User Management
              </li>
            </Link>

            {expandedUserManagement === true
              ? <ul className="sidebarSubList">
                <Link to="/user/management/respondents" className="sublink">
                  <li className={styles.sidebarSubListItem}>
                    <People className={styles.sidebarSubIcon} />
                      Respondent Accounts
                  </li>
                </Link>

                <Link to="/user/management/researchers" className="sublink">
                  <li className={styles.sidebarSubListItem}>
                    <People className={styles.sidebarSubIcon} />
                    Researcher Accounts
                  </li>
                </Link>
              </ul>
              : null
            }

            <Link to="/profile/researcher/account/view" className="link">
              <li className={styles.sidebarListItem}>
                <Person className={styles.sidebarIcon} />
                My Profile
              </li>
            </Link>

            <Link to="/logout" className="link">
              <li className={styles.sidebarListItem}>
                <button class={styles.logout}>Logout</button>
              </li>
            </Link>

          </ul>
        </div>
      </div>
    </div>
  );
}